import { useState } from "react";
import ls from "local-storage";
import * as CONFIG from "../../Components/_config";
import { Button, Modal, Row, Col, Form,Spinner } from "react-bootstrap";
import axios from "axios";
import { toast } from "react-toastify";

function RedemptionModal({ show, handleClose }) {
  const [loading, setLoading] = useState(false);
  let wholesaler_number = ls.get("wholesalerId") ? ls.get("wholesalerId") : "";
  const downLoad = () => {
    var data = JSON.stringify({
      "redemption-start-date": startDate,
      "redemption-end-date": endDate
    });
    var config = {
      method: "post",
      url:
        CONFIG.API_URL +
        `/coupon/redemption-summary?type=wholesaler&id=${wholesaler_number}`,
      headers: CONFIG.config_head.headers,
      data: data
    };
    axios(config)
      .then((response) => {
        //console.log(response.data.Message);
        window.location.replace(response.data.Message);
        setLoading(false)
      })
      .catch(function (error) {
        if (error.response) {
          setLoading(false)
          if (error.response.status === 502) {
            toast.error("Bad Request!");
          } else if (error.response.status === 400) {
            toast.error(error.response.data.Message);
          } else if (error.response.status === 403) {
            toast.error(error.response.data.Message);
            ls.clear("token");
            ls.clear("brands");

            setTimeout(() => {
              window.location.href = "/login";
            }, 2400);
          } else {
            toast.error(error.response.data.Message);
          }
        }
      });
  };
  const changeDate1 = (e) => {
    const value = e.target.value;
    const global_start_date = new Date(value);
    setStartDate(global_start_date.toISOString());
    //console.log(global_start_date.toISOString());
  };
  const changeDate2 = (e) => {
    const value = e.target.value;
    const global_start_date = new Date(value);
    setEndDate(global_start_date.toISOString());
    console.log(global_start_date.toISOString());
  };
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title style={{ fontWeight: "500" }}>
            Download Redemption Summary
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "100%"
          }}
        >
          <Row>
            <Col xs={12} lg={6}>
              <Form.Group>
                <Form.Label style={{ fontSize: "12px", fontWeight: "500" }}>
                  Redemption Start Date
                </Form.Label>
                <Form.Control type="date" onChange={(e) => changeDate1(e)} />
              </Form.Group>
            </Col>
            <Col xs={12} lg={6}>
              <Form.Group>
                <Form.Label style={{ fontSize: "12px", fontWeight: "500" }}>
                  Redemption End Date
                </Form.Label>
                <Form.Control type="date" onChange={(e) => changeDate2(e)} />
              </Form.Group>
            </Col>
          </Row>
          <p
            style={{
              marginTop: "15px",
              color: "lightgray",
              fontWeight: "600",
              fontStyle: "italic",
              fontSize: "12px",
              marginBottom: "5px"
            }}
          >
            Note - Maximum Range Allowed is 75 Days
          </p>
          <Button
            style={{ marginTop: "10px", width: "150px", marginBottom: "15px" }}
            variant="primary"
            onClick={() => { downLoad();setLoading(true) }}
            disabled={loading}
          >

                {loading ? (
                  <span>
                    <Spinner animation="border" size="sm" /> Downloading...
                  </span>
                  ) : (
                        "Download"
                )}
          </Button>
        </Modal.Body>
        {/* <Modal.Footer> */}
        {/* <Button variant="secondary" onClick={handleClose}>
            Close
          </Button> */}
        {/* <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button> */}
        {/* </Modal.Footer> */}
      </Modal>
    </>
  );
}

export default RedemptionModal;
