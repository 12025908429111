import React from 'react';
import { Form } from 'react-bootstrap';
import ClipLoader from "react-spinners/ClipLoader";
import Blockbg from "../../Assets/images/blockbg.png";
import Select from 'react-select'
// const warhouses = [
//     { value: 'Fort Scott, KS', label: 'Fort Scott, KS' },
//     { value: 'Kansas City, KS', label: 'Kansas City, KS' },
//     { value: 'Memphis, MS', label: 'Memphis, MS' },
//     { value: 'Gulf Coast/Peari River, LA', label: 'Gulf Coast/Peari River, LA' },
//     { value: 'Kenosha, WI', label: 'Kenosha, WI' },
//     { value: 'Nashville, TN', label: 'Nashville, TN' },
//     { value: 'Norfolk, NE', label: 'Norfolk, NE' },
//     { value: 'Oklahoma City, OK', label: 'Oklahoma City, OK' },
//     { value: 'Springfield, MO', label: 'Springfield, MO' },
// ];


class SelectTargets extends React.Component {

    constructor(props) {
        super(props)
        this.state = { checkedListAll: [] }
    }

    componentDidUpdate(prevProps, nextProps) {
        if (prevProps !== this.props) {
            this.setState({
                checkedListAll: prevProps.checkedListAll,

            });
        }
    }


    render() {
        console.log('checkedListAll', this.state.checkedListAll)

        return (
            <div className="row  g-3">
                <div className=" col-12">
                    <div className="Common_Block">
                        <div className="Common_Block_Header d-flex align-items-center bg-white text-black justify-content-center">
                            <h5 className="text-black mr-10">
                                <input
                                    type="checkbox"
                                    className="mr-7"
                                    id="wholesalercheckbox"
                                    onChange={(e) =>
                                        this.props.selectWholesalerCheckbox(e)
                                    }
                                />
                                Wholesalers
                            </h5>
                            <h5 className="text-black">
                                <input
                                    type="checkbox"
                                    className="mr-7"
                                    id="retailercheckbox"
                                    onChange={(e) =>
                                        this.props.selectRetailerCheckbox(e)
                                    }
                                />
                                Retailers
                            </h5>
                            {/* <p className="ShowLink">Show Retailers</p> */}
                        </div>
                        <div className="Common_Block_Body pr-12 pl-12 pt-20 pb-20 Select_Target_Forms">
                            {this.props.wholeSalerLoader ? (
                                <div
                                    className="Clip_Loader"
                                    style={{ minHeight: "280px" }}
                                >
                                    <ClipLoader
                                        color={"rgb(0, 143, 251)"}
                                        loading={true}
                                        size={50}
                                    />
                                </div>
                            ) : this.props.WholeSalerlist &&
                                this.props.flag === 1 ? (
                                <>
                                    <div className="row  justify-content-center">
                                        <div className='col-xl-4 col-lg-6 col-12'>

                                            <label class="form-label">Select Wholesaler: </label>

                                            <select onChange={this.props.selectWholesaler} className="form-control ">
                                                <option value={0}>Select Please</option>
                                                {this.props.WholeSalerlist.map((val, idx) => {
                                                    return val.has_full_access === true ? (

                                                        <option data-logo={val.logo} data-wholesalername={val.wholesaler_name} value={val.wholesaler_guid}>{val.wholesaler_name}</option>
                                                    ) : (
                                                        ""
                                                    );
                                                })}
                                            </select>
                                        </div>


                                    </div>

                                </>

                            ) : (
                                ""
                            )}
                            {this.props.retailerLoader ? (
                                <div
                                    className="Clip_Loader"
                                    style={{ minHeight: "280px" }}
                                >
                                    <ClipLoader
                                        color={"rgb(0, 143, 251)"}
                                        loading={true}
                                        size={50}
                                    />
                                </div>
                            ) : this.props.retailer_array &&
                                this.props.flag === 2 ? (
                                <>
                                    <div className="Retailer_Block">
                                        <div className="header_right" style={{ float: 'right', marginTop: '-14px', color: '#000' }}>
                                            <input type="checkbox" onClick={this.props.selectAllRetailerCheckboxes.bind(this)} value="checkedall"
                                                style={{ marginRight: '5px', width: '18px', height: '18px' }} />
                                            {" "}
                                            Check / Uncheck All
                                        </div>
                                        <div className="Common_Block_Header d-flex align-items-center bg-white text-black justify-content-end"></div>
                                        {
                                            <ul className=" mt-15 d-flex  Retailer">
                                                {this.props.retailer_array.map(
                                                    (val, idx1) => (
                                                        <>
                                                            <li key={idx1}>
                                                                <div className="Retailer_Block">
                                                                    <input
                                                                        type="checkbox"
                                                                        name="retailername"
                                                                        value={val.wholesaler_id}
                                                                        checked={this.state.checkedListAll.some(el => el.deal_retailer_id === val.retailer_id)}
                                                                        onChange={(e) =>
                                                                            this.props.selectRetailer(
                                                                                e,
                                                                                val.retailer_name,
                                                                                val.retailer_id,
                                                                                val.wholesaler_id,
                                                                                val.logo
                                                                            )
                                                                        }
                                                                        style={{
                                                                            margin: "9px",
                                                                            zoom: "1.2",
                                                                            float: "right"
                                                                        }}
                                                                    />
                                                                    <div className="RetailerImage">
                                                                        <img
                                                                            src={val.logo ? val.logo : Blockbg}
                                                                            className="img-fluid "
                                                                            alt={val.retailer_name}
                                                                        />
                                                                    </div>
                                                                    <h5> {val.retailer_name}</h5>
                                                                </div>
                                                            </li>
                                                        </>
                                                    )
                                                )}
                                            </ul>
                                        }
                                    </div>
                                </>
                            ) : (
                                ""
                            )}

                            {

                                (this.props.flag === 1) && (this.props.showwarehouse === true) ?
                                    Object.keys(this.props.warehouseDetails).length > 0 ?

                                        <div className='row mt-15  justify-content-center' id="warehouseid">
                                            <div className='col-xl-4 col-lg-6 col-12'>
                                                <Form.Group controlId="exampleForm.ControlTextarea1">
                                                    <Form.Label>Select Warehouse:</Form.Label>
                                                    <Select isMulti options={Object.keys(this.props.warehouseDetails).length > 0 ? this.props.options : ''} onChange={this.props.handleWarehouse} className="react-select-container" />
                                                </Form.Group>
                                            </div>
                                        </div>



                                        // <div className='row mt-15  justify-content-center' id="warehouseid">

                                        //      <div className='col-xl-4 col-lg-6 col-12'>
                                        // <b>Select Warehouse : </b>
                                        //     <select multiple={true} onChange={(e)=> this.props.handleSelectwarehouse(e)} className="form-control col-md-6">

                                        //     {this.props.warehouseDetails.map((val, idx) => {
                                        //     return ( 
                                        //         <option data-wholesalerid={val.wholesaler_id} value={val.warehouse_id}>{val.warehouse_name}</option>
                                        //            );
                                        //     })}
                                        //     </select>
                                        //     </div>
                                        //     </div>
                                        : ''
                                    : ''
                            }


                            <div className="row mt-10">
                                <div className="col-12 d-flex justify-content-end">
                                    <div className="TableButton">
                                        <button
                                            type="button"
                                            className="mr-10 btn btn-primary"
                                            onClick={(e) => {
                                                this.props.changePrevTab(e, 2);
                                            }}
                                        >
                                            <i
                                                className="fas fa-arrow-left mr-10"
                                                aria-hidden="true"
                                            ></i>{" "}
                                            Prev
                                        </button>
                                        <button
                                            type="button"
                                            className="btn btn-primary"
                                            onClick={(e) => {
                                                this.props.changeThirdTab(e, 5);
                                            }}
                                        >
                                            Next{" "}
                                            <i
                                                className="fas fa-arrow-right ml-10"
                                                aria-hidden="true"
                                            ></i>{" "}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default SelectTargets