import React from "react";
//import Header from "../../Dashboard/Header";
//import sort from "../../Assets/images/sort.svg"; 
import deal from "../../../Assets/images/deals.png";
import edit_pencil from "../../../Assets/images/edit_pencil.png";
import expire from "../../../Assets/images/expire.png";
import analytics from "../../../Assets/images/analytics.png";

import { Link } from "react-router-dom";
//import { Button, Form, Pagination } from 'react-bootstrap';

import ls from "local-storage";
import $ from "jquery";

import * as CONFIG from "../../../Components/_config";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//import ReactPaginate from "react-paginate";
import { Redirect } from "react-router";
//import { Form } from "react-bootstrap";
import ClipLoader from "react-spinners/ClipLoader";
 
import DataTable from "react-data-table-component";

const Loader = () => <div id="cover-spin"></div>;


class CompanyDeal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      OverviewDetails: "",
      upcarray: [],
      UpcList: "",
      calling: false,
      ListOfCoupons: "",
      CouponList: "",
      offset: 0,
      perPage: 10,
      currentPage: 0,
      loading: false,
      couponLoader: true,
      columns: [
        {
          name: "DEAL TITLE",
          selector: (row) => row.deal_title,
          cell: row => (
            <>
                <a
                href="/"
                className="text-decoration-none"
                data-bs-toggle="modal"
                data-bs-target="#inactivemodal"
              >
                     {row.deal_title.replace(/\\'/g, "\'")}
                </a>
                </>),
          sortable: true
        },

        {
          name: "VALUE",
          selector: (row) => row.deal_amount
        },
        {
          name: "START",
          selector: (row) => this.handleDateSecond(new Date(row.deal_start_date.split("T")[0])),
          sortable: true
        },

        {
          name: "END",
          selector: (row) => this.handleDateSecond(new Date(row.deal_end_date.split("T")[0])),
          sortable: true
        },
        {
          name: "ORDER BY",
          selector: (row) => this.handleDateSecond(new Date(row.deal_order_by_date.split("T")[0])),
          sortable: true
        },

        {
          name: "ORDERS",
          selector: (row) => row.deal_order_count,
          cell: row => (
            <Link
              to={{
                pathname: "/",
                state: {
                  params: {
                    id1: row.deal_guid,
                  }
                }
              }}
            >
              {row.deal_order_count}
            </Link>
          )
        },
        {
          name: "ORDER QTY",
          selector: (row) => row.deal_order_quantity,
          sortable: true
        },
        {
          name: "WHOLESALER",
          selector: (row) => row.wholesaler_name,
          sortable: true
        },
        {
          name: "EDIT/EXPIRE",
          selector: (row) => row.category_name,
          cell: row => (
            <>
              <a
                href="/"
                className="text-decoration-none"
                data-bs-toggle="modal"
                data-bs-target="#inactivemodal"
              >
             
                <img src={edit_pencil} height={'23px'} width={'22px'} style={{ marginRight: '12px' }} alt={row.category_name} />
              </a>

              <a
                href="/"
                className="text-decoration-none"
                data-bs-toggle="modal"
                data-bs-target="#inactivemodal"
              >
                <img src={expire} height={'26px'} width={'25px'} alt={`expire`} />
              </a>

            </>
          )
        },

        {
          name: "ANALYSIS",
          selector: (row) => row.category_name,
          cell: row => (
            <a
              href="/"
              className="text-decoration-none"
              data-bs-toggle="modal"
              data-bs-target="#inactivemodal"
            >
              <img src={analytics} height={'26px'} width={'25px'} alt={`analytics`} />
            </a>

          )
        },

        // {
        //   name: "EDIT/EXPIRE",
        //   sortable: true,
        //   cell: row => (
        //     <Link className="ml-25" to="">
        //     <svg
        //       width="18"
        //       height="18"
        //       viewBox="0 0 18 18"
        //       fill="none"
        //       xmlns="http://www.w3.org/2000/svg"
        //     >
        //       <path
        //         d="M12.728 6.68596L11.314 5.27196L2 14.586V16H3.414L12.728 6.68596ZM14.142 5.27196L15.556 3.85796L14.142 2.44396L12.728 3.85796L14.142 5.27196ZM4.242 18H0V13.757L13.435 0.321961C13.6225 0.13449 13.8768 0.0291748 14.142 0.0291748C14.4072 0.0291748 14.6615 0.13449 14.849 0.321961L17.678 3.15096C17.8655 3.33849 17.9708 3.5928 17.9708 3.85796C17.9708 4.12313 17.8655 4.37743 17.678 4.56496L4.243 18H4.242Z"
        //         fill="current"
        //       />
        //     </svg>
        //   </Link>

        //   )
        // },

        {
          name: "STATUS",
          selector: (row) => row.deal_status,
          sortable: true,
          cell: row => (
            row.deal_status === 1 ?
              <button type="button" className="btn btn-outline-success">
                Active
              </button>
              :
              <button type="button" className="btn btn-outline-danger">
                Expired
              </button>

          )
        },

      ]


    };
    this.handlePageClick = this.handlePageClick.bind(this);
  }

  async componentDidMount() {

    this.receivedDeal();
  }

  componentDidUpdate(prevProps, nextProps) {
    if(prevProps !== this.props){

      this.receivedDeal();
    }
    
  }
  handleDateSecond(date) {
    var month = date.getMonth() + 1;
    var day = date.getDate();
    var year = date.getFullYear();

    if (month < 10) month = "0" + month.toString();
    if (day < 10) day = "0" + day.toString();

    let calculate_date = month + "-" + day + "-" + year;
    return calculate_date;
  }

  receivedDeal() {
    //var guid = ls.get("brands") ? ls.get("brands")[0].guid : "";
         this.setState({ tableData: this.props.dealData.slice(0,3), couponLoader: false });
       }

  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState(
      {
        currentPage: selectedPage,
        offset: offset
      },
      () => {
        this.receivedDeal();
      }
    );
  };

  // AlertModal = (e, coupon_id) => {
  //   e.preventDefault();

     
  //   $("#confirmModal").trigger("click");

  //   this.setState({ couponId: coupon_id });
  // };

  showLoader = () => {
    this.setState({ loading: true });
  }
  hideLoader = () => {

    this.setState({ loading: false });

  };

  expirecoupon(cid) {
    console.log(cid);
    //  console.log(e)
    var c_guid = cid;

    $("#staticBackdrop").css("display", "none");
    $(".modal-backdrop").first().css("display", "none");

    this.showLoader();


    var config = {
      method: "get",
      url: CONFIG.API_URL + `coupon/expire/${c_guid}`,
      headers: CONFIG.config_head.headers
    };

    axios(config)
      .then((response) => {
        if (response.status === 204) {

          this.hideLoader();
          toast.success("Expired Succesfully");
          setTimeout(() => window.location.reload(), 2500)

        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 502) {
            toast.error("Bad Request!");
          } else if (error.response.status === 400) {

            this.hideLoader();
            toast.error(error.response.data.Message);
          } else {

            this.hideLoader();
            toast.error(error.response.data.Message);
            console.log(error);
          }
        }
      });
  }
  render() {

    const isLogged = ls.get("token");
    const is_consent_provided = ls.get("decodetoken") ? ls.get("decodetoken").is_consent_provided : "";

    if (!isLogged || is_consent_provided === false) {

      return <Redirect to="/login" />;

    }

    return (
      <>
        {/* BEGIN: STATICBACKDROP MODAL */}

        {/* <button
          className="btn btn-success"
          data-bs-toggle="modal"
          data-bs-target="#staticBackdrop"
          id="confirmModal"
          style={{ display: "none" }}
        >
          Alert
        </button>

        <div
          className="modal fade Confirmation_Modal"
          id="staticBackdrop"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <i className="fas fa-exclamation-triangle text-center "></i>
                <h4 className="mt-20 fw-bold text-center">Are you sure ?</h4>
                <p className="mt-10 text-center">
                  You want to expire this selected coupon.
                </p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={(e) =>
                    this.expirecoupon(
                      this.state.couponId ? this.state.couponId : ""
                    )
                  }
                >
                  Yes
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </div> */}

        {/* END: STATICBACKDROP MODAL */}
        {/* BEGIN: EDIT MODAL */}
         
        {/* END: EDIT MODAL */}
        {/* BEGIN: VIEW MODAL */}

        {/* END: VIEW MODAL */}
        
        {this.state.loading ? <Loader /> : null}

        <section className="pb-24">
          {/* <div className="container-fluid"> */}
            
            <div className="row justify-content-center">
              <div className=" col-12">
                <div className="Coupons_Block mt-21">
                  <div className="Common_Block_Coupons_Header d-flex align-items-center justify-content-between Deals_Header">
                    <div className="d-flex align-items-center">
                      <img src={deal} className="img-fluid mr-7" alt="deal" />
                      <h5>DIGITAL DEALS</h5>
                    </div>
                    <div className="Common_Block_Coupons_Header_Right">
           


                      <div className="Sort">
                        {/* <svg width="20" height="17" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M16 0L20 5H17V17H15V5H12L16 0ZM11 15V17H0V15H11ZM11 8V10H0V8H11ZM9 1V3H0V1H9Z" fill="current"/>
                                    </svg> */}

                        {/* <span>Sort</span> */}
                      </div>
                      <div className="View" style={{borderLeft:'none'}}>
                      <Link to="/" onClick={ (event) => event.preventDefault() }>
              {" "}
              <span>View Deals</span>{" "}
            </Link>

                        {/* <span>View: Weekly</span>   */}
                      </div>
                    </div>
                  </div>

                  {this.state.couponLoader ? (
                    <div
                      className="Clip_Loader"
                      style={{ minHeight: "280px" }}
                    >
                      <ClipLoader
                        color={"rgb(0, 143, 251)"}
                        loading={true}
                        size={50}
                      />
                    </div>
                  ) :

                    <div className="table-responsive Coupons_Table">
                      <DataTable
                        columns={this.state.columns}
                        data={this.state.tableData}
                        pagination
                      />

                    </div>
                  }

                  <div className="d-flex align-items-center justify-content-end Pagination_Block ">
                  </div>
                </div>
              </div>
            </div>

          {/* </div> */}
        </section>
       </>
    );
  }
}
export default CompanyDeal;
