import React from "react";
import ClipLoader from "react-spinners/ClipLoader";
import Retailer from "../../../Assets/images/retailer.svg";
import Stores from "../../../Assets/images/stores.svg";
import Sold from "../../../Assets/images/sold.svg";
import Upcs from "../../../Assets/images/upcs.svg";
import wholesalers from "../../../Assets/images/wholesalers.svg";
import { Link } from "react-router-dom";

class Overview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      OverviewDetails: [],
      UpcList: [],
      loader: false,
      wholesalerArray: [],
      retailerarr: [],
      // subscribeStore: [],
      feedresult: [],
      company_upc_array: []
    };
  }

  async componentDidMount() {}

  render() {
    var ts = 0;
    for (var i = 0; i < this.props.feedresult.length; i++) {
      ts =
        ts +
        parseFloat(
          this.props.feedresult[i].TotalSales.replace(/,/g, "").substring(1)
        );
    }

    return this.props.loader ? (
      <div className="Clip_Loader" style={{ minHeight: "280px" }}>
        <ClipLoader color={"rgb(0, 143, 251)"} loading={true} size={50} />
      </div>
    ) : this.props.OverviewDetails ? (
      <ul className="OverviewList">
        <li className="newOverview">
          <div className="Icon">
            <img src={Retailer} className="img-fluid" alt="Retailer" />
          </div>
          <div className="OverviewList_Info">
            <p>Retailers</p>
            <span>
              {this.props.OverviewDetails
                ? this.props.OverviewDetails.Retailers
                : ""}
            </span>
          </div>
        </li>
        <li className="newOverview">
          <div className="Icon">
            <img src={Upcs} className="img-fluid" alt="Retailer" />
          </div>
          <div className="OverviewList_Info">
            <p>UPCs</p>
            <span>
              <Link to="upclist">
                {this.props.OverviewDetails
                  ? this.props.OverviewDetails.UPCs
                  : ""}{" "}
              </Link>
            </span>
          </div>
        </li>
        <li className="newOverview">
          <div className="Icon">
            <img src={Sold} className="img-fluid" alt="Retailer" />
          </div>
          <div className="OverviewList_Info">
            <p>Units Sold</p>

            <span>
              {this.props.feedresult
                ?.map((i) => i.UnitsSold)
                ?.reduce((a, b) => a + b, 0)
                .toLocaleString()}
            </span>
          </div>
        </li>
        <li className="newOverview">
          <div className="Icon">
            <img src={wholesalers} className="img-fluid" alt="Retailer" />
          </div>
          <div className="OverviewList_Info">
            <p>Wholesalers</p>
            <span>
              {this.props.OverviewDetails
                ? this.props.OverviewDetails.Wholesalers
                : ""}
            </span>
          </div>
        </li>
        <li className="newOverview">
          <div className="Icon">
            <img src={Stores} className="img-fluid" alt="Retailer" />
          </div>
          <div className="OverviewList_Info">
            <p>Stores</p>
            <span>
              {this.props.OverviewDetails
                ? this.props.OverviewDetails.Stores
                : ""}
            </span>
            <p style={{ fontSize: "11px" }}>Selling UPCs</p>
          </div>
        </li>

        <li className="newOverview">
          <div className="Icon">
            <img src={Stores} className="img-fluid" alt="Retailer" />
          </div>
          <div className="OverviewList_Info">
            <p> Stores</p>
            <span>
              {this.props.subscribeStore
                ? this.props.subscribeStore.SubscribedStores
                : ""}
            </span>
            <p style={{ fontSize: "11px" }}>Subscribed</p>
          </div>
        </li>
      </ul>
    ) : (
      ""
    );
  }
}

export default Overview;
