import React from 'react'
import Header from '../Dashboard/Header'
import * as CONFIG from "../../Components/_config";
import axios from "axios";
import { toast } from "react-toastify";
import ls from "local-storage";
import $ from "jquery";
import SubscribeRWModal from "../../Components/Common/SubscribeRWModal";
import { Redirect } from "react-router";
import FooterStrict from '../Dashboard/FooterStrict';
import { getCompanyWholesaler } from "../../API/Profile";



const Loader = () => <div id="cover-spin"></div>;

class AddUser extends React.Component {
  constructor(props) {
    super(props);
    toast.configure();

    this.state = {
      userinfo: "",
      modalclick: false,
      hideButton: false,
      brand_job_title: '',
      role_guid: '',
      formControls: {
      }
    }
  }

  async componentDidMount() {
    // const guid = ls.get("selectedbrand") ? ls.get("selectedbrand")[0].guid : "";
    const AllBrands = ls.get("brands") ? ls.get("brands") : "";

    const token_config = {
      headers: CONFIG.config_head.headers
    };
    const requestOne = axios.get(
      CONFIG.API_URL + `master?type=role&search=''`,
      token_config
    );
    axios
      .all([requestOne])
      .then(
        axios.spread((...responses) => {
          let responseOne = responses[0];

          this.setState({
            masterRoles: responseOne.data.Message,
            AllBrands: AllBrands
          },
          );
        })
      )
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 400) {
            toast.error(error.response.data.Message);
          }
          else if (error.response.status === 403) {
            toast.error(error.response.data.Message);
            ls.clear("token");
            ls.clear("brands");
            window.location.href = '/login'
          } else {
            toast.error(error.response.data.Message);
          }
        }
      });


    getCompanyWholesaler().then((response) => {
      this.setState({
        companyWholesaler: response.Message
      });
    });
  }

  changeHandler = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    this.setState({
      formControls: {
        ...this.state.formControls,
        [name]: value
      }
    });
  };

  rolechangeHandler = (e) => {
    var testEmail = /^[A-Z0-9._%+-]+@([A-Z0-9-]+\.)+[A-Z]{2,4}$/i;

    if ($("#first_name_user").val().length <= 0) {
      toast.error("First Name can't be blank", { theme: "colored" });
      return false;
    }
    else if ($("#last_name_user").val().length <= 0) {
      toast.error("Last Name can't be blank", { theme: "colored" });
      return false;
    }
    else if ($("#useremail").val().length <= 0) {
      toast.error("Please provide a properly formatted email address", { theme: "colored" });

      return false;
    }
    else if (!testEmail.test($("#useremail").val())) {

      toast.error("Please provide a properly formatted email address", { theme: "colored" });
      return false;
    }

    else if ($("#Phone").val().trim().length <= 0) {
      toast.error("Phone No can't be blank", { theme: "colored" });
      return false;
    }
    else if ($("#Phone").val().trim().length < 10) {
      toast.error("Phone should be length 10", { theme: "colored" });
      return false;
    }
    else if ($("#Phone").val().trim().length > 10) {
      toast.error("Phone No must be with a maximum length of 10", { theme: "colored" });
      return false;
    }
    else if ($("#role option:selected").val() === 'Select Please') {
      toast.error("Role can't be blank", { theme: "colored" });
      return false;
    }
    else {

      var text = $("#role option:selected").text();
      var selectValue = e.target.value;

      if (selectValue === '51371542-2364-4110-AEAB-2C4A03A2F2A7' || selectValue === 'EABD1833-BF0C-4700-8F5E-EA35D57AA6E2'
        || selectValue === 'C0D54360-2B27-4992-AB4F-310DD39561A8' || selectValue === '17D34712-8285-4EF9-8EE7-E1671FE8D538') {

        this.setState({
          formcontroldetails: this.state.formControls,
          modalclick: true,
          hideButton: true,
          role_guid: selectValue,
          brand_job_title: text
        })
      }
      else {
        this.setState({ hideButton: false, role_guid: selectValue, brand_job_title: text })
      }
    }
  }

  brandchangeHandler = (e) => {
    this.setState({ selectedBrandguid: e.target.value })
  }
  SaveUser = async (e) => {

    var role_guid = e.target.getAttribute('data-roleguid');
    var brand_job_title = e.target.getAttribute('data-brandjobtitle');
    var testEmail = /^[A-Z0-9._%+-]+@([A-Z0-9-]+\.)+[A-Z]{2,4}$/i;

    e.preventDefault();

    if ($("#first_name_user").val().length <= 0) {
      toast.error("First Name can't be blank", { theme: "colored" });
      return false;
    }
    else if ($("#last_name_user").val().length <= 0) {
      toast.error("Last Name can't be blank", { theme: "colored" });
      return false;
    }
    else if ($("#useremail").val().length <= 0) {
      toast.error("Please provide a properly formatted email address", { theme: "colored" });

      return false;
    }
    else if (!testEmail.test($("#useremail").val())) {

      toast.error("Please provide a properly formatted email address", { theme: "colored" });
      return false;
    }

    else if ($("#Phone").val().trim().length <= 0) {
      toast.error("Phone No can't be blank", { theme: "colored" });
      return false;
    }
    else if ($("#Phone").val().trim().length < 10) {
      toast.error("Phone No should be length 10", { theme: "colored" });
      return false;
    }
    else if ($("#Phone").val().trim().length > 10) {
      toast.error("Phone No must be with a maximum length of 10", { theme: "colored" });
      return false;
    }
    else if ($("#role option:selected").val() === 'Select Please') {
      toast.error("Role can't be blank", { theme: "colored" });
      return false;
    }
    else {
      $("#exampleModal").css("display", "none");
      $(".modal-backdrop").first().css("display", "none");

      this.showLoader();

      var data = JSON.stringify({
        first_name: this.state.formControls.first_name,
        last_name: this.state.formControls.last_name,
        email: this.state.formControls.useremail,
        primary_phone: this.state.formControls.Phone,
        secondary_phone: "",
        brand: this.state.selectedBrandguid,
        role: role_guid,
        brand_job_title: brand_job_title

      });
      var config = {
        method: "post",
        url: CONFIG.API_URL + "user",
        headers: CONFIG.config_head.headers,
        data: data
      };

      axios(config)
        .then((res) => {
          if (res.status === 201) {

            toast.success('User Added Successfully')
            this.hideLoader();
            setTimeout(() => window.location.reload(),
              3000
            );

          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 400) {

              this.hideLoader();
              toast.error(error.response.data.Message);

              // setTimeout(()=>{window.location.reload()},3500)


            }
            else {
              this.hideLoader();
              toast.error(error.response.data.Message);
              // setTimeout(()=>{window.location.reload()},3500)

            }

          }
        });
    }
  }
  showLoader = () => {
    this.setState({ loading: true });
  }
  hideLoader = () => {
    this.setState({ loading: false });
  };


  componentDidUpdate() {
    if (this.state.modalclick === true) {
      $("#subscribemodal").trigger("click");
      this.setState({ modalclick: false })
    }
  }

  render() {
    const isLogged = ls.get("token");
    const is_consent_provided = ls.get("decodetoken") ? ls.get("decodetoken").is_consent_provided : "";
    const selectedbrand = ls.get("selectedbrand") ? ls.get("selectedbrand") : "";


    if (!isLogged || is_consent_provided === false) {
      return <Redirect to="/login" />;
    }

    return (
      <>
        <Header location={`addUser`} />
        {this.state.loading ? <Loader /> : null}

        <div className='ContentBlock  pt-40 pb-24 Main_Block'>
          <div className='container'>

            <div className='Add_User_Form_Block row g-3 '>
              <h5 style={{ float: 'right' }}>

                {(!!selectedbrand && selectedbrand.length > 0) ? (

                  <a href={`/profile/${this.props.location.state.params.id5}`} style={{ float: 'right' }}> BACK TO PROFILE </a>
                ) :

                  <a href={`/companyprofile/${this.props.location.state.params.id5}`} style={{ float: 'right' }}> BACK TO PROFILE </a>
                }


              </h5>
              <div className="col-lg-6 col-12">
                <label
                  htmlFor="inputEmail4"
                  className="form-label"
                >
                  First Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="First Name"
                  name="first_name"
                  id="first_name_user"
                  onChange={this.changeHandler}
                />

              </div>
              <div className="col-lg-6 col-12">
                <label
                  htmlFor="inputEmail4"
                  className="form-label "
                >
                  Last Name
                </label>
                <input
                  type="text"
                  className="form-control profile-input-control"
                  placeholder="Last Name"
                  name="last_name"
                  id="last_name_user"
                  onChange={this.changeHandler}

                />
              </div>
              <div className="col-lg-6 col-12">
                <label
                  htmlFor="inputEmail4"
                  className="form-label "
                >
                  Email
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Email"
                  name="useremail"
                  id="useremail"
                  onChange={this.changeHandler}
                />

              </div>
              <div className="col-lg-6 col-12">
                <label
                  htmlFor="inputEmail4"
                  className="form-label "
                >
                  Phone Number
                </label>
                <input
                  type="text"
                  className="form-control "
                  placeholder="Phone Number"
                  name="Phone"
                  id="Phone"
                  onChange={this.changeHandler}

                />

              </div>


              {/* <div className="col-lg-6 col-12">
                <label
                  htmlFor="inputEmail4"
                  className="form-label "
                >
                  Select Brand
                </label>
                <select className="form-select" aria-label="Default select example" id="role" name="role" onChange={this.brandchangeHandler}>
                  <option defaultValue={0}>Select Please</option>

                  {this.state.AllBrands ?
                    <>
                      {this.state.AllBrands.map((val, idx) => {

                        return val.role_name !== 'rsa admin' ? (
                          <>
                            <option value={val.guid} key={idx}>{val.name}</option>
                          </>
                        )
                          : ''
                      }
                      )}
                    </>
                    : ''
                  }
                </select>
              </div> */}

              <div className="col-lg-6 col-12">
                <label
                  htmlFor="inputEmail4"
                  className="form-label "
                >
                  Role
                </label>
                <select className="form-select" aria-label="Default select example" id="role" name="role" onChange={this.rolechangeHandler}>
                  <option defaultValue={0}>Select Please</option>

                  {this.state.masterRoles ?
                    <>
                      {this.state.masterRoles.map((val, idx) => {

                        return val.role_name !== 'rsa admin' && val.role_name !== 'global sales director'  && val.role_name !== 'wholesaler' && val.role_name!=='brand admin'? (
                          <>
                            <option value={val.brandiq_user_role_guid} key={idx}>{val.role_name}</option>
                          </>
                        )
                          : ''
                      }
                      )}
                    </>
                    : ''
                  }
                </select>
              </div>

              <div className='col-12'>
                {this.state.hideButton ? ''
                  :
                  <button type="submit" className="btn Btn_blue w-fit-content px-5 py-2 rounded-2"
                    data-roleguid={this.state.role_guid ? this.state.role_guid : ''} data-brandjobtitle={this.state.brand_job_title ? this.state.brand_job_title : ''} onClick={(e) => {
                      this.SaveUser(e);
                    }}>Save</button>
                }
              </div>
            </div>
          </div>
          {
            this.state.formcontroldetails && this.state.companyWholesaler && (
              <>
                <button className="Digital_Brand_Button approve"
                  data-bs-toggle="modal"
                  id="subscribemodal"
                  data-bs-target="#subscribe-sel-drop"
                  style={{
                    display: "none",
                  }}
                >
                  Add
                </button>

                <SubscribeRWModal
                  allRetailer={this.props.location.state.params.id3}
                  brandRetailer={this.props.location.state.params.id4}
                  brandWholesaler={this.props.location.state.params.id1}
                  allWholesaler={this.props.location.state.params.id2}
                  formControl={this.state.formcontroldetails}
                  role_guid={this.state.role_guid}
                  brand_job_title={this.state.brand_job_title}
                  companyWholesaler={this.state.companyWholesaler}
                />
              </>
            )}
        </div>
        <FooterStrict />
      </>
    )
  }
}

export default AddUser;
