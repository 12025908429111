import React from "react";
import $ from "jquery";
import axios from "axios";
import * as CONFIG from "../../Components/_config";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ItemWholesaler from './AddWholesaler/ItemWholesaler';
import ls from "local-storage";

const Loader = () => <div id="cover-spin"></div>;


class AddWholesalerModal extends React.Component {
  constructor(props) {
    super(props);
    toast.configure();

    this.state = {
      wholesalerdata: this.props.wholesalerData,
      wholesaler_number: [],
      brand_guid: this.props.brandguidata
    };

    this.selectAllCheckboxes = this.selectAllCheckboxes.bind(this);
    this.selectWholesaler = this.selectWholesaler.bind(this);
  }

  selectWholesaler(e) {

    const { value } = e.target;

    if (e.target.checked) {

      let wholesaler_number_arr = [
        ...this.state.wholesaler_number,
        parseInt(value)
      ];
      this.setState({ wholesaler_number: wholesaler_number_arr });
    }

    else {
      let removeIndex = this.state.wholesaler_number.findIndex(
        (itm) => itm === parseInt(value)
      );
      if (removeIndex > -1) {
        this.state.wholesaler_number.splice(removeIndex, 1); // 2nd parameter means remove one item only
      }
    }

  }


  selectAllCheckboxes(e) {

    const { checked } = e.target;
    const wholesalerIdarr = [];

    if (checked) {
      this.props.wholesalerData.map((val) => {
        return (
          wholesalerIdarr.push(val.wholesaler_id)
        )
      });
    }

    this.setState({
      wholesaler_number: wholesalerIdarr,
      ItemsChecked: checked,
    });

  };

  componentDidMount() {
    // console.log('>>>>wlist',this.props.WholeSalerlist)
    $("#selectwhole-sel-drop").on("click", function (e) {
      if ($(e.target).parent().attr("data-dismiss")) {
      }
    });
  }

 
  showLoader = () => {
    this.setState({ loading: true });
  }
  hideLoader = () => {
    this.setState({ loading: false });
  };

  saveWholesaler() {

    this.showLoader();
    var data = JSON.stringify({
      wholesalers: this.state.wholesaler_number,
      contact: {},
      upc: [],
      retailers: []
    });

    var b_guid = this.state.brand_guid;
    var config = {
      method: "patch",
      url: CONFIG.API_URL + `brand/status/${b_guid}`,
      headers: CONFIG.config_head.headers,
      data: data
    };
    axios(config)
      .then((response) => {
        if (response.status === 200) {

          toast.success("Wholesaler added Successfully.");
          this.hideLoader();
          setTimeout(() => { window.location.reload() }, 3000);
        }
      })
      .catch( (error)=> {
          if (error.response) {
         
        if (error.response.status === 403) {
            this.hideLoader();
            toast.error(error.response.data.Message);
            ls.clear('brands');
            ls.clear('token');
            ls.clear('selectbrand');
            window.location.href = '/login';
          }
          else {
            this.hideLoader();
            toast.error(error.response.data.Message);
            setTimeout(
              () => window.location.reload(), 2500
            );
          }
        }
      });
  }

  render() {

    return (
      <>
       {this.state.loading ? <Loader /> : null}

        <div
          className="modal fade"
          id="selectwholesaler-sel-drop"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-custom modal-dialog-scrollable">
            <div className="modal-content whole-content">
              <div className="modal-header borderemove brand-whole-hd">
                <h5 className="modal-title" id="exampleModalLabel">
                  Select Wholesalers
                </h5>

                <div className="header_right" style={{ float: 'right', marginTop: '-2px', marginRight: '80px', color: '#fff' }}>
                  <input type="checkbox" onClick={this.selectAllCheckboxes.bind(this)} value="checkedall" />{" "}Check / Uncheck All
                </div>

                <button
                  type="button"
                  className="btn-close wholeseler-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>

              <div className="modal-body CustomScroll whole-seler-body">
                <div className="tab-content" id="nav-tabContent">
                  <div
                    className="tab-pane fade show active tab-text"
                    id="nav-home"
                    role="tabpanel"
                    aria-labelledby="nav-home-tab"
                  >
                    <div className="Common_brand_detail">
                      {Object.keys(this.state.wholesalerdata).length > 0 ? (
                        <div className="pr-12 pl-12 pt-20">

                          {/*<div className="Search">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Retailer Name"
                />
    
                <div className="searchImg">
                  <img
                    src={Search}
                    className="img-fluid "
                    alt="search"
                  />
                </div>

              </div> */}

                          <ul className=" mt-15 d-flex  Retailer">
                            {this.state.wholesalerdata.map((val, idx) => (

                              <ItemWholesaler
                                items={val}
                                key={val.id}
                                selectedItems={this.selectAllCheckboxes.bind(this)}
                                ItemsChecked={this.state.ItemsChecked}
                                checkedListAll={this.state.wholesaler_number}
                                handleCheckboxClick={this.selectWholesaler}
                                wholesalerIdarr={this.state.wholesalerIdarr} />


                            ))}
                          </ul>
                        </div>
                      ) : (
                        "No Wholesaler available to choose"
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer brand-whole-footer">
                <button
                  type="button"
                  className="btn btn-primary Btn_blue_modal"
                  onClick={() => this.saveWholesaler()}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default AddWholesalerModal;
