import scissors from "../../Assets/images/scissors.png";
const CouponPreview = ({ selectedFile, title, description }) => {

    return (
        <div className="Common_Block mt-21">

            <div className="Common_Block_Header d-flex align-items-center justify-content-between">
                <h5 className="">Coupon preview</h5>
            </div>
            <div className="Common_Block_Body pt-12 pr-12 pb-12 pl-12">
                <div className="Coupons_Card text-center">
                    <img
                        src={scissors}
                        className="img-fluid scissors"
                        alt="Products"
                    />
                    <img
                        src={
                            selectedFile
                        }
                        className="img-fluid"
                        alt=""
                        id="img-id"
                    />
                    <h2 className="Price">
                        {title}
                        <span></span>
                    </h2>
                    <p>{description}</p>
                </div>
            </div>
        </div>
    )
}

export default CouponPreview;