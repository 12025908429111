import Blockbg from "../../../Assets/images/blockbg.png";
import ls from "local-storage";


const AssignBrandModal = ({ remainingWholesaler, remainingRetailer, brandChange, selectWholesaler, selectRetailer, saveBrand }) => {

  const AllBrands = ls.get("brands") ? ls.get("brands") : "";


  return (
    <>
      <div
        className="modal fade"
        id="assign-brand-modal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-custom modal-dialog-scrollable">
          <div className="modal-content whole-content">
            <div className="modal-header borderemove brand-whole-hd">
              <h5 className="modal-title" id="exampleModalLabel">
                Assign Brand
              </h5>
              <button
                type="button"
                className="btn-close wholeseler-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>

            <div className="modal-body CustomScroll whole-seler-body">
              <div className="tab-content" id="nav-tabContent">
                <div
                  className="tab-pane fade show active tab-text"
                  id="nav-home"
                  role="tabpanel"
                  aria-labelledby="nav-home-tab"
                >
                  <div className="Common_brand_detail">
                    <div className="pr-12 pl-12 pt-20">

                      <div className="selectBrand" style={{ display: 'inline-flex', padding: '20px 60px' }}>

                        <h5 style={{ padding: '9px 24px' }}>Select Brand: </h5>

                        <select className="my_class" onChange={brandChange} style={{ padding: '10px 30px' }}>
                          <option value="" disabled selected>Select Brand</option>
                          {AllBrands ?
                            <>
                              {AllBrands.map((val, idx) => {
                                return val.role_name !== 'rsa admin' ? (
                                  <>
                                    <option value={val.guid} key={idx}>{val.name}</option>
                                  </>
                                )
                                  : ''
                              }
                              )}
                            </>
                            : ''
                          }

                          {/* <option value={`wizard`} >Digital Coupon</option> */}

                        </select>

                      </div>

                      <div className="profile-box">
                        <div className="Card ">
                          <div className="Card_Header  Steps_Header">
                            <h5>Select Wholesaler</h5>
                          </div>
                          <div className="Common_brand_detail">
                            {Object.keys(remainingWholesaler).length > 0 ? (
                              <div className="pr-12 pl-12 pt-20">
                                <ul className=" mt-15 d-flex  Retailer">
                                  {remainingWholesaler.map((val, idx) => { 
                                   // console.log('val',val)
                                   return val.has_full_access === true ? (
                                       
                                    <li key={idx}>
                                      <div className="Retailer_Block">
                                        <input
                                          type="checkbox"
                                          onChange={(e) =>
                                            selectWholesaler(e,
                                              val
                                            )
                                          }
                                        />
                                        <div className="RetailerImage">
                                          <img
                                            src={val.logo ? val.logo : Blockbg}
                                            className="img-fluid "
                                            alt="Retailer"
                                          />
                                        </div>
                                        <h5 style={{ color: '#000' }}>{val.wholesaler_name}</h5>
                                      </div>
                                    </li>
                                  ): (
                                    ""
                                )
                                  })}
                                </ul>
                              </div>
                            ) : (
                              "No Wholesaler available to choose"
                            )}
                          </div>

                        </div>


                      </div>


                      <div className="profile-box">
                        <div className="Card ">
                          <div className="Card_Header  Steps_Header">
                            <h5>Select Retailers</h5>
                          </div>
                          <div className="Common_brand_detail">
                            {Object.keys(remainingRetailer).length > 0 ? (
                              <div className="pr-12 pl-12 pt-20">
                                <ul className=" mt-15 d-flex  Retailer">
                                  {remainingRetailer.map((val, idx) => (
                                    <li key={idx}>
                                      <div className="Retailer_Block">
                                        <input
                                          type="checkbox"
                                          onChange={(e) =>
                                            selectRetailer(
                                              e,
                                              val,
                                              
                                            )
                                          }
                                        />
                                        <div className="RetailerImage">
                                          <img
                                            src={val.logo ? val.logo : Blockbg}
                                            className="img-fluid "
                                            alt="Retailer"
                                          />
                                        </div>
                                        <h5 style={{ color: '#000' }}>{val.retailer_name}</h5>
                                      </div>
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            ) : (
                              "No Wholesaler available to choose"
                            )}
                          </div>

                        </div>


                      </div>


                    </div>
                  </div>
                  <div className="modal-footer brand-whole-footer">
                    <button
                      type="button"
                      className="btn btn-primary Btn_blue_modal"
                      onClick={saveBrand}
                    >
                      Save
                    </button>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default AssignBrandModal;