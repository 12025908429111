import React, { useEffect, useState } from "react";
import Header from "../Dashboard/Header";
import { getCompanyBrandStore } from "../../API/Company";

import { getUser, getCompanyUser, getCompanyWholesaler, RemoveUser } from "../../API/Profile"

import AccountDetails from './AccountDetails';
import ChangePassword from './ChangePassword';
import ClipLoader from "react-spinners/ClipLoader";
import { useParams } from "react-router-dom";
import Users from "./Users";
import MySubscriptions from "./MySubscriptions";
import $ from "jquery";
import ConfirmationRemoveUser from "../../Components/Common/ConfirmationModal/ConfirmationRemoveUser";
import { toast } from "react-toastify";

const CompanyProfile = () => {

  const { user_guid } = useParams();
  const [users, SetUser] = useState('');
  const [companyusers, SetCompanyusers] = useState([]);
  const [companywholesalers, SetCompanywholesalers] = useState([]);
  const [subscribeStore, SetSubscribeStore] = useState([]);

  const [accountLoader, setAccountLoader] = useState(false);
  const [userguid, setUserguid] = useState('');
  const [loading, setLoading] = useState(false);

  // const [companyData, setCompanyData] = useState([]);
  // useEffect(() => {
  //   getCompany().then(res => {
  //     setCompanyData(res.Message);
  //     //setAccountLoader(!accountLoader);
  //   })
  // }, [])

  useEffect(() => {
    getUser(user_guid).then(res => {
      SetUser(res.Message);
      //setAccountLoader(!accountLoader);
    })
  }, [user_guid])// eslint-disable-line

  useEffect(() => {
    getCompanyUser().then(res => {
      SetCompanyusers(res.Message);
      //setAccountLoader(!accountLoader);
    })
  }, [])// eslint-disable-line

  useEffect(() => {
    getCompanyWholesaler().then((response) => {
      SetCompanywholesalers(response.Message)

    });
  }, [])// eslint-disable-line

  useEffect(() => {

    getCompanyBrandStore().then((response) => {
      SetSubscribeStore(response.Message)
    });
  }, [])// eslint-disable-line

  const AlertModal = (e) => {
    var remove_user_guid = e.target.getAttribute("data-userguid");
    $("#confirmuserModal").trigger("click");
    setUserguid(remove_user_guid);
  }

  const RemoveUserCompany = (userguid) => {

    $("#staticBackdrop").css("display", "none");
    $(".modal-backdrop").first().css("display", "none");
    showLoader();
    RemoveUser(userguid).then((response) => {
      hideLoader();
      if (response.status === 204) {
        toast.success('User Removed Successfully');
        setTimeout(() => { window.location.reload() }, 2500);
      }
      else {
        toast.error('Oops something went wrong!, Please try again later.');
        setTimeout(() => { window.location.reload() }, 2500);
      }
    })
  };

  const showLoader = () => {
    setLoading(true);
  }
  const hideLoader = () => {
    setLoading(false);
  };



  const updateUser = () => {
    return (
      <></>
    );
  }

  const Loader = () => <div id="cover-spin"></div>;

  return (
    <>
      <Header location={`companyProfile`} />
      {loading ? <Loader /> : null}

      <section className="ContentBlock pt-24 pb-24 Main_Block">
        <div className="container-fluid ">
          <div className="row justify-content-center ">
            <div className="col-xxl-2 col-lg-3 col-12">
              <div
                className="nav flex-column nav-pills"
                id="v-pills-tab"
                role="tablist"
                aria-orientation="vertical"
              >
                <button
                  className="nav-link profile-tab active"
                  id="v-pills-home-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#v-pills-home"
                  type="button"
                  role="tab"
                  aria-controls="v-pills-home"
                  aria-selected="true"
                >
                  Account Details
                </button>
                <button
                  className="nav-link profile-tab"
                  id="v-pills-settings-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#v-pills-settings"
                  type="button"
                  role="tab"
                  aria-controls="v-pills-settings"
                  aria-selected="false"
                >
                  Change Password
                </button>
                <button
                  className="nav-link profile-tab"
                  id="v-pills-susbscription-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#v-pills-susbscription"
                  type="button"
                  role="tab"
                  aria-controls="v-pills-susbscription"
                  aria-selected="false"
                >
                  My Subscriptions
                </button>
                <button
                  className="nav-link profile-tab"
                  id="v-pills-user-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#v-pills-user"
                  type="button"
                  role="tab"
                  aria-controls="v-pills-user"
                  aria-selected="false"
                >
                  Users
                </button>

                {/* <button
                  className="nav-link profile-tab"
                  id="v-pills-invoices-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#v-pills-invoices"
                  type="button"
                  role="tab"
                  aria-controls="v-pills-invoices"
                  aria-selected="false"
                >
                  Invoices
                </button> */}

              </div>
            </div>


            <div className="col-xxl-7 col-lg-9 col-12">
              <div className="tab-content mb-30" id="v-pills-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="v-pills-home"
                  role="tabpanel"
                  aria-labelledby="v-pills-home-tab"
                >
                  {
                    users ? (
                      <AccountDetails
                        ClipLoader={ClipLoader}
                        accountLoader={accountLoader} userinfo={users}
                        updateUser={updateUser} />
                    ) :
                      <div className="Clip_Loader" style={{ minHeight: "280px" }}>
                        <ClipLoader
                          color={"rgb(0, 143, 251)"}
                          loading={true}
                          size={50}
                        />
                      </div>
                  }
                </div>

                <div
                  className="tab-pane fade"
                  id="v-pills-susbscription"
                  role="tabpanel"
                  aria-labelledby="v-pills-susbscription-tab"
                >
                  {companywholesalers && subscribeStore && (

                    <MySubscriptions companywholesalers={companywholesalers} subscribeStore={subscribeStore}
                    />)
                  }

                </div>

                {
                  <ChangePassword user_guid={user_guid} showLoader={showLoader} hideLoader={hideLoader}/>
                }

                {
                  <div
                    className="tab-pane fade "
                    id="v-pills-user"
                    role="tabpanel"
                    aria-labelledby="v-pills-user-tab"
                  >
                    {companyusers && (
                      <Users props_user_guid={user_guid} companyusers={companyusers} AlertModal={AlertModal} />
                    )
                    }
                  </div>
                }

              </div>
            </div>

            <button className="btn btn-success" data-bs-toggle="modal" data-bs-target="#staticBackdrop" id="confirmuserModal" style={{ display: "none" }}>
              Alert
            </button>
            <ConfirmationRemoveUser RemoveUser={RemoveUserCompany} userguid={userguid} />
          </div>
        </div>
      </section>
    </>
  )
}

export default CompanyProfile;