import React from "react";
import { Link } from "react-router-dom";
import HelpCenterimg from "../../Assets/images/help-center.svg";
import FooterStrict from "../Dashboard/FooterStrict";

class HelpCenter extends React.Component {
  render() {
    return (
      <>
        <header className="Header">
          <div className="Second_Header_help">
            <div className="d-flex align-items-center Coupons_Head sec-header-head justify-content-between">
              <div class="d-flex align-items-center mb-0">
                <img
                  src={HelpCenterimg}
                  className="img-fluid mr-7"
                  alt="Add Icon"
                />
                <span>Help Center</span>
              </div>
              <div class="mt-btn d-flex">
                <div className="dropdown Profile_Dropdown">
                  <button
                    className="helpcent-head dropdown-toggle"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    English (US)
                    <svg
                      width="14"
                      class="help-icon ml-7"
                      height="8"
                      viewBox="0 0 14 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.00072 5.17205L11.9507 0.222046L13.3647 1.63605L7.00072 8.00005L0.636719 1.63605L2.05072 0.222046L7.00072 5.17205Z"
                        fill="current"
                      />
                    </svg>
                  </button>
                  <ul
                    className="dropdown-menu "
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <li>
                      <Link className="dropdown-item" to="">
                        English(US)
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/">
                        English(Uk)
                      </Link>
                    </li>
                  </ul>
                </div>
                <div>
                  <button className="helpcent-head">
                    <svg
                      width="22"
                      height="13"
                      class="help-icon mr-7"
                      viewBox="0 0 22 13"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10.602 8.76L12.014 10.172L20.48 1.706L21.894 3.12L12.014 13L5.65 6.636L7.064 5.222L9.189 7.347L10.602 8.759V8.76ZM10.604 5.932L15.556 0.979004L16.966 2.389L12.014 7.342L10.604 5.932ZM7.777 11.587L6.364 13L0 6.636L1.414 5.222L2.827 6.635L2.826 6.636L7.777 11.587V11.587Z"
                        fill="current"
                      />
                    </svg>
                    Submit a request
                  </button>
                </div>
                <div>
                  <button className="helpcent-head">
                    <svg
                      width="12"
                      height="17"
                      viewBox="0 0 12 17"
                      fill="none"
                      class="help-icon mr-7"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12 16.5H10.5V15C10.5 14.4033 10.2629 13.831 9.84099 13.409C9.41903 12.9871 8.84674 12.75 8.25 12.75H3.75C3.15326 12.75 2.58097 12.9871 2.15901 13.409C1.73705 13.831 1.5 14.4033 1.5 15V16.5H0V15C0 14.0054 0.395088 13.0516 1.09835 12.3483C1.80161 11.6451 2.75544 11.25 3.75 11.25H8.25C9.24456 11.25 10.1984 11.6451 10.9016 12.3483C11.6049 13.0516 12 14.0054 12 15V16.5ZM6 9.75C5.40905 9.75 4.82389 9.6336 4.27792 9.40746C3.73196 9.18131 3.23588 8.84984 2.81802 8.43198C2.40016 8.01412 2.06869 7.51804 1.84254 6.97208C1.6164 6.42611 1.5 5.84095 1.5 5.25C1.5 4.65905 1.6164 4.07389 1.84254 3.52792C2.06869 2.98196 2.40016 2.48588 2.81802 2.06802C3.23588 1.65016 3.73196 1.31869 4.27792 1.09254C4.82389 0.866396 5.40905 0.75 6 0.75C7.19347 0.75 8.33807 1.22411 9.18198 2.06802C10.0259 2.91193 10.5 4.05653 10.5 5.25C10.5 6.44347 10.0259 7.58807 9.18198 8.43198C8.33807 9.27589 7.19347 9.75 6 9.75V9.75ZM6 8.25C6.79565 8.25 7.55871 7.93393 8.12132 7.37132C8.68393 6.80871 9 6.04565 9 5.25C9 4.45435 8.68393 3.69129 8.12132 3.12868C7.55871 2.56607 6.79565 2.25 6 2.25C5.20435 2.25 4.44129 2.56607 3.87868 3.12868C3.31607 3.69129 3 4.45435 3 5.25C3 6.04565 3.31607 6.80871 3.87868 7.37132C4.44129 7.93393 5.20435 8.25 6 8.25V8.25Z"
                        fill="current"
                      />
                    </svg>
                    Sign In
                  </button>
                </div>
              </div>
            </div>
          </div>
        </header>
        <div className="container-fluid ContentBlock  pb-24 Main_Block">
          <div className="row justify-content-center ">
            <div class="col-xl-8 col-lg-12 col-12">
              <div class="profile-box">
                <h1 class="account-head">Submit a request</h1>
                <form class="row gx-4 gy-3">
                  <div class="col-md-6">
                    <label
                      for="inputEmail4"
                      class="form-label ml-10 main-form-profile"
                    >
                      Please choose your issue below
                    </label>
                    <input
                      type="email"
                      class="form-control profile-input-control"
                      placeholder="Enter Name"
                    />
                  </div>
                  <div class="col-md-6">
                    <label
                      for="inputEmail4"
                      class="form-label ml-10 main-form-profile"
                    >
                      Your email address<font color="red">*</font>
                    </label>
                    <input
                      type="email"
                      class="form-control profile-input-control"
                      placeholder="Enter Email"
                      id="inputEmail4"
                    />
                  </div>
                  <div class="col-md-6">
                    <label
                      for="inputEmail4"
                      class="form-label ml-10 main-form-profile"
                    >
                      Subject<font color="red">*</font>
                    </label>
                    <input
                      type="email"
                      class="form-control profile-input-control"
                      placeholder="Enter Text"
                    />
                    <div class="mt-15 helpcenter_choose">
                      <label
                        for="inputCity"
                        class="form-label ml-10 main-form-profile"
                      >
                        Attachments
                      </label>
                      <div className="position-relative w-fit-content">
                        <button
                          type="button"
                          className="btn  d-block img-box-drag"
                        >
                          Add file
                          <span class="d-block color-fnt">
                            or drop files <br></br> here
                          </span>
                        </button>
                        <input
                          type="file"
                          class="form-control hide-choose-profile"
                          id="inputGroupFile04"
                          aria-describedby="inputGroupFileAddon04"
                          aria-label="Upload"
                        ></input>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <label
                      for="exampleFormControlTextarea1"
                      class="form-label ml-10 main-form-profile"
                    >
                      Message<font color="red">*</font>
                    </label>
                    <textarea
                      type="text"
                      class="form-control profile-input-control mb-5"
                      id="exampleFormControlTextarea1"
                      rows="3"
                      placeholder="Enter Message"
                    />
                    <span class="message-box-text">
                      Please enter the details of your request. A member of our
                      support staff will respond as soon as possible.
                    </span>
                  </div>
                  <div class="col-12 mt-40">
                    <button
                      type="submit"
                      class="btn btn-primary profile-update"
                    >
                      Save
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
         <FooterStrict />
      </>
    );
  }
}
export default HelpCenter;
