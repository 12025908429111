import React from "react";
import Chart from "react-apexcharts";
import ClipLoader from "react-spinners/ClipLoader";
import ls from "local-storage";

class ClipsChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      upcarray: [],
      UpcList: "",
      calling: false,
      data: "",
      clips: "",
      series: [
        {
          name: "Net Profit",
          data: []
        }
      ],
      options: {
        chart: {
          type: "bar",
          height: 350
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "25%",
            endingShape: "rounded"
          }
        },
        dataLabels: {
          enabled: true
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"]
        },
        xaxis: {
          categories: []
        },
        yaxis: {
          title: {
            text: " "
          }
        },
        fill: {
          opacity: 1
        },
        tooltip: {
          // y: {
          //   formatter: function (val) {
          //     return "$ " + val + " thousands"
          //   }
          // }
        }
      }
    };

    this.couponSearch = this.couponSearch.bind(this);
  }

  componentDidUpdate(prevProps) {
    // compare props
    if (this.props.dataParentToChild !== prevProps.dataParentToChild) {
      this.setState({
        data: this.props.dataParentToChild
      });

      //console.log('didupdaet')
    }
    this.couponSearch();
  }

  componentDidMount() {
    //    console.log('dismount')

    var guid = ls.get("brands") ? ls.get("brands")[0].guid : "";

    var token = ls.get("token") ? ls.get("token") : "";
    var udata = "";
  }

  couponSearch() {
    const SeriesData = [];
    const wholesaler_name = [];
    const Redemptions = [];

    //  console.log('state',typeof this.state.data.xaxis)

    if (this.state.data && this.state.calling == false) {
      this.state.data.series.map((val, idx) => {
        console.log(this.state.data.series[idx].Data);

        SeriesData.push({
          name: this.state.data.series[idx].Unit,
          data: this.state.data.series[idx].Data
        });
      });

      //  this.state.data.xaxis.map((val,idx) => {

      //         wholesaler_name.push(val.categories)
      //  })
      for (var j = 0; j < this.state.data.xaxis.categories.length; j++) {
        // State1.push(res.data.Message.xaxis.categories[j]);
        wholesaler_name.push(this.state.data.xaxis.categories[j]);
      }

      // console.log(wholesaler_name)

      this.setState({
        calling: true,

        series: SeriesData,
        options: {
          chart: {
            type: "bar",
            height: 350
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: "55%",
              endingShape: "rounded"
            }
          },
          dataLabels: {
            enabled: true
          },
          stroke: {
            show: true,
            width: 2,
            colors: ["transparent"]
          },
          xaxis: {
            categories: wholesaler_name
          },
          yaxis: {
            title: {
              text: ""
            }
          },
          fill: {
            opacity: 1
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return parseInt(val);
              }
            }
          }
        }
      });
    }
  }

  render() {
    return (
      <div
        className="ProductChart"
        style={{ height: "400px", position: "relative" }}
      >{
          this.props.loader ?
            <div className="Clip_Loader" style={{ minHeight: "280px" }}>
              <ClipLoader
                color={"rgb(0, 143, 251)"}
                loading={true}
                size={50}
              />
            </div>
            :
            Object.keys(this.state.data).length > 0 ? (
              <Chart
                options={this.state.options}
                series={this.state.series}
                type="bar"
                height="100%"
                width="100%"
              />
            ) : (
              <span
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "400px"
                }}
              >
                No Data Available
              </span>
            )
        }
      </div>
    );
  }
}

export default ClipsChart;
