import React from "react";
import { Link } from "react-router-dom";

class SignInmodal extends React.Component {
  render() {
    return (
      <div
        className="modal fade"
        id="signinModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl modal-dialog-scrollable">
          <div className="modal-content pd-right">
            <div className="modal-header borderemove">
              <nav className="w-100">
                <div className="nav nav-tabs" id="nav-tab" role="tablist"></div>
              </nav>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body CustomScroll">
              <div className="tab-content" id="nav-tabContent">
                <div
                  className="tab-pane fade show active tab-text"
                  id="nav-home"
                  role="tabpanel"
                  aria-labelledby="nav-home-tab"
                >
                  <h5 className="term-head">.</h5>
                  <p>Bad Request -- Check Authorization Token.</p>
                </div>
                <div
                  className="tab-pane fade"
                  id="nav-profile"
                  role="tabpanel"
                  aria-labelledby="nav-profile-tab"
                >
                  <h5 className="term-head">Acceptance of the Terms</h5>
                  <p>
                    You accept the Terms by remaining on the Site. You may also
                    accept the Terms by clicking to accept or agree to the Terms
                    where this option is made available to you by BrandIQ in the
                    user interface.
                  </p>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <Link to="/login">
                <button
                  type="button"
                  className="btn btn-primary Btn_blue_modal"
                >
                  Login
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default SignInmodal;
