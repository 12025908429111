import * as CONFIG from "../../Components/_config";
import axios from "axios";
import { Link } from "react-router-dom";
import Assign from "../../Assets/images/assign-icon.png";

import {CapitaliZeWord, renderTooltip} from "../../Components/Helper";
import { getRemainingWholesaler, getRemainingRetailer } from "../../API/Profile";
import ls from "local-storage";
import AssignBrandModal from "../../Components/Common/AssignBrand/AssignBrandModal";
import { useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';


const Users = ({ userList, Delete, Edit, props_user_guid, brandWholesaler, AllWholesaler, replaceModalItem,AllRetailer, masterRoles, brandRetailer,AlertModal }) => {

  const [remainingWholesaler, setremainingWholesaler] = useState([]);
  const [remainingRetailer, setremainingRetailer] = useState([]);
  const [wholesalerIdarr, setWholesalerIdarr] = useState([]);
  const [retailerIdarr, setRetailerIdarr] = useState([]);

  const [userRoleguid, setuserRoleguid] = useState('');
  const [userBrandguid, setuserBrandguid] = useState('');
  const [userguid, setUserguid] = useState('');

  const [loading, setLoading] = useState(false);

  const Loader = () => <div id="cover-spin"></div>;

  const getWholesalerRetailer = (e) => {
    //setModalIsOpen(!isModalOpen);
    setuserBrandguid(e.target.value)
    getRemainingWholesaler(e.target.value).then((response) => {
      setremainingWholesaler(response.Message)
    });

    getRemainingRetailer(e.target.value).then((response) => {
      setremainingRetailer(response.Message)
    });
  }

  const selectWholesaler = (e, item) => {
    if (e.target.checked) {
      setWholesalerIdarr(
        [...wholesalerIdarr, item.wholesaler_id],
      );
    }

    else {
      setWholesalerIdarr(
        wholesalerIdarr.filter((e) => e !== item.wholesaler_id),
      );
    }
  }

  const selectRetailer = (e, item, retailer_id) => {
    if (e.target.checked) {
      setRetailerIdarr([...retailerIdarr, {
        wholesaler_id: item.wholesaler_id,
        retailer_id: item.retailer_id,
      }],
      );
    } else {
      setRetailerIdarr(retailerIdarr.filter((e) => e.retailer_id !== item.retailer_id))
    }
  }

  const saveBrand = () => {
    toggleLoader();
    var data = JSON.stringify({
      brand: userBrandguid,
      role: userRoleguid,
      wholesalers: wholesalerIdarr,
      retailers: retailerIdarr
    });

    var config = {
      method: "post",
      url: CONFIG.API_URL + `user/assign-brand/${userguid}`,
      headers: CONFIG.config_head.headers,
      data: data
    };

    axios(config)
      .then((response) => {
        if(response.status === 200) {
          toggleLoader();
          toast.success("Brand Assigned Successfully");
          window.location.reload();
        }
      })
      .catch((error)=> {
        if (error.response.status === 400) {
           toast.error(error.response.data.Message);
 
           setTimeout(()=>{window.location.reload()},2800)
 
        } else if (error.response.status === 403) {
          toast.error(error.response.data.Message);
          ls.clear("token");
          ls.clear("brands");
          window.location.href = "/login";
        } else {

          setTimeout(()=>{toast.error(error.response.data.Message)},2500);
          setTimeout(()=>{window.location.reload()},2800)
        }
      });


  }
  const displayBrandasignModal = (e) => {
    var filter = "role_name";
    var keyword = e.target.getAttribute("data-user_role_name");
    var user_guid = e.target.getAttribute("data-user_guid");

    const selectRole = masterRoles.length > 0 ? masterRoles.filter(function (obj) {
      return obj[filter] === keyword;
    }) : '';

    setuserRoleguid(selectRole[0].brandiq_user_role_guid)
    setUserguid(user_guid)
  }

  const toggleLoader=()=>{
    setLoading(!loading);
  
  }
  return (
    <>
       {loading ? <Loader /> : null} 

      <div className="profile-box">
        <div className="d-flex align-items-center justify-content-between User_Tab_Head">
          <h1 className="account-head mb-0">Users</h1>
          {CONFIG.config_user_scope.includes('user:write') ?

            <Link to={{
              pathname: "/AddUser", state: {
                params: {
                  id1: brandWholesaler,
                  id2: AllWholesaler,
                  id3: AllRetailer,
                  id4: brandRetailer,
                  id5: props_user_guid
                }
              }
            }} className="Add_User_Button " >
              <i className="fas fa-plus mr-3"></i> Add User
            </Link>
            :
            <Link to="/" className="Add_User_Button " data-bs-toggle="modal" data-bs-target="#ViewModal" >
              <i className="fas fa-plus mr-3"></i> Add User
            </Link>
          }
        </div>

        <div className="User_List_Main mt-20">
          {userList ?
            <div className="row g-3">
              {userList.map((val, idx) => {
                return (
                  <div className="col-xl-6 col-12" key={idx}>

                    {val.user_guid !== props_user_guid ?
                      CONFIG.config_user_scope.includes('user:write') ?
                      <OverlayTrigger
                      placement="top"
                      delay={{ show: 250, hide: 400 }}
                      overlay={renderTooltip('Remove')}
                      >
                        <img src={Delete} className="img-fluid" alt="Remove" 
                        style={{ width: "33px", height: "31px", float: "right", cursor: 'pointer' }} data-userguid={val.user_guid}
                          onClick={(e) => AlertModal(e)} />
                          </OverlayTrigger>
                        :
                        <img src={Delete} className="img-fluid" alt="Remove" style={{ width: "32px", height: "31px", float: "right", cursor: 'pointer' }} data-bs-toggle="modal" data-bs-target="#ViewModal" />
                      : ''
                    }


                    {val.user_guid !== props_user_guid ?
                     
                     CONFIG.config_user_scope.includes('user:write') ?
                     <OverlayTrigger
                     placement="top"
                     delay={{ show: 250, hide: 400 }}
                     overlay={renderTooltip('Edit')}
                     >
                        <img src={Edit} className="img-fluid" alt="Edit" data-bs-toggle="modal" data-bs-target="#exampleModal"
                          onClick={() => replaceModalItem(idx)} style={{ float: 'right', padding: '3px', width: "37px", height: "35px", cursor: 'pointer' }} />
                      </OverlayTrigger>
                        :
                        <img src={Edit} className="img-fluid" alt="Edit" data-bs-toggle="modal" data-bs-target="#ViewModal"
                          style={{ float: 'right', padding: '3px', width: "37px", height: "35px", cursor: 'pointer' }} />
                      : ''
                    }

                    {val.user_guid !== props_user_guid ?
                      CONFIG.config_user_scope.includes('user:write') ?

                      <OverlayTrigger placement="top"
                      delay={{ show: 250, hide: 400 }}
                      overlay={renderTooltip('Assign Brand')}
                      > 
                    <img src={Assign} className="img-fluid" alt="Assign Brand" data-user_role_name={val.role_name} data-user_guid={val.user_guid}
                          data-bs-toggle="modal" onClick={(e) => displayBrandasignModal(e)}
                          data-bs-target="#assign-brand-modal" style={{ float: 'right', padding: '3px', width: "37px", height: "35px", cursor: 'pointer' }} />
                        </OverlayTrigger>
                        : 
                        <img src={Assign} className="img-fluid" alt="Assign" data-bs-toggle="modal" data-bs-target="#ViewModal"
                        style={{ float: 'right', padding: '3px', width: "37px", height: "35px", cursor: 'pointer' }} />
                      : ''
                    }

                    <ul className="User_List">
                      <li>
                        <span>Name:</span>
                        <p>{CapitaliZeWord(val.first_name) + ' ' + CapitaliZeWord(val.last_name)}</p>
                      </li>
                      <li>
                        <span>Role:</span>
                        <p>{CapitaliZeWord(val.role_name)}</p>
                      </li>
                      <li>
                        <span>Email:</span>
                        <p>{val.email}</p>
                      </li>
                      <li>
                        <span>Phone:</span>
                        <p>{val.primary_phone}</p>
                      </li>
                    </ul>
                  </div>
                )
              }
              )}
            </div>
            : ''
          }
        </div>

        <AssignBrandModal brandChange={getWholesalerRetailer}
          remainingWholesaler={remainingWholesaler}
          remainingRetailer={remainingRetailer}
          selectWholesaler={selectWholesaler}
          selectRetailer={selectRetailer}
          saveBrand={saveBrand} />
      </div>
    </>
  )
}

export default Users;