import React from "react";
//import { Link } from "react-router-dom";
import ls from "local-storage";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import * as CONFIG from "../../_config";
import ItemRetailer from './ItemRetailer'

const Loader = () => <div id="cover-spin"></div>;

class AddRetailerModal extends React.Component {

  constructor(props) {
    super(props);
    toast.configure();

    this.state = {
      retailerdata: this.props.retailerData,
      retailer_number: [],
      brand_guid: this.props.brandguidata
    };

    this.selectAllCheckboxes = this.selectAllCheckboxes.bind(this);
    this.selectRetailer = this.selectRetailer.bind(this);

  }

  selectRetailer(e) {

    const { value } = e.target;
    const { wholesaler_id } = e.target.dataset;


    if (e.target.checked) {
      let obj = {
        wholesaler_id: parseInt(wholesaler_id),
        retailer_id: parseInt(value)
      };

      this.state.retailer_number.push(obj);
    } else {

      let removeIndex = this.state.retailer_number.findIndex(
        (itm) => itm.retailer_id === parseInt(value)
      );

      if (removeIndex > -1) {
        this.state.retailer_number.splice(removeIndex, 1); // 2nd parameter means remove one item only
      }
    }
  }

  selectAllCheckboxes(e) {

    const { checked } = e.target;
    const retailerObj = [];

    if (checked) {
      this.props.retailerData.map((val) => {
        return (
          retailerObj.push({ wholesaler_id: val.wholesaler_id, retailer_id: val.retailer_id })

        )
      });
    }
    this.setState({
      retailer_number: retailerObj,
      ItemsChecked: checked,
    });
  };

  saveRetailer() {
    this.showLoader();

    var data = JSON.stringify({
      retailers: this.state.retailer_number,
      contact: {},
      upc: [],
      wholesalers: []
    });

    var b_guid = this.state.brand_guid;

    var config = {
      method: "patch",
      url: CONFIG.API_URL + `brand/status/${b_guid}`,
      headers: CONFIG.config_head.headers,
      data: data
    };
    axios(config)
      .then((response) => {
        if (response.status === 200) {
          toast.success("Retailer added Successfully.");
          this.hideLoader();

          setTimeout(() => { window.location.reload() }, 3000);
        }
      })
      .catch((error) => {
        if (error.response) {

          if (error.response.status === 403) {
            this.hideLoader();
            toast.error(error.response.data.Message);
            ls.clear('brands');
            ls.clear('token');
            ls.clear('selectbrand');
            window.location.href = '/login';
          }
          else {
            this.hideLoader();
            toast.error(error.response.data.Message);
            setTimeout(
              () => window.location.reload(), 2500
            );
          }
        }
      });
  }

  showLoader = () => {
    this.setState({ loading: true });
  }
  hideLoader = () => {
    this.setState({ loading: false });
  };


  render() {
    //console.log('>>retailerinfo>>>>>',typeof this.state.retailerdata)

    return (
      <>
        {this.state.loading ? <Loader /> : null}

        <div
          className="modal fade"
          id="selectretailer-sel-drop"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-custom modal-dialog-scrollable">
            <div className="modal-content whole-content">
              <div className="modal-header borderemove brand-whole-hd">
                <h5 className="modal-title" id="exampleModalLabel">
                  Select Retailer
                </h5>

                <div className="header_right" style={{ float: 'right', marginTop: '-2px', marginRight: '80px', color: '#fff' }}>
                  <input type="checkbox" onClick={this.selectAllCheckboxes.bind(this)} value="checkedall" />{" "}Check / Uncheck All
                </div>

                <button
                  type="button"
                  className="btn-close wholeseler-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>

              <div className="modal-body CustomScroll whole-seler-body">
                <div className="tab-content" id="nav-tabContent">
                  <div
                    className="tab-pane fade show active tab-text"
                    id="nav-home"
                    role="tabpanel"
                    aria-labelledby="nav-home-tab"
                  >
                    <div className="Common_brand_detail">
                      <div className="pr-12 pl-12 pt-20">
                        {/* <div className="Search">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Retailer Name"
                />
    
                <div className="searchImg">
                  <img
                    src={Search}
                    className="img-fluid "
                    alt="search"
                  />
                </div>

              </div> */}

                        <ul className=" mt-15 d-flex  Retailer">
                          {Object.keys(this.state.retailerdata).length > 0 ? (
                            <>
                              {this.state.retailerdata.map((val, idx) => {
                                return (

                                  <ItemRetailer
                                    items={val}
                                    key={val.id}
                                    selectedItems={this.selectAllCheckboxes.bind(this)}
                                    ItemsChecked={this.state.ItemsChecked}
                                    checkedListAll={this.state.retailer_number}
                                    handleCheckboxClick={this.selectRetailer}
                                  />
                                );
                              })}
                            </>
                          ) : (
                            "No Retailers to choose"
                          )}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer brand-whole-footer">
                <button
                  type="button"
                  className="btn btn-primary Btn_blue_modal"
                  onClick={() => this.saveRetailer()}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default AddRetailerModal;
