const ConfirmationRemoveUser = ({userguid,RemoveUser}) => {


    return (
      <div
        className="modal fade Confirmation_Modal"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <i className="fas fa-exclamation-triangle text-center "></i>
              <h4 className="mt-20 fw-bold text-center">Are you sure ?</h4>
              <p className="mt-10 text-center">
                You want to remove this selected user.
              </p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                onClick={(e) =>
                  RemoveUser(userguid)
                }
              >
                Yes
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                No
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }
  export default ConfirmationRemoveUser;