import React from "react";
const BrandContact =({brandDetails,ShowBrand}) => {

return(
    <>      
    <div
    className="tab-pane fade"
    id="pills-profile"
    role="tabpanel"
    aria-labelledby="pills-profile-tab"
  >
   
  {brandDetails.user.length>0?
    <div className="row">
      
      <div className="col-12 col-lg-6">
        <ul className="a-fixed-left-grid-inner mb-col-lf">
          <li className="d-flex align-items-center pb-5 pt-5">
            <span className="a-col-left">First Name :</span>
            <span className="a-col-right">
              {brandDetails.user[0].first_name?brandDetails.user[0].first_name:''}
            </span>
          </li>
        
          <li className="d-flex align-items-center pb-5 pt-5">
            <span className="a-col-left">Email :</span>
            <span className="a-col-right">
              {brandDetails.user[0].email?brandDetails.user[0].email:''}
            </span>
          </li>
        </ul>
      </div>
      <div className="col-12 col-lg-6">
        <ul className="a-fixed-left-grid-inner mb-col-lf">
          <li className="d-flex align-items-center pb-5 pt-5">
            <span className="a-col-left">Last Name :</span>
            <span className="a-col-right">
              {brandDetails.user[0].last_name?brandDetails.user[0].last_name:''}
            </span>
          </li>
          {/* <li className="d-flex align-items-center pb-5 pt-5">
          <span className="a-col-left">Role :</span>
          <span className="a-col-right">UI/UX Designer</span>
        </li> */}
          <li className="d-flex align-items-center pb-5 pt-5">
            <span className="a-col-left">Phone :</span>
            <span className="a-col-right">
              {brandDetails.user[0].primary_phone?brandDetails.user[0].primary_phone:''}
            </span>
          </li>
        </ul>
      </div>
    </div>
    :''
    }


  </div>
</>
)

}

export default BrandContact;