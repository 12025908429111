import React from "react";
import $ from "jquery";
import ls from "local-storage";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as CONFIG from "../../Components/_config";
import { brandBywholesaler } from "../../API/Profile";
import ClipLoader from "react-spinners/ClipLoader";
import ItemCategory from './CreateUser/ItemCategory';


const Loader = () => <div id="cover-spin"></div>;

class SubscribeRWModal extends React.Component {

  constructor(props) {
    super(props);
    toast.configure();

    this.state = {
      AllRetailer: this.props.allRetailer,
      brandRetailer: this.props.brandRetailer,
      brandWholesaler: this.props.brandWholesaler,
      AllWholesaler: this.props.allWholesaler,
      retailer_number: [],
      wholesaler_number: [],
      formControlinfo: this.props.formControl,
      role_guid: this.props.role_guid,
      brand_job_title: this.props.brand_job_title,
      checkedList: [],
      brandBywholesalerflag: false,
      brandByWholesaler: [],
      brandBywholesalerLoader: false,
      checkedBrandsList: [],
      wholesalerIdarr: [],
      checkedListAll: [],
      ItemsChecked: false
    };

    this.brandBywholesaler = this.brandBywholesaler.bind(this);
    this.SaveUser = this.SaveUser.bind(this);
    this.selectAllCheckboxes = this.selectAllCheckboxes.bind(this);
    //  this.handleCheckboxClick = this.handleCheckboxClick.bind(this);
    this.selectedItems = this.selectedItems.bind(this);
    this.selectWholesaler = this.selectWholesaler.bind(this)
  }

  // selectRetailer(e, wholesaler_id, retailer_id) {
  //   if (e.target.checked) {

  //     let obj = {
  //       wholesaler_id: wholesaler_id,
  //       retailer_id: retailer_id
  //     };

  //     this.state.retailer_number.push(obj);
  //   } else {
  //     let removeIndex = this.state.retailer_number.findIndex(
  //       (itm) => itm.retailer_id === retailer_id
  //     );

  //     if (removeIndex > -1) {
  //       this.state.retailer_number.splice(removeIndex, 1); // 2nd parameter means remove one item only
  //     }
  //   }
  // }

  selectBrands(e, item) {
    let brandArray = []
    if (e.target.checked) {
      brandArray = this.state.checkedBrandsList.filter(CheckedId =>
        CheckedId !== item.brand_guid
      )
      brandArray.push(item.brand_guid)
    }
    else {
      brandArray = this.state.checkedBrandsList.filter(CheckedId =>
        CheckedId !== item.brand_guid
      )
    }
    this.setState({
      checkedBrandsList: brandArray,
      brandBywholesalerflag: true,
    })
  }

  selectAllCheckboxes(e) {

    const { checked } = e.target;

    const collection = [];
    const wholesalerIdarr = [];


    if (checked) {

      this.props.companyWholesaler.map(val => {

        collection.push(val.wholesaler_number)

        wholesalerIdarr.push(val.wholesaler_id)

      });
    }
    this.setState({
      checkedListAll: collection,
      ItemsChecked: checked,
      brandBywholesalerLoader: true,
      brandBywholesalerflag: true,
      wholesalerIdarr: wholesalerIdarr,

    });


  };

  selectedItems(e) {
    const { value, checked } = e.target;
    let { checkedListAll } = this.state;

    if (checked) {
      checkedListAll = [...checkedListAll, value];
    } else {
      checkedListAll = checkedListAll.filter(el => el !== value);
      if (this.state.ItemsChecked) {
        this.setState({
          ItemsChecked: !this.state.ItemsChecked
        });
      }
    }
    this.setState({ checkedListAll });
  }


  selectWholesaler(e) {
    const { value } = e.target;

    let resultArray = []
    let wholesalerIdarr = [];

    if (e.target.checked) {
      resultArray = this.state.checkedListAll.filter(CheckedId =>
        CheckedId !== value
      )

      wholesalerIdarr = this.state.wholesalerIdarr.filter(CheckedId =>
        CheckedId !== e.target.dataset.wholesaler_id
      )

      resultArray.push(parseInt(value))
      wholesalerIdarr.push(e.target.dataset.wholesaler_id)
    }
    else {
      resultArray = this.state.checkedListAll.filter(CheckedId =>
        CheckedId !== parseInt(value)
      )

      wholesalerIdarr = this.state.wholesalerIdarr.filter(CheckedId =>
        CheckedId !== e.target.dataset.wholesaler_id
      )
    }
    this.setState({
      brandBywholesalerLoader: true,
      checkedListAll: resultArray,
      brandBywholesalerflag: true,
      wholesalerIdarr: wholesalerIdarr
    })

  }

  brandBywholesaler() {
    brandBywholesaler({
      wholesaler_number_arr: this.state.checkedListAll
    }).then((response) => {

      this.setState({
        brandBywholesalerflag: false,
        brandBywholesalerLoader: false,
        brandByWholesaler: response.Message,
      });
    });

  }

  SaveUser() {
    $("#subscribe-sel-drop").css("display", "none");
    $(".modal-backdrop").first().css("display", "none");

    this.showLoader();

   
    var data = JSON.stringify({
      first_name: this.state.formControlinfo.first_name,
      last_name: this.state.formControlinfo.last_name,
      email: this.state.formControlinfo.useremail,

      primary_phone: this.state.formControlinfo.Phone,
      secondary_phone: "",
      role: this.state.role_guid,
      brand_job_title: this.state.brand_job_title,

      brand: this.state.checkedBrandsList,
      wholesalers: this.state.wholesalerIdarr,
      retailers: []
    });
    var config = {
      method: "post",
      url: CONFIG.API_URL + "user",
      headers: CONFIG.config_head.headers,
      data: data
    };
    axios(config)
      .then((res) => {
        if (res.status === 201) {
          this.hideLoader();
          toast.success('User Added Successfully')
          setTimeout(
            () => window.location.reload(),
            3000
          );
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 400) {
            this.hideLoader();
            toast.error(error.response.data.Message);
            // setTimeout(
            //   () => window.location.reload(), 2500
            // );
          }
          else if (error.response.status === 403) {
            this.hideLoader();
            toast.error(error.response.data.Message);
            ls.clear('brands');
            ls.clear('token');
            ls.clear('selectbrand');
            window.location.href = '/login';
          }
          else {
            this.hideLoader();
            toast.error(error.response.data.Message);
            // setTimeout(
            //   () => window.location.reload(), 2500
            // );
          }
        }
      });

  }

  showLoader = () => {
    this.setState({ loading: true });
  }
  hideLoader = () => {
    this.setState({ loading: false });
  };


  // componentDidUpdate(prevProps) {
  //   // compare props
  // }
  // UNSAFE_componentWillReceiveProps(nextProps) {

  //    this.setState({
  //     formControlinfo: nextProps.formControl,
  //     role_guid: nextProps.role_guid,
  //     brand_job_title: nextProps.brand_job_title
  //   });
  // }

  componentDidUpdate(prevProps, nextProps) {

    if (prevProps !== this.props) {
      this.setState({
        formControlinfo: prevProps.formControl,
        role_guid: prevProps.role_guid,
        brand_job_title: prevProps.brand_job_title
      },
        () => {
          if (this.state.brandBywholesalerflag === true) {
            this.brandBywholesaler();
          }
        });
    }
  }

  render() {

    return (
      <>
        {this.state.loading ? <Loader /> : null}

        <div
          className="modal fade"
          id="subscribe-sel-drop"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-custom modal-dialog-scrollable">
            <div className="modal-content whole-content">
              <div className="modal-header borderemove brand-whole-hd">
                <h5 className="modal-title" id="exampleModalLabel">
                  Subscribed Wholesaler
                </h5>
                <button
                  type="button"
                  className="btn-close wholeseler-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>


              </div>

              <div className="modal-body CustomScroll whole-seler-body">
                <div className="tab-content" id="nav-tabContent">
                  <div
                    className="tab-pane fade show active tab-text"
                    id="nav-home"
                    role="tabpanel"
                    aria-labelledby="nav-home-tab"
                  >
                    <div className="Common_brand_detail">
                      <div className="pr-12 pl-12 pt-20">
                        <div className="profile-box">
                          <h1 className="account-head">My Subscriptions</h1>
                          {
                            Object.keys(this.props.companyWholesaler).length > 0 &&
                            <div className="Card ">
                              <div className="Card_Header  Steps_Header">
                                <h5>Select Wholesalers</h5>
                                <div className="header_right" style={{ float: 'right', marginTop: '-15px', color: '#fff' }}>
                                  <input type="checkbox" onClick={this.selectAllCheckboxes.bind(this)} value="checkedall"
                                    style={{ marginRight: '5px' }} />
                                  {" "}
                                  Check / Uncheck All
                                </div>
                              </div>

                              <div className="Card_Body p-3">
                                {this.props.companyWholesaler ? (
                                  <>
                                    <ul className=" d-flex  Retailer">
                                      {this.props.companyWholesaler.map(
                                        (val, idx) => {
                                          return (
                                            <ItemCategory

                                              items={val}
                                              key={val.id}
                                              selectedItems={this.selectAllCheckboxes.bind(this)}
                                              ItemsChecked={this.state.ItemsChecked}
                                              checkedListAll={this.state.checkedListAll}
                                              handleCheckboxClick={this.selectWholesaler}
                                              wholesalerIdarr={this.state.wholesalerIdarr}
                                            />
                                            // <li key={idx}>
                                            //   <div className="Retailer_Block">
                                            //     <input
                                            //       type="checkbox"
                                            //       onChange={(e) =>
                                            //         this.selectWholesaler(
                                            //           e,
                                            //           val.wholesaler_number,
                                            //           val.wholesaler_id
                                            //         )
                                            //       }
                                            //       value={val.wholesaler_guid}
                                            //     />
                                            //     <div className="RetailerImage">
                                            //       <img
                                            //         className="img-fluid"
                                            //         alt={val.wholesaler_name}
                                            //         src={
                                            //           val.logo
                                            //         }
                                            //       />
                                            //     </div>
                                            //     <h5>
                                            //       {val.wholesaler_name}
                                            //     </h5>
                                            //   </div>
                                            // </li>
                                          )
                                        }
                                      )}
                                    </ul>
                                  </>
                                ) : (
                                  "NO Wholesaler Available"
                                )
                                }
                              </div>
                            </div>
                          }

                          {
                            <div className="Card mt-20">
                              <div className="Card_Header Steps_Header">
                                <h5>Brands</h5>
                              </div>

                              {this.state.brandBywholesalerLoader ? (
                                <div
                                  className="Clip_Loader"
                                  style={{ minHeight: "280px" }}
                                >
                                  <ClipLoader
                                    color={"rgb(0, 143, 251)"}
                                    loading={true}
                                    size={50}
                                  />
                                </div>
                              ) :

                                <div className="Card_Body p-3">
                                  {this.state.brandByWholesaler.length > 0 ? (
                                    <>
                                      <ul className=" mt-15 d-flex  Retailer">
                                        {this.state.brandByWholesaler.map(
                                          (val, idx) => {
                                            return (
                                              <li key={idx}>
                                                <div className="Retailer_Block">
                                                  <input
                                                    type="checkbox"
                                                    onChange={(e) =>
                                                      this.selectBrands(
                                                        e,
                                                        val
                                                      )
                                                    }
                                                  />
                                                  <div className="RetailerImage">
                                                    <img
                                                      className="img-fluid"
                                                      alt="Retailer"
                                                      src={val.logo}

                                                    />
                                                  </div>
                                                  <h5>
                                                    {val.brand_name}
                                                  </h5>
                                                </div>
                                              </li>
                                            );
                                          }
                                        )}
                                      </ul>
                                    </>
                                  ) : (
                                    "No Brands Available"
                                  )}
                                </div>
                              }
                            </div>}

                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer brand-whole-footer">

                {this.state.checkedBrandsList.length >= 1 ?
                  <button
                    type="button"
                    className="btn btn-primary Btn_blue_modal"
                    onClick={() => this.SaveUser()}
                  >
                    Save
                  </button> : ''}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default SubscribeRWModal;
