import React from "react";
import { Button } from "react-bootstrap";
import { renderTooltip } from "../../../Components/Helper";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Info from "../../../Assets/images/info.png";

class Textarea extends React.Component {
  constructor() {
    super();
    this.state = {
      checkboxlength: ""
    };
  }

  render() {
    return (
      <React.Fragment>
        <div className="table-responsive Create_Coupons_Table">
          {this.props.UpcList ? (
            <>
              {
                <div className="Common_Block_Body pt-17 pb-17 pr-14 pl-14 Build_Offer_Block">
                  <h5 className="Title">
                    Enter UPCs
                    <OverlayTrigger
                      placement="top"
                      delay={{ show: 250, hide: 400 }}
                      overlay={renderTooltip(
                        "Only integer allowed, No spaces and special charcters like `@`,`#`,etc... not allowed "
                      )}
                    >
                      <img
                        src={Info}
                        className="img-fluid"
                        alt="Edit"
                        style={{
                          padding: "1px",
                          width: "31px",
                          height: "31px",
                          cursor: "pointer"
                        }}
                      />
                    </OverlayTrigger>
                  </h5>

                  <div className="row">
                    <div className="col-lg-6 col-md-12 col-12">
                      <textarea
                        type="text"
                        rows="10"
                        className="upctextarea"
                        onChange={this.props.handleUpcChange.bind(this)}
                                                      
                        defaultValue={
                          this.props.UpcList
                            ? this.props.UpcList.map((upc) => {
                                return upc.upc11;
                              })
                            : ""
                        }
                      />
                    </div>
                  </div>
                </div>
              }

              <table className="table mb-0">
                <tfoot>
                  <tr className="Bottom_Block">
                    <td className="" colSpan="3">
                      <div className="d-flex align-items-center justify-content-end">
                        <div className="TableButton">
                          <Button onClick={(e) => this.props.changeTab(e, 2)}>
                            Next <i className="fas fa-arrow-right ml-10"></i>{" "}
                          </Button>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tfoot>
              </table>
            </>
          ) : (
            ""
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default Textarea;
