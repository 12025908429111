import axios from 'axios';
import React, { useEffect, useState } from 'react'
import * as CONFIG from "../../Components/_config";

export default function FooterStrict() {

    const [date, setDate] = useState("")
   // const today = new Date();

    const monthArray = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

    useEffect(() => {
        let config = {
            method: "get",
            url: CONFIG.API_URL + `master?type=last_publish_fe&search`,
            headers: CONFIG.config_head.headers
        };
        if (localStorage.getItem("footerDate"))
            setDate(localStorage.getItem("footerDate"))
        else
            axios(config).then(res => {
                const dt = new Date(res.data.Message);
                const response = dt.toString();
                const data = response.split(" ")
                let monthNumber;
                if ((monthArray.indexOf(data[1]) + 1) < 10) {
                    monthNumber = `0${monthArray.indexOf(data[1]) + 1}`
                }
                else monthNumber = monthArray.indexOf(data[1]) + 1
                const dateFinal = `${monthNumber}-${data[2]}-${data[3]}`;
                const time = data[4].split(":");
                const timeFinal = `${time[0]}:${time[1]}`
                const final = `${dateFinal} ${timeFinal}`
                localStorage.setItem("footerDate", final);
                setDate(final)
            })
                .catch(error => {
                    console.log(error)
                })
    }, [])
    return (
        <footer className='Footer'>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-12'>
                        {
                            date ?
                                <p>Last published: {date}</p>
                                :
                                <p>Last published: ---</p>
                        }
                    </div>
                </div>
            </div>
        </footer>
    )
}
