import * as CONFIG from "../../Components/_config";
import { Link } from "react-router-dom";
import { CapitaliZeWord, renderTooltip } from "../../Components/Helper";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Delete from "../../Assets/images/delete.png";

const Users = ({ companyusers, props_user_guid, brandRetailer, AlertModal }) => {

  return (
    <>
      <div className="profile-box">
        <div className="d-flex align-items-center justify-content-between User_Tab_Head">
          <h1 className="account-head mb-0">Users</h1>
          {CONFIG.config_user_scope.includes('user:write') ?

            <Link to={{
              pathname: "/AddUser", state: {
                params: {
                  id4: brandRetailer,
                  id5: props_user_guid
                }
              }
            }} className="Add_User_Button " >
              <i className="fas fa-plus mr-3"></i> Add User
            </Link>
            :
            <Link to="/" className="Add_User_Button " data-bs-toggle="modal" data-bs-target="#ViewModal" >
              <i className="fas fa-plus mr-3"></i> Add User
            </Link>
          }
        </div>

        <div className="User_List_Main mt-20">
          {companyusers ?
            <div className="row g-3">
              {companyusers.map((val, idx) => {
                return (
                  <div className="col-xl-6 col-12" key={idx}>

                    {val.user_guid !== props_user_guid ?
                      CONFIG.config_user_scope.includes('user:write') ?
                        <OverlayTrigger
                          placement="top"
                          delay={{ show: 250, hide: 400 }}
                          overlay={renderTooltip('Remove')}
                        >
                          <img src={Delete} className="img-fluid" alt="Remove"
                            style={{ width: "33px", height: "31px", float: "right", cursor: 'pointer' }} data-userguid={val.user_guid}
                            onClick={(e) => AlertModal(e)} />
                        </OverlayTrigger>
                        :
                        <img src={Delete} className="img-fluid" alt="Remove" style={{ width: "32px", height: "31px", float: "right", cursor: 'pointer' }} data-bs-toggle="modal" data-bs-target="#ViewModal" />
                      : ''
                    }
                    <ul className="User_List">
                      <li>
                        <span>Name:</span>
                        <p>{CapitaliZeWord(val.first_name) + ' ' + CapitaliZeWord(val.last_name)}</p>
                      </li>
                      <li>
                        <span>Role:</span>
                        <p>{CapitaliZeWord(val.role_name)}</p>
                      </li>
                      <li>
                        <span>Email:</span>
                        <p>{val.email}</p>
                      </li>
                      <li>
                        <span>Phone:</span>
                        <p>{val.primary_phone}</p>
                      </li>
                    </ul>
                  </div>
                )
              }
              )}
            </div>
            : ''
          }
        </div>
      </div>
    </>
  )
}

export default Users;