import React from "react";

class BillingTab extends React.Component {

   
  render() {
  
    return (
      <>
          <div
                        className="tab-pane fade"
                        id="pills-billing"
                        role="tabpanel"
                        aria-labelledby="pills-billing-tab"
                      >
                        <div className="row">
                          <div className="col-12 col-lg-6">
                            <ul className="a-fixed-left-grid-inner mb-col-lf">
                              Coming Soon
                            </ul>
                          </div>
                        </div>
                      </div>

      </>
    );
  }
}
export default BillingTab;