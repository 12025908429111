import React from 'react'
import { Form } from "react-bootstrap";
import FileIcon from "../../Assets/images/file-icon.png";
import scissors from "../../Assets/images/scissors.png";
import ProductsImage from "../../Assets/images/products.svg";
import OfferValueImage from "../../Assets/images/offer-value.svg";
import caseImage from "../../Assets/images/case.png";
import Pallet from "../../Assets/images/pallet.png";
import Truck from "../../Assets/images/truck.png";
import Shipper from "../../Assets/images/shipper.png";
import Unit from "../../Assets/images/unit.png";
import Info from "../../Assets/images/info.png";
import { renderTooltip } from "../../Components/Helper";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

class Builddeal extends React.Component {
    render() {
        return (

            <div className="row  g-3">
                <div className="col-xxl-9 col-xl-8 col-12">
                    <div className="Common_Block">
                        <div className="Common_Block_Header d-flex align-items-center justify-content-between">
                            <h5 className="">Offer Details</h5>
                        </div>
                        <div className="Common_Block_Body pt-17 pb-17 pr-14 pl-14 Build_Offer_Block">
                            <h5 className="Title">Deal Unit</h5>
                            <div className="row">
                                <div className="col-xxl-2 col-lg-3 col-md-4 col-12">
                                    <div
                                        className={`Offer_Type ${this.props.offer_type ? `Selected` : ` `
                                            }`}
                                        onClick={(e) =>
                                            this.props.onchangeOffertype(e, 1)
                                        }
                                    >
                                        <div className="position-relative Image_Block">
                                            <svg width="75" height="76" viewBox="0 0 75 76" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="37.7998" cy="37.875" r="25" class="DarkGray" fill='current' />
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M15.9374 7.74333C22.0607 3.19119 29.622 0.5 37.7998 0.5C45.9776 0.5 53.5389 3.19119 59.6622 7.74333C60.5486 8.40233 60.733 9.65516 60.074 10.5416C59.415 11.4281 58.1622 11.6124 57.2757 10.9534C51.8152 6.89402 45.0847 4.5 37.7998 4.5C30.5149 4.5 23.7844 6.89403 18.3239 10.9535C17.4374 11.6124 16.1846 11.4281 15.5256 10.5416C14.8666 9.65516 15.051 8.40233 15.9374 7.74333ZM65.0261 15.6001C65.9257 14.9592 67.1745 15.1688 67.8155 16.0684C72.2111 22.2377 74.7998 29.8164 74.7998 38C74.7998 46.1836 72.2111 53.7623 67.8155 59.9316C67.1745 60.8311 65.9257 61.0408 65.0261 60.3999C64.1265 59.7589 63.9168 58.5101 64.5578 57.6105C68.4841 52.0998 70.7998 45.3284 70.7998 38C70.7998 30.6716 68.4841 23.9002 64.5578 18.3895C63.9168 17.4899 64.1265 16.2411 65.0261 15.6001ZM10.5735 15.6001C11.4731 16.2411 11.6828 17.4899 11.0418 18.3895C7.11551 23.9002 4.7998 30.6716 4.7998 38C4.7998 45.3284 7.11551 52.0998 11.0418 57.6105C11.6828 58.5101 11.4731 59.7589 10.5735 60.3999C9.67393 61.0408 8.42508 60.8312 7.78413 59.9316C3.38855 53.7623 0.799805 46.1836 0.799805 38C0.799805 29.8164 3.38855 22.2377 7.78413 16.0684C8.42508 15.1689 9.67393 14.9592 10.5735 15.6001ZM15.5256 65.4584C16.1846 64.5719 17.4374 64.3876 18.3239 65.0466C23.7844 69.106 30.5149 71.5 37.7998 71.5C45.0847 71.5 51.8152 69.106 57.2757 65.0465C58.1622 64.3876 59.415 64.5719 60.074 65.4584C60.733 66.3448 60.5486 67.5977 59.6622 68.2567C53.5389 72.8088 45.9776 75.5 37.7998 75.5C29.622 75.5 22.0607 72.8088 15.9375 68.2567C15.051 67.5977 14.8666 66.3448 15.5256 65.4584Z" class="LightGray" fill='current' />
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M15.9373 7.74333C22.0606 3.19119 29.6218 0.5 37.7996 0.5C45.9775 0.5 53.5387 3.19119 59.662 7.74333C60.5484 8.40233 60.7328 9.65516 60.0738 10.5416C59.4148 11.4281 58.162 11.6124 57.2755 10.9535C51.815 6.89402 45.0845 4.5 37.7996 4.5C30.5147 4.5 23.7842 6.89403 18.3237 10.9535C17.4373 11.6125 16.1844 11.4281 15.5254 10.5416C14.8664 9.65516 15.0508 8.40233 15.9373 7.74333ZM65.0259 15.6001C65.9255 14.9592 67.1744 15.1688 67.8153 16.0684C72.2109 22.2377 74.7996 29.8164 74.7996 38C74.7996 46.1836 72.2109 53.7623 67.8153 59.9316C67.1744 60.8311 65.9255 61.0408 65.0259 60.3999C64.1263 59.7589 63.9167 58.5101 64.5576 57.6105C68.4839 52.0998 70.7996 45.3284 70.7996 38C70.7996 30.6716 68.4839 23.9002 64.5576 18.3895C63.9167 17.4899 64.1263 16.2411 65.0259 15.6001Z" class="LightGray" fill='current' />
                                            </svg>

                                            <img src={caseImage} className="img-fluid" alt="Case" />
                                        </div>
                                        <p>Case</p>
                                    </div>
                                </div>
                                <div className="col-xxl-2 col-lg-3 col-md-4 col-12">
                                    <div
                                        className="Offer_Type"
                                        onClick={(e) =>
                                            this.props.onchangeOffertype(e, 2)
                                        }
                                    >
                                        <div className="position-relative Image_Block">
                                            <svg width="75" height="76" viewBox="0 0 75 76" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="37.7998" cy="37.875" r="25" class="DarkGray" fill='current' />
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M15.9374 7.74333C22.0607 3.19119 29.622 0.5 37.7998 0.5C45.9776 0.5 53.5389 3.19119 59.6622 7.74333C60.5486 8.40233 60.733 9.65516 60.074 10.5416C59.415 11.4281 58.1622 11.6124 57.2757 10.9534C51.8152 6.89402 45.0847 4.5 37.7998 4.5C30.5149 4.5 23.7844 6.89403 18.3239 10.9535C17.4374 11.6124 16.1846 11.4281 15.5256 10.5416C14.8666 9.65516 15.051 8.40233 15.9374 7.74333ZM65.0261 15.6001C65.9257 14.9592 67.1745 15.1688 67.8155 16.0684C72.2111 22.2377 74.7998 29.8164 74.7998 38C74.7998 46.1836 72.2111 53.7623 67.8155 59.9316C67.1745 60.8311 65.9257 61.0408 65.0261 60.3999C64.1265 59.7589 63.9168 58.5101 64.5578 57.6105C68.4841 52.0998 70.7998 45.3284 70.7998 38C70.7998 30.6716 68.4841 23.9002 64.5578 18.3895C63.9168 17.4899 64.1265 16.2411 65.0261 15.6001ZM10.5735 15.6001C11.4731 16.2411 11.6828 17.4899 11.0418 18.3895C7.11551 23.9002 4.7998 30.6716 4.7998 38C4.7998 45.3284 7.11551 52.0998 11.0418 57.6105C11.6828 58.5101 11.4731 59.7589 10.5735 60.3999C9.67393 61.0408 8.42508 60.8312 7.78413 59.9316C3.38855 53.7623 0.799805 46.1836 0.799805 38C0.799805 29.8164 3.38855 22.2377 7.78413 16.0684C8.42508 15.1689 9.67393 14.9592 10.5735 15.6001ZM15.5256 65.4584C16.1846 64.5719 17.4374 64.3876 18.3239 65.0466C23.7844 69.106 30.5149 71.5 37.7998 71.5C45.0847 71.5 51.8152 69.106 57.2757 65.0465C58.1622 64.3876 59.415 64.5719 60.074 65.4584C60.733 66.3448 60.5486 67.5977 59.6622 68.2567C53.5389 72.8088 45.9776 75.5 37.7998 75.5C29.622 75.5 22.0607 72.8088 15.9375 68.2567C15.051 67.5977 14.8666 66.3448 15.5256 65.4584Z" class="LightGray" fill='current' />
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M15.9373 7.74333C22.0606 3.19119 29.6218 0.5 37.7996 0.5C45.9775 0.5 53.5387 3.19119 59.662 7.74333C60.5484 8.40233 60.7328 9.65516 60.0738 10.5416C59.4148 11.4281 58.162 11.6124 57.2755 10.9535C51.815 6.89402 45.0845 4.5 37.7996 4.5C30.5147 4.5 23.7842 6.89403 18.3237 10.9535C17.4373 11.6125 16.1844 11.4281 15.5254 10.5416C14.8664 9.65516 15.0508 8.40233 15.9373 7.74333ZM65.0259 15.6001C65.9255 14.9592 67.1744 15.1688 67.8153 16.0684C72.2109 22.2377 74.7996 29.8164 74.7996 38C74.7996 46.1836 72.2109 53.7623 67.8153 59.9316C67.1744 60.8311 65.9255 61.0408 65.0259 60.3999C64.1263 59.7589 63.9167 58.5101 64.5576 57.6105C68.4839 52.0998 70.7996 45.3284 70.7996 38C70.7996 30.6716 68.4839 23.9002 64.5576 18.3895C63.9167 17.4899 64.1263 16.2411 65.0259 15.6001Z" class="LightGray" fill='current' />
                                            </svg>
                                            <img src={Pallet} className="img-fluid" alt="Pallet" />
                                        </div>
                                        <p>Pallet</p>
                                    </div>
                                </div>
                                <div className="col-xxl-2 col-lg-3 col-md-4 col-12">
                                    <div
                                        className="Offer_Type" onClick={(e) =>
                                            this.props.onchangeOffertype(e, 3)
                                        }
                                    >
                                        <div className="position-relative Image_Block">
                                            <svg width="75" height="76" viewBox="0 0 75 76" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="37.7998" cy="37.875" r="25" class="DarkGray" fill='current' />
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M15.9374 7.74333C22.0607 3.19119 29.622 0.5 37.7998 0.5C45.9776 0.5 53.5389 3.19119 59.6622 7.74333C60.5486 8.40233 60.733 9.65516 60.074 10.5416C59.415 11.4281 58.1622 11.6124 57.2757 10.9534C51.8152 6.89402 45.0847 4.5 37.7998 4.5C30.5149 4.5 23.7844 6.89403 18.3239 10.9535C17.4374 11.6124 16.1846 11.4281 15.5256 10.5416C14.8666 9.65516 15.051 8.40233 15.9374 7.74333ZM65.0261 15.6001C65.9257 14.9592 67.1745 15.1688 67.8155 16.0684C72.2111 22.2377 74.7998 29.8164 74.7998 38C74.7998 46.1836 72.2111 53.7623 67.8155 59.9316C67.1745 60.8311 65.9257 61.0408 65.0261 60.3999C64.1265 59.7589 63.9168 58.5101 64.5578 57.6105C68.4841 52.0998 70.7998 45.3284 70.7998 38C70.7998 30.6716 68.4841 23.9002 64.5578 18.3895C63.9168 17.4899 64.1265 16.2411 65.0261 15.6001ZM10.5735 15.6001C11.4731 16.2411 11.6828 17.4899 11.0418 18.3895C7.11551 23.9002 4.7998 30.6716 4.7998 38C4.7998 45.3284 7.11551 52.0998 11.0418 57.6105C11.6828 58.5101 11.4731 59.7589 10.5735 60.3999C9.67393 61.0408 8.42508 60.8312 7.78413 59.9316C3.38855 53.7623 0.799805 46.1836 0.799805 38C0.799805 29.8164 3.38855 22.2377 7.78413 16.0684C8.42508 15.1689 9.67393 14.9592 10.5735 15.6001ZM15.5256 65.4584C16.1846 64.5719 17.4374 64.3876 18.3239 65.0466C23.7844 69.106 30.5149 71.5 37.7998 71.5C45.0847 71.5 51.8152 69.106 57.2757 65.0465C58.1622 64.3876 59.415 64.5719 60.074 65.4584C60.733 66.3448 60.5486 67.5977 59.6622 68.2567C53.5389 72.8088 45.9776 75.5 37.7998 75.5C29.622 75.5 22.0607 72.8088 15.9375 68.2567C15.051 67.5977 14.8666 66.3448 15.5256 65.4584Z" class="LightGray" fill='current' />
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M15.9373 7.74333C22.0606 3.19119 29.6218 0.5 37.7996 0.5C45.9775 0.5 53.5387 3.19119 59.662 7.74333C60.5484 8.40233 60.7328 9.65516 60.0738 10.5416C59.4148 11.4281 58.162 11.6124 57.2755 10.9535C51.815 6.89402 45.0845 4.5 37.7996 4.5C30.5147 4.5 23.7842 6.89403 18.3237 10.9535C17.4373 11.6125 16.1844 11.4281 15.5254 10.5416C14.8664 9.65516 15.0508 8.40233 15.9373 7.74333ZM65.0259 15.6001C65.9255 14.9592 67.1744 15.1688 67.8153 16.0684C72.2109 22.2377 74.7996 29.8164 74.7996 38C74.7996 46.1836 72.2109 53.7623 67.8153 59.9316C67.1744 60.8311 65.9255 61.0408 65.0259 60.3999C64.1263 59.7589 63.9167 58.5101 64.5576 57.6105C68.4839 52.0998 70.7996 45.3284 70.7996 38C70.7996 30.6716 68.4839 23.9002 64.5576 18.3895C63.9167 17.4899 64.1263 16.2411 65.0259 15.6001Z" class="LightGray" fill='current' />
                                            </svg>
                                            <img src={Truck} className="img-fluid" alt="Truck" />

                                        </div>
                                        <p>Truck</p>
                                    </div>
                                </div>
                                <div className="col-xxl-2 col-lg-3 col-md-4 col-12"  >
                                    <div
                                        className="Offer_Type" onClick={(e) =>
                                            this.props.onchangeOffertype(e, 4)
                                        }>
                                        <div className="position-relative Image_Block">
                                            <svg width="75" height="76" viewBox="0 0 75 76" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="37.7998" cy="37.875" r="25" class="DarkGray" fill='current' />
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M15.9374 7.74333C22.0607 3.19119 29.622 0.5 37.7998 0.5C45.9776 0.5 53.5389 3.19119 59.6622 7.74333C60.5486 8.40233 60.733 9.65516 60.074 10.5416C59.415 11.4281 58.1622 11.6124 57.2757 10.9534C51.8152 6.89402 45.0847 4.5 37.7998 4.5C30.5149 4.5 23.7844 6.89403 18.3239 10.9535C17.4374 11.6124 16.1846 11.4281 15.5256 10.5416C14.8666 9.65516 15.051 8.40233 15.9374 7.74333ZM65.0261 15.6001C65.9257 14.9592 67.1745 15.1688 67.8155 16.0684C72.2111 22.2377 74.7998 29.8164 74.7998 38C74.7998 46.1836 72.2111 53.7623 67.8155 59.9316C67.1745 60.8311 65.9257 61.0408 65.0261 60.3999C64.1265 59.7589 63.9168 58.5101 64.5578 57.6105C68.4841 52.0998 70.7998 45.3284 70.7998 38C70.7998 30.6716 68.4841 23.9002 64.5578 18.3895C63.9168 17.4899 64.1265 16.2411 65.0261 15.6001ZM10.5735 15.6001C11.4731 16.2411 11.6828 17.4899 11.0418 18.3895C7.11551 23.9002 4.7998 30.6716 4.7998 38C4.7998 45.3284 7.11551 52.0998 11.0418 57.6105C11.6828 58.5101 11.4731 59.7589 10.5735 60.3999C9.67393 61.0408 8.42508 60.8312 7.78413 59.9316C3.38855 53.7623 0.799805 46.1836 0.799805 38C0.799805 29.8164 3.38855 22.2377 7.78413 16.0684C8.42508 15.1689 9.67393 14.9592 10.5735 15.6001ZM15.5256 65.4584C16.1846 64.5719 17.4374 64.3876 18.3239 65.0466C23.7844 69.106 30.5149 71.5 37.7998 71.5C45.0847 71.5 51.8152 69.106 57.2757 65.0465C58.1622 64.3876 59.415 64.5719 60.074 65.4584C60.733 66.3448 60.5486 67.5977 59.6622 68.2567C53.5389 72.8088 45.9776 75.5 37.7998 75.5C29.622 75.5 22.0607 72.8088 15.9375 68.2567C15.051 67.5977 14.8666 66.3448 15.5256 65.4584Z" class="LightGray" fill='current' />
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M15.9373 7.74333C22.0606 3.19119 29.6218 0.5 37.7996 0.5C45.9775 0.5 53.5387 3.19119 59.662 7.74333C60.5484 8.40233 60.7328 9.65516 60.0738 10.5416C59.4148 11.4281 58.162 11.6124 57.2755 10.9535C51.815 6.89402 45.0845 4.5 37.7996 4.5C30.5147 4.5 23.7842 6.89403 18.3237 10.9535C17.4373 11.6125 16.1844 11.4281 15.5254 10.5416C14.8664 9.65516 15.0508 8.40233 15.9373 7.74333ZM65.0259 15.6001C65.9255 14.9592 67.1744 15.1688 67.8153 16.0684C72.2109 22.2377 74.7996 29.8164 74.7996 38C74.7996 46.1836 72.2109 53.7623 67.8153 59.9316C67.1744 60.8311 65.9255 61.0408 65.0259 60.3999C64.1263 59.7589 63.9167 58.5101 64.5576 57.6105C68.4839 52.0998 70.7996 45.3284 70.7996 38C70.7996 30.6716 68.4839 23.9002 64.5576 18.3895C63.9167 17.4899 64.1263 16.2411 65.0259 15.6001Z" class="LightGray" fill='current' />
                                            </svg>
                                            <img src={Shipper} className="img-fluid" alt="Shipper" />

                                        </div>
                                        <p>Shipper</p>

                                    </div>
                                </div>
                                <div className="col-xxl-2 col-lg-3 col-md-4 col-12">
                                    <div
                                        className="Offer_Type" onClick={(e) =>
                                            this.props.onchangeOffertype(e, 5)
                                        }>
                                        <div className="position-relative Image_Block">
                                            <svg width="75" height="76" viewBox="0 0 75 76" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="37.7998" cy="37.875" r="25" class="DarkGray" fill='current' />
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M15.9374 7.74333C22.0607 3.19119 29.622 0.5 37.7998 0.5C45.9776 0.5 53.5389 3.19119 59.6622 7.74333C60.5486 8.40233 60.733 9.65516 60.074 10.5416C59.415 11.4281 58.1622 11.6124 57.2757 10.9534C51.8152 6.89402 45.0847 4.5 37.7998 4.5C30.5149 4.5 23.7844 6.89403 18.3239 10.9535C17.4374 11.6124 16.1846 11.4281 15.5256 10.5416C14.8666 9.65516 15.051 8.40233 15.9374 7.74333ZM65.0261 15.6001C65.9257 14.9592 67.1745 15.1688 67.8155 16.0684C72.2111 22.2377 74.7998 29.8164 74.7998 38C74.7998 46.1836 72.2111 53.7623 67.8155 59.9316C67.1745 60.8311 65.9257 61.0408 65.0261 60.3999C64.1265 59.7589 63.9168 58.5101 64.5578 57.6105C68.4841 52.0998 70.7998 45.3284 70.7998 38C70.7998 30.6716 68.4841 23.9002 64.5578 18.3895C63.9168 17.4899 64.1265 16.2411 65.0261 15.6001ZM10.5735 15.6001C11.4731 16.2411 11.6828 17.4899 11.0418 18.3895C7.11551 23.9002 4.7998 30.6716 4.7998 38C4.7998 45.3284 7.11551 52.0998 11.0418 57.6105C11.6828 58.5101 11.4731 59.7589 10.5735 60.3999C9.67393 61.0408 8.42508 60.8312 7.78413 59.9316C3.38855 53.7623 0.799805 46.1836 0.799805 38C0.799805 29.8164 3.38855 22.2377 7.78413 16.0684C8.42508 15.1689 9.67393 14.9592 10.5735 15.6001ZM15.5256 65.4584C16.1846 64.5719 17.4374 64.3876 18.3239 65.0466C23.7844 69.106 30.5149 71.5 37.7998 71.5C45.0847 71.5 51.8152 69.106 57.2757 65.0465C58.1622 64.3876 59.415 64.5719 60.074 65.4584C60.733 66.3448 60.5486 67.5977 59.6622 68.2567C53.5389 72.8088 45.9776 75.5 37.7998 75.5C29.622 75.5 22.0607 72.8088 15.9375 68.2567C15.051 67.5977 14.8666 66.3448 15.5256 65.4584Z" class="LightGray" fill='current' />
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M15.9373 7.74333C22.0606 3.19119 29.6218 0.5 37.7996 0.5C45.9775 0.5 53.5387 3.19119 59.662 7.74333C60.5484 8.40233 60.7328 9.65516 60.0738 10.5416C59.4148 11.4281 58.162 11.6124 57.2755 10.9535C51.815 6.89402 45.0845 4.5 37.7996 4.5C30.5147 4.5 23.7842 6.89403 18.3237 10.9535C17.4373 11.6125 16.1844 11.4281 15.5254 10.5416C14.8664 9.65516 15.0508 8.40233 15.9373 7.74333ZM65.0259 15.6001C65.9255 14.9592 67.1744 15.1688 67.8153 16.0684C72.2109 22.2377 74.7996 29.8164 74.7996 38C74.7996 46.1836 72.2109 53.7623 67.8153 59.9316C67.1744 60.8311 65.9255 61.0408 65.0259 60.3999C64.1263 59.7589 63.9167 58.5101 64.5576 57.6105C68.4839 52.0998 70.7996 45.3284 70.7996 38C70.7996 30.6716 68.4839 23.9002 64.5576 18.3895C63.9167 17.4899 64.1263 16.2411 65.0259 15.6001Z" class="LightGray" fill='current' />
                                            </svg>
                                            <img src={Unit} className="img-fluid" alt="Unit" />

                                        </div>
                                        <p>Unit</p>

                                    </div>
                                </div>

                            </div>
                            {/* <form className="Offer_Form">  */}
                            <div className="row">
                                <div className="col-12 mt-28 d-flex align-items-center">

                                </div>

                                <div className="col-6 mt-28">
                                    <Form.Group
                                        controlId="reward_amount"
                                        className="form-group"
                                    >
                                        <Form.Label>Deal Value (per unit)</Form.Label>
                                        <OverlayTrigger
                                            placement="top"
                                            delay={{ show: 250, hide: 400 }}
                                            overlay={renderTooltip('Example: For 99c Enter .99')}
                                        >
                                            <img src={Info} className="img-fluid" alt="Edit"
                                                style={{ padding: '1px', width: "28px", height: "28px", cursor: 'pointer' }} />
                                        </OverlayTrigger>

                                        {/* <button
                                            className="btn border-0 p-0 ml-15"
                                            type="button"
                                            data-toggle="tooltip"
                                            data-placement="top"
                                            title="'Example : For 99c enter .99"
                                        >
                                            <i className="fas fa-info-circle"></i>
                                        </button> */}

                                        <Form.Control
                                            type="text"
                                            placeholder="E.g. 1.00"
                                            name="reward_amount"
                                            onChange={(e) => this.props.changeHandler(e)}
                                            step=".01"
                                        />
                                    </Form.Group>
                                </div>

                                <div className="col-6 mt-28">
                                    <Form.Group
                                        controlId="reward_amount"
                                        className="form-group"
                                    >
                                        <Form.Label>Deal Code</Form.Label>

                                        <Form.Control
                                            type="text"
                                            placeholder="Deal Code"
                                            name="deal_code"
                                            onChange={(e) => this.props.changeHandler(e)}

                                        />
                                    </Form.Group>
                                </div>

                                <div className="col-12 mt-28">
                                    <Form.Group
                                        controlId="title"
                                        className="form-group"
                                    >
                                        <Form.Label>Title</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Title"
                                            name="title"
                                            onChange={(e) => this.props.changeHandler(e)}
                                        />
                                    </Form.Group>
                                </div>
                                <div className="col-12 mt-28">
                                    <Form.Group className="form-group">
                                        <Form.Label>Description</Form.Label>
                                        <Form.Control
                                            placeholder="Description"
                                            as="textarea"
                                            rows={1}
                                            name="description"
                                            id="description"
                                            onChange={(e) => this.props.changeHandler(e)}
                                        />
                                    </Form.Group>
                                </div>

                                {/* <div className="col-12 mt-28 row"> */}

                                {/* </div> */}

                                <div className="col-md-3 col-12 mt-28">
                                    <Form.Group
                                        className="form-group"
                                        controlId="exampleForm.ControlInput1"
                                        style={{ marginTop: '7px' }}>
                                        <Form.Label>Manufacturer ships between</Form.Label>

                                    </Form.Group>
                                </div>

                                <div className="col-md-3 col-12 mt-28">
                                    <Form.Group
                                        className="form-group"
                                        controlId="exampleForm.ControlInput1"
                                    >
                                        <Form.Label>Start Date</Form.Label>
                                        <Form.Control
                                            type="date"
                                            name="start_date"
                                            onKeyDown={(e) => e.preventDefault()}
                                            onChange={(e) => this.props.setStartDate(e)}
                                            min={
                                                new Date(
                                                    new Date().getTime() +
                                                    1 * 24 * 60 * 60 * 1000
                                                )
                                                    .toISOString()
                                                    .split("T")[0]
                                            }
                                        />
                                    </Form.Group>
                                </div>
                                <div className="col-md-3 col-12 mt-28">
                                    <Form.Group
                                        className=" form-group"
                                        controlId="exampleForm.ControlInput2"
                                    >
                                        <Form.Label className="form-group">
                                            End Date
                                        </Form.Label>

                                        <Form.Control
                                            type="date"
                                            name="end_date"
                                            onKeyDown={(e) => e.preventDefault()}
                                            onChange={(e) => this.props.setEndDate(e)}
                                            min={
                                                new Date().toISOString().split("T")[0]
                                            }
                                        />
                                    </Form.Group>
                                </div>
                                <div className="col-md-3 col-12 mt-28">
                                    <Form.Group
                                        className="form-group"
                                        controlId="shutoff_date"
                                    >
                                        <Form.Label className="form-group">
                                            Order must be placed by
                                        </Form.Label>
                                        <Form.Control
                                            type="date"
                                            name="shutoff_date"
                                            onKeyDown={(e) => e.preventDefault()}
                                            onChange={(e) => this.props.setShutoffDate(e)}
                                            min={
                                                new Date().toISOString().split("T")[0]
                                            }
                                        />
                                    </Form.Group>
                                </div>

                                <div className="col-12 mt-28">
                                    <Form.Group className="form-group">
                                        <Form.Label>
                                            Min Purchase Quantity
                                        </Form.Label>
                                        <Form.Control
                                            placeholder="Enter qty here, max qty allowed is 10"
                                            type="text"
                                            name="min_purchase_amount"
                                            id="min_purchase_amount"
                                            onChange={(e) => this.props.changeHandler(e)}
                                            max={10}
                                        />
                                    </Form.Group>
                                </div>

                                <div className="col-12 mt-28">
                                    <Form.Group
                                        controlId="Descripation"
                                        className="form-group"
                                    >
                                        <Form.Label>
                                            Purchase Requirement Description
                                        </Form.Label>
                                        <Form.Control
                                            placeholder="Purchase Requirement Description"
                                            as="textarea"
                                            rows={1}
                                            defaultValue=" "
                                            name="additional_details"
                                            onChange={(e) => this.props.changeHandler(e)}
                                        />
                                    </Form.Group>
                                </div>

                                <div className="col-12 mt-28">
                                    <Form.Group
                                        controlId="Descripation"
                                        className="form-group"
                                    >
                                        <Form.Label>
                                            Deal Legal Disclaimer
                                        </Form.Label>
                                        <Form.Control
                                            placeholder="Deal Legal Copy"
                                            as="textarea"
                                            rows={4}
                                            defaultValue=" "
                                            name="disclaimer"
                                            onChange={(e) => this.props.changeHandler(e)}
                                        />
                                    </Form.Group>
                                </div>
                            </div>
                            <div className="row ">
                                <div className="col-lg-4 col-md-6 col-12 mt-28">
                                    <Form.Group
                                        controlId="Description"
                                        className="form-group"
                                    >
                                        <Form.Label>Campaign Image</Form.Label>
                                        <p>
                                            File must be 250px * 250px, 150dpi png or
                                            jpg
                                        </p>

                                        <div className="File_Upload position-relative ml-7">
                                            <img
                                                src={FileIcon}
                                                className="img-fluid"
                                                alt="File Icon"
                                            />
                                            <p>
                                                <b>Drag & Drop Your</b> files here or{" "}
                                                <b>
                                                    <span>browse</span>
                                                </b>{" "}
                                                your computer
                                            </p>
                                            <input
                                                id="file-upload"
                                                type="file"
                                                name="fileUpload"
                                                onChange={(e) => this.props.onFileChange(e)}
                                                onClick={(event) => {
                                                    event.target.value = null;
                                                }}
                                            />
                                        </div>
                                    </Form.Group>
                                </div>

                                <div className="col-lg-4 col-md-6 col-12 mt-28"></div>
                            </div>

                            <div className="row">
                            </div>
                            <div className="row mt-20">
                                <div className="col-12 d-flex justify-content-end">
                                    <div className="TableButton">
                                        <button
                                            type="button"
                                            className="mr-10 btn btn-primary"
                                            onClick={(e) => {
                                                this.props.changePrevTab(e, 1);
                                            }}
                                        >
                                            <i
                                                className="fas fa-arrow-left mr-10"
                                                aria-hidden="true"
                                            ></i>{" "}
                                            Prev
                                        </button>
                                        <button
                                            type="button"
                                            className="btn btn-primary"
                                            onClick={(e) => {
                                                this.props.changeSecondTab(e, 3);
                                            }}
                                        >
                                            Next{" "}
                                            <i
                                                className="fas fa-arrow-right ml-10"
                                                aria-hidden="true"
                                            ></i>{" "}
                                        </button>
                                    </div>
                                </div>
                            </div>

                            {/* </form>  */}
                        </div>
                    </div>
                </div>
                <div className="col-xxl-3 col-xl-4 col-12">
                    <div className="Common_Block">
                        <div className="Common_Block_Header d-flex align-items-center justify-content-between">
                            <h5 className="">
                                Digital Deal Campaign Summary
                            </h5>
                        </div>
                        <div className="Common_Block_Body">
                            <ul className="Campaign_Summery">
                                <li>
                                    <img
                                        src={ProductsImage}
                                        className="img-fluid"
                                        alt="Products"
                                    />
                                    <span>Products</span>
                                    <h5>{this.props.checkboxlength}</h5>
                                </li>
                                <li>
                                    <img
                                        src={OfferValueImage}
                                        className="img-fluid"
                                        alt="Offer Value"
                                    />
                                    <span>Deal Value</span>
                                    <h5>
                                        {this.props.reward_amount}
                                    </h5>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="Common_Block mt-21">
                        <div className="Common_Block_Header d-flex align-items-center justify-content-between">
                            <h5 className="">Deal Preview</h5>
                        </div>
                        <div className="Common_Block_Body pt-12 pr-12 pb-12 pl-12">
                            <div className="Coupons_Card text-center">
                                <img
                                    src={scissors}
                                    className="img-fluid scissors"
                                    alt="Products"
                                />
                                <img
                                    src={
                                        this.props.selectedFile
                                            ? this.props.selectedFile
                                            : ""
                                    }
                                    className="img-fluid"
                                    alt=""
                                />
                                <h2 className="Price">
                                    {this.props.title
                                        ? this.props.title
                                        : ""}
                                    <span></span>
                                </h2>
                                <p>{this.props.description}</p>
                                {/* <button>Any Size</button> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Builddeal