import React from "react";
// import $ from "jquery";

// import { Link } from "react-router-dom";
// import ls from "local-storage";
// import axios from "axios";

// import Retailer from "../../Assets/images/retailer.svg";
// import Stores from "../../Assets/images/stores.svg";
// import Sold from "../../Assets/images/sold.svg";
// import Upcs from "../../Assets/images/upcs.svg";
// import Texas from "../../Assets/images/Texas.svg";
// import Retailer1 from "../../Assets/images/retailer-1.png";
// import Retailer2 from "../../Assets/images/retailer-2.png";
// import Retailer3 from "../../Assets/images/retailer-3.png";
// import Retailer4 from "../../Assets/images/retailer-4.png";
// import Retailer5 from "../../Assets/images/retailer-5.png";
// import Retailer6 from "../../Assets/images/retailer-6.png";
// import Retailer7 from "../../Assets/images/retailer-7.png";
// import Retailer8 from "../../Assets/images/retailer-8.png";
// import Retailer9 from "../../Assets/images/retailer-9.png";
// import Retailer10 from "../../Assets/images/retailer-10.png";
// import Search from "../../Assets/images/search.svg";

class RetailerModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      retailerdata: this.props.retailerData
    };
  }

  componentDidMount() {
    // console.log('>>>>wlist',this.props.WholeSalerlist)
  }

  // componentDidUpdate(prevProps) {
  //   // compare props
  //   if (this.props.dataParentToChild !== prevProps.dataParentToChild) {
  //     this.setState({
  //        retailerdata:this.props.retailerData

  //     })
  //    // this.couponSearch();

  //   }
  // }

  render() {
    console.log(">>retailer info>>>>>", this.state.retailerdata.length);

    return (
      <>
        <div
          className="modal fade"
          id="selectretaler-drop"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-custom modal-dialog-scrollable">
            <div className="modal-content whole-content">
              <div className="modal-header borderemove brand-whole-hd">
                <h5 className="modal-title" id="exampleModalLabel">
                  Select Retailers
                </h5>
                <button
                  type="button"
                  className="btn-close wholeseler-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>

              <div className="modal-body CustomScroll whole-seler-body">
                <div class="tab-content" id="nav-tabContent">
                  <div
                    class="tab-pane fade show active tab-text"
                    id="nav-home"
                    role="tabpanel"
                    aria-labelledby="nav-home-tab"
                  >
                    <div className="Common_brand_detail">
                      <div className="pr-12 pl-12 pt-20">
                        <ul className=" mt-15 d-flex  Retailer">
                          {this.state.retailerdata ? (
                            <>
                              {this.state.retailerdata.map((val, idx) => {
                                return val.map((retailer, key) => {
                                  console.log("ret", retailer);

                                  return (
                                    <li key={key}>
                                      <div className="Retailer_Block">
                                        <input
                                          type="checkbox"
                                          onChange={(e) =>
                                            this.props.checkretailerFunction(
                                              e,
                                              retailer.wholesaler_id,
                                              retailer.retailer_id
                                            )
                                          }
                                        />
                                        <div className="RetailerImage">
                                          <img
                                            src={retailer.logo}
                                            className="img-fluid "
                                            alt="Retailer"
                                          />
                                        </div>
                                        <h5>{retailer.retailer_name}</h5>
                                      </div>
                                    </li>
                                  );
                                });
                              })}
                            </>
                          ) : (
                            "No Retailers Available"
                          )}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer brand-whole-footer">
                <button
                  type="button"
                  className="btn btn-primary Btn_blue_modal"
                  //data-bs-dismiss="modal"
                  onClick={() => this.props.simplifiedFunction()}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default RetailerModal;
