import ls from "local-storage";
import Stores from "../../Assets/images/stores.svg";

const MySubscription = ({ companywholesalers, subscribeStore }) => {

    const companyData = ls.get('companyData') ? ls.get('companyData') : [];

    var allWholesalers = [];
    allWholesalers = companywholesalers.filter(element => companyData.CompanyWS.includes(element.wholesaler_number))

    return (

        <div className="profile-box">
            <h1 className="account-head">My Subscriptions</h1>
            {

                <div className="Card ">
                    <div className="Card_Header  Steps_Header">
                        <h5>Wholesalers</h5>
                    </div>
                    <div className="Card_Body p-3">
                        {allWholesalers.length > 0 ? (
                            <>
                                <ul className=" d-flex  Retailer">
                                    {allWholesalers.map(
                                        (val, idx) => {
                                            return (

                                                <li key={idx}>
                                                    <div className="Retailer_Block">
                                                        <div className="RetailerImage">
                                                            <img
                                                                className="img-fluid"
                                                                alt={val.wholesaler_name}
                                                                src={
                                                                    val.logo
                                                                }
                                                            />
                                                        </div>
                                                        <h5>
                                                            {val.wholesaler_name
                                                            }{" "}
                                                        </h5>
                                                    </div>
                                                </li>
                                            )
                                        }
                                    )}
                                </ul>
                            </>
                        ) : (
                            "NO Wholesaler Available"
                        )}
                    </div>
                </div>
            }

            {
                companyData.length > 0 ?
                    <div className="Card mt-20 Overview  Subscription_Stores">
                        <div className="Card_Header  Steps_Header">
                            <h5>Stores</h5>
                        </div>
                        <div className="Card_Body p-3">
                            <ul className="OverviewList row">

                                <li className="newOverview w-auto mb-0 col-md-6">
                                    <div className="Icon">
                                        <img
                                            src={Stores}
                                            className="img-fluid"
                                            alt="Retailer"
                                        />
                                    </div>
                                    <div className="OverviewList_Info">
                                        <p>Stores</p>
                                        <span className="d-block">{companyData ? companyData.Overview.Stores : ''}</span>
                                        <small className="d-block">Selling UPCs</small>

                                    </div>
                                </li>

                                <li className="newOverview w-auto mb-0">
                                    <div className="Icon">
                                        <img
                                            src={Stores}
                                            className="img-fluid"
                                            alt="Retailer"
                                        />
                                    </div>
                                    <div className="OverviewList_Info">
                                        <p>Stores</p>
                                        <span className="d-block">{subscribeStore ? subscribeStore.SubscribedStores : ''}</span>
                                        <small className="d-block">Subscribed </small>

                                    </div>


                                </li>

                            </ul>
                        </div>
                    </div>
                    : ''
            }
        </div>
    )

}
export default MySubscription;