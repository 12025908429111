import $ from "jquery";
import React from "react";
import { Button } from "react-bootstrap";
import Header from "../Dashboard/Header";
import ls from "local-storage";
import * as CONFIG from "../../Components/_config";
import axios from "axios";
import RV from "simple-react-validator";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Redirect } from "react-router";
import DataTable from "react-data-table-component";
import FooterStrict from "../Dashboard/FooterStrict";
import deal from "../../Assets/images/deals.png"; 
import edit_pencil from "../../Assets/images/edit_pencil.png"; 
import expire from "../../Assets/images/expire.png"; 
import analytics from "../../Assets/images/analytics.png"; 

 
import ClipLoader from "react-spinners/ClipLoader";
const Loader = () => <div id="cover-spin"></div>;


 
var masterdata = "";
//export default function Wizard() {
class Order extends React.Component {
  constructor(props) {
    super(props);
    this.validator = new RV();
    this.validator.visibleFields = [];
    toast.configure();
    this.state = {
      upcList: "",
      upcGuid: "",
      upc_guid: [],
      upc_guid_list: "",
      orderfile:'',
 
      columns: [
     
        {
          name: "RETAILER",
          selector: (row) => row.retailer_name
        },
        {
          name: "STORE",
          selector: (row) => row.store_name,
          sortable: true
        },
        {
          name: "WAREHOUSE",
          selector: (row) => row.warehouse_name,
          sortable: true
      },
        {
          name: "ORDER DATE",
          selector: (row) => this.handleSeconDdate(new Date(row.order_date.substring(
            0,
            row.order_date.length - 1
          )))
        },
        {
          name: "ORDER QTY",
          selector: (row) => row.order_quanity,
          sortable: true
      },
      {
        name: "AMOUNT",
        selector: (row) =>'$'+ row.order_amount,
        sortable: true
    },
    {
      name: "EDIT/EXPIRE",
      selector: (row) => row.category_name,
      cell: row => (
         <>
             <a
        href="/"
        className="text-decoration-none"
        data-bs-toggle="modal" 
        data-bs-target="#inactivemodal"
       >
         <img src={edit_pencil} height={'23px'} width={'22px'} style={{marginRight:'12px'}} alt={`pencil`}/>
          </a>

          <a
        href="/"
        className="text-decoration-none"
        data-bs-toggle="modal" 
        data-bs-target="#inactivemodal"
       >
          <img src={expire} height={'26px'} width={'25px'} alt={`expire`}/>
          </a>

          </>
        )
    },
     
    {
      name: "ANALYSIS",
      selector: (row) => row.category_name,
      cell: row => (
        <a
        href="/"
        className="text-decoration-none"
        data-bs-toggle="modal" 
        data-bs-target="#inactivemodal"
       >
          <img src={analytics} height={'26px'} width={'25px'} alt={`analytics`}/>
      </a>

        )
    },
 
    {
      name: "STATUS",
      selector: (row) => row.deal_order_status,
      sortable: true,
      cell: row => (
        row.deal_order_status ===1 ?
        <button type="button" className="btn btn-outline-success">
        Active
       </button>
        :   
        <button type="button" className="btn btn-outline-danger">
        Expired
        </button>
     
      )
    },
  
      ],

      formControls: {}
    };
    //this.selectWholesaler = this.selectWholesaler.bind(this);
  }

 
  async componentDidMount() {
    $(".wizard li").click(function () {
      $(this).removeClass("completed");
      $(this).prevAll().addClass("completed");
      $(this).nextAll().removeClass("completed");
      $(".wizard li").removeClass("active");
      $(this).addClass("active");
    });
    $(".Offer_Type").on("click", function () {
      $(".Offer_Type").removeClass("Selected");
      $(this).addClass("Selected");
    });
    $(".Search_Box").on("click", function () {
      $(".Search_Box input").css("display", "block");
    });

    $(document).mouseup(function (e) {
      if ($(e.target).closest(".Search_Box input").length === 0) {
        $(".Search_Box input").css("display", "none");
      }
    });

   // var b_guid = ls.get("brands") ? ls.get("brands")[0].guid : "";
    const b_guid = ls.get("selectedbrand") ? ls.get("selectedbrand")[0].guid : "";

 
    var deal_guid = this.props.location.state.params.id1;
    const token_config = {
      headers: CONFIG.config_head.headers
    };


    const requestOne = axios.get(
      CONFIG.API_URL + `brand/${b_guid}`,
      token_config
    );
    const requestTwo = axios.get(
      CONFIG.API_URL + `deal/order/${deal_guid}`,
      token_config
    );
  
   
        axios
        .all([requestOne, requestTwo ])
        .then(
          axios.spread((...responses) => {
           // let responseOne = responses[0];
            let responseTwo = responses[1];
             
            this.setState({
                tableData: responseTwo.data.Message.Orders,
                warehouse:responseTwo.data.Message.warehouse,
                orderfile:responseTwo.data.Message.AllOrderURL

             });
  
           })
        )    
      .catch(function (error) {
        if(error.response){
          if (error.response.status === 502) {
              toast.error("Bad Request!");
          } else if (error.response.status === 400) {
              
              toast.error(error.response.data.Message);
            
          } else if (error.response.status === 403) {
              toast.error(error.response.data.Message);
              ls.clear("token");
              ls.clear("brands");
              window.location.href = "/login";
          } else {
            toast.error(error.response.data.Message);
              
            console.log(error);
          }
      }
      });
  }

  rowSelectCritera = (row) => this.state.upcArr.includes(row.upc11);

 
  handleDate(date) {
    var month = date.getMonth() + 1;
    var day = date.getDate();
    var year = date.getFullYear();

    if (month < 10) month = "0" + month.toString();
    if (day < 10) day = "0" + day.toString();

    let calculate_date = year + "-" + month + "-" + day;

    return calculate_date;
  }

  handleSeconDdate(date) {
    var month = date.getMonth() + 1;
    var day = date.getDate();
    var year = date.getFullYear();

    if (month < 10) month = "0" + month.toString();
    if (day < 10) day = "0" + day.toString();

    let calculate_date = month + "-" + day + "-" + year;
    return calculate_date;
  }

  changeHandler = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    this.setState({
      formControls: {
        ...this.state.formControls,
        [name]: value
      }
    });
  };


  
  render() {
    
    const isLogged = ls.get("token");
    if (!isLogged) {
      return <Redirect to="/login" />;
    }
    
   
  return (
      <>
  <Header location={`Order`}/>

{this.state.loading ? <Loader /> : null}

<section className="ContentBlock  pb-24 Main_Block">
  <div className="container-fluid">
    <div className="row justify-content-center">
      <div className=" col-12">
        <div className="Coupons_Block mt-21">
          <div className="Common_Block_Coupons_Header d-flex align-items-center justify-content-between Deals_Header">
            <div className="d-flex align-items-center">
              <img src={deal} className="img-fluid mr-7" alt="deal" />
              <h5>  DEAL ORDERS</h5>

           
            </div>
           
          <div className="Common_Block_Coupons_Header_Right">

          <a
                  href={this.state.orderfile}
                  target="_blank"
                  rel="noopener noreferrer"
                  download
                 >
                  <Button id="myorderbtn" style={{marginRight:'65px'}}>Download Store Orders</Button>
                </a>

           
           
            {
            CONFIG.config_coupon_scope.includes('coupon:write') ?
           <a href="/listdeal"><button className="btn btn-primary profile-update" type="button"> BACKTO DEALS </button></a>
           :
           
           <a href="/" data-bs-toggle="modal" data-bs-target="#ViewModal"><button className="btn btn-primary profile-update" type="button" > CREATE DIGITAL COUPON </button></a>
      
            }
           
              <div className="Sort">
                {/* <svg width="20" height="17" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M16 0L20 5H17V17H15V5H12L16 0ZM11 15V17H0V15H11ZM11 8V10H0V8H11ZM9 1V3H0V1H9Z" fill="current"/>
                            </svg> */}

                {/* <span>Sort</span> */}
              </div>
              <div className="View">
                {/* <span>View: Weekly</span>   */}
              </div>
            </div>
          </div>

          {this.state.couponLoader ? (
                <div
                  className="Clip_Loader"
                  style={{ minHeight: "280px" }}
                >
                  <ClipLoader
                    color={"rgb(0, 143, 251)"}
                    loading={true}
                    size={50}
                  />
                </div>
              ) :
          
          <div className="table-responsive Coupons_Table">
                <DataTable
                              columns={this.state.columns}
                              data={this.state.tableData}
                              pagination
                            />
            
          </div>
}
      
          <div className="d-flex align-items-center justify-content-end Pagination_Block ">
             </div>
        </div>
      </div>
    </div>
  </div>
</section>
 <FooterStrict />

      </>
    );
  }
}

export default Order;
