//import ls from "local-storage";
import { toast } from "react-toastify";
import * as CONFIG from "../Components/_config";
import axios from "axios";

export const buildCouponRetailer = (data) => {
  var config = {
    method: "post",
    url: CONFIG.API_URL + "coupon/",
    headers: CONFIG.config_head.headers,
    data: data
  };
  return axios(config)
    .then((response) => {
      if (response.status === 200)
        return response;
    })
    .catch((error) => {
      if (error.response.status === 400) {
        toast.error(error.response.data.Message);
        return error.response
      } else if (error.response.status === 403) {
        toast.error(error.response.data.Message);
        return error.response
      } else {
        toast.error(error.response.data.Message);
        return error.response
      }
    });
};

export const getWholsalerList = (brandguid) => {
  var masterconfig = {
    method: "get",
    url: CONFIG.API_URL + `master/${brandguid}?type=wholesaler&search=''`,
    headers: CONFIG.config_head.headers
  };
  return axios(masterconfig)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response.status === 502) {
        toast.error("Bad Request!");
        return error;
      } else if (error.response.status === 400) {
        //return error;
        toast.error(error.response.data.Message);
      } else if (error.response.status === 403) {
        //return error;

        toast.error(error.response.data.Message);
      } else {
        //return error;
        toast.error(error.response.data.Message);
      }
    });
};

export const getRetailerList = (brandguid) => {
  var config = {
    method: "get",
    url: CONFIG.API_URL + `master/${brandguid}?type=retailer&search=''`,
    headers: CONFIG.config_head.headers
  };
  return axios(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {

      console.log(error);
      if (error.response.status === 400) {
        toast.error(error.response.data.Message);
        //return error
      } else if (error.response.status === 403) {
        toast.error(error.response.data.Message);
        //return error 
      } else {
        toast.error(error.response.data.Message);
        //return error
      }
    });
};

export const buildCoupon = (data) => {
  var config = {
    method: "post",
    url: CONFIG.API_URL + "coupon/",
    headers: CONFIG.config_head.headers,
    data: data
  };
  return axios(config)
    .then((response) => {

      if (response.status === 200) {
        return response;
      }
    })
    .catch((error) => {
      if (error.response.status === 400) {
        toast.error(error.response.data.Message);
        return error.response
      } else if (error.response.status === 403) {
        toast.error(error.response.data.Message);
        return error.response
      } else {
        toast.error(error.response.data.Message);
        return error.response
      }
    });
};

//bulk coupon creation

export const buildCouponMultiple = (data) => {
  var config = {
    method: "post",
    url: CONFIG.API_URL + "coupon/addmultiplecoupons",
    headers: CONFIG.config_head.headers,
    data: data
  };
  return axios(config)
    .then((response) => {

      if (response.status === 200) {
        return response;
      }
    })
    .catch((error) => {
      if (error.response.status === 400) {
        toast.error(error.response.data.Message);
        return error.response
      } else if (error.response.status === 403) {
        toast.error(error.response.data.Message);
        return error.response
      } else {
        toast.error(error.response.data.Message);
        return error.response
      }
    });
};
