import React from "react";
import Header from "../Dashboard/Header";
import Avtar1 from "../../Assets/images/avtar-1.png";
import Avtar2 from "../../Assets/images/avtar-2.png";
import Avtar3 from "../../Assets/images/avtar-3.png";
import Avtar4 from "../../Assets/images/avtar-4.png";
import Avtar5 from "../../Assets/images/avtar-5.png";
import Avtar6 from "../../Assets/images/avtar-6.png";
import Retailer from "../../Assets/images/retailer.svg";
import ls from "local-storage";
import axios from "axios";
import * as CONFIG from "../../Components/_config";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import RV from "simple-react-validator";
import $ from "jquery";
import { Redirect } from "react-router";
import Stores from "../../Assets/images/stores.svg";
import Sold from "../../Assets/images/sold.svg";
//import Wholesalers from "../../Assets/images/wholesalers.svg";
//import { Link } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import Delete from "../../Assets/images/delete.png";
import Edit from "../../Assets/images/edit.png";
import EditUserModal from "../../Components/Common/EditUserModal";
import FooterStrict from "../Dashboard/FooterStrict";
//import Upcs from "../../Assets/images/upcs.svg";
import Download from "../../Assets/images/download.png";

import DataTable from "react-data-table-component";
import AccountDetails from './AccountDetails';
import MySubscription from "./MySubscriptions";
import Users from "./Users";
import Journey from "./Journey";


const Loader = () => <div id="cover-spin"></div>;


class Profile extends React.Component {
  constructor(props) {
    super(props);

    this.updateModalUser = this.updateModalUser.bind(this);
    toast.configure();
    this.validator = new RV();
    this.validator.visibleFields = [];

    this.state = {
      userinfo: "",
      brandDetails: "",
      AllWholesaler: "",
      brandWholesaler: {},
      AllRetailer: "",
      brandRetailer: {},
      loading: false,
      formControls: {},
      accountLoader: true,
      userList: '',
      requiredItem: 0,
      journeydetails: {},
      journeyLoader: true,
      currentsummarydetails: {},
      asofDatadetails: {},
      title: 'All Brands',
      columns: [
        {
          name: 'Invoice Date',
          selector: row => row.InvoiceDate,
          sortable: true
        },
        {
          name: 'Invoice Id',
          selector: row => row.InvoiceNumber,
          sortable: true
        },
        {
          name: 'Amount',
          selector: row => row.InvoiceAmount === 'null' ? 0 : '$' + row.InvoiceAmount,
        },
        {
          name: 'Download',
          cell: row => (
            <img src={Download} style={{ height: '35px', width: '35px' }} alt={`download`} />


          )
        }
      ]
    };

    this.AlertModal = this.AlertModal.bind(this);
    this.replaceModalItem = this.replaceModalItem.bind(this);
  }

  async componentDidMount() {

    var user_guid = this.props.match.params.user_guid;

    // var guid = ls.get("brands") ? ls.get("brands")[0].guid : "";
    const selectedbrand = ls.get("selectedbrand") ? ls.get("selectedbrand") : "";
    var guid = selectedbrand[0].guid;

    var udata = "";

    const token_config = {
      headers: CONFIG.config_head.headers
    };
    var profile_config = {
      headers: CONFIG.config_head.headers,
      data: udata
    };

    const requestOne = axios.get(
      CONFIG.API_URL + `brand/${guid}`,
      profile_config
    );

    const requestTwo = axios.get(
      CONFIG.API_URL + `user/${user_guid}`,
      token_config
    );

    const requestThree = axios.get(
      CONFIG.API_URL + `master/${guid}?type=wholesaler&search=''`,
      token_config
    );

    const requestFour = axios.get(
      CONFIG.API_URL + `brand/getstores//${guid}?`,
      token_config
    );

    const requestFive = axios.get(
      CONFIG.API_URL + `master?type=role&search=''`,
      token_config
    );

    const requestSix = axios.get(
      CONFIG.API_URL + `brand/invoice/${guid}`,
      token_config
    )

    axios
      .all([requestOne, requestTwo, requestThree, requestFour, requestFive, requestSix])
      .then(
        axios.spread((...responses) => {
          let responseOne = responses[0];
          let responseTwo = responses[1];
          let responseThree = responses[2];
          let responseFour = responses[3];
          let responseFive = responses[4];

          let responseSix = responses[5];


          var upcarray = [];
          var retailerarray = [];
          var wholesalerarray = [];

          Object.keys(responseOne.data.Message.upc).map((val, idx) =>

            upcarray.push(responseOne.data.Message.upc[val].upc11)
          );

          Object.keys(responseOne.data.Message.retailers).map((val, idx) =>

            retailerarray.push(parseInt(responseOne.data.Message.retailers[val]))
          );


          var groups = Object.create(null);

          responseThree.data.Message.forEach(function (a) {
            groups[a.wholesaler_number] = groups[a.wholesaler_number] || [];
            groups[a.wholesaler_number].push(a);
          });

          var wholesaleridarr = [];
          var brandWholesalerArr = [];

          responseThree.data.Message.map((val, idx) =>
            wholesaleridarr.push(val.wholesaler_guid)
          );

          responseOne.data.Message.wholesalers.map((val, idx) => {
            return val.has_full_access ? (
              brandWholesalerArr.push(val),
              wholesalerarray.push(parseInt(val.WholesalerNumber))
            )
              : ''
          })

          this.setState({
            brandInfo: responseOne.data.Message,
            userinfo: responseTwo.data.Message,
            brandWholesaler: brandWholesalerArr,
            brandRetailer: responseOne.data.Message.retailers,
            AllWholesaler: groups,
            wholesaleridArr: wholesaleridarr,
            upclist: upcarray,
            retailerlist: retailerarray,
            wholesalerlist: wholesalerarray,
            getStoredetails: responseFour.data.Message,
            masterRoles: responseFive.data.Message,
            accountLoader: false,
            userList: responseOne.data.Message.user,
            overviewdetails: {},
            invoicedetails: responseSix.data.Message,
            tableData: responseSix.data.Message,

          },
            () => {
              this.getOverViewDetails();
              this.retailerList();
              this.journey()
            },
          );


        })
      )
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 400) {
            toast.error(error.response.data.Message);
          }
          else if (error.response.status === 403) {
            toast.error(error.response.data.Message);

            //window.location.href = "/login";
          } else {
            toast.error(error.response.data.Message);
          }
        }
      });
  }


  async journey() {
    const token_config = {
      headers: CONFIG.config_head.headers
    };

    const guid = ls.get("selectedbrand") ? ls.get("selectedbrand")[0].guid : "";

    const requestOne = axios.get(
      CONFIG.API_URL + `brand/journey/${guid}`,
      token_config
    );


    axios
      .all([requestOne])
      .then(
        axios.spread((...responses) => {
          let responseOne = responses[0];

          Object.keys(responseOne.data.Message).length > 0 ?

            this.setState({
              currentsummarydetails: responseOne.data.Message[Object.keys(responseOne.data.Message).length - 1],
              journeyLoader: false,
              asofDatadetails: responseOne.data.Message,

            })
            :
            this.setState({
              journeydetails: responseOne.data.Message,
              journeyLoader: false
            })


        })
      ).catch(() => {


      })
  }

  async getOverViewDetails() {
    var today = new Date().toISOString().split("T")[0];
    var dateParts = today.split("-");
    dateParts[0] = parseInt(dateParts[0], 10) - 1;
    var pastYear = dateParts.join("-");

    let data = JSON.stringify({
      BrandID: 0,
      EndDate: today,
      OrderBy: "QtySOld",
      StartDate: pastYear,
      UPC: this.state.upclist,
      type: 'Overview',
      Wholesaler: this.state.wholesalerlist,
      Retailer: this.state.retailerlist
    });
    let config = {
      method: "post",
      url: CONFIG.API_URL + "query",
      headers: CONFIG.config_head.headers,
      data: data
    };
    return axios(config)
      .then((response) => {

        this.setState({ overviewdetails: response.data.Message[0] })
      })
      .catch(function (error) {
        console.log(error);
      });
  }


  showLoader = () => {
    this.setState({ loading: true });
  }
  hideLoader = () => {

    this.setState({ loading: false });

  };

  async retailerList() {

    //var guid = ls.get("brands") ? ls.get("brands")[0].guid : "";
    const guid = ls.get("selectedbrand") ? ls.get("selectedbrand")[0].guid : "";

    const url = CONFIG.API_URL + `master/${guid}?type=retailer&search=''`;

    const result = await axios.get(url, {
      headers: CONFIG.config_head.headers
    });

    var groups = Object.create(null);

    result.data.Message.forEach(function (a) {
      groups[a.rsa_identifier] = groups[a.rsa_identifier] || [];
      groups[a.rsa_identifier].push(a);
    });

    this.setState({ AllRetailer: groups });


  }

  AlertModal(e) {

    var user_guid = e.target.getAttribute("data-userguid");
    $("#confirmuserModal").trigger("click");
    this.setState({ user_guid: user_guid });
  }

  RemoveUser(user_guid) {
    $("#staticBackdrop").css("display", "none");
    $(".modal-backdrop").first().css("display", "none");

    this.showLoader();

    let config = {
      method: "delete",
      url: CONFIG.API_URL + `user/${user_guid}`,
      headers: CONFIG.config_head.headers,
    };
    return axios(config)
      .then((response) => {
        if (response.status === 204) {
          toast.success('User Removed Successfully')
          this.hideLoader();
          setTimeout(() => window.location.reload(), 2400)
        }

      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 400) {

            this.hideLoader();
            toast.error(error.response.data.Message);
            window.location.reload()

          }
          else {
            this.hideLoader();
            toast.error(error.response.data.Message);
            window.location.reload();
          }
        }
      });
  }
  changeHandler = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    this.setState({
      formControls: {
        ...this.state.formControls,
        [name]: value
      }
    });
  };

  updatePassword = async (e) => {
    e.preventDefault();

    if (!this.validator.allValid()) {
      //toast.error("Please fill all the fields.")
      this.validator.showMessages();

      this.forceUpdate();
      return false;
    } else {

      var user_guid = this.props.match.params.user_guid;

      var pattern = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])([!@#\$%\^&\*]?)(?=.{8,})/);

      if (!pattern.test(this.state.formControls.new_password)) {
        toast.error('New Password should be 8 characters long, contain a number, a capital letter and a special character')
      }
      else {

        this.showLoader();
        var data = JSON.stringify({
          old_password: this.state.formControls.old_password.trim(),
          new_password: this.state.formControls.new_password.trim()
        });

        var config = {
          method: "post",
          url: CONFIG.API_URL + `user/password-reset/${user_guid}`,
          headers: CONFIG.config_head.headers,
          data: data
        };

        axios(config)
          .then((response) => {
            if (response.status === 204) {
              this.hideLoader();
              toast.success("Password Sent Successfully");
              window.location.reload();
            }
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.status === 502) {
                this.hideLoader();
                toast.error("Bad Request!");
              } else if (error.response.status === 400) {
                this.hideLoader();
                toast.error(error.response.data.Message);
              } else if (error.response.status === 403) {
                this.hideLoader();

                toast.error(error.response.data.Message);
                ls.clear("token");
                ls.clear("brands");
                window.location.href = "/login";
              } else {
                this.hideLoader();
                toast.error(error.response.data.Message);
              }
            }
          });
      }
    }
  };

  updateUser = async (e) => {
    e.preventDefault();

    var user_guid = this.props.match.params.user_guid;
    //var b_guid = ls.get("brands") ? ls.get("brands")[0].guid : "";
    const b_guid = ls.get("selectedbrand") ? ls.get("selectedbrand")[0].guid : "";

    var role_guid = ls.get("brands") ? ls.get("brands")[0].role_guid : "";

    if ($("#primary_phone").val().trim().length > 10) {
      toast.error("Phone No must be with a maximum length of 10", {
        theme: "colored"
      });
      return false;
    } else {

      var data = JSON.stringify({
        first_name: this.state.formControls.first_name
          ? this.state.formControls.first_name
          : this.state.userinfo.first_name,
        last_name: this.state.formControls.last_name
          ? this.state.formControls.last_name
          : this.state.userinfo.last_name,
        primary_phone: this.state.formControls.primary_phone
          ? this.state.formControls.primary_phone
          : this.state.userinfo.primary_phone,
        email: this.state.formControls.email
          ? this.state.formControls.email
          : this.state.userinfo.email,
        secondary_phone: "",
        role: role_guid,
        brand_job_title: this.state.userinfo.brand_job_title,
        state: this.state.userinfo.user_status
      });

      var config = {
        method: "put",
        url: CONFIG.API_URL + `user/${user_guid}/${b_guid}`,
        headers: CONFIG.config_head.headers,
        data: data
      };


      axios(config)
        .then((response) => {
          // console.log(response)
          if (response.status === 201) {
            this.hideLoader();
            toast.success("Updated Successfully");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };


  updateModalUser(item) {
    //return;
    var groups = Object.create(null);

    this.state.masterRoles.forEach(function (a) {
      groups[a.role_name] = groups[a.role_name] || [];
      groups[a.role_name].push(a);
    });

    var role_guid = groups[item.role_name][0].brandiq_user_role_guid;
    //var b_guid = ls.get("brands") ? ls.get("brands")[0].guid : "";
    const b_guid = ls.get("selectedbrand") ? ls.get("selectedbrand")[0].guid : "";

    if (item.phone) {

      if ((item.phone.trim().length > 10) || (item.phone.trim().length < 10)) {

        toast.error("Phone No must be with a maximum length of 10", {
          theme: "colored"
        });
        return false;
      }
      else {

        $("#exampleModal").css("display", "none");
        $(".modal-backdrop").first().css("display", "none");
        this.showLoader();
        var data = JSON.stringify({
          first_name: item.first_name,
          last_name: item.last_name,
          primary_phone: item.primary_phone,
          email: item.email,
          secondary_phone: "",
          role: item.role_guid,
          brand_job_title: item.brand_job_title,
          state: 1

        });

        var config = {
          method: "put",
          url: CONFIG.API_URL + `user/${item.user_guid}/${b_guid}`,
          headers: CONFIG.config_head.headers,
          data: data
        };

        axios(config)
          .then((response) => {
            if (response.status === 201) {
              toast.success("Updated Successfully");
              this.hideLoader();
              // setTimeout(() => window.location.reload(), 2500);
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      }


    }
    else {
      $("#exampleModal").css("display", "none");
      $(".modal-backdrop").first().css("display", "none");


      this.showLoader();
      var updatedata = JSON.stringify({
        first_name: item.first_name,
        last_name: item.last_name,
        primary_phone: item.primary_phone,
        email: item.email,
        secondary_phone: "",
        role: role_guid,
        brand_job_title: item.brand_job_title,
        state: 1
      });

      var updateconfig = {
        method: "put",
        url: CONFIG.API_URL + `user/${item.user_guid}/${b_guid}`,
        headers: CONFIG.config_head.headers,
        data: updatedata
      };

      axios(updateconfig)
        .then((response) => {
          if (response.status === 201) {
            toast.success("Updated Successfully");
            this.hideLoader();
            setTimeout(() => window.location.reload(), 2500);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }

  replaceModalItem(index) {
    this.setState({
      requiredItem: index
    });
  }


  render() {
    const requiredItem = this.state.requiredItem;
    let modalData = this.state.userList[requiredItem];

    //console.log('modalData',modalData)

    const role_name = ls.get("selectedbrand") ? ls.get("selectedbrand")[0].role_name : "";

    const isLogged = ls.get("token");
    const is_consent_provided = ls.get("decodetoken") ? ls.get("decodetoken").is_consent_provided : "";

    if (!isLogged || is_consent_provided === false) {
      return <Redirect to="/login" />;
    }
    return (
      <>
        {/* BEGIN:ADD USER MODAL */}

        {/* END:ADD USER MODAL */}
        {/* BEGIN:CHOOSE AVTAR MODAL */}
        <div className="modal fade Choose_Avtar_Modal" id="ChooseAvtar" tabIndex="-1" aria-labelledby="ChooseAvtar" aria-hidden="true">
          <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                {/* <h5 class="modal-title" id="ChooseAvtar">Choose Avtar</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
              </div>
              <div className="modal-body">
                <div className="row g-3">
                  <div className="col-lg-3 col-md-4  col-6">
                    <div className="Avtar_Image_Box">
                      <img
                        src={Avtar1}
                        className="img-fluid "
                        alt="Avtar"
                      />
                      <div className="Selected_Image">
                        <div className="form-check">
                          <input className="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" value="option1" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-4  col-6">
                    <div className="Avtar_Image_Box">
                      <img
                        src={Avtar2}
                        className="img-fluid "
                        alt="Avtar"
                      />
                      <div className="Selected_Image">
                        <div className="form-check">
                          <input className="form-check-input" type="radio" name="exampleRadios" id="exampleRadios2" value="option2" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-4  col-6">
                    <div className="Avtar_Image_Box">
                      <img
                        src={Avtar3}
                        className="img-fluid "
                        alt="Avtar"
                      />
                      <div className="Selected_Image">
                        <div className="form-check">
                          <input className="form-check-input" type="radio" name="exampleRadios" id="exampleRadios3" value="option3" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-4  col-6">
                    <div className="Avtar_Image_Box">
                      <img
                        src={Avtar4}
                        className="img-fluid "
                        alt="Avtar"
                      />
                      <div className="Selected_Image">
                        <div className="form-check">
                          <input className="form-check-input" type="radio" name="exampleRadios" id="exampleRadios4" value="option4" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-4  col-6">
                    <div className="Avtar_Image_Box">
                      <img
                        src={Avtar5}
                        className="img-fluid "
                        alt="Avtar"
                      />
                      <div className="Selected_Image">
                        <div className="form-check">
                          <input className="form-check-input" type="radio" name="exampleRadios" id="exampleRadios5" value="option5" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-4  col-6">
                    <div className="Avtar_Image_Box">
                      <img
                        src={Avtar6}
                        className="img-fluid "
                        alt="Avtar"
                      />
                      <div className="Selected_Image">
                        <div className="form-check">
                          <input className="form-check-input" type="radio" name="exampleRadios" id="exampleRadios6" value="option6" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer justify-content-center">
                {/* <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button> */}
                <button type="button" className="btn Btn_blue w-fit-content px-4 py-2 rounded-2">Save</button>
              </div>
            </div>
          </div>
        </div>
        {/* END:CHOOSE AVTAR MODAL */}

        <Header title={this.state.title} location={`profile`} />

        {this.state.loading ? <Loader /> : null}

        <section className="ContentBlock pt-24 pb-24 Main_Block">
          <div className="container-fluid ">
            <div className="row justify-content-center ">
              <div className="col-xxl-2 col-lg-3 col-12">
                <div
                  className="nav flex-column nav-pills"
                  id="v-pills-tab"
                  role="tablist"
                  aria-orientation="vertical"
                >
                  <button
                    className="nav-link profile-tab active"
                    id="v-pills-home-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#v-pills-home"
                    type="button"
                    role="tab"
                    aria-controls="v-pills-home"
                    aria-selected="true"
                  >
                    Account Details
                  </button>
                  <button
                    className="nav-link profile-tab"
                    id="v-pills-settings-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#v-pills-settings"
                    type="button"
                    role="tab"
                    aria-controls="v-pills-settings"
                    aria-selected="false"
                  >
                    Change Password
                  </button>
                  <button
                    className="nav-link profile-tab"
                    id="v-pills-susbscription-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#v-pills-susbscription"
                    type="button"
                    role="tab"
                    aria-controls="v-pills-susbscription"
                    aria-selected="false"
                  >
                    My Subscriptions
                  </button>
                  <button
                    className="nav-link profile-tab"
                    id="v-pills-user-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#v-pills-user"
                    type="button"
                    role="tab"
                    aria-controls="v-pills-user"
                    aria-selected="false"
                  >
                    Users
                  </button>
                  <button
                    className="nav-link profile-tab"
                    id="v-pills-journey-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#v-pills-journey"
                    type="button"
                    role="tab"
                    aria-controls="v-pills-journey"
                    aria-selected="false"
                  >
                    Journey
                  </button>

                  <button
                    className="nav-link profile-tab"
                    id="v-pills-invoices-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#v-pills-invoices"
                    type="button"
                    role="tab"
                    aria-controls="v-pills-invoices"
                    aria-selected="false"
                  >
                    Invoices
                  </button>

                </div>
              </div>


              <div className="col-xxl-7 col-lg-9 col-12">
                <div className="tab-content mb-30" id="v-pills-tabContent">
                  <div
                    className="tab-pane fade show active"
                    id="v-pills-home"
                    role="tabpanel"
                    aria-labelledby="v-pills-home-tab"
                  >

                    {
                      this.state.brandInfo && this.state.userinfo ? (

                        <AccountDetails role_name={role_name} Avtar1={Avtar1}
                          brandInfo={this.state.brandInfo} ClipLoader={ClipLoader}
                          accountLoader={this.state.accountLoader} userinfo={this.state.userinfo} inputChange={this.changeHandler}
                          updateUser={this.updateUser} />
                      ) :

                        <div className="Clip_Loader" style={{ minHeight: "280px" }}>
                          <ClipLoader
                            color={"rgb(0, 143, 251)"}
                            loading={true}
                            size={50}
                          />
                        </div>
                    }
                  </div>



                  <div
                    className="tab-pane fade"
                    id="v-pills-settings"
                    role="tabpanel"
                    aria-labelledby="v-pills-settings-tab"
                  >
                    <div className="profile-box">
                      <h1 className="account-head">Change Password</h1>
                      <form className="row gx-4 gy-3" method="post">
                        <div className="col-md-12">
                          <label
                            htmlFor="inputEmail4"
                            className="form-label ml-10 main-form-profile"
                          >
                            Current Password
                          </label>
                          <input
                            type="password"
                            className="form-control profile-input-control"
                            placeholder="Enter Old Password"
                            name="old_password"
                            onChange={this.changeHandler}
                          />
                          <samp className="text-danger">
                            {this.validator.message(
                              "Old Passoword",
                              this.state.formControls.old_password,
                              `required`
                            )}
                          </samp>
                        </div>
                        <div className="col-md-12">
                          <label
                            htmlFor="inputEmail4"
                            className="form-label ml-10 main-form-profile"
                          >
                            New Password
                          </label>
                          <input
                            type="password"
                            className="form-control profile-input-control"
                            placeholder="Enter New Password"
                            name="new_password"
                            onChange={this.changeHandler}
                          />
                          <samp className="text-danger">
                            {this.validator.message(
                              "New Passoword",
                              this.state.formControls.new_password,
                              `required`
                            )}
                          </samp>
                        </div>
                        <div className="col-md-12">
                          <label
                            htmlFor="inputEmail4"
                            className="form-label ml-10 main-form-profile"
                          >
                            Confirm Password
                          </label>
                          <input
                            type="password"
                            className="form-control profile-input-control"
                            placeholder="Enter  New Password Again"
                            name="confirm_password"
                            onChange={this.changeHandler}
                          />
                          <samp className="text-danger">
                            {this.validator.message(
                              "Confirm Passoword",
                              this.state.formControls.confirm_password,
                              `required`
                            )}
                          </samp>
                        </div>

                        <div className="col-12 mt-25">
                          <button
                            type="submit"
                            className="btn btn-primary profile-update"
                            onClick={(e) => {
                              this.updatePassword(e);
                            }}
                          >
                            Update Password
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>


                  <div
                    className="tab-pane fade"
                    id="v-pills-susbscription"
                    role="tabpanel"
                    aria-labelledby="v-pills-susbscription-tab"
                  >
                    {this.state.brandWholesaler && this.state.brandRetailer && this.state.overviewdetails
                      && this.state.AllWholesaler && this.state.AllRetailer && this.state.getStoredetails && (
                        <MySubscription brandWholesaler={this.state.brandWholesaler} brandRetailer={this.state.brandRetailer}
                          overviewdetails={this.state.overviewdetails} AllWholesaler={this.state.AllWholesaler}
                          AllRetailer={this.state.AllRetailer} getStoredetails={this.state.getStoredetails} Stores={Stores}
                        />
                      )}
                  </div>


                  <div
                    className="tab-pane fade "
                    id="v-pills-user"
                    role="tabpanel"
                    aria-labelledby="v-pills-user-tab"
                  >
                    {
                      this.state.userList && this.state.brandRetailer
                      && this.state.AllRetailer && this.state.AllWholesaler
                      && this.state.brandWholesaler && (

                        <Users userList={this.state.userList} Edit={Edit} Delete={Delete}
                          props_user_guid={this.props.match.params.user_guid} brandRetailer={this.state.brandRetailer}
                          AllRetailer={this.state.AllRetailer} AllWholesaler={this.state.AllWholesaler}
                          brandWholesaler={this.state.brandWholesaler}
                          masterRoles={this.state.masterRoles}
                          AlertModal={this.AlertModal}
                          replaceModalItem={this.replaceModalItem} />
                      )
                    }

                  </div>


                  <div
                    className="tab-pane fade"
                    id="v-pills-journey"
                    role="tabpanel"
                    aria-labelledby="v-pills-journey-tab"
                  >
                    <div className="profile-box">
                      <h1 className="account-head">Journey</h1>

                      {
                        this.state.currentsummarydetails && this.state.asofDatadetails ? (
                          <Journey currentsummarydetails={this.state.currentsummarydetails} asofDatadetails={this.state.asofDatadetails}
                            Sold={Sold} Retailer={Retailer} Stores={Stores} />
                        ) :
                          (<div className="Clip_Loader" style={{ minHeight: "280px" }}>
                            <ClipLoader
                              color={"rgb(0, 143, 251)"}
                              loading={true}
                              size={50}
                            />
                          </div>
                          )
                      }


                    </div>



                  </div>

                  <div
                    className="tab-pane fade"
                    id="v-pills-invoices"
                    role="tabpanel"
                    aria-labelledby="v-pills-invoices-tab"
                  >
                    <div className="profile-box">
                      <h1 className="account-head">Invoice</h1>
                      <div className="col-md-12">


                        <DataTable
                          columns={this.state.columns}
                          data={this.state.tableData}

                        />
                      </div>


                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>


          <>
            <button className="btn btn-success" data-bs-toggle="modal" data-bs-target="#staticBackdrop" id="confirmuserModal" style={{ display: "none" }}>
              Alert
            </button>

            <div
              className="modal fade Confirmation_Modal"
              id="staticBackdrop"
              data-bs-backdrop="static"
              data-bs-keyboard="false"
              tabIndex="-1"
              aria-labelledby="staticBackdropLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-body">
                    <i className="fas fa-exclamation-triangle text-center "></i>
                    <h4 className="mt-20 fw-bold text-center">Are you sure?</h4>
                    <p className="mt-10 text-center">
                      You want to remove this selected user.
                    </p>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={(e) =>
                        this.RemoveUser(
                          this.state.user_guid ? this.state.user_guid : ""
                        )
                      }
                    >
                      Yes
                    </button>
                    <button
                      type="button"
                      className="btn btn-secondary"
                      data-bs-dismiss="modal"
                    >
                      No
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </>

          {this.state.userList && modalData && (

            <EditUserModal
              edit_user_modal_data={modalData}
              updateModalUser={this.updateModalUser}
              allRetailer={this.state.AllRetailer}
              brandRetailer={this.state.brandRetailer}
              brandWholesaler={this.state.brandWholesaler}
              allWholesaler={this.state.AllWholesaler}
            />
          )}

        </section>
        <FooterStrict />
      </>
    );
  }
}
export default Profile;
