import React from "react";
import { Link } from "react-router-dom";
import DigitalCoupons from "../../Assets/images/coupons.svg";
import { Button } from "react-bootstrap";
import './select.scss';
import $ from "jquery";

const Summary = ({ selectedbrand }) => {


    const handleChange = (e) => {
        switch (e.target.value) {
            case 'wizard-cross-sell':
                window.location.href = '/wizard/digital?type=cross-sell';
                break;
            case 'wizard-UPC':
                window.location.href = '/wizard/digital?type=UPC';
                break;
            case 'deal':
                window.location.href = '/deal';
                break;
        }
    }

    const handleChangeModal = (e) => {
        e.preventDefault();
        $("#unauthorized").trigger("click");
    }

    return (

        <div className="col-xxl-5 col-xl-6 col-12 " style={{ width: '43.666667% !important' }}>
            <div className="Card Digital_Coupons">
                <div className="Card_Header d-flex align-items-center justify-content-between flex-wrap">
                    <div className="d-flex align-items-center">
                        <img
                            src={DigitalCoupons}
                            className="img-fluid"
                            alt="Digial Coupons"
                        />
                        <h5 style={{ marginLeft: '2px', fontSize: '15px' }}>Digital Offer Builder</h5>
                    </div>

                    <div className="Card_Header_Right d-flex align-items-center View_Page_Link">

                    </div>
                </div>
                <div className="Card_Body ">
                    <p className="">
                        Build and publish digital coupons & deals directly to retailers
                        and loyalty applications. You can set clip and
                        redemptions limit by wholesaler.
                    </p>

                    <div className="d-flex align-items-center mt-60 " style={{ marginLeft: '-6px' }}>
                        {selectedbrand.length > 0 ?
                            selectedbrand[0].scopes.includes('coupon:write') ?
                                <Button variant="" type="submit" className=" ButtonBlue" disabled={selectedbrand.length > 0 ? false : true}>
                                    <Link to="/wizard/fsi" className="text-white">{" "}CREATE DIGITAL FSI{" "}</Link>
                                </Button>
                                :

                                <Button variant="" type="submit" className=" ButtonBlue">
                                    <Link to="/" className="text-white" data-bs-toggle="modal" data-bs-target="#ViewModal">
                                        {" "}
                                        CREATE DIGITAL FSI{" "}
                                    </Link>
                                </Button>
                            :
                            <Button variant="" type="submit" className=" ButtonBlue" disabled={true}>
                                <Link to="/" className="text-white" data-bs-toggle="modal" data-bs-target="#ViewModal">{" "}CREATE DIGITAL FSI{" "}</Link>
                            </Button>
                        }
                        {
                            selectedbrand.length > 0 ?
                                <>
                                    <select className="my_class" onChange={handleChange}>
                                        <option value="" disabled selected>Create Trade Promotion</option>
                                        <option value={`wizard-cross-sell`} disabled={selectedbrand[0].scopes.includes('flexcoupon:write') ? false : true} >Digital Coupon - Cross Sell</option>
                                        <option value={`wizard-UPC`} disabled={selectedbrand[0].scopes.includes('flexcoupon:write') ? false : true} >Digital Coupon - UPC</option>
                                        <option value={`deal`} disabled={selectedbrand[0].scopes.includes('deal:write') ? false : true}>Digital Deal</option>
                                    </select>
                                </>
                                :
                                <select className="my_class" disabled={true}>
                                    <option value="" disabled selected>Create Trade Promotion</option>
                                    <option disabled>Digital Coupon</option>
                                    <option disabled>Digital Deal</option>

                                </select>
                        }
                    </div>
                </div>
            </div>
            <button
                className="btn btn-success"
                data-bs-toggle="modal"
                data-bs-target="#ViewModal"
                id="unauthorized"
                style={{ display: "none" }}
            >
                Alert
            </button>

        </div>



    )

};

export default Summary;