import React from "react";
import Header from "../../Components/Admin/layout/header";
import { Redirect } from "react-router";

import { Link } from "react-router-dom";
import { Button, Spinner } from "react-bootstrap";
import DataTable from "react-data-table-component";
import * as CONFIG from "../../Components/_config";

import $ from "jquery";
import axios from "axios";
import ls from "local-storage";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import WholesalerModal from "../../Components/Common/WholesalerModal";
import RetailerModal from "../../Components/Common/RetailerModal";
import FooterStrict from "../Dashboard/FooterStrict";
import { downloadCoupon } from '../../API/Admin';


class BrandList extends React.Component {
  constructor(props) {
    super(props);
    toast.configure();
    this.state = {
      brandList: "",
      buttonLoading: false,
      tableData: "",
      WholeSalerlist: "",
      wholesaler_number: [],
      wholesaler_number_array: "",
      retailer: [],
      retailer_array: "",
      wholesaler_full_list_guid: "",
      retailer_response_arr: [],
      retailer_number_arr: [],
      downloadCouponloader: false,

      file: "https://support.staffbase.com/hc/en-us/article_attachments/360009197031/username.csv",

      // retailer_response_array:[],
      columns: [
        {
          name: "Brand Name",
          selector: (row) => row.brand_name,
          sortable: true
        },
        {
          name: "COMPANY NAME",
          selector: (row) => row.company_name,
          sortable: true
        },
        {
          name: "ADDRESS",
          selector: (row) =>
            row.address1 +
            "," +
            row.address2 +
            "," +
            row.city +
            "," +
            row.postal_code +
            "," +
            row.country_state
        },
        {
          name: "MEMBER SINCE",
          selector: (row) =>
            new Date(row.member_since).toISOString().split("T")[0],
          sortable: true
        },
        {
          name: "STATUS",
          selector: (row) => row.brand_status,
          sortable: true,
          button: true,
          cell: (row) =>
            row.brand_status === 1 ? (
              <a href="/#" className="status approval">
                Active
              </a>
            ) : (
              <a href="/#" className="status pending">
                Pending
              </a>
            )
        },
        {
          name: "VIEW",
          sortable: true,
          button: true,
          cell: (row) => (
            <Link to={`/brandetails/${row.brand_guid}`}>
              <svg
                width="26"
                height="18"
                viewBox="0 0 26 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.9991 0C19.2898 0 24.5235 3.88 25.6213 9C24.5246 14.12 19.2898 18 12.9991 18C6.70845 18 1.47479 14.12 0.376953 9C1.47362 3.88 6.70845 0 12.9991 0ZM12.9991 16C15.3785 15.9996 17.6872 15.3068 19.5475 14.0352C21.4077 12.7635 22.7092 10.9883 23.239 9C22.7072 7.0133 21.4049 5.24 19.5448 3.97003C17.6848 2.70005 15.3771 2.00853 12.9991 2.00853C10.6211 2.00853 8.31343 2.70005 6.45339 3.97003C4.59336 5.24 3.29101 7.0133 2.75929 9C3.28907 10.9883 4.59057 12.7635 6.45079 14.0352C8.311 15.3068 10.6197 15.9996 12.9991 16ZM12.9991 13.5C11.6067 13.5 10.2714 13.0259 9.28681 12.182C8.30224 11.3381 7.74912 10.1935 7.74912 9C7.74912 7.80653 8.30224 6.66193 9.28681 5.81802C10.2714 4.97411 11.6067 4.5 12.9991 4.5C14.3915 4.5 15.7269 4.97411 16.7114 5.81802C17.696 6.66193 18.2491 7.80653 18.2491 9C18.2491 10.1935 17.696 11.3381 16.7114 12.182C15.7269 13.0259 14.3915 13.5 12.9991 13.5ZM12.9991 11.5C13.7727 11.5 14.5145 11.2366 15.0615 10.7678C15.6085 10.2989 15.9158 9.66304 15.9158 9C15.9158 8.33696 15.6085 7.70107 15.0615 7.23223C14.5145 6.76339 13.7727 6.5 12.9991 6.5C12.2256 6.5 11.4837 6.76339 10.9367 7.23223C10.3897 7.70107 10.0825 8.33696 10.0825 9C10.0825 9.66304 10.3897 10.2989 10.9367 10.7678C11.4837 11.2366 12.2256 11.5 12.9991 11.5Z"
                  fill="#03053D"
                />
              </svg>
            </Link>
          )
        },
        {
          name: "ACTION",
          button: true,
          cell: (row) =>
            row.brand_status === 1 ? (
              <Button
                variant="danger"
                data-tag="allowRowEvents"
                data-action="delete"
                onClick={(e) => this.AlertModal(e, row.brand_guid)}
              >
                Inactive
              </Button>
            ) : (
              <Button
                variant="success"
                data-tag="allowRowEvents"
                data-action="approve"
                value={row.brand_guid}
                onClick={this.handleStatus}
              >
                Approve
              </Button>
            )
        }
      ]
    };
    this.handleStatus = this.handleStatus.bind(this);
    this.selectbox = this.selectbox.bind(this);
    this.nextbtnWholesaler = this.nextbtnWholesaler.bind(this);
    this.selectRetailer = this.selectRetailer.bind(this);
    this.couponownload = this.couponownload.bind(this);
  }

  componentDidMount() {
    var data = JSON.stringify({
      active: 4,
      page: 1,
      count: 10,
      sort: "-name",
      search: ""
    });
    var config = {
      method: "post",
      url: CONFIG.API_URL + "brand/search",
      headers: CONFIG.config_head.headers,
      data: data
    };
    axios(config)
      .then((response) => {
        this.setState({
          tableData: response.data.Message.entries,
          brandList: response.data
        });
      })
      .catch(function (error) {
        if (error.response.status === 400) {
          toast.error(error.response.data.Message);
          ls.clear("token");
          ls.clear("brands");
          window.location.href = "/login";
        } else if (error.response.status === 403) {
          toast.error(error.response.data.Message);
          ls.clear("token");
          ls.clear("brands");
          window.location.href = "/login";
        } else {
          toast.error(error.response.data.Message);
        }
      });
    var masterdata = "";
    var wholesaler_list_guid = [];
    var masterconfig = {
      method: "get",
      url: CONFIG.API_URL + `master?type=wholesaler&search=''`,
      headers: CONFIG.config_head.headers,
      data: masterdata
    };
    axios(masterconfig)
      .then((response) => {
        this.setState({ WholeSalerlist: response.data.Message });
        for (const obj of response.data.Message) {
          wholesaler_list_guid.push(obj.wholesaler_guid);
        }
        this.setState({ wholesaler_full_list_guid: wholesaler_list_guid });
        ls.remove("wholesaler_after_guid");
        ls.set("wholesaler_after_guid", this.state.wholesaler_full_list_guid);
      })
      .catch(function (error) {
        //  toast.error(error.response.data.Message);
      });
  }

  AlertModal = (e, brand_guid) => {
    e.preventDefault();

    console.log(brand_guid);

    $("#confirmModal").trigger("click");

    this.setState({ brand_guid: brand_guid });
  };

  deactivate(brand_guid) {

    var data = JSON.stringify({
      wholesalers: [],
      retailers: []
    });
    var config = {
      method: "post",
      url: CONFIG.API_URL + `brand/status/${brand_guid}/3`,
      headers: CONFIG.config_head.headers,
      data: data
    };
    axios(config)
      .then((response) => {
        toast.success("Deactivated Successfully");
        window.location.reload();
      })
      .catch(function (error) {
        if (error.response.status === 502) {
          toast.error("Bad Request!");
        } else if (error.response.status === 400) {
          toast.error(error.response.data.Message);
        } else if (error.response.status === 403) {
          toast.error(error.response.data.Message);
        } else {
          toast.error(error.response.data.Message);
        }
      });
  }

  handleStatus(e) {
    var brand_guid = e.target.value;

    // var action = e.target.getAttribute('data-action') == 'approve' ? 1 : e.target.getAttribute('data-action') == 'delete' ? 3 : '';

    $("#confirmBrandModal").trigger("click");

    // ls.set('brand_guid', e.target.value);
    // ls.set('action', action);

    this.setState({ brand_guid_activate: brand_guid });
  }
  activateBrand(brand_guid) {

    var data = JSON.stringify({
      wholesalers: [],
      retailers: []
    });
    var config = {
      method: "post",
      url: CONFIG.API_URL + `brand/status/${brand_guid}/1`,
      headers: CONFIG.config_head.headers,
      data: data
    };
    axios(config)
      .then((response) => {
        var user_guid = response.data.Message.UserGuid;
        var data = JSON.stringify({
          old_password: "",
          new_password: ""
        });
        var config = {
          method: "post",
          url: CONFIG.API_URL + `user/password-reset/${user_guid}`,
          headers: CONFIG.config_head.headers,
          data: data
        };
        axios(config)
          .then((response) => {
            if (response.status === 204) {
              this.setState({
                buttonLoading: false,
              }, () => {
                toast.success("Activated Successfully");
                window.location.reload();
              })
            }
          })
          .catch((error) => {
            this.setState({
              buttonLoading: false
            }, () => {
              if (error.response.status === 502) {
                toast.error("Bad Request!");
              } else if (error.response.status === 400) {
                toast.error(error.response.data.Message);
                // window.location.href='/login';
              } else if (error.response.status === 403) {
                toast.error(error.response.data.Message);
              } else {
                toast.error(error.response.data.Message);
              }
            })
          });
      })
      .catch((error) => {
        this.setState({
          buttonLoading: false,
        }, () => {
          if (error.response.status === 502) {
            toast.error("Bad Request!");
          } else if (error.response.status === 400) {
            toast.error(error.response.data.Message);
            $("#approveBackdropbrand").css("display", "none");
            $(".modal-backdrop").first().css("display", "none");

            // window.location.href='/login';
          } else if (error.response.status === 403) {
            toast.error(error.response.data.Message);
            $("#approveBackdropbrand").css("display", "none");
            $(".modal-backdrop").first().css("display", "none");
          } else {
            toast.error(error.response.data.Message);
            $("#approveBackdropbrand").css("display", "none");
            $(".modal-backdrop").first().css("display", "none");
          }
        })
      });
  }

  selectbox(e, w_num, wholesaler_guid) {
    //var ar1 = [];
    // var wholesaler_guid = wholesaler_guid;
    if (e.target.checked) {
      //let wh_number = [];
      //wh_number = [...wh_number, w_num];
      //this.setState({wholesaler_number: wh_number })
      this.state.wholesaler_number.push(w_num);
      // console.log('guid', this.state.wholesaler_full_list_guid)
      const index =
        this.state.wholesaler_full_list_guid.indexOf(wholesaler_guid);
      console.log(this.state.wholesaler_number);
      if (index > -1) {
        this.state.wholesaler_full_list_guid.splice(index, 1); // 2nd parameter means remove one item only
      }
      console.log(this.state.wholesaler_full_list_guid);
    } else {
      this.state.wholesaler_full_list_guid.push(wholesaler_guid);
      //console.log('guid', this.state.wholesaler_full_list_guid)
      let removeIndex = this.state.wholesaler_number.findIndex(
        (itm) => itm === w_num
      );
      if (removeIndex > -1) {
        this.state.wholesaler_number.splice(removeIndex, 1); // 2nd parameter means remove one item only
      }
    }
    //ls.remove('wholesaler_after_guid');
    ls.remove("wh_arr");
    ls.set("wholesaler_after_guid", this.state.wholesaler_full_list_guid);
    ls.set("wh_arr", this.state.wholesaler_number);
  }

  async nextbtnWholesaler() {
    var wholesaler_after_guid = [];
    // var wholesaler_after_guid = ls.get("wholesaler_after_guid")
    //   ? ls.get("wholesaler_after_guid")
    //   : [];

    // console.log(wholesaler_after_guid.length)
    //var token = ls.get("token") ? ls.get("token") : "";
    let posts = [];
    if (wholesaler_after_guid.length > 0) {
      //wholesaler_after_guid.map((val,idx)=>{
      let id = "";
      let i = 0;
      while (i < wholesaler_after_guid.length) {
        id = wholesaler_after_guid[i];
        const url = CONFIG.API_URL + `master/${id}?type=retailer&search=''`;
        const result = await axios.get(url, {
          headers: CONFIG.config_head.headers
        });
        posts = [...posts, result.data.Message];
        $("#selectwhole-sel-drop").css("display", "none");
        $(".modal-backdrop").first().css("display", "none");
        i++;

        this.setState({
          retailer_array: ""
        });
      }
    } else {
      this.setState({ retailer_array: [] });
    }
    this.setState({
      retailer_array: posts
    });
    $("#retailermodal").trigger("click");
  }

  selectRetailer(e, wholesaler_id, retailer_id) {
    // var retailer_id = parseInt(retailer_id);
    //var wholesaler_id = parseInt(wholesaler_id);
    if (e.target.checked) {
      let obj = {
        wholesaler_id: wholesaler_id,
        retailer_id: retailer_id
      };
      this.state.retailer_number_arr.push(obj);
      ls.remove("add_retailer_arr");
      ls.set("add_retailer_arr", this.state.retailer_number_arr);
    } else {
      let removeIndex = this.state.retailer_number_arr.findIndex(
        (itm) => itm.retailer_id === retailer_id
      );
      // const index = this.state.retailer_number_arr.indexOf(wholesaler_id);
      if (removeIndex > -1) {
        this.state.retailer_number_arr.splice(removeIndex, 1); // 2nd parameter means remove one item only
      }
      ls.set("add_retailer_arr", this.state.retailer_number_arr);
      // this.state.retailer_number_arr.pop({ wholesaler_id,retailer_id,  });
    }
  }

  addWholesaler() {
    //var token = ls.get("token") ? ls.get("token") : "";
    var b_guid = ls.get("brand_guid") ? ls.get("brand_guid") : "";
    var wholesaler_array = ls.get("wh_arr") ? ls.get("wh_arr") : [];
    var wholesaler_array = wholesaler_array.filter(function (
      item,
      index,
      inputArray
    ) {
      return inputArray.indexOf(item) == index;
    });
    var add_retailer_arr = ls.get("add_retailer_arr")
      ? ls.get("add_retailer_arr")
      : "";
    var data = JSON.stringify({
      wholesalers: wholesaler_array,
      retailers: add_retailer_arr
    });
    var config = {
      method: "post",
      url: CONFIG.API_URL + `brand/status/${b_guid}/1`,
      headers: CONFIG.config_head.headers,
      data: data
    };
    axios(config)
      .then((response) => {
        var user_guid = response.data.Message.UserGuid;
        var data = JSON.stringify({
          old_password: "",
          new_password: ""
        });
        var config = {
          method: "post",
          url: CONFIG.API_URL + `user/password-reset/${user_guid}`,
          headers: CONFIG.config_head.headers,
          data: data
        };
        axios(config)
          .then((response) => {
            if (response.status === 204) {
              toast.success("Activated Successfully");
              window.location.reload();
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  async couponownload() {

    this.setState({
      downloadCouponloader: true
    }, () => {

      downloadCoupon().then((response) => {

        if (response.status === 200) {

          this.setState({ downloadCouponloader: false, file: response.data.Message, });

          $("#mybtn").trigger('click');
        }
        else {

          toast.error(response.data.Message);
        }

      });
    })


  }

  render() {
    const isLogged = ls.get("token");

    if (!isLogged) {
      return <Redirect to="/login" />;
    }

    return (
      <>
        <Header />
        <section className="ContentBlock  pt-24 pb-24 Main_Block">
          <a
            href={this.state.file}
            target="_blank"
            rel="noopener noreferrer"
            download
            style={{ display: "none" }}
          >
            <Button id="mybtn">Download File</Button>
          </a>

          <div className="container-fluid">
            <div className="row justify-content-center">
              <div className=" col-12">
                <div className="Coupons_Block mt-21">
                  <div className="Common_Block_Body">
                    <div className="table-responsive BrandList_Table">
                      <button
                        className="Digital_Brand_Button approve"
                        data-bs-toggle="modal"
                        id="wholesalermodal"
                        data-bs-target="#selectwhole-sel-drop"
                        style={{ display: "none" }}
                      >
                        Approve
                      </button>
                      <WholesalerModal
                        simplifiedFunction={this.nextbtnWholesaler}
                        checkboxFunction={this.selectbox}
                        dataParentToChild={this.state.WholeSalerlist}
                      />
                      {this.state.retailer_array && (
                        <>
                          <button
                            className="Digital_Brand_Button approve"
                            data-bs-toggle="modal"
                            id="retailermodal"
                            data-bs-target="#selectretaler-drop"
                            style={{ display: "none" }}
                          >
                            Approve
                          </button>

                          <RetailerModal
                            simplifiedFunction={this.addWholesaler}
                            checkretailerFunction={this.selectRetailer}
                            retailerData={this.state.retailer_array}
                          />
                        </>
                      )}


                      {this.state.brandList ? (
                        <>


                          <Button
                            variant=""
                            type="button"
                            className="Digital_Brand_Button approve"
                            style={{ float: "right", padding: '10px', border: 'none', margin: '5px' }}
                            onClick={this.couponownload}
                          >
                            Download All Coupons
                            {
                              this.state.downloadCouponloader ?
                                <Spinner
                                  as="span"
                                  animation="border"
                                  size="sm"
                                  role="status"
                                  aria-hidden="true"
                                  className={"float-end"}
                                />
                                :
                               
                                ''
                            }
                          </Button>

                          <DataTable
                            columns={this.state.columns}
                            data={this.state.tableData}
                            pagination
                          />
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="d-flex align-items-center justify-content-end Pagination_Block"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* BEGIN: STATICBACKDROP MODAL */}

          <button
            className="btn btn-success"
            data-bs-toggle="modal"
            data-bs-target="#staticBackdrop"
            id="confirmModal"
            style={{ display: "none" }}
          >
            Alert
          </button>

          <div
            className="modal fade Confirmation_Modal"
            id="staticBackdrop"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabindex="-1"
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-body">
                  <i className="fas fa-exclamation-triangle text-center "></i>
                  <h4 className="mt-20 fw-bold text-center">Are you sure ?</h4>
                  <p className="mt-10 text-center">
                    You want to deactivate this selected brand.
                  </p>
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={(e) =>
                      this.deactivate(
                        this.state.brand_guid ? this.state.brand_guid : ""
                      )
                    }
                  >
                    Yes
                  </button>
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                  >
                    No
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/* END: STATICBACKDROP MODAL */}

          <button
            className="btn btn-success"
            data-bs-toggle="modal"
            data-bs-target="#approveBackdropbrand"
            id="confirmBrandModal"
            style={{ display: "none" }}
          >
            Alert
          </button>
          <div
            className="modal fade Confirmation_Modal"
            id="approveBackdropbrand"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex="-1"
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-body">
                  <i className="fas fa-exclamation-triangle text-center "></i>
                  <h4 className="mt-20 fw-bold text-center">Are you sure?</h4>
                  <p className="mt-10 text-center">
                    You want to approve this selected brand.
                  </p>
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    className="btn btn-primary"
                    disabled={this.state.buttonLoading}
                    onClick={(e) => {
                      this.setState({
                        buttonLoading: true
                      }, () => {
                        this.activateBrand(
                          this.state.brand_guid_activate
                            ? this.state.brand_guid_activate
                            : ""
                        )
                      })
                    }}
                  >
                    Yes
                    {
                      this.state.buttonLoading &&
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        className={"margin-left-spinner"}
                      />
                    }
                  </button>
                  <button
                    type="button"
                    className="btn btn-secondary"
                    disabled={this.state.buttonLoading}
                    data-bs-dismiss="modal"
                  >
                    No
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
        <FooterStrict />
      </>
    );
  }
}

export default BrandList;
