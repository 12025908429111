import React from "react";
import $ from "jquery";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
 
const Loader = () => <div id="cover-spin"></div>;


class AddBrandWholesaler extends React.Component {
  constructor(props) {
    super(props);
    toast.configure();

    this.state = {
      wholesalerdata: this.props.wholesalerData,
      brand_guid: this.props.brandguidata,
      checkedListAll:[],
    };
  }




  

  componentDidMount() {
    // console.log('>>>>wlist',this.props.WholeSalerlist)
    $("#selectwhole-sel-drop").on("click", function (e) {
      if ($(e.target).parent().attr("data-dismiss")) {
      }
    });
  }

 
 
  componentDidUpdate(prevProps, nextProps) {
    if (prevProps !== this.props) {
      this.setState({
        checkedListAll: prevProps.checkedListAll,
      
      });
    }
  }

  

  render() {

    return (
      <>
       {this.state.loading ? <Loader /> : null}

        <div
          className="modal fade"
          id="selectwholesaler-sel-drop"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-custom modal-dialog-scrollable">
            <div className="modal-content whole-content">
              <div className="modal-header borderemove brand-whole-hd">
                <h5 className="modal-title" id="exampleModalLabel">
                  Select Wholesalers
                </h5>

                <div className="header_right" style={{ float: 'right', marginTop: '-2px', marginRight: '80px', color: '#fff' }}>
                  <input type="checkbox" onClick={this.props.selectAllCheckboxes.bind(this)} value="checkedall" />{" "}Check / Uncheck All
                </div>

                <button
                  type="button"
                  className="btn-close wholeseler-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>

              <div className="modal-body CustomScroll whole-seler-body">
                <div className="tab-content" id="nav-tabContent">
                  <div
                    className="tab-pane fade show active tab-text"
                    id="nav-home"
                    role="tabpanel"
                    aria-labelledby="nav-home-tab"
                  >
                    <div className="Common_brand_detail">
                      {Object.keys(this.state.wholesalerdata).length > 0 ? (
                        <div className="pr-12 pl-12 pt-20">

                          <ul className=" mt-15 d-flex  Retailer">
                            {this.state.wholesalerdata.map((val, idx) => (

                                    <li key={val.wholesaler_number}>
                                    <div className="Retailer_Block">
                                    <input
                                        type="checkbox"
                                        value={val.wholesaler_id}
                                        checked={this.state.checkedListAll.includes(val.wholesaler_id)}
                                        onChange={this.props.selectWholesaler}
                                    />
                                    <div className="RetailerImage">
                                        <img
                                        className="img-fluid"
                                        alt={val.wholesaler_name}
                                        src={
                                            val.logo
                                        }
                                        />
                                    </div>
                                    <h5>
                                        {val.wholesaler_name}
                                    </h5>
                                    </div>
                                    </li>


                            ))}
                          </ul>
                        </div>
                      ) : (
                        "No Wholesaler available to choose"
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="modal-footer brand-whole-footer">
                <button
                  type="button"
                  className="btn btn-primary Btn_blue_modal"
                      data-bs-dismiss="modal"
                  aria-label="Close"
             
                >
                  Save
                </button>
              </div>

            </div>
          </div>
        </div>
      </>
    );
  }
}
export default AddBrandWholesaler;
