import React from "react";
import ClipLoader from "react-spinners/ClipLoader";
import Shoppers from "../../../Assets/images/shoppers.svg";
import Dollar from "../../../Assets/images/doller.svg";

const UserSaleSummary = ({ retailerfeed,summaryLoader,retailerarr }) => {

 
  return (
   
      summaryLoader ? (
        <div
          className="Clip_Loader"
          style={{ minHeight: "280px" }}
        >
          <ClipLoader
            color={"rgb(0, 143, 251)"}
            loading={true}
            size={50}
          />
        </div>
      ) :
        <div className=" h-100">
          <div className="OverView_Outer_Block ">
            <div className=" Overview">
              <div className="Card_Body p-0">
                <div className="table-responsive Sales_Summery_Table">
                  <table className="table">
                    <thead>
                      <tr>
                        <th width="40%">
                        </th>
                        <th>
                          <div className="Sales_Summery_Table_Head_Ttl">
                            <div className="Icon">
                              <img
                                src={Shoppers}
                                className="img-fluid"
                                alt="Retailer"
                              />
                            </div>
                            <span>Users</span>
                          </div>
                        </th>
                        <th>
                          <div className="Sales_Summery_Table_Head_Ttl">
                            <div className="Icon">
                              <img
                                src={Shoppers}
                                className="img-fluid"
                                alt="Retailer"
                              />
                            </div>
                            <span>Shoppers</span>
                          </div>
                        </th>
                        <th>
                          <div className="Sales_Summery_Table_Head_Ttl">
                            <div className="Icon">
                              <img
                                src={Dollar}
                                className="img-fluid"
                                alt="Retailer"
                              />
                            </div>
                            <span>Units Sold </span>
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {retailerarr.length > 0 ? (
                        <>
                          {Object.keys(retailerfeed).map(
                            (val, idx) => {
                              return parseInt(
                                retailerfeed[val].Users
                              ) > 0 ? (
                                <tr>
                                  <td>

                                    <span>
                                      {retailerfeed[val]
                                        .RetailerName
                                        ? retailerfeed[val]
                                          .RetailerName
                                        : retailerfeed[val]
                                          .WholeSalerName}
                                    </span>

                                  </td>
                                  <td >


                                  <span>
                                    {retailerfeed[
                                      val
                                    ].TotalUsers.toString().replace(
                                      /\B(?=(\d{3})+(?!\d))/g,
                                      ","
                                    )}{" "}
                                  </span>

                                </td>
                                  <td >

                                    <span>
                                      {retailerfeed[
                                        val
                                      ].Users.toString().replace(
                                        /\B(?=(\d{3})+(?!\d))/g,
                                        ","
                                      )}
                                    </span>

                                  </td>
                                  <td className="newOverview">


                                  <span>
                                    {
                                      retailerfeed[val]?.UnitsSold.toLocaleString()
                                    }
                                  </span>

                                  </td>
                                </tr>
                              ) : (
                                ""
                              );
                            }
                          )}</>
                      ) : (
                        <>{Object.keys(retailerfeed).map(
                          (val, idx) => {
                            return parseInt(
                              retailerfeed[val].Users
                            ) > 0 ? (
                              <tr>
                                <td >


                                  <span>
                                    {retailerfeed[val]
                                      .RetailerName
                                      ? retailerfeed[val]
                                        .RetailerName
                                      : retailerfeed[val]
                                        .WholeSalerName}
                                  </span>

                                </td>
                                <td >


                                  <span>
                                    {retailerfeed[
                                      val
                                    ].TotalUsers.toString().replace(
                                      /\B(?=(\d{3})+(?!\d))/g,
                                      ","
                                    )}{" "}
                                  </span>

                                </td>
                                <td >


                                  <span>
                                    {retailerfeed[
                                      val
                                    ].Users.toString().replace(
                                      /\B(?=(\d{3})+(?!\d))/g,
                                      ","
                                    )}{" "}
                                  </span>

                                </td>
                                <td >
                                  <span>
                                    {
                                      retailerfeed[val]?.UnitsSold.toLocaleString()
                                    }
                                  </span>

                                </td>
                              </tr>
                            ) : (
                              ""
                            );
                          }
                        )}</>
                      )}
                    </tbody>
                  </table>
                </div>
                {/* <tr className="Stores_Header">
                <li></li>
                <li>Shoppers</li>
                <li>Total Sales (Data from last 90 days)</li>
              </tr> */}
                {/* {this.state.summaryLoader ? (
                <div
                  className="Clip_Loader"
                  style={{ minHeight: "280px" }}
                >
                  <ClipLoader
                    color={"rgb(0, 143, 251)"}
                    loading={true}
                    size={50}
                  />
                </div>
              ) : this.state.retailerarr.length > 0 ? (
                <ul className="OverviewList p-2">
                  {Object.keys(this.state.retailerfeed).map(
                    (val, idx) => {
                      return parseInt(
                        this.state.retailerfeed[val].Users
                      ) > 0 ? (
                        <>
                          <li className="newOverview">
                            <div className="Icon">
                              <img
                                src={
                                  this.state.retailerfeed[val]
                                    .RetailerName
                                    ? Retailer
                                    : wholesalers
                                }
                                className="img-fluid"
                                alt="Retailer"
                              />
                            </div>
                            <div className="OverviewList_Info">
                              <p>
                                {this.state.retailerfeed[val]
                                  .RetailerName
                                  ? this.state.retailerfeed[val]
                                      .RetailerName
                                  : this.state.retailerfeed[val]
                                      .WholeSalerName}
                              </p>
                            </div>
                          </li>
                          <li className="newOverview">
                            <div className="Icon">
                              <img
                                src={Shoppers}
                                className="img-fluid"
                                alt="Retailer"
                              />
                            </div>
                            <div className="OverviewList_Info">
                              <p className="Head_ttl">Shoppers </p>
                              <span>
                                {this.state.retailerfeed[
                                  val
                                ].Users.toString().replace(
                                  /\B(?=(\d{3})+(?!\d))/g,
                                  ","
                                )}
                              </span>
                            </div>
                          </li>
                          <li className="newOverview">
                            <div className="Icon">
                              <img
                                src={Dollar}
                                className="img-fluid"
                                alt="Wholesaler"
                              />
                            </div>
                            <div className="OverviewList_Info">
                              <p className="Head_ttl">Total Sales</p>
                              <span>
                                {
                                  this.state.retailerfeed[val]
                                    .TotalSales
                                }
                              </span>
                            </div>
                          </li>
                        </>
                      ) : (
                        ""
                      );
                    }
                  )}
                </ul>
              ) : (
                <ul className="OverviewList p-2">
                  {Object.keys(this.state.retailerfeed).map(
                    (val, idx) => {
                      return parseInt(
                        this.state.retailerfeed[val].Users
                      ) > 0 ? (
                        <>
                          <li className="newOverview">
                            <div className="Icon">
                              <img
                                src={
                                  this.state.retailerfeed[val]
                                    .RetailerName
                                    ? Retailer
                                    : wholesalers
                                }
                                className="img-fluid"
                                alt="Retailer"
                              />
                            </div>
                            <div className="OverviewList_Info">
                              <p>
                                {this.state.retailerfeed[val]
                                  .RetailerName
                                  ? this.state.retailerfeed[val]
                                      .RetailerName
                                  : this.state.retailerfeed[val]
                                      .WholeSalerName}
                              </p>
                            </div>
                          </li>
                          <li className="newOverview">
                            <div className="Icon">
                              <img
                                src={Shoppers}
                                className="img-fluid"
                                alt="Retailer"
                              />
                            </div>
                            <div className="OverviewList_Info">
                              <p className="Head_ttl">Shoppers </p>
                              <span>
                                {this.state.retailerfeed[
                                  val
                                ].Users.toString().replace(
                                  /\B(?=(\d{3})+(?!\d))/g,
                                  ","
                                )}{" "}
                              </span>
                            </div>
                          </li>
                          <li className="newOverview">
                            <div className="Icon">
                              <img
                                src={Dollar}
                                className="img-fluid"
                                alt="Retailer"
                              />
                            </div>
                            <div className="OverviewList_Info">
                              <p className="Head_ttl">Total Sales</p>

                              <span>
                                {
                                  this.state.retailerfeed[val]
                                    .TotalSales
                                }
                              </span>
                            </div>
                          </li>
                        </>
                      ) : (
                        ""
                      );
                    }
                    //     )
                    //     )
                    // }
                  )}
                </ul>
              )} */}
              </div>
            </div>
          </div>
        </div>
      
  
   

  )

};

export default UserSaleSummary;