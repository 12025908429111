import React from "react";
import * as XLSX from "xlsx";
import Header from "../Dashboard/Header";
import * as CONFIG from "../../Components/_config";
import Retailer from "../../Assets/images/retailer.svg";
import Stores from "../../Assets/images/stores.svg";
import Sold from "../../Assets/images/sold.svg";
import Upload from "../../Assets/images/upload-arrow.svg";
import Upcs from "../../Assets/images/upcs.svg";
import Add from "../../Assets/images/plus.svg";
import DataTable from "react-data-table-component";
import Delete from "../../Assets/images/delete.png";
import Edit from "../../Assets/images/edit_pencil.png";
import Coupons from "../../Assets/images/coupons_new.svg";
import { Button } from "react-bootstrap";
import $ from "jquery";
import axios from "axios";
import CompanyProductChart from "../../Components/Chart/CompanyProductChart";
import ls from "local-storage";
import { toast } from "react-toastify";
import AddWholesalerModal from "../../Components/Common/AddWholesalerModal";
import AddRetailerModal from "../../Components/Common/AddRetailer/AddRetailerModal";
import ClipLoader from "react-spinners/ClipLoader";
import { Redirect } from "react-router";
import "react-toastify/dist/ReactToastify.css";
import FooterStrict from "../Dashboard/FooterStrict";
import BillingTab from "./BillingTab";
import { renderTooltip } from "../../Components/Helper";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import BrandContact from "./BrandContact";
import AddBrand from "../AddBrand/AddBrand";
import { Form, Spinner } from "react-bootstrap";

class BrandDetails extends React.Component {
  constructor(props) {
    super(props);
    toast.configure();
    this.inputRef = React.createRef();
    this.state = {
      brandDetails: "",
      AllWholesaler: "",
      brandWholesaler: "",
      AllRetailer: "",
      upcarray: [],
      wholesalerarray: [],
      wholesalerArray: "",
      retailerarr: "",
      wholesaleridArr: [],
      wholesaler_number: [],
      brandRetailer: {},
      OverviewDetails: "",
      loader: false,
      totalcouponCount: "",
      showbrand: false,
      deleteID: "",
      deletLoader: false,
      masterWholesaler: [],
      uploadData: [],
      uploadLoader: false,
      downloadCouponloader: false,
       formControls: {
        upc11: "",
        itemCode: "",
        category: "",
        productName: "",
        upcCategoryID: "",
        upcGuid: ""
      },
      open: "",
      modalLoader: false,
      Categorylist: [],
      selectedUpcID: "",
      excelFile: null,
      excelData: []
    };
    this.ShowBrand = this.ShowBrand.bind(this);

  }

  getCategory = () => {
    const guid = this.props.match.params.b_guid
    var data = "";
    var config = {
      method: "get",
      url: CONFIG.API_URL + `brand/allcategory/${guid}`,
      headers: CONFIG.config_head.headers,
      data: data
    };
    axios(config)
      .then((response) => {
        var groups = Object.create(null);
        response.data.Message.forEach(function (a) {
          groups[a.category_name] = groups[a.category_name] || [];
          groups[a.category_name].push(a);
        });
        this.setState({
          Categorylist: response.data.Message,
          categoryLoader: false
        });
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 400) {
            this.setState({ Categorylist: '', categoryLoader: false })
          } else if (error.response.status === 403) {
            toast.error(error.response.data.Message);
            this.setState({ Categorylist: '', categoryLoader: false })
          } else {
            this.setState({ Categorylist: '', categoryLoader: false })
            toast.error(error.response.data.Message);
          }
        }
      });
  }

  handleInputFile = () => {
    this.inputRef.current.click();
  }

  handleFile = (e) => {
    const selectedFile = e.target.files[0];
    let reader = new FileReader();
    reader.readAsArrayBuffer(selectedFile);
    reader.onload = (e) => {
      this.setState(
        {
          excelFile: e.target.result,
        },
        () => {
          const workbook = XLSX.read(this.state.excelFile, { type: "buffer" });
          const workSheetName = workbook.SheetNames[0];
          const workSheet = workbook.Sheets[workSheetName];
          const data = XLSX.utils.sheet_to_json(workSheet);
          this.setState({
            excelData: data,
          }, () => {
            let data = this.state.excelData.slice();
            let lengthArray = data.map(t => Object.keys(t).length);
            let BigObjectIndex = lengthArray.indexOf(
              Math.max.apply(null, lengthArray)
            );
            let excel = data[BigObjectIndex];
            let array = []
            if (excel.hasOwnProperty("UPC") && Object.keys(excel).length >= 1) {
              for (let i = 0; i < data.length; i++) {
                let info = {
                  "product_name": data[i]["PRODUCTNAME"] ? data[i]["PRODUCTNAME"] : "",
                  upc11: data[i]["UPC"],
                  upc12: null,
                  upc14: null,
                  upc16: null,
                  category: data[i]["CATEGORY"] ? data[i]["CATEGORY"] : "",
                  item_code: data[i]["ITEMCODE"] ? data[i]["ITEMCODE"] : ""
                }
                array.push(info)
              }
              this.setState({
                uploadData: array
              }, () => {
                this.addHandle("upload")
              })
            }
            else toast.error("No UPC value present");
          });
        }
      );
    };
  };
  handleDelete = (upcguid) => {
    this.setState({
      deleteID: upcguid,
      deletLoader: true
    }, () => {
      const requestURL = CONFIG.API_URL + `brand/products/${upcguid}`
      const token_config = {
        headers: CONFIG.config_head.headers
      };
      axios.delete(requestURL, token_config).then(response => {
        if (response.status === 204)
          this.setState({
            deletLoader: false
          }, () => {
            this.callAPIs()
          })
      }).catch(error => {
        console.log(error)
      })
    })
  }

  selectchangeHandler = (event) => {
    let name = this.state.Categorylist.filter(data => data.upc_category_id.toString() === event.target.value.toString()).map(info => {
      return info.category_name
    })
    const value = event.target.value;
    this.setState({
      formControls: {
        ...this.state.formControls,
        category: name[0],
        upcCategoryID: value
      }
    });
  };

  changeHandler = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    this.setState({
      formControls: {
        ...this.state.formControls,
        [name]: value
      }
    });
  };

  callAPIs = () => {
    this.setState({
      loader: true
    }, () => {
      var b_guid = this.props.match.params.b_guid;
      const token_config = {
        headers: CONFIG.config_head.headers
      };

      const requestOne = axios.get(
        CONFIG.API_URL + `brand/${b_guid}`,
        token_config
      );

      const requestTwo = axios.get(
        CONFIG.API_URL + `master/${b_guid}?type=wholesaler&search=''`,
        token_config
      );

      const requestThree = axios.get(
        CONFIG.API_URL + `brand/getremainingws/${b_guid}`,
        token_config
      );
      const requestFour = axios.get(
        CONFIG.API_URL + `brand/getremainingret/${b_guid}`,
        token_config
      );
      axios
        .all([requestOne, requestTwo, requestThree, requestFour])
        .then(
          axios.spread((...responses) => {
            let responseOne = responses[0];
            let responseTwo = responses[1];
            let responseThree = responses[2];
            let responseFour = responses[3];

            const wholesaler_number = [];
            for (const obj of responseTwo.data.Message) {
              wholesaler_number.push(obj.wholesaler_number);
            }

            var groups = Object.create(null),
              result;

            responseTwo.data.Message.forEach(function (a) {
              groups[a.wholesaler_number] = groups[a.wholesaler_number] || [];
              groups[a.wholesaler_number].push(a);
            });

            // eslint-disable-next-line no-unused-vars
            result = Object.keys(groups).map(function (k) {
              var temp = {};
              temp[k] = groups[k];
              return temp;
            });

            var wholesaleridarr = [];
            responseThree.data.Message.map((val, idx) =>
              wholesaleridarr.push(val.wholesaler_guid)
            );

            responseOne.data.Message.upc.map((val, idx) =>
              this.state.upcarray.push(val.upc11)
            );
            var retailer = [];
            for (const obj of responseOne.data.Message.retailers) {
              retailer.push(parseInt(obj));
            }
            // this.setState({ UpcList: this.state.upcarray});
            if (responseOne.data.Message.wholesalers.length > 0) {
              responseOne.data.Message.wholesalers.map((val, idx) => {
                return val.has_full_access === true
                  ? this.state.wholesalerarray.push(val.WholesalerNumber)
                  : "";
              });
            }
            this.setState({
              brandDetails: responseOne.data.Message,
              tableData: responseOne.data.Message.upc,
              brandWholesaler: responseOne.data.Message.wholesalers,
              brandRetailer: responseOne.data.Message.retailers,
              AllWholesaler: groups,
              masterWholesaler: responseTwo.data.Message,
              wholesaleridArr: wholesaleridarr,
              remainingWholesaler: responseThree.data.Message,
              brand_guid: b_guid,
              remainingRetailer: responseFour.data.Message,
              wholesalerArray: this.state.wholesalerarray,
              UpcList: this.state.upcarray,
              retailerarr: retailer
            });

            this.retailerList();
            this.getOverViewDetails();
            this.searchCoupon();
          })
        )
        .catch(function (error) {
          if (error.response) {
            if (error.response.status === 400) {
              // toast.error(error.response.data.Message);

              // ls.clear("token");
              // ls.clear("brands");

              // window.location.href = "/login";
            } else if (error.response.status === 403) {
              toast.error(error.response.data.Message);
              ls.clear("token");
              ls.clear("brands");

              window.location.href = "/login";
            } else {
              toast.error(error.response.data.Message);
            }
          }
        });
    })

  }
  componentDidMount() {
    this.callAPIs();
    this.getCategory();
  }

 

  getOverViewDetails() {
    //var token = ls.get("token") ? ls.get("token") : "";
    var today = new Date().toISOString().split("T")[0];
    var dateParts = today.split("-");
    dateParts[0] = parseInt(dateParts[0], 10) - 1;
    var pastYear = dateParts.join("-");

    let data = JSON.stringify({
      BrandID: 0,
      EndDate: today,
      OrderBy: "QtySOld",
      StartDate: pastYear,
      UPC: this.state.UpcList,
      type: "Overview",
      Wholesaler: this.state.wholesalerArray,
      Retailer: this.state.retailerarr
    });
    let config = {
      method: "post",
      url: CONFIG.API_URL + "query",
      headers: CONFIG.config_head.headers,
      data: data
    };
    axios(config)
      .then((response) => {
        this.setState({
          OverviewDetails: response.data.Message[0],
          loader: false
        });
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 400) {
            this.setState({
              OverviewDetails: [],
              loader: false
            });
          }
          else {
            this.setState({
              OverviewDetails: [],
              loader: false
            });
          }

        }
        console.log(error);
      });
  }

  editHandle = async (e) => {
    e.preventDefault();
    if (this.state.formControls.upc11 === "") {
      toast.error("UPC can't be blank", { theme: "colored" });
      return false;
    }
    else {
      this.setState({
        modalLoader: true
      }, () => {
        var data = JSON.stringify({
          product_name: this.state.formControls.productName ? this.state.formControls.productName : '',
          upc11: this.state.formControls.upc11,
          upc_guid: this.state.formControls.upcGuid,
          upc12: null,
          upc14: null,
          upc16: null,
          category_name: this.state.formControls.category,
          item_code: this.state.formControls.itemCode,
          upc_category_id: this.state.formControls.upcCategoryID ? this.state.formControls.upcCategoryID : 0
        });
        var config = {
          method: "put",
          url: CONFIG.API_URL + `brand/products/${this.props.match.params.b_guid}`,
          headers: CONFIG.config_head.headers,
          data: data
        };
        axios(config)
          .then((res) => {
            if (res.status === 200) {
              this.setState({
                modalLoader: false
              }, () => {
                toast.success("Updated Successfully");
                setTimeout(() => window.location.reload(), 500)
              })
            }
          })
          .catch((error) => {
            this.setState({
              modalLoader: false
            }, () => {
              if (error.response) {
                if (error.response.status === 502) {
                  toast.error("Bad Request, Please Try Again!");
                } else if (error.response.status === 400) {
                  toast.error(error.response.data.Message);
                } else {
                  toast.error(error.response.data.Message);
                }
              }
            })
          });
      })

    }
  };

  addHandle = async (value) => {
    if (value === "add" && this.state.formControls.upc11 === "") {
      toast.error("UPC11 can't be blank", { theme: "colored" });
      return false;
    }
    else {
      this.setState({
        modalLoader: true,
        uploadLoader: value === "add" ? false : true
      }, () => {
        var data = this.state.uploadData.length > 0 ? this.state.uploadData :
          [
            {
              product_name: this.state.formControls.productName ? this.state.formControls.productName : '',
              upc11: this.state.formControls.upc11,
              upc12: null,
              upc14: null,
              upc16: null,
              category: this.state.formControls.category,
              item_code: this.state.formControls.itemCode,
            }
          ]
        var config = {
          method: "put",
          url: CONFIG.API_URL + `brand/products/${this.props.match.params.b_guid}`,
          headers: CONFIG.config_head.headers,
          data: data
        };
        axios(config)
          .then((res) => {
            if (res.status === 200) {
              this.setState({
                modalLoader: false,
                uploadLoader: false
              }, () => {
                toast.success("Added Successfully");
                setTimeout(() => window.location.reload(), 500)
              })
            }
          })
          .catch((error) => {
            this.setState({
              modalLoader: false,
              uploadLoader: false
            }, () => {
              if (error.response) {
                if (error.response.status === 502) {
                  toast.error("Bad Request, Please Try Again!");
                } else if (error.response.status === 400) {
                  toast.error(error.response.data.Message);
                } else {
                  toast.error(error.response.data.Message);
                }
              }
            })
          });
      })
    }
  };

  searchCoupon() {
    var b_guid = this.props.match.params.b_guid;

    var data = JSON.stringify({
      brandId: b_guid,
      active: 1,
      page: 1,
      count: 10,
      sort: "-name",
      "include-clip": true,
      "include-redemption": true,
      search: "",
      "coupon-start-date": "",
      "coupon-end-date": ""
    });

    var config = {
      method: "post",
      url: CONFIG.API_URL + "coupon/search",
      headers: CONFIG.config_head.headers,
      data: data
    };

    axios(config)
      .then((response) => {
        var clip_count = 0;
        var redemption_count = 0;

        for (var j = 0; j < response.data.Message.entries.length; j++) {
          clip_count = clip_count + response.data.Message.entries[j].clip_count;
        }
        for (var i = 0; i < response.data.Message.entries.length; i++) {
          redemption_count =
            redemption_count +
            response.data.Message.entries[i].redemption_count;
        }

        this.setState({
          totalcouponCount: response.data.Message.totalcount,
          total_clip_count: clip_count,
          total_redemption_count: redemption_count
        });
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 400) {
            this.setState({
              totalcouponCount: 0,
              total_clip_count: 0,
              total_redemption_count: 0
            });
          } else if (error.response.status === 403) {
            toast.error(error.response.data.Message);
            ls.clear("token");
            ls.clear("brands");
            window.location.href = "/login";
          } else {
            toast.error(error.response.data.Message);
          }
        }
      });
  }

  async retailerList() {
    let posts = [];
    if (Object.keys(this.state.wholesaleridArr).length > 0) {
      let id = "";
      let i = 0;
      while (i < Object.keys(this.state.wholesaleridArr).length) {
        id = this.state.wholesaleridArr[i];
        const url = CONFIG.API_URL + `master/${id}?type=retailer&search=''`;
        const result = await axios.get(url, {
          headers: CONFIG.config_head.headers
        });
        posts = [...posts, result.data.Message];
        let rv = [];
        rv = [].concat(...posts);

        var groups = Object.create(null);
        // eslint-disable-next-line no-loop-func
        rv.forEach(function (a) {
          groups[a.rsa_identifier] = groups[a.rsa_identifier] || [];
          groups[a.rsa_identifier].push(a);
        });
        i++;
      }
      this.setState({ AllRetailer: groups });
    }
  }

  AlertModal(e) {
    var retailerId = e.target.getAttribute("data-retailerId");
    var wholesalerid = e.target.getAttribute("data-wholesalerid");
    var type = e.target.getAttribute("data-action");
    if (type === "wholesaler") {
      $("#confirmwholesalerModal").trigger("click");
      this.setState({ wholesalerid: wholesalerid });
    } else {
      $("#confirmModal").trigger("click");
      this.setState({ retailerId: retailerId });
    }
  }

  RemoveRetailer(retailerId) {
    var b_guid = this.props.match.params.b_guid;
    $("#staticBackdrop").css("display", "none");
    $(".modal-backdrop").first().css("display", "none");

    var config = {
      method: "delete",
      url: CONFIG.API_URL + `brand/retailer/${b_guid}/${retailerId}`,
      headers: CONFIG.config_head.headers
    };
    axios(config)
      .then((response) => {
        if (response.status === 204) {

          setTimeout(() => { toast.success("Retailer removed successfully") }, 2500);
          setTimeout(() => { window.location.reload() }, 3000);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  RemoveWholesaler(wholesalerId) {
    var b_guid = this.props.match.params.b_guid;

    $("#staticBackdrop").css("display", "none");
    $(".modal-backdrop").first().css("display", "none");

    var config = {
      method: "delete",
      url: CONFIG.API_URL + `brand/wholesaler/${b_guid}/${wholesalerId}`,
      headers: CONFIG.config_head.headers
    };

    axios(config)
      .then((response) => {
        if (response.status === 204) {
          setTimeout(toast.success("Wholesaler removed successfully"), 4000);

          setTimeout(window.location.reload(), 6000);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  ShowBrand() {
    this.setState({ showbrand: true })
  }

  handleAdd = () => {
    this.setState({
      open: "add"
    }, () => {
      $("#addEditUpcModal").trigger("click");
    })
  }

  handleEdit = (e) => {
    this.setState({
      open: "edit"
    }, () => {
      $("#addEditUpcModal").trigger("click");
      if (e)
        this.setState({
          selectedUpcID: e.category_name,
          formControls: {
            upc11: e.upc11,
            itemCode: e.item_code,
            category: e.category_name,
            productName: e.product_name,
            upcGuid: e.upc_guid
          }
        })
    })
  }

  render() {
    const columns = [
      {
        name: "UPC11",
        selector: (row) => row.upc11,
        sortable: true
      },
      {
        name: "Product Name",
        selector: (row) => row.product_name
      },
      {
        name: "Item Code",
        selector: (row) => row.item_code
      },
      {
        name: "Category",
        selector: (row) => row.category_name
      },
      {
        name: "",
        selector: (row) => <div>
          {this.state.deletLoader && row.upc_guid === this.state.deleteID ?
            <ClipLoader
              color={"rgb(0, 143, 251)"}
              loading={true}
              size={25}
            />
            :
            <img
              className="delete-icon"
              src={Delete}
              alt="Remove"
              onClick={() => this.handleDelete(row.upc_guid)}
            />
          }
        </div>
      },
      {
        name: "",
        selector: (row) => <div>
          <img
            className="edit-icon"
            src={Edit}
            alt="Remove"
            onClick={() => this.handleEdit(row)}
          />
        </div>
      }
    ]
    const isLogged = ls.get("token");
    const is_consent_provided = ls.get("decodetoken")
      ? ls.get("decodetoken").is_consent_provided
      : "";
    if (!isLogged || is_consent_provided === false) {
      return <Redirect to="/login" />;
    }
    return (
      <>
        <Header />

        {
          this.state.showbrand === false ?


            <section className="ContentBlock  pt-24 pb-24 Main_Block">
             
              <div className="container-fluid">
                <div className="row justify-content-center">
                  <div className=" col-12 col-xl-8 col-lg-10">
                    <div className="Card Overview">
                      <div className="Card_Body">
                        {this.state.loader ? (
                          <div
                            className="Clip_Loader"
                            style={{ minHeight: "280px" }}
                          >
                            <ClipLoader
                              color={"rgb(0, 143, 251)"}
                              loading={true}
                              size={50}
                            />
                          </div>
                        ) : this.state.OverviewDetails ? (
                          <ul className="OverviewList-detail">
                            <li>
                              <div className="Icon">
                                <img
                                  src={Retailer}
                                  className="img-fluid"
                                  alt="Retailer"
                                />
                              </div>
                              <div className="OverviewList_Info">
                                <p>Retailers</p>
                                <span>{this.state.OverviewDetails.Retailers}</span>
                              </div>
                            </li>
                            <li>
                              <div className="Icon">
                                <img
                                  src={Stores}
                                  className="img-fluid"
                                  alt="Retailer"
                                />
                              </div>
                              <div className="OverviewList_Info">
                                <p>Stores</p>
                                <span>{this.state.OverviewDetails.Stores}</span>
                              </div>
                            </li>
                            <li>
                              <div className="Icon">
                                <img
                                  src={Upcs}
                                  className="img-fluid"
                                  alt="Retailer"
                                />
                              </div>
                              <div className="OverviewList_Info">
                                <p>UPCs</p>
                                <span>{this.state.OverviewDetails.UPCs}</span>
                              </div>
                            </li>
                            <li>
                              <div className="Icon">
                                <img
                                  src={Sold}
                                  className="img-fluid"
                                  alt="Retailer"
                                />
                              </div>
                              <div className="OverviewList_Info">
                                <p>Units sold</p>
                                <span>{this.state.OverviewDetails.QtySold}</span>
                              </div>
                            </li>

                            <li>
                              <div className="Icon">
                                <img
                                  src={Coupons}
                                  className="img-fluid"
                                  alt="Retailer"
                                />
                              </div>
                              <div className="OverviewList_Info">
                                <p>Coupons</p>
                                <span>
                                  {this.state.totalcouponCount
                                    ? this.state.totalcouponCount
                                    : 0}
                                </span>
                              </div>
                            </li>
                            <li>
                              <div className="Icon">
                                <img
                                  src={Sold}
                                  className="img-fluid"
                                  alt="Retailer"
                                />
                              </div>
                              <div className="OverviewList_Info">
                                <p>Redemptions</p>
                                <span>
                                  {this.state.total_redemption_count
                                    ? this.state.total_redemption_count
                                    : 0}
                                </span>
                              </div>
                            </li>
                            <li>
                              <div className="Icon">
                                <img
                                  src={Sold}
                                  className="img-fluid"
                                  alt="Retailer"
                                />
                              </div>
                              <div className="OverviewList_Info">
                                <p>Clips</p>
                                <span>
                                  {this.state.total_clip_count
                                    ? this.state.total_clip_count
                                    : 0}
                                </span>
                              </div>
                            </li>
                          </ul>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>




                  <div className="d-flex align-items-center Brand_Head justify-content-between">
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb mb-0">
                        <li className="breadcrumb-item" aria-current="page"></li>
                      </ol>
                    </nav>

                    {this.state.brandDetails ?

                      <div class="">

                        </div>
                      : ''
                    }
                  </div>

                  <div className="col-12">
                    {this.state.brandDetails ? (
                      <div className="nav-image brand-nav-head Card">
                        <ul
                          className="nav nav-pills brand-main-tab mb-3"
                          id="pills-tab"
                          role="tablist"
                        >
                          <li
                            className="nav-item Brand-detail-tab"
                            role="presentation"
                            style={{ width: "16%" }}
                          >
                            <button
                              className="nav-link brand-tab-detail active"
                              id="pills-home-tab"
                              data-bs-toggle="pill"
                              data-bs-target="#pills-home"
                              type="button"
                              role="tab"
                              aria-controls="pills-home"
                              aria-selected="true"
                            >
                              Brand Info
                            </button>
                          </li>
                          <li
                            className="nav-item Brand-detail-tab"
                            role="presentation"
                            style={{ width: "16%" }}
                          >
                            <button
                              className="nav-link brand-tab-detail"
                              id="pills-profile-tab"
                              data-bs-toggle="pill"
                              data-bs-target="#pills-profile"
                              type="button"
                              role="tab"
                              aria-controls="pills-profile"
                              aria-selected="false"
                            >
                              Brand Contact
                            </button>
                          </li>

                          <li
                            className="nav-item Brand-detail-tab"
                            role="presentation"
                            style={{ width: "16%" }}
                          >
                            <button
                              className="nav-link brand-tab-detail"
                              id="pills-upc-tab"
                              data-bs-toggle="pill"
                              data-bs-target="#pills-upc"
                              type="button"
                              role="tab"
                              aria-controls="pills-upc"
                              aria-selected="false"
                            >
                              UPC List
                            </button>
                          </li>

                          <li
                            className="nav-item Brand-detail-tab"
                            role="presentation"
                            style={{ width: "18%" }}
                          >
                            <button
                              className="nav-link brand-tab-detail"
                              id="pills-productm-tab"
                              data-bs-toggle="pill"
                              data-bs-target="#pills-productm"
                              type="button"
                              role="tab"
                              aria-controls="pills-productm"
                              aria-selected="false"
                            >
                              Product Movement
                            </button>
                          </li>

                          <li
                            className="nav-item Brand-detail-tab"
                            role="presentation"
                            style={{ width: "16%" }}
                          >
                            <button
                              className="nav-link brand-tab-detail"
                              id="pills-subscription-tab"
                              data-bs-toggle="pill"
                              data-bs-target="#pills-subscription"
                              type="button"
                              role="tab"
                              aria-controls="pills-subscription"
                              aria-selected="false"
                            >
                              Subscriptions
                            </button>
                          </li>

                          <li
                            className="nav-item Brand-detail-tab"
                            role="presentation"
                            style={{ width: "18%" }}
                          >
                            <button
                              className="nav-link brand-tab-detail"
                              id="pills-billing-tab"
                              data-bs-toggle="pill"
                              data-bs-target="#pills-billing"
                              type="button"
                              role="tab"
                              aria-controls="pills-billing"
                              aria-selected="false"
                            >
                              Billing
                            </button>
                          </li>
                        </ul>

                        <div
                          className="tab-content brand-tab-bg"
                          id="pills-tabContent"
                        >
                          <div
                            className="tab-pane fade show active"
                            id="pills-home"
                            role="tabpanel"
                            aria-labelledby="pills-home-tab"
                          >
                            {this.state.brandDetails.brand_status === 1 ?

                              <div style={{ float: 'right' }}>

                                <button className="mt-5 Btn_blue btn" onClick={this.ShowBrand}
                                  style={{ lineHeight: '10px', margin: '26px', width: '80%' }}> Add Brand</button>
                              </div>
                              : ''
                            }
                            <div className="row">
                              <div className="col-12 col-lg-6">
                                <ul className="a-fixed-left-grid-inner mb-col-lf">
                                  <li className="d-flex align-items-center pb-5 pt-5">
                                    <span className="a-col-left">Brand Name :</span>
                                    <span className="a-col-right">
                                      {this.state.brandDetails.brand_name}
                                    </span>
                                  </li>
                                  <li className="d-flex align-items-center pb-5 pt-5">
                                    <span className="a-col-left">Address 1 :</span>
                                    <span className="a-col-right">
                                      {/* {this.state.brandDetails.address1} {this.state.brandDetails.address2}
                                   */}
                                      {this.state.brandDetails.address1}
                                    </span>
                                  </li>
                                  <li className="d-flex align-items-center pb-5 pt-5">
                                    <span className="a-col-left">City :</span>
                                    <span className="a-col-right">
                                      {" "}
                                      {this.state.brandDetails.city}
                                    </span>
                                  </li>
                                  <li className="d-flex align-items-center pb-5 pt-5">
                                    <span className="a-col-left">Zip :</span>
                                    <span className="a-col-right">
                                      {" "}
                                      {this.state.brandDetails.postal_code}
                                    </span>
                                  </li>
                                  {/* <li className="d-flex align-items-center pb-5 pt-5">
                                <span className="a-col-left">Company Name :</span>
                                <span className="a-col-right">  {this.state.brandDetails.company_name}</span>
                              </li> */}
                                  <li className="d-flex align-items-center pb-5 pt-5">
                                    <span className="a-col-left">Logo :</span>
                                    <span className="a-col-right">
                                      <img
                                        src={this.state.brandDetails.logo}
                                        className="img-fluid"
                                        alt="Retailer"
                                      />
                                    </span>
                                  </li>
                                </ul>
                              </div>
                              <div className="col-12 col-lg-6">
                                <ul className="a-fixed-left-grid-inner mb-col-lf">
                                  <li className="d-flex align-items-center pb-5 pt-5">
                                    <span className="a-col-left">Status :</span>
                                    {this.state.brandDetails.brand_status === 1 ? (
                                      <span className="a-col-right status approval">
                                        Active{" "}
                                      </span>
                                    ) : (
                                      <span className="a-col-right status pending">
                                        Pending{" "}
                                      </span>
                                    )}
                                  </li>
                                  <li className="d-flex align-items-center pb-5 pt-5">
                                    <span className="a-col-left">Address 2 :</span>
                                    <span className="a-col-right">
                                      {this.state.brandDetails.address1}
                                    </span>
                                  </li>
                                  <li className="d-flex align-items-center pb-5 pt-5">
                                    <span className="a-col-left">State :</span>
                                    <span className="a-col-right">
                                      {this.state.brandDetails.country_state}
                                    </span>
                                  </li>
                                  <li className="d-flex align-items-center pb-5 pt-5">
                                    <span className="a-col-left">Website :</span>
                                    <span className="a-col-right">
                                      {this.state.brandDetails.website}
                                    </span>
                                  </li>
                                  {/* <li className="d-flex align-items-center pb-5 pt-5">
                                <span className="a-col-left">FEIN :</span>
                                <span className="a-col-right">56234556</span>
                              </li> */}
                                </ul>
                              </div>
                            </div>
                          </div>

                          {this.state.brandDetails && (

                            <BrandContact brandDetails={this.state.brandDetails} ShowBrand={this.ShowBrand}


                            />

                          )}


                          <div
                            className="tab-pane fade"
                            id="pills-subscription"
                            role="tabpanel"
                            aria-labelledby="pills-subscription-tab"
                          >
                            <div className="row">
                              <div className="col-12 col-lg-12">
                                <div className="Common_Block_Body pr-12 pl-12 pt-20 pb-20">
                                  <div className="Coupons_Block mt-21 row">
                                    <div className="Common_Block_Coupons_Header d-flex align-items-center justify-content-between Steps_Header">
                                      <h5>Selected Wholesalers with Full Access</h5>

                                      <div className="" style={{ float: "right" }}>
                                        {this.state.remainingWholesaler && (
                                          <>
                                            <button
                                              className="Digital_Brand_Button approve"
                                              data-bs-toggle="modal"
                                              id="wholesalermodal"
                                              data-bs-target="#selectwholesaler-sel-drop"
                                              style={{
                                                borderColor: "#3d8ecc ",
                                                backgroundColor: "#3d8ecc",
                                                color: "#fff",
                                                fontWeight: "600",
                                                paddingTop: "4px"
                                              }}
                                            >
                                              Add
                                            </button>

                                            <AddWholesalerModal
                                              simplifiedFunction={
                                                this.addWholesaler
                                              }
                                              checkwholesalerFunction={
                                                this.selectWholesaler
                                              }
                                              wholesalerData={
                                                this.state.remainingWholesaler
                                              }
                                              brandguidata={this.state.brand_guid}
                                            />
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  </div>

                                  {this.state.brandDetails ? (
                                    <>
                                      <ul className=" mt-15 d-flex  Retailer">
                                        {Object.keys(
                                          this.state.brandWholesaler
                                        ).map((val, idx) => {
                                          return this.state.brandWholesaler[val]
                                            .has_full_access === true ? (
                                            //console.log(this.state.AllWholesaler?this.state.AllWholesaler[this.state.brandDetails.wholesalers[val].WholesalerNumber][0].wholesaler_name:'')
                                            <li key={idx}>
                                              <div className="Retailer_Block">

                                                <OverlayTrigger
                                                  placement="top"
                                                  delay={{ show: 250, hide: 400 }}
                                                  overlay={renderTooltip('Remove')}
                                                >

                                                  <img
                                                    src={Delete}
                                                    className="img-fluid"
                                                    alt="Remove"
                                                    style={{
                                                      width: "35px",
                                                      height: "35px",
                                                      float: "right"
                                                    }}
                                                    data-wholesalerid={
                                                      this.state.AllWholesaler
                                                        ? this.state.AllWholesaler[
                                                          this.state.brandDetails
                                                            .wholesalers[val]
                                                            .WholesalerNumber
                                                        ][0].wholesaler_id
                                                        : ""
                                                    }
                                                    data-action="wholesaler"
                                                    onClick={(e) =>
                                                      this.AlertModal(e)
                                                    }
                                                  />
                                                </OverlayTrigger >

                                                <div className="RetailerImage">
                                                  <img
                                                    className="img-fluid"
                                                    alt="Retailer"
                                                    src={
                                                      this.state.AllWholesaler
                                                        ? this.state.AllWholesaler[
                                                          this.state.brandDetails
                                                            .wholesalers[val]
                                                            .WholesalerNumber
                                                        ][0].logo
                                                        : ""
                                                    }
                                                  />
                                                </div>
                                                <h5>
                                                  {this.state.AllWholesaler
                                                    ? this.state.AllWholesaler[
                                                      this.state.brandDetails
                                                        .wholesalers[val]
                                                        .WholesalerNumber
                                                    ][0].wholesaler_name
                                                    : ""}{" "}
                                                </h5>
                                              </div>
                                            </li>
                                          ) : (
                                            ""
                                          );
                                        })}
                                      </ul>
                                    </>
                                  ) : (
                                    ""
                                  )}

                                  <div className="Common_Block_Coupons_Header d-flex align-items-center justify-content-between Steps_Header">
                                    <h5>Selected Retailers</h5>

                                    <div className="" style={{ float: "right" }}>
                                      {this.state.remainingRetailer && (
                                        <>
                                          <button
                                            className="Digital_Brand_Button"
                                            data-bs-toggle="modal"
                                            id="retailermodal"
                                            data-bs-target="#selectretailer-sel-drop"
                                            style={{
                                              borderColor: "#3d8ecc",
                                              backgroundColor: "#3d8ecc",
                                              color: "#fff",
                                              fontWeight: "600",
                                              paddingTop: "4px"
                                            }}
                                          >
                                            Add
                                          </button>

                                          <AddRetailerModal
                                            retailerData={
                                              this.state.remainingRetailer
                                            }
                                            brandguidata={this.state.brand_guid}
                                          />
                                        </>
                                      )}
                                    </div>
                                  </div>

                                  {Object.keys(this.state.brandRetailer).length >
                                    0 ? (
                                    <>
                                      <ul className=" mt-15 d-flex  Retailer">
                                        {Object.keys(this.state.brandRetailer).map(
                                          (val, idx) => {
                                            return (
                                              // console.log(this.state.AllRetailer?this.state.AllRetailer[parseInt(this.state.brandRetailer[val])][0].retailer_name:'')
                                              <li key={idx}>
                                                <div className="Retailer_Block">
                                                  <OverlayTrigger
                                                    placement="top"
                                                    delay={{ show: 250, hide: 400 }}
                                                    overlay={renderTooltip('Remove')}
                                                  >

                                                    <img
                                                      src={Delete}
                                                      className="img-fluid"
                                                      alt="Remove"
                                                      data-action="retailer"
                                                      data-retailerId={
                                                        this.state.AllRetailer
                                                          ? this.state.AllRetailer[
                                                            parseInt(
                                                              this.state
                                                                .brandRetailer[val]
                                                            )
                                                          ][0].retailer_id
                                                          : ""
                                                      }
                                                      style={{
                                                        width: "35px",
                                                        height: "35px",
                                                        float: "right"
                                                      }}
                                                      onClick={(e) =>
                                                        this.AlertModal(e)
                                                      }
                                                    />
                                                  </OverlayTrigger>

                                                  <div className="RetailerImage">
                                                    <img
                                                      className="img-fluid"
                                                      alt="Retailer"
                                                      src={
                                                        this.state.AllRetailer
                                                          ? this.state.AllRetailer[
                                                            parseInt(
                                                              this.state
                                                                .brandRetailer[
                                                              val
                                                              ]
                                                            )
                                                          ][0].logo
                                                          : ""
                                                      }
                                                    />
                                                  </div>
                                                  <h5>
                                                    {this.state.AllRetailer
                                                      ? this.state.AllRetailer[
                                                        parseInt(
                                                          this.state
                                                            .brandRetailer[val]
                                                        )
                                                      ][0].retailer_name
                                                      : ""}{" "}
                                                  </h5>
                                                </div>
                                              </li>
                                            );
                                          }
                                        )}
                                      </ul>
                                    </>
                                  ) : (
                                    "No Retailers added"
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div
                            className="tab-pane fade"
                            id="pills-upc"
                            role="tabpanel"
                            aria-labelledby="pills-upc-tab"
                          >
                            <div className="row">
                              <div className="col-12 col-lg-12">
                                {this.state.brandDetails ? (
                                  <div className="Common_Block_Body">
                                    <div className="table-responsive Coupons_Table">
                                      {
                                        this.state.loader ?
                                          <div
                                            className="Clip_Loader"
                                            style={{ minHeight: "280px" }}
                                          >
                                            <ClipLoader
                                              color={"rgb(0, 143, 251)"}
                                              loading={true}
                                              size={50}
                                            />
                                          </div>
                                          :
                                          <DataTable
                                            columns={columns}
                                            data={this.state.tableData}
                                            pagination
                                          />
                                      }
                                    </div>
                                    <div className="add-upc-button">
                                      <button
                                        type="button"
                                        className="btn btn-primary"
                                        onClick={this.handleAdd}
                                      >
                                        <img src={Add} alt={"add"} className="add-image" />
                                        Add Upcs
                                      </button>
                                    </div>

                                    <div className="d-flex align-items-center justify-content-end Pagination_Block"></div>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </div>

                          <div
                            className="tab-pane fade"
                            id="pills-productm"
                            role="tabpanel"
                            aria-labelledby="pills-productm-tab"
                          >
                            <div className="row">
                              <div className="col-12 col-lg-12">
                                {this.state.wholesalerArray &&
                                  this.state.retailerarr && (
                                    <CompanyProductChart
                                      wholesalerArray={this.state.wholesalerArray}
                                      UpcList={this.state.UpcList}
                                      retailerarr={this.state.retailerarr}
                                    />
                                  )}
                              </div>
                            </div>
                          </div>
                          <BillingTab />
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div >
              </div >
            </section >
            :
            this.state.remainingRetailer && this.state.remainingWholesaler && this.state.brand_guid && this.state.brandDetails && (
              <AddBrand remainingRetailer={this.state.remainingRetailer} brandguid={this.state.brand_guid} addWholesaler={this.addWholesaler}
                selectWholesaler={this.selectWholesaler} remainingWholesaler={this.state.masterWholesaler} company_id={this.state.brandDetails.company_id} />
            )
        }
        <button
          className="btn btn-success"
          data-bs-toggle="modal"
          data-bs-target="#staticBackdrop"
          id="confirmModal"
          style={{ display: "none" }}
        >
          Alert
        </button>
        <button
          className="btn btn-success"
          data-bs-toggle="modal"
          data-bs-target="#staticBackdropwholesaler"
          id="confirmwholesalerModal"
          style={{ display: "none" }}
        >
          Alert
        </button>
        <button
          className="btn btn-success"
          data-bs-toggle="modal"
          data-bs-target="#addEditUpc"
          id="addEditUpcModal"
          style={{ display: "none" }}
        >
          Alert
        </button>

        <div
          className="modal fade Confirmation_Modal"
          id="staticBackdrop"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <i className="fas fa-exclamation-triangle text-center "></i>
                <h4 className="mt-20 fw-bold text-center">Are you sure?</h4>
                <p className="mt-10 text-center">
                  You want to remove this selected retailer.
                </p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={(e) =>
                    this.RemoveRetailer(
                      this.state.retailerId ? this.state.retailerId : ""
                    )
                  }
                >
                  Yes
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal fade Confirmation_Modal"
          id="staticBackdropwholesaler"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <i className="fas fa-exclamation-triangle text-center "></i>
                <h4 className="mt-20 fw-bold text-center">Are you sure?</h4>
                <p className="mt-10 text-center">
                  You want to remove this selected wholesaler.
                </p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={(e) =>
                    this.RemoveWholesaler(
                      this.state.wholesalerid ? this.state.wholesalerid : ""
                    )
                  }
                >
                  Yes
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal fade Confirmation_Modal"
          id="addEditUpc"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-upc modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <h4 className="fw-bold">{this.state.open === "edit" ? "Edit" : "Add"} UPC</h4>
                <hr />
                {
                  this.state.uploadLoader ?
                    <div
                      className="Clip_Loader"
                      style={{ minHeight: "280px" }}
                    ><div>
                        <span style={{ display: "flex", justifyContent: "center" }}>
                          <ClipLoader
                            color={"rgb(0, 143, 251)"}
                            loading={true}
                            size={50}
                          />
                        </span>
                        <br />
                        Uploading file...
                      </div>
                    </div>
                    :
                    <div className="Upc_List">
                      <div className="row ">
                        <div className="col-lg-6 col-12">
                          <Form.Group className="Form_Group" controlId="upc11">
                            <Form.Label>UPC (11)</Form.Label>
                            <div className="Input_Icon">
                              <Form.Control
                                type="text"
                                placeholder="Enter UPC (11)"
                                name={"upc11"}
                                defaultValue={this.state.formControls.upc11}
                                onChange={this.changeHandler}
                              />
                            </div>
                          </Form.Group>
                        </div>
                        <div className="col-lg-6 col-12">
                          <Form.Group className="Form_Group" controlId="upc11">
                            <Form.Label>Category</Form.Label>
                            <div className="Input_Icon">
                              <select className="form-select"
                                aria-label="Default select example"
                                id="category_name" name="category"
                                onChange={this.selectchangeHandler}>
                                <option defaultValue={0} value={0}>Select Please</option>
                                {this.state.Categorylist ?
                                  <>
                                    {this.state.Categorylist.map((val, idx) => {
                                      let select = val.category_name === this.state.selectedUpcID ? 'selected' : ''
                                      return (
                                        <>
                                          <option value={val.upc_category_id} selected={select} key={idx}>{val.category_name}</option>
                                        </>
                                      )

                                    }
                                    )}
                                  </>
                                  : ""
                                }
                              </select>
                            </div>
                          </Form.Group>
                        </div>
                        <div className="col-lg-6 col-12">
                          <Form.Group
                            className="Form_Group"
                            controlId="Product"
                          >
                            <Form.Label>Item Code</Form.Label>
                            <div className="Input_Icon">
                              <Form.Control
                                type="text"
                                defaultValue={this.state.formControls.itemCode}
                                placeholder="Enter Item Code"
                                name="itemCode"
                                onChange={this.changeHandler}
                              />
                            </div>
                          </Form.Group>
                        </div>
                        <div className="col-lg-6 col-12">
                          <Form.Group
                            className="Form_Group"
                            controlId="Product"
                          >
                            <Form.Label>Product Name</Form.Label>
                            <div className="Input_Icon">
                              <Form.Control
                                type="text"
                                defaultValue={this.state.formControls.productName}
                                placeholder="Enter Product Name"
                                name="productName"
                                onChange={this.changeHandler}
                              />
                            </div>
                          </Form.Group>
                        </div>
                      </div>
                    </div>
                }

              </div>
              <div className="modal-footer display-upload">
                {
                  this.state.open === "edit" ?
                    <div></div>
                    :
                    <div>
                      <a
                        href={"https://my-brandiqpub.s3.amazonaws.com/UPC.xlsx"}
                        target="_blank"
                        rel="noopener noreferrer"
                        download
                        style={{
                          color: "#0d6ef",
                          textDecoration: "underline",
                          marginTop: "4px",
                        }}
                      >
                        Click this link to download sample UPC file.
                      </a>
                      <br />
                      <button
                        style={{ marginTop: "4px", }}
                        type="button"
                        className="btn btn-secondary"
                        onClick={this.handleInputFile}
                        disabled={this.state.modalLoader}
                      >
                        <img style={{
                          height: "12px",
                          width: "12px",
                          marginRight: "8px"
                        }}
                          alt="upload" src={Upload} />
                        Upload
                      </button>
                    </div>
                }

                <input
                  type="file"
                  accept=".csv,.xlsx,.xls"
                  ref={this.inputRef}
                  style={{ display: "none" }}
                  onChange={(e) => this.handleFile(e)}
                  onClick={(e) => e.target.value = null}
                />
                <div style={{ display: "flex", gap: "10px" }}>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={e => this.state.open === "edit" ? this.editHandle(e) : this.addHandle("add")}
                    disabled={this.state.modalLoader}
                  >
                    Save
                    {
                      this.state.modalLoader &&
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        className={"margin-left-spinner"}
                      />
                    }
                  </button>
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                    disabled={this.state.modalLoader}
                  >
                    Cancel
                  </button>
                </div>

              </div>
            </div>
          </div>
        </div>

        <div
          className="modal fade Confirmation_Modal"
          id="approveBackdropbrand"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <i className="fas fa-exclamation-triangle text-center "></i>
                <h4 className="mt-20 fw-bold text-center">Are you sure?</h4>
                <p className="mt-10 text-center">
                  You want to approve this selected brand.
                </p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={(e) => this.activateBrand()}
                >
                  Yes
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal fade Confirmation_Modal"
          id="staticBackdropbrand"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <i className="fas fa-exclamation-triangle text-center "></i>
                <h4 className="mt-20 fw-bold text-center">Are you sure ?</h4>
                <p className="mt-10 text-center">
                  You want to deactivate this selected brand.
                </p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={(e) => this.deactivate()}
                >
                  Yes
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </div>
        <FooterStrict />
      </>
    );
  }
}

export default BrandDetails;
