import Tooltip from 'react-bootstrap/Tooltip';

export const renderTooltip = (props) => {
  return (
    <Tooltip id="button-tooltip">
      {props}
    </Tooltip>
  )
};


export const CapitaliZeWord = (string) => {
  const arr = string.split(" ");
  for (var i = 0; i < arr.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
  }
  const capitalIzeString = arr.join(" ");
  return capitalIzeString;
};


export const DateFormatter = (date) => {
  var month = date.getMonth() + 1;
  var day = date.getDate();
  var year = date.getFullYear();

  if (month < 10) month = "0" + month.toString();
  if (day < 10) day = "0" + day.toString();

  let calculate_date = month + "-" + day + "-" + year;
  return calculate_date;
}

export const CalenderDateFormatter = (date) => {
  var month = date.getMonth() + 1;
  var day = date.getDate();
  var year = date.getFullYear();

  if (month < 10) month = "0" + month.toString();
  if (day < 10) day = "0" + day.toString();

  let calculate_date = year + "-" + month + "-" + day;


  return calculate_date;
}


