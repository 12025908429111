import ls from "local-storage";
import { toast } from "react-toastify";
import * as CONFIG from "../Components/_config";
import axios from "axios";

//let guid = ls.get("brands") ? ls.get("brands")[0].guid : "";
//let token = ls.get("token") ? ls.get("token") : "";
//let decodetoken = ls.get("decodetoken") ? ls.get("decodetoken") : "";
// const requestFour = axios.get(
//   CONFIG.API_URL + `brand/getremainingret/${b_guid}`,
//   token_config
// );



export const getRemainingWholesaler = (b_guid) => {
  let config = {
    method: "get",
    url: CONFIG.API_URL + `master/${b_guid}?type=wholesaler&search=''`,
    headers: CONFIG.config_head.headers
  };
  return axios(config)
    .then((response) => {
      return response.data;
    })
    .catch(function (error) {
      if (error.response.status === 400) {
        toast.error(error.response.data.Message);
        ls.clear("token");
        ls.clear("brands");
        window.location.href = "/login";
      } else if (error.response.status === 403) {
        toast.error(error.response.data.Message);
        ls.clear("token");
        ls.clear("brands");
        window.location.href = "/login";
      } else {
        toast.error(error.response.data.Message);
      }
    });
};


export const getRemainingRetailer = (b_guid) => {
  let config = {
    method: "get",
    url: CONFIG.API_URL + `master/${b_guid}?type=retailer&search=''`,
    headers: CONFIG.config_head.headers
  };
  return axios(config)
    .then((response) => {

      return response.data;
    })
    .catch(function (error) {
      if (error.response.status === 400) {
        toast.error(error.response.data.Message);

      } else if (error.response.status === 403) {
        toast.error(error.response.data.Message);
        ls.clear("token");
        ls.clear("brands");
        window.location.href = "/login";
      } else {
        toast.error(error.response.data.Message);
      }
    });
};


export const getCompanyWholesaler = () => {
  let config = {
    method: "get",
    url: CONFIG.API_URL + `company/wholesalers`,
    headers: CONFIG.config_head.headers
  };
  return axios(config)
    .then((response) => {

      return response.data;
    })
    .catch(function (error) {
      if (error.response.status === 400) {
        toast.error(error.response.data.Message);
      } else if (error.response.status === 403) {
        toast.error(error.response.data.Message);
        ls.clear("token");
        ls.clear("brands");
        window.location.href = "/login";
      } else {
        toast.error(error.response.data.Message);
      }
    });
};


export const brandBywholesaler = (info) => {
  let data = JSON.stringify({
    wholesalers: info.wholesaler_number_arr,

  });

  let config = {
    method: "post",
    url: CONFIG.API_URL + `brand/bywholesalers`,
    headers: CONFIG.config_head.headers,
    data: data
  };
  return axios(config)
    .then((response) => {

      return response.data;
    })
    .catch((error) => {
      
      if (error.response.status === 400) {
        toast.error(error.response.data.Message);
      } else if (error.response.status === 403) {
        toast.error(error.response.data.Message);
        ls.clear("token");
        ls.clear("brands");
        window.location.href = "/login";
      } else {
        toast.error(error.response.data.Message);
      }
    });
};


export const getUser = (user_guid) => {
  let config = {
    method: "get",
    url: CONFIG.API_URL + `user/${user_guid}`,
    headers: CONFIG.config_head.headers
  };
  return axios(config)
    .then((response) => {
         return response.data;
    })
    .catch(function (error) {
      if (error.response.status === 400) {
        toast.error(error.response.data.Message);
        
      } else if (error.response.status === 403) {
        toast.error(error.response.data.Message);
        ls.clear("token");
        ls.clear("brands");
        window.location.href = "/login";
      } else {
        console.log(error.response.data.Message);
      }
    });
};

 
export const getCompanyUser = () => {
  let config = {
    method: "get",
    url: CONFIG.API_URL + `user/bycompany`,
    headers: CONFIG.config_head.headers
  };
  return axios(config)
    .then((response) => {
         return response.data;
    })
    .catch(function (error) {
      if (error.response.status === 400) {
        toast.error(error.response.data.Message);
        
      } else if (error.response.status === 403) {
        toast.error(error.response.data.Message);
        ls.clear("token");
        ls.clear("brands");
        window.location.href = "/login";
      } else {
        console.log(error.response.data.Message);
      }
    });
};

export const RemoveUser =(remove_user_guid)=>{
  //delete
    let config = {
      method: "delete",
      url: CONFIG.API_URL + `user/${remove_user_guid}`,
      headers: CONFIG.config_head.headers
    };
    return axios(config)
      .then((response) => {
           console.log('response',response)
           return response;
      })
      .catch((error)=> {
        if (error.response.status === 400) {
            return error;
        }else if (error.response.status === 403) {

          toast.error(error.response.data.Message);  
          ls.clear("token");
          ls.clear("brands");
          window.location.href = "/login";
        } 
        else {
          return error;
       
        }
      });
  }
  



 