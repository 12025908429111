import React from 'react'

class CampaignDetails extends React.Component {
    render() {
        return (
            <React.Fragment>
                <div className="row  g-3">
                    {Object.keys(this.props.camp_wholesalername).length >
                        0 ? (
                        <>
                            {Object.keys(this.props.camp_wholesalername).map(
                                (val, idx) => (
                                    <div className=" col-12" key={idx}>
                                        <div className="Campaign_Details_Card">
                                            <div className="Campaign_Details_Card_Header d-flex align-items-center justify-content-between">
                                                <div className="d-flex align-items-center ">
                                                    <div className="Campaign_Logo">
                                                        <img
                                                            src={
                                                                this.props.camp_wholesaler_logo[
                                                                val
                                                                ]
                                                            }
                                                            className="img-fluid "
                                                            alt="campaign"
                                                        />
                                                    </div>
                                                    <h5 className="ml-16">{val}</h5>
                                                </div>
                                                <span>
                                                    {/* <img src={Plus} className="img-fluid " alt="Plus" /> */}
                                                </span>
                                            </div>
                                            <div className="Campaign_Details_Card_Body">
                                                <ul>
                                                    <li>
                                                        <div className="d-flex align-items-center Title_Block">
                                                            <h5>Start Date</h5>
                                                            <img
                                                                src={Error}
                                                                className="img-fluid ml-5"
                                                                alt="Error"
                                                            />
                                                        </div>
                                                        <div className="Content_Block">
                                                            <div className="Date_Picker">
                                                                <input
                                                                    type="date"
                                                                    onKeyDown={(e) =>
                                                                        e.preventDefault()
                                                                    }
                                                                    name="campaign_start_date"
                                                                    id="campaign_start_date"
                                                                    defaultValue={
                                                                        this.props.camp_minDate
                                                                    }
                                                                    min={
                                                                        this.props.camp_minDate
                                                                            ? this.props.camp_minDate
                                                                            : this.props.camp_minDate
                                                                    }
                                                                    onChange={(e) =>
                                                                        this.props.onchangeSetdate(e, val)
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="d-flex align-items-center Title_Block">
                                                            <h5>Expiration Date</h5>
                                                            <img
                                                                src={Error}
                                                                className="img-fluid ml-5"
                                                                alt="Error"
                                                            />
                                                        </div>
                                                        <div className="Content_Block">
                                                            <input
                                                                type="date"
                                                                name="camp_end_date"
                                                                onKeyDown={(e) =>
                                                                    e.preventDefault()
                                                                }
                                                                id="campaign_end_date"
                                                                defaultValue={
                                                                    this.props.camp_maxDate
                                                                }
                                                                max={
                                                                    this.props.camp_maxDate
                                                                        ? this.props.camp_maxDate
                                                                        : ""
                                                                }
                                                                onChange={(e) =>
                                                                    this.props.onchangeSetcampEnddate(
                                                                        e,
                                                                        val
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="d-flex align-items-center Title_Block">
                                                            <h5>Shutoff Date</h5>
                                                            <img
                                                                src={Error}
                                                                className="img-fluid ml-5"
                                                                alt="Error"
                                                            />
                                                        </div>
                                                        <div className="Content_Block">
                                                            <div className="Date_Picker">
                                                                <input
                                                                    type="date"
                                                                    name="shut_off_date"
                                                                    onKeyDown={(e) =>
                                                                        e.preventDefault()
                                                                    }
                                                                    defaultValue={
                                                                        this.props.camp_shutoffDate
                                                                    }
                                                                    max={
                                                                        this.props.camp_maxDate
                                                                            ? this.props.camp_maxDate
                                                                            : ""
                                                                    }
                                                                    onChange={(e) =>
                                                                        this.props.onchangeShutoffdate(
                                                                            e,
                                                                            val
                                                                        )
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="d-flex align-items-center Title_Block">
                                                            <h5>Max Clips</h5>
                                                            <img
                                                                src={Error}
                                                                className="img-fluid ml-5"
                                                                alt="Error"
                                                            />
                                                        </div>
                                                        <div className="Content_Block">
                                                            <input
                                                                type="number"
                                                                defaultValue={1000000}
                                                                name="clip_limit"
                                                                id={`clip_limit-` + val}
                                                                onChange={(e) =>
                                                                    this.props.onchangeCliplimit(e, val)
                                                                }
                                                            />
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                )
                            )}
                        </>
                    ) : this.props.retailer_camp_array ? (
                        <>
                            {Object.keys(this.props.retailer_camp_array).map(
                                (val1, idx1) => {
                                    return (
                                        <div className=" col-12" key={idx1}>
                                            <div className="Campaign_Details_Card">
                                                <div className="Campaign_Details_Card_Header d-flex align-items-center justify-content-between">
                                                    <div className="d-flex align-items-center ">
                                                        <div className="Campaign_Logo">
                                                            <img
                                                                src={
                                                                    this.props.retailerlogo[val1]
                                                                }
                                                                className="img-fluid "
                                                                alt="campaign"
                                                            />
                                                        </div>
                                                        <h5 className="ml-16">{val1}</h5>
                                                    </div>
                                                    <span></span>
                                                </div>
                                                <div className="Campaign_Details_Card_Body">
                                                    <ul>
                                                        <li>
                                                            <div className="d-flex align-items-center Title_Block">
                                                                <h5>Start Date</h5>
                                                                <img
                                                                    src={Error}
                                                                    className="img-fluid ml-5"
                                                                    alt="Error"
                                                                />
                                                            </div>
                                                            <div className="Content_Block">
                                                                <div className="Date_Picker">
                                                                    <input
                                                                        type="date"
                                                                        onKeyDown={(e) =>
                                                                            e.preventDefault()
                                                                        }
                                                                        name="camp_retailer_start_date"
                                                                        id="camp_retailer_start_date"
                                                                        defaultValue={
                                                                            this.props.camp_minDate
                                                                        }
                                                                        min={
                                                                            this.props.camp_minDate
                                                                                ? this.props.camp_minDate
                                                                                : this.props.camp_minDate
                                                                        }
                                                                        onChange={(e) =>
                                                                            this.props.onchangesetRetailerstartdate(
                                                                                e,
                                                                                val1
                                                                            )
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="d-flex align-items-center Title_Block">
                                                                <h5>Expiration Date</h5>
                                                                <img
                                                                    src={Error}
                                                                    className="img-fluid ml-5"
                                                                    alt="Error"
                                                                />
                                                            </div>
                                                            <div className="Content_Block">
                                                                <input
                                                                    type="date"
                                                                    name="camp_retailer_end_date"
                                                                    onKeyDown={(e) =>
                                                                        e.preventDefault()
                                                                    }
                                                                    id="camp_retailer_end_date"
                                                                    defaultValue={
                                                                        this.props.camp_maxDate
                                                                    }
                                                                    max={
                                                                        this.props.camp_maxDate
                                                                            ? this.props.camp_maxDate
                                                                            : ""
                                                                    }
                                                                    onChange={(e) =>
                                                                        this.props.onchangesetRetailerenddate(
                                                                            e,
                                                                            val1
                                                                        )
                                                                    }
                                                                />
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="d-flex align-items-center Title_Block">
                                                                <h5>Shutoff Date</h5>
                                                                <img
                                                                    src={Error}
                                                                    className="img-fluid ml-5"
                                                                    alt="Error"
                                                                />
                                                            </div>
                                                            <div className="Content_Block">
                                                                <div className="Date_Picker">
                                                                    <input
                                                                        type="date"
                                                                        name="camp_retailer_shutoff_date"
                                                                        id="camp_retailer_shutoff_date"
                                                                        onKeyDown={(e) =>
                                                                            e.preventDefault()
                                                                        }
                                                                        defaultValue={
                                                                            this.props.camp_shutoffDate
                                                                        }
                                                                        max={
                                                                            this.props.camp_maxDate
                                                                                ? this.props.camp_maxDate
                                                                                : ""
                                                                        }
                                                                        onChange={(e) =>
                                                                            this.props.onchangesetRetailershuroffdate(
                                                                                e,
                                                                                val1
                                                                            )
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="d-flex align-items-center Title_Block">
                                                                <h5>Max Clips</h5>
                                                                <img
                                                                    src={Error}
                                                                    className="img-fluid ml-5"
                                                                    alt="Error"
                                                                />
                                                            </div>
                                                            <div className="Content_Block">
                                                                <input
                                                                    type="text"
                                                                    defaultValue={1000000}
                                                                    name="retailer_clip_limit"
                                                                    id={
                                                                        "retailer_clip_limit-" + val1
                                                                    }
                                                                    onChange={(e) =>
                                                                        this.props.onchangeretailerCliplimit(
                                                                            e,
                                                                            val1
                                                                        )
                                                                    }
                                                                />
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                }
                            )}
                        </>
                    ) : (
                        ""
                    )}
                </div>
                <div className="row mt-23 mb-20">
                    <div className="col-12 d-flex justify-content-end">
                        <div className="TableButton">
                            <button
                                type="button"
                                className="mr-10 btn btn-primary"
                                onClick={(e) => {
                                    this.props.changePrevTab(e, 3);
                                }}
                            >
                                <i
                                    className="fas fa-arrow-left mr-10"
                                    aria-hidden="true"
                                ></i>{" "}
                                Prev
                            </button>
                            <button
                                type="button"
                                className="btn btn-primary"
                                onClick={(e) => {
                                    this.props.changeFourthTab(e, 5);
                                }}
                            >
                                Next{" "}
                                <i
                                    className="fas fa-arrow-right ml-10"
                                    aria-hidden="true"
                                ></i>{" "}
                            </button>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default CampaignDetails