import React from "react";

class ItemWholesaler extends React.Component {

  constructor(props) {
    super(props);
    this.state = { checkedListAll: [] }
  }
  componentDidUpdate(prevProps, nextProps) {
    if (prevProps !== this.props) {
      this.setState({
        checkedListAll: prevProps.checkedListAll,
        wholesalerIdarr:prevProps.wholesalerIdarr
      });
    }
  }
  
  render() {
    const {
      items,
      selectedItems,
    } = this.props;

    return (
      <>
        <li key={items.wholesaler_number}>
          <div className="Retailer_Block">
            <input
              type="checkbox"
              selectedItems={selectedItems}
              value={items.wholesaler_id}
              checked={this.state.checkedListAll.includes(items.wholesaler_id)}
              onChange={this.props.handleCheckboxClick}
            />
            <div className="RetailerImage">
              <img
                className="img-fluid"
                alt={items.wholesaler_name}
                src={
                  items.logo
                }
              />
            </div>
            <h5>
              {items.wholesaler_name}
            </h5>
          </div>
        </li>
      </>
    );
  }
}
export default ItemWholesaler;