import React from 'react'
import Header from '../../Components/Admin/layout/header'
import * as CONFIG from "../../Components/_config";
import axios from "axios";
import { toast } from "react-toastify";
import ls from "local-storage";
import $ from "jquery";
import { Redirect } from "react-router";

//import AddBrandWholesaler from '../../Components/Common/AddBrandModal/AddBrandWholesaler';
import AdminWholesaler from '../../Components/Common/AddAdminWholesaler/AdminWholesaler';
import { getMasterRoles } from '../../API/Wholesalerapi';




const Loader = () => <div id="cover-spin"></div>;

class AddWholesaler extends React.Component {
    constructor(props) {
        super(props);
        toast.configure();

        this.state = {
            userinfo: "",
            modalclick: false,
            hideButton: false,
            brand_job_title: '',
            role_guid: '',
            wholesaler_number: [],
            retailer_number: [],
            selectedFile: '',
            upc11Arr: [],
            masterRoles: [],
            brand_guid:[],


            formControls: {
            }

        }

        //  this.selectAllCheckboxes = this.selectAllCheckboxes.bind(this);
        this.selectWholesaler = this.selectWholesaler.bind(this);

        // this.selectRetailer = this.selectRetailer.bind(this);
        // this.selectAllRetailerCheckboxes = this.selectAllRetailerCheckboxes.bind(this);

    }


    changeHandler = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        this.setState({
            formControls: {
                ...this.state.formControls,
                [name]: value
            }
        });
    };

    selectWholesaler(e) {

        const { value } = e.target;
        const brand_guid_arr = [e.target.getAttribute("data-brandguid")];
    
        if (e.target.checked) {

            let wholesaler_number_arr = [
                parseInt(value)
            ];

            this.setState({ wholesaler_number: wholesaler_number_arr,brand_guid:brand_guid_arr });
        }

        else {
            let removeIndex = this.state.wholesaler_number.findIndex(
                (itm) => itm === parseInt(value)
            );
            if (removeIndex > -1) {
                this.state.wholesaler_number.splice(removeIndex, 1); // 2nd parameter means remove one item only
            }
            let removebrnadguidIndex = this.state.brand_guid.findIndex(

                (item)=>item === parseInt(value)
            )
            if (removebrnadguidIndex > -1) {
                this.state.brand_guid.splice(removebrnadguidIndex, 1); // 2nd parameter means remove one item only
            }

        }
    }





    showLoader = () => {
        this.setState({ loading: true });
    }
    hideLoader = () => {
        this.setState({ loading: false });
    };

    saveWholesaler() {

        const filterRole = this.state.masterRoles ? this.state.masterRoles.filter(el => el.role_name === 'wholesaler') : '';




        var z = new RegExp(/^\d*\.?\d*$/);

        if ($("#first_name").val().length <= 0) {
            toast.error("First Name can't be blank", { theme: "colored" });
            return false;
        }
        else if ($("#last_name").val().length <= 0) {
            toast.error("Last Name can't be blank", { theme: "colored" });
            return false;
        }
        else if ($("#primary_phone").val().length <= 0) {
            toast.error("Primary Phone can't be blank", { theme: "colored" });
            return false;
        }
        else if ($("#email").val().length <= 0) {
            toast.error("Email can't be blank", { theme: "colored" });
            return false;
        }

        else if (this.state.wholesaler_number <= 0) {
            toast.error("Please select at least one Wholesaler", { theme: "colored" });
            return false;
        }

        else {



            this.showLoader();
            var data =
                JSON.stringify({
                    first_name: this.state.formControls.first_name,
                    last_name: this.state.formControls.last_name,
                    email: this.state.formControls.email,
                    primary_phone: this.state.formControls.primary_phone,
                    secondary_phone: this.state.formControls.secondary_phone,
                   // brand: ["B2A81B2E-2DB2-4A0C-8F61-20BA11D04D6F"],
                    brand:this.state.brand_guid,
                
                    role: filterRole[0].brandiq_user_role_guid,
                    brand_job_title: filterRole[0].role_name,
                    wholesalers: this.state.wholesaler_number,
                    retailers: [], //this.state.selectedFile

                });

            var config = {
                method: "post",
                url: CONFIG.API_URL + "user",
                headers: CONFIG.config_head.headers,
                data: data
            };
            axios(config)
                .then((response) => {

                    if (response.status === 201) {
                        this.hideLoader();
                        toast.success('Wholesaler is added successfully')
                        setTimeout(() => { window.location.reload() }, 2500)
                    }
                })
                .catch((error) => {
                    if (error.response) {


                        if (error.response.status === 502) {
                            this.hideLoader();
                            toast.error(error.response.data.message);
                            setTimeout(() => { window.location.reload() }, 2500)
                        }
                        else if (error.response.status === 400) {

                            this.hideLoader();
                            toast.error(error.response.data.Message)
                            setTimeout(() => { window.location.reload() }, 2500)
                        }
                    }
                })


        }

    }

    componentDidMount() {

        const token_config = {
            headers: CONFIG.config_head.headers
        };

        const requestOne = axios.get(
            CONFIG.API_URL + `master?type=wholesaler&search=''`,
            token_config
        );
        axios
            .all([requestOne])
            .then(
                axios.spread((...responses) => {
                    let responseOne = responses[0];

                    this.setState({

                        masterWholesaler: responseOne.data.Message,

                    });
                })
            )
            .catch(function (error) {
                if (error.response) {
                    if (error.response.status === 400) {

                        toast.error(error.response.data.Message);
                    } else if (error.response.status === 403) {
                        toast.error(error.response.data.Message);
                        ls.clear("token");
                        ls.clear("brands");

                        window.location.href = "/login";
                    } else {
                        toast.error(error.response.data.Message);
                    }
                }
            });




        const search = async () => {
            const { data } = await getMasterRoles()

            this.setState({ masterRoles: data.Message });
        }
        search();


    }
    render() {

        const isLogged = ls.get("token");
        const is_consent_provided = ls.get("decodetoken") ? ls.get("decodetoken").is_consent_provided : "";
        if (!isLogged || is_consent_provided === false) {
            return <Redirect to="/login" />;
        }
        const filterWholesaler = this.state.masterWholesaler ? this.state.masterWholesaler.filter(el => this.state.wholesaler_number.includes(el.wholesaler_id)) : ''


        return (
            <>
                <Header location={`addUser`} />

                {this.state.loading ? <Loader /> : null}


                <div className='ContentBlock  pt-40 pb-24 Main_Block'>
                    <div className='container'>

                        <div className='Add_User_Form_Block row g-3 '>
                            <h5 style={{ float: 'right' }}>



                            </h5>
                            <div className="col-lg-6 col-12">
                                <label
                                    htmlFor="inputEmail4"
                                    className="form-label"
                                >
                                    First Name
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="First Name"
                                    name="first_name"
                                    id="first_name"
                                    onChange={this.changeHandler}
                                />

                            </div>
                            <div className="col-lg-6 col-12">
                                <label
                                    htmlFor="inputEmail4"
                                    className="form-label "
                                >
                                    Last Name
                                </label>
                                <input
                                    type="text"
                                    className="form-control profile-input-control"
                                    placeholder="Last Name"
                                    name="last_name"
                                    id="last_name"
                                    onChange={this.changeHandler}

                                />
                            </div>
                            <div className="col-lg-6 col-12">
                                <label
                                    htmlFor="inputEmail4"
                                    className="form-label "
                                >
                                    Email
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Email"
                                    name="email"
                                    id="email"
                                    onChange={this.changeHandler}
                                />

                            </div>
                            <div className="col-lg-6 col-12">
                                <label
                                    htmlFor="inputEmail4"
                                    className="form-label "
                                >
                                    Primary Phone
                                </label>
                                <input
                                    type="text"
                                    className="form-control "
                                    placeholder="Primary Phone"
                                    name="primary_phone"
                                    id="primary_phone"
                                    onChange={this.changeHandler}

                                />

                            </div>

                            <div className="col-lg-6 col-12">
                                <label
                                    htmlFor="inputEmail4"
                                    className="form-label "
                                >
                                    Secondary Phone
                                </label>
                                <input
                                    type="text"
                                    className="form-control "
                                    placeholder="Secondary Phone"
                                    name="secondary_phone"
                                    id="secondary_phone"
                                    onChange={this.changeHandler}

                                />

                            </div>

                            <div className="col-lg-6 col-12">
                                <br />

                                <button
                                    className="Digital_Brand_Button approve"
                                    data-bs-toggle="modal"
                                    id="wholesalermodal"
                                    data-bs-target="#selectwholesaler-sel-drop"
                                    style={{
                                        borderColor: "#3d8ecc ",
                                        backgroundColor: "#3d8ecc",
                                        color: "#fff",
                                        fontWeight: "600",
                                        padding: "12px",
                                        width: '204px'
                                    }}
                                >
                                    Select Wholesalers
                                </button>
                            </div>


                            {filterWholesaler.length > 0 && (
                                <div className="col-lg-10 col-12">
                                    <label
                                        htmlFor="inputEmail4"
                                        className="form-label "
                                    >Selected Wholesaler</label>

                                    <div className="pr-12 pl-12 pt-20">

                                        <ul className="mt-15 d-flex  Retailer">
                                            {filterWholesaler.map((val, idx) => (

                                                <li key={val.wholesaler_number}>
                                                    <div className="Retailer_Block">

                                                        <div className="RetailerImage">
                                                            <img
                                                                className="img-fluid"
                                                                alt={val.wholesaler_name}
                                                                src={
                                                                    val.logo
                                                                }
                                                            />
                                                        </div>
                                                        <h5>
                                                            {val.wholesaler_name}
                                                        </h5>
                                                    </div>
                                                </li>


                                            ))}
                                        </ul>
                                    </div>



                                </div>
                            )
                            }



                            <div className='col-12'>
                                <button type="submit" className="btn Btn_blue w-fit-content px-5 py-2 rounded-2"
                                    data-roleguid={``} data-brandjobtitle={``} onClick={(e) => {
                                        this.saveWholesaler(e);
                                    }}>Save</button>
                            </div>
                        </div>
                    </div>


                    {
                        this.state.masterWholesaler && (
                            <AdminWholesaler
                                selectWholesaler={this.selectWholesaler}
                                checkedListAll={this.state.wholesaler_number}
                                wholesalerData={this.state.masterWholesaler}
                            />
                        )
                    }

                </div>
            </>
        )
    }
}
export default AddWholesaler;
