import $ from "jquery";
import React from "react";
import Header from "../Dashboard/Header";
import ls from "local-storage";
import RV from "simple-react-validator";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Redirect } from "react-router";
import {
  buildCoupon,
  buildCouponRetailer,
  getWholsalerList,
  getRetailerList
} from "../../API/CreateDeal";
import { getBrands } from "../../API/DashBoard";
import CreateCoupon from "./CreateCoupons";
import Builddeal from "./Builddeal";
import SelectTargets from "./SelectTargets";
import CampaignDetails from "./CampaignDetails";
import Preview from "./Preview";
import FooterStrict from "../Dashboard/FooterStrict";
import axios from "axios";
import * as CONFIG from "../../Components/_config";


const Loader = () => <div id="cover-spin"></div>;

class Deal extends React.Component {
  constructor(props) {
    super(props);
    this.validator = new RV();
    this.validator.visibleFields = [];
    toast.configure();
    this.selected = {};

    this.state = {
      step: 0,
      upcList: "",
      upcGuid: "",
      upc_guid: [],
      upc_guid_list: "",
      deal_unit: 1,
      offer_type: "Selected",
      selectValue: "select",
      camp_minDate: "",
      camp_maxDate: "",
      camp_shutoffDate: "",
      wholesaler_start_date: "",
      iso_wholesaler_start_date: "",
      wholesaler_end_date: "",
      wholesaler_shutoff_date: "",
      wholesalerid: [],
      wholesaler_end_date_show: "",
      selectedFile: "",
      global_prev_end_date: "",
      signup: false,
      WholeSalerlist: [],
      wholeSalerLoader: false,
      retailerLoader: false,
      flag: 0,
      camp_wholesalername: [],
      camp_wholesaler_start_date: [],
      camp_wholesaler_end_date: [],
      camp_wholesaler_end_date_show: [],
      camp_iso_wholesaler_start_date: [],
      camp_iso_wholesaler_end_date: [],
      camp_iso_wholesaler_shutoff_date: [],
      camp_wholesaler_logo: [],
      camp_wholesaler_id: [],
      camp_retailer_id: [],
      retailerid: [],
      camp_clip_limit: [],
      camp_clip_limit_val: "",
      camp_reedem_limit: [],
      camp_reedem_limit_val: "",
      camp_retailer_clip_limit: [],
      retailer_clip_limit_val: "",
      camp_retailer_reedem_limit: [],
      retailer_reedem_limit_val: "",
      global_build_start_date: "",
      global_build_end_date: "",
      global_build_shutoff_date: "",
      global_prev_start_date: "",
      checkboxlength: "",
      wholesalername1: [],
      wholesalerlogo: [],
      checkeupc: false,
      retailer: [],
      retailer_array: "",
      retailer_camp_array: [],
      retailer_coupon: [],
      retailer_coupon_array: "",
      camp_retailer_start_date: [],
      retailer_start_date: "",
      coupon_retailer_start_date: [],
      coupon_retailer_iso_start_date: "",
      camp_retailer_end_date: [],
      retailer_end_date: "",
      coupon_retailer_end_date: [],
      coupon_retailer_iso_end_date: "",
      camp_iso_retailer_start_date: [],
      camp_iso_retailer_end_date: [],
      camp_iso_retailer_shutoff_date: [],
      coupon_target_type_id: "",
      retailerlogo: [],
      is_flex: "",
      offercheckbox: 0,
      spinloading: false,
      deal_retailer: [],
      createCouponLoader: true,
      warehouseDetails: {},
      warehousename: {},
      showwarehouse: false,
      title: 'All Brands',
      columns: [
        {
          name: "BRAND",
          selector: (row) => this.state.UpcList.brand_name
        },
        {
          name: "UPC",
          selector: (row) => row.upc11,
          sortable: true
        },
        {
          name: "PRODUCT NAME",
          selector: (row) => row.product_name
        }
      ],
      formControls: {}
    };
    this.selectAllRetailerCheckboxes = this.selectAllRetailerCheckboxes.bind(this);
    this.selectRetailer = this.selectRetailer.bind(this);
  }
  async componentDidMount() {

    const selectedbrand = ls.get("selectedbrand") ? ls.get("selectedbrand") : "";

    $(".wizard li").click(function () {
      $(this).removeClass("completed");
      $(this).prevAll().addClass("completed");
      $(this).nextAll().removeClass("completed");
      $(".wizard li").removeClass("active");
      $(this).addClass("active");
    });
    $(".Offer_Type").on("click", function () {
      $(".Offer_Type").removeClass("Selected");
      $(this).addClass("Selected");
    });
    $(".Search_Box").on("click", function () {
      $(".Search_Box input").css("display", "block");
    });
    $(document).mouseup(function (e) {
      if ($(e.target).closest(".Search_Box input").length === 0) {
        $(".Search_Box input").css("display", "none");
      }
    });

    getBrands(selectedbrand[0].guid).then((response) => {
      this.setState({
        UpcList: response.Message,
        createCouponLoader: false,
        tableData: response.Message.upc
      });
    });
  }

  handleSeconDdate(date) {
    var month = date.getMonth() + 1;
    var day = date.getDate();
    var year = date.getFullYear();
    if (month < 10) month = "0" + month.toString();
    if (day < 10) day = "0" + day.toString();
    let calculate_date = month + "-" + day + "-" + year;
    return calculate_date;
  }

  selectWholesalerCheckbox = (e) => {
    $("#retailercheckbox").prop("checked", false);
    const selectedbrand = ls.get("selectedbrand") ? ls.get("selectedbrand") : "";


    this.setState({
      flag: 1
    });
    if (this.state.WholeSalerlist.length === 0) {
      this.setState(
        {
          wholeSalerLoader: true
        },
        () => {
          if (e.target.checked) {
            getWholsalerList(selectedbrand[0].guid).then((response) => {
              this.setState({
                WholeSalerlist: response.Message,
                wholeSalerLoader: false
              });
            });
          }
        }
      );
    }
  }

  selectRetailerCheckbox = (e) => {
    $("#wholesalercheckbox").prop("checked", false);
    $("#warehouseid").css("display", "none");
    const selectedbrand = ls.get("selectedbrand") ? ls.get("selectedbrand") : "";


    this.setState({
      flag: 2,
      showwarehouse: false,

    });
    if (this.state.retailer_array.length === 0) {
      this.setState(
        {
          retailerLoader: true
        },
        () => {
          if (e.target.checked) {


            getRetailerList(selectedbrand[0].guid).then((response) => {
              this.setState({
                retailer_array: response.Message,
                retailerLoader: false,
                warehouseDetails: {},
              });
            });
          }
        }
      );
    }
  }

  changeHandler = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    this.setState({
      formControls: {
        ...this.state.formControls,
        [name]: value
      }
    });
  };

  showLoader = () => {
    this.setState({ spinloading: true });
  };

  hideLoader = () => {
    this.setState({ spinloading: false });
  };

  // onchangeCheckbox =  async(event,upcguid)=>{
  //   const name = event.target.name;
  //   const value = event.target.value;

  //   if(event.target.checked){

  //   this.setState({checkboxlength:$("input[type='checkbox'][name='upcSeries']:checked").length })

  //   this.state.upc_guid[upcguid]= upcguid;

  //   this.setState({upc_guid_list:this.state.upc_guid});

  //   this.setState ({
  //     upcList:value,
  //   })

  // }
  // else{

  //   this.setState({checkboxlength:$("input[type='checkbox'][name='upcSeries']:checked").length })

  //   delete this.state.upc_guid_list[upcguid];

  // }

  // }

  onchangeFlex = async (e) => {
    if (e.target.checked) {
      this.setState({ is_flex: 1 });
    } else {
      this.setState({ is_flex: 0 });
    }
  };

  onchangeoffercheckbox = async (e) => {
    if (e.target.checked) {
      this.setState({ offercheckbox: 1 });
    } else {
      this.setState({ offercheckbox: 0 });
    }
  };

  onchangeOffertype = async (event, Offer_Type) => {
    this.setState({ deal_unit: Number(Offer_Type) });
  };

  onchangeCliplimit = async (e, key) => {
    this.state.camp_clip_limit[key] = e.target.value;
    this.setState({ camp_clip_limit_val: this.state.camp_clip_limit });
  };

  onchangeretailerCliplimit = async (e, key) => {
    this.state.camp_retailer_clip_limit[key] = e.target.value;
    this.setState({
      retailer_clip_limit_val: this.state.camp_retailer_clip_limit
    });
  };

  onchangeretailerReedemlimit = async (e, key) => {
    this.state.camp_retailer_reedem_limit[key] = e.target.value;
    this.setState({
      retailer_reedem_limit_val: this.state.camp_retailer_reedem_limit
    });
  };

  onchangelReedemlimit = async (e, key) => {
    this.state.camp_reedem_limit[key] = e.target.value;
    this.setState({ camp_reedem_limit_val: this.state.camp_reedem_limit });
  };

  onchangeSetdate = async (e, key) => {
    const value1 = e.target.value.replace(/-/g, "/");
    const iso_camp_start_date = new Date(e.target.value);
    const iso_camp_start_date1 = new Date(value1);
    var month = iso_camp_start_date1.getMonth() + 1;
    var day = iso_camp_start_date1.getDate();
    var year = iso_camp_start_date1.getFullYear();
    if (month < 10) month = "0" + month.toString();
    if (day < 10) day = "0" + day.toString();
    let setstartdate = month + "-" + day + "-" + year;
    this.state.camp_wholesaler_start_date[key] = setstartdate;
    this.setState({
      wholesaler_start_date: this.state.camp_wholesaler_start_date
    });
    this.state.camp_iso_wholesaler_start_date[key] =
      iso_camp_start_date.toISOString();
    this.setState({
      iso_wholesaler_start_date: this.state.camp_iso_wholesaler_start_date
    });
  };

  onchangeSetcampEnddate = async (e, key) => {
    const value1 = e.target.value.replace(/-/g, "/");
    const iso_camp_end_date = new Date(e.target.value);
    const iso_camp_end_date1 = new Date(value1);
    var month = iso_camp_end_date1.getMonth() + 1;
    var day = iso_camp_end_date1.getDate();
    var year = iso_camp_end_date1.getFullYear();
    if (month < 10) month = "0" + month.toString();
    if (day < 10) day = "0" + day.toString();
    let setdate = month + "-" + day + "-" + year;
    this.state.camp_wholesaler_end_date[key] = setdate;
    this.setState({
      wholesaler_end_date_show: this.state.camp_wholesaler_end_date
    });
    this.state.camp_iso_wholesaler_end_date[key] =
      iso_camp_end_date.toISOString();
    this.setState({
      wholesaler_end_date: this.state.camp_iso_wholesaler_end_date
    });
  };

  onchangeShutoffdate = async (e, key) => {
    const iso_camp_shutoff_date = new Date(e.target.value);
    this.state.camp_iso_wholesaler_shutoff_date[key] =
      iso_camp_shutoff_date.toISOString();
    this.setState({
      wholesaler_shutoff_date: this.state.camp_iso_wholesaler_shutoff_date
    });
  };

  setStartDate = async (e) => {
    const value = e.target.value;
    const value1 = e.target.value.replace(/-/g, "/");
    const global_start_date = new Date(value);
    const global_start_date1 = new Date(value1);
    let prev_start_date = this.handleSeconDdate(global_start_date1);
    var maxDate = global_start_date.toISOString().split("T")[0];
    let build_start_date = global_start_date.toISOString();
    $("input[type='date'][name='camp_retailer_start_date']").attr(
      "min",
      maxDate
    );
    $("input[type='date'][name='camp_retailer_end_date']").attr("min", maxDate);
    $("input[type='date'][name='camp_retailer_shutoff_date']").attr(
      "min",
      maxDate
    );
    $("input[type='date'][name='camp_retailer_start_date']").val(maxDate);
    this.setState({
      global_build_start_date: build_start_date,
      global_prev_start_date: prev_start_date,
      camp_minDate: maxDate
    });
    if (Object.keys(this.state.retailer).length > 0) {
      Object.keys(this.state.retailer).map(
        (val, index) =>
          (this.state.camp_retailer_start_date[val] = prev_start_date)
      );
      this.setState({
        retailer_start_date: this.state.camp_retailer_start_date
      });
    }
    if (Object.keys(this.state.camp_wholesalername).length > 0) {
      Object.keys(this.state.camp_wholesalername).map(
        (val, index) =>
          (this.state.camp_wholesaler_start_date[val] = prev_start_date)
      );
      this.setState({
        wholesaler_start_date: this.state.camp_wholesaler_start_date
      });
    }
  };

  setEndDate = async (e) => {
    const value = e.target.value;
    const value1 = e.target.value.replace(/-/g, "/");
    const global_end_date = new Date(value);
    const global_end_date1 = new Date(value1);

    let prev_end_date = this.handleSeconDdate(global_end_date1);
    var maxDate = global_end_date.toISOString().split("T")[0];
    let build_end_date = global_end_date.toISOString();
    $("#shutoff_date").val(value);
    $("#shutoff_date").attr("max", "YYYY-MM-DD");
    $("input[type='date'][name='shutoff_date']").attr("max", maxDate);
    $("input[type='date'][name='camp_retailer_start_date']").attr(
      "max",
      maxDate
    );
    $("input[type='date'][name='camp_retailer_end_date']").attr("max", maxDate);
    $("input[type='date'][name='camp_retailer_shutoff_date']").attr(
      "max",
      maxDate
    );
    $("input[type='date'][name='camp_retailer_end_date']").val(maxDate);
    this.setState({
      global_build_end_date: build_end_date,
      global_prev_end_date: prev_end_date,
      camp_maxDate: maxDate
    });
    if (Object.keys(this.state.retailer).length > 0) {
      Object.keys(this.state.retailer).map(
        (val, index) => (this.state.camp_retailer_end_date[val] = prev_end_date)
      );
      this.setState({ retailer_end_date: this.state.camp_retailer_end_date });
    }
    if (Object.keys(this.state.camp_wholesalername).length > 0) {
      Object.keys(this.state.camp_wholesalername).map(
        (val, index) =>
          (this.state.camp_wholesaler_end_date[val] = prev_end_date)
      );
      this.setState({
        wholesaler_end_date: this.state.camp_wholesaler_end_date
      });
    }
  };

  setShutoffDate = async (e) => {
    const value = e.target.value;
    const value1 = e.target.value.replace(/-/g, "/");

    const global_shutoff_date = new Date(value);
    const global_shutoff_date1 = new Date(value1);

    var month = global_shutoff_date1.getMonth() + 1;
    var day = global_shutoff_date1.getDate();

    if (month < 10) month = "0" + month.toString();
    if (day < 10) day = "0" + day.toString();

    var maxDate = global_shutoff_date.toISOString().split("T")[0];

    let build_shutoff_date = global_shutoff_date.toISOString();

    $("input[type='date'][name='camp_retailer_shutoff_date']").val(maxDate);

    this.setState({
      global_build_shutoff_date: build_shutoff_date,
      camp_shutoffDate: maxDate
    });
  };

  onFileChange = (event) => {
    let files = event.target.files;
    let images = event.target.files[0];
    if (!images.name.match(/\.(jpg|jpeg|png|PNG|JPEG|JPG)$/)) {
      toast.error("Please Select a Valid Image!");
      return false;
    }

    for (var i = 0; i < files.length; i++) {
      let file = files[i];
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.setState({ selectedFile: reader.result });
      };
    }
  };

  onchangesetRetailerstartdate = async (e, key) => {
    const value1 = e.target.value.replace(/-/g, "/");
    let setstartdate = this.handleSeconDdate(new Date(value1));
    this.state.camp_retailer_start_date[key] = setstartdate;

    this.setState({
      retailer_start_date: this.state.camp_retailer_start_date
    });

    this.state.camp_iso_retailer_start_date[key] = new Date(
      e.target.value
    ).toISOString();

    this.setState({
      iso_retailer_start_date: this.state.camp_iso_retailer_start_date
    });
  };

  onchangesetRetailerenddate = async (e, key) => {
    const value1 = e.target.value.replace(/-/g, "/");
    let setenddate = this.handleSeconDdate(new Date(value1));
    this.state.camp_retailer_end_date[key] = setenddate;
    this.setState({ retailer_end_date: this.state.camp_retailer_end_date });
    this.state.camp_iso_retailer_end_date[key] = new Date(
      e.target.value
    ).toISOString();
    this.setState({
      iso_retailer_end_date: this.state.camp_iso_retailer_end_date
    });
  };

  onchangesetRetailershuroffdate = async (e, key) => {
    //const iso_camp_retailer_start_date = new Date(e.target.value);
    const retailer_camp_shutoff_date = new Date(e.target.value);

    this.state.camp_iso_retailer_shutoff_date[key] =
      retailer_camp_shutoff_date.toISOString();

    this.setState({
      iso_retailer_shutoff_date: this.state.camp_iso_retailer_shutoff_date
    });
  };

  selectWholesaler = async (e) => {


    //master/wholesalerGUID?type=warehouse&search
    var wholesaler_guid = e.target.value;

    var wholesalername1 = [];
    wholesalername1.push({ 'wholesalername': e.target.options[e.target.selectedIndex].dataset.wholesalername, 'wholesalerlogo': e.target.options[e.target.selectedIndex].dataset.logo })


    this.state.wholesalername1[e.target.options[e.target.selectedIndex].dataset.wholesalername] = e.target.options[e.target.selectedIndex].dataset.wholesalername;
    this.state.wholesalerlogo[e.target.options[e.target.selectedIndex].dataset.wholesalername] = e.target.options[e.target.selectedIndex].dataset.logo;

    const token_config = {
      headers: CONFIG.config_head.headers
    };

    const requestOne = axios.get(
      CONFIG.API_URL + `master/${wholesaler_guid}?type=warehouse&search=''`,
      token_config
    );
    axios
      .all([requestOne])
      .then(
        axios.spread((...responses) => {
          let responseOne = responses[0];
          $("#warehouseid").show();


          var options = responseOne.data.Message.map(item => {
            return {
              label: item.warehouse_name,
              value: item.warehouse_id,
              wholesaler_id: item.wholesaler_id
            }
          })


          this.setState({
            warehouseDetails: responseOne.data.Message,
            camp_wholesalername: wholesalername1,
            camp_wholesaler_logo: this.state.wholesalerlogo,
            options: options,
            showwarehouse: true,

          });

        })
      )
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 400) {
            toast.error(error.response.data.Message);

            this.setState({
              warehouseDetails: {},
              showwarehouse: false,
              camp_wholesalername: {}

            })


          } else if (error.response.status === 403) {
            toast.error(error.response.data.Message);
            ls.clear("token");
            ls.clear("brands");

            window.location.href = "/login";
          } else {
            toast.error(error.response.data.Message);
          }
        }
      });
  };

  selectAllRetailerCheckboxes(e) {

    const { checked } = e.target;

    //const allWholesalername = [];

    var obj = [];

    if (checked) {

      // eslint-disable-next-line array-callback-return
      this.state.retailer_array.map((val) => {

        obj.push({
          retailer_name: val.retailer_name, deal_retailer_id: val.retailer_id, deal_wholesaler_id: val.retailer_id,

          retailerlogo: val.logo, camp_retailer_id: val.retailer_id,
          camp_wholesaler_id: val.wholesaler_id,
        })
      });
    }
    this.setState({
      retailer_camp_array: obj,
      coupon_target_type_id: 2
    });
  };


  handleWarehouse = (selectedOption) => {


    const flavors = [];
    const warehousename_arr = [];

    for (let i = 0; i < selectedOption.length; i++) {

      warehousename_arr.push({ 'warehouse_name': selectedOption[i].label })

      flavors.push({ 'wholesaler_id': parseInt(selectedOption[i].wholesaler_id), 'warehouse_id': parseInt(selectedOption[i].value) });
    }

    this.setState({ warehouse: flavors, retailers: [], warehousename: warehousename_arr })


  }

  // handleSelectwarehouse= async (e) => {

  //   console.log('wholesaler',e.target.selectedOptions)

  //   var selectedItems = e.target.selectedOptions;
  //   var wholesalerid = e.target.options[e.target.selectedIndex].dataset.wholesalerid;


  //   const flavors = [];
  //   const warehousename_arr = [];

  //   for (let i=0; i<selectedItems.length; i++) {

  //       warehousename_arr.push({'warehouse_name':selectedItems[i].text})
  //       flavors.push({'wholesaler_id': parseInt(wholesalerid),'warehouse_id':parseInt(selectedItems[i].value)});  
  //   }
  //   this.setState({warehouse: flavors,retailers:[], warehousename:warehousename_arr})

  //   }

  selectRetailer = async (e, retailer_name, retailer_id, wholesaler_id, logo) => {
    var obj = {};

    if (e.target.checked) {

      this.state.coupon_target_type_id = 2;

      obj['retailer_name'] = retailer_name;
      obj['deal_retailer_id'] = retailer_id;
      obj['deal_wholesaler_id'] = wholesaler_id;

      obj['retailerlogo'] = logo;
      obj['camp_retailer_id'] = retailer_id;

      obj['camp_wholesaler_id'] = wholesaler_id;

      this.state.retailer_camp_array.push(obj);

      // this.setState({
      //   retailer_coupon: newArray,
      //   retailer_camp_array: this.state.retailer,
      //   camp_wholesalername: {},
      //   wholesalerid: this.state.camp_wholesaler_id,
      //   retailerid: this.state.camp_retailer_id,
      //   retailerlogo: this.state.retailerlogo
      // });
    }

    else {
      let removeIndex = this.state.retailer_camp_array.findIndex(
        (itm) => itm.retailer_id === parseInt(retailer_id)
      );

      if (removeIndex > -1) {
        this.state.retailer_camp_array.splice(removeIndex, 1); // 2nd parameter means remove one item only
      }

      // const index = this.state.retailer_coupon.indexOf(e.target.value);
      // if (index > -1) {
      //   this.state.retailer_coupon.splice(index, 1); // 2nd parameter means remove one item only
      // }
      // delete this.state.retailer[retailer_name];
      // this.setState({ retailer_camp_array: this.state.retailer });
    }

  };

  checkReedem = async (e, key) => {
    if ($("#clip_limit-" + key).val() < $("#redeem_limit" + key).val()) {
      alert("shold be less");
    } else {
      this.setState({ signup: true });
    }
  };

  checkRetailerreedem = async (e, key) => {
    if (
      $("#retailer_clip_limit-" + key).val() >
      $("#retailer_redeem_limit-" + key).val()
    ) {
      $("#error").html("Clips should be less than Reedem");
      $(this).css("border", "solid 2px red");
    } else {
      $("#error").html("");
      this.setState({ signup: true });
    }
  };

  changeTab = async (e, key) => {
    if (this.state.checkboxlength <= 0) {
      toast.error("Please select at least one product to continue", {
        theme: "colored"
      });
      return false;
    } else {
      $("#nav-step-" + key + "-tab").trigger("click");
      $("#nav-step-" + key + "-tab").attr('aria-selected', false);

    }
  };

  changeSecondTab = async (e, key) => {

    var z = new RegExp(/^\d*\.?\d*$/);
    if (!z.test($("#reward_amount").val()) || ($("#reward_amount").val().trim().length <= 0)) {
      toast.error("Please Fill Deal Value Offer, accept only numeric values", { theme: "colored" });
      return false;
    }
    else if ($("#title").val().length <= 0) {
      toast.error("Please Fill Title Field", { theme: "colored" });
      return false;
    }
    else if ($("#description").val().trim().length <= 0) {
      toast.error("Please Fill  Description field", { theme: "colored" });
      return false;
    }
    else if ($("#min_purchase_amount").val().trim().length <= 0 || !z.test($("#min_purchase_amount").val())) {
      toast.error("Please Fill Minimum Purchase Offer, accept only numeric values", { theme: "colored" });
      return false;
    }
    else if ($("#min_purchase_amount").val() > 10) {

      toast.error("Minimum Purchase Quantity should not have value greater than 10,", { theme: "colored" });
      return false;

    }
    // else if ($("#file-upload").val().trim().length <= 0) {
    //   toast.error("Please Upload Coupon Image", { theme: "colored" });
    //   return false;
    // }
    else if (
      $("input[type='date'][name='start_date']").val().length > 0 &&
      $("input[type='date'][name='end_date']").val().length > 0 &&
      $("input[type='date'][name='shutoff_date']").val().length > 0
    ) {

      $("#nav-step-" + key + "-tab").trigger("click");
      $("#nav-step-" + key + "-tab").attr('aria-selected', false);

    }

    else if (
      new Date($("input[type='date'][name='start_date']").val()).getTime() <
      new Date(
        Date.UTC(
          new Date().getUTCFullYear(),
          new Date().getUTCMonth(),
          new Date().getUTCDate()
        )
      ).getTime()
    ) {

      $("#nav-step-" + key + "-tab").trigger("click");
      $("#nav-step-" + key + "-tab").attr('aria-selected', false);

    }
    else {

      toast.error("Please Select Date", { theme: "colored" });
      return false;
    }
  };

  changeThirdTab = async (e, key) => {
    this.setState({ signup: true });
    $("#nav-step-" + key + "-tab").trigger("click");
    $("#nav-step-" + key + "-tab").attr('aria-selected', false);

    //}
  };

  changeFourthTab = async (e, key) => {
    $("#nav-step-" + key + "-tab").trigger("click");
    // $("#nav-step-" + key + "-tab").attr('aria-selected',false);

  };

  changePrevTab = async (e, key) => {
    $("#nav-step-" + key + "-tab").trigger("click");
  };

  buildCoupon = async (e) => {
    e.preventDefault();
    if (this.state.signup === false) {
      toast.error("Please Provide all Informations !");
    } else {
      if (this.state.offercheckbox === 1) {

        const selectedbrand = ls.get("selectedbrand") ? ls.get("selectedbrand") : "";

        var Wholesaler_arr = [];

        Object.keys(this.state.camp_wholesalername).map((val, idx) =>
          Wholesaler_arr.push({
            wholesaler_id: this.state.camp_wholesaler_id[val],
            start_date: this.state.iso_wholesaler_start_date[val],
            end_date: this.state.wholesaler_end_date[val],
            shutoff_date: this.state.wholesaler_shutoff_date[val],
            clip_limit: this.state.camp_clip_limit_val[val]
              ? parseInt(this.state.camp_clip_limit_val[val])
              : 1000000,
            redeem_limit: 0,
            retailer: []
          })
        );
        var upc_guid_arr = [];
        Object.keys(this.state.upc_guid_list).map((val, idx) =>
          upc_guid_arr.push(val)
        );

        const resolveAfter3Sec = new Promise((resolve) =>
          setTimeout(resolve, 100)
        );
        toast.promise(resolveAfter3Sec, {
          pending: "Processing 👌",
          success: "Processing 👌",
          error: "Processing 🤯"
        });

        //let guid = ls.get("brands") ? ls.get("brands")[0].guid : "";


        let data = JSON.stringify({

          title: this.state.formControls.title,
          description: this.state.formControls.description,
          start_date: this.state.global_build_start_date,
          end_date: this.state.global_build_end_date,
          order_before_date: this.state.global_build_shutoff_date,
          deal_limit: 0,
          deal_unit: this.state.deal_unit,
          deal_type_id: 1,
          deal_code: this.state.formControls.deal_code ? this.state.formControls.deal_code : '',
          min_quantity: 1,
          deal_amount: this.state.formControls.reward_amount ? parseFloat(this.state.formControls.reward_amount) : 1,

          is_deal_amount_percentage: 0,
          limit_per_retailer: 0,

          addtional_details: this.state.formControls.additional_details ? this.state.formControls.additional_details : "",
          disclaimer: this.state.formControls.disclaimer ? this.state.formControls.disclaimer : "",
          image_1: this.state.selectedFile,
          image_1_thumbnail: "string",
          media_url: "string",
          brand_guid: selectedbrand[0].guid,
          deal_target_type_id: 1,
          warehouses: this.state.warehouse,
          retailers: this.state.retailers,
          upc: this.state.upc_guid_list
        });


        buildCoupon(data).then((response) => {
          if (response.status === 200) {
            setTimeout(() => window.location.href = "/confirmdealmessage", 2500);
          }
          // else if (response.status === 400) {
          //   setTimeout(() => toast.error(response.data.Message), 3000);

          // }
          // else {
          //   setTimeout(() => toast.error(response.data.Message), 3000);

          // }

        });

      }
      else {
        toast.error("Please confirm the deal");
      }
    }
  };

  buildCouponRetailer = async (e) => {
    e.preventDefault();
    if (this.state.signup === false) {
      toast.error("Please Provide all Informations !");
    } else {
      if (this.state.offercheckbox === 1) {

        //var Wholesaler_arr = [];
        var deal_retailer_build_arr_ = [];

        this.state.retailer_camp_array.map((val, idx) =>
          deal_retailer_build_arr_.push({
            retailer_id: val.camp_retailer_id,
            start_date: this.state.global_build_start_date,
            end_date: this.state.global_build_end_date,
            wholesaler_id: val.camp_wholesaler_id,
            order_by_date: this.state.global_build_shutoff_date,
            deal_limit: 0,
            order_limit: 0
          })
        );


        var upc_guid_arr = [];
        Object.keys(this.state.upc_guid_list).map((val, idx) =>
          upc_guid_arr.push(val)
        );


        const resolveAfter3Sec = new Promise((resolve) =>
          setTimeout(resolve, 100)
        );
        toast.promise(resolveAfter3Sec, {
          pending: "Processing 👌",
          success: "Processing 👌",
          error: "Processing 🤯"
        });

        const selectedbrand = ls.get("selectedbrand") ? ls.get("selectedbrand") : "";

        var data = JSON.stringify({

          title: this.state.formControls.title,
          description: this.state.formControls.description,
          start_date: this.state.global_build_start_date,
          end_date: this.state.global_build_end_date,
          order_before_date: this.state.global_build_shutoff_date,
          deal_limit: 0,
          deal_code: this.state.formControls.deal_code ? this.state.formControls.deal_code : '',

          deal_unit: this.state.deal_unit,
          deal_type_id: 1,
          min_quantity: 1,
          deal_amount: this.state.formControls.reward_amount
            ? parseFloat(this.state.formControls.reward_amount)
            : 1,

          is_deal_amount_percentage: 0,
          limit_per_retailer: 0,

          addtional_details: this.state.formControls.additional_details
            ? this.state.formControls.additional_details
            : "",
          disclaimer: this.state.formControls.disclaimer
            ? this.state.formControls.disclaimer
            : "",
          image_1: this.state.selectedFile,
          image_1_thumbnail: "string",
          media_url: "string",
          brand_guid: selectedbrand[0].guid,
          deal_target_type_id: 2,
          warehouses: [],
          retailers: deal_retailer_build_arr_,

          upc: this.state.upc_guid_list
        });

        // console.log(data);return;

        buildCouponRetailer(data).then((response) => {


          if (response.status === 200) {
            setTimeout(() => window.location.href = "/confirmdealmessage", 3000);
          }
          // else if (response.status === 400) {
          //   setTimeout(() => toast.error(response.data.Message), 3000);

          // }
          // else {
          //   setTimeout(() => toast.error(response.data.Message), 3000);

          // }

        });
      } else {
        toast.error("Please confirm the deal");
      }
    }
  };

  getUniqueListBy(arr) {
    return [...new Map(arr.map((item) => [item, item])).values()];
  }

  handlerowChange = ({ selectedRows, e }) => {
    this.setState({
      checkboxlength: Object.keys(selectedRows).length
    });
    selectedRows.map(
      // eslint-disable-next-line react/no-direct-mutation-state
      (val, idx) => (this.state.upc_guid[val.upc_guid] = val.upc_guid)
    );
    var upc_guid = [];
    for (const obj of selectedRows) {
      upc_guid.push(obj.upc_guid);
    }
    this.setState({ upc_guid_list: upc_guid });
  };

  render() {
    const isLogged = ls.get("token");
    if (!isLogged) {
      return <Redirect to="/login" />;
    }

    return (
      <>
        <Header title={this.state.title} location={`deal`} />

        {this.state.spinloading ? <Loader /> : null}

        <section className="ContentBlock  pt-24 pb-24 Main_Block">
          <div className="Tab_Header">
            <div className="container-fluid">
              <div className="row ">
                <div className="col-12">
                  <ul className="nav nav-tabs wizard pb-70">
                    <li className="active">
                      <button
                        className="nav-link "
                        id="nav-step-1-tab"
                        style={{ background: "linear-gradient(105.16deg, #2a80c6 39.34%, #1fb3cd 83.14%) !important" }}
                        data-bs-toggle="tab"
                        data-bs-target="#nav-step-1"
                        type="button"
                        role="tab"
                        aria-controls="step-1"
                        aria-selected="true"
                        onClick={() => this.setState({ step: 0 })}
                      >
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M21 2.992V21.008C20.9979 21.2706 20.8926 21.5219 20.7068 21.7075C20.521 21.8931 20.2696 21.9982 20.007 22H3.993C3.72981 22 3.47739 21.8955 3.2912 21.7095C3.105 21.5235 3.00027 21.2712 3 21.008V2.992C3.00209 2.72938 3.10742 2.47813 3.29322 2.29251C3.47902 2.1069 3.73038 2.00183 3.993 2H20.007C20.555 2 21 2.444 21 2.992ZM19 4H5V20H19V4ZM11.293 13.121L15.536 8.879L16.95 10.293L11.293 15.95L7.403 12.06L8.818 10.646L11.293 13.121Z"
                            fill="current"
                          />
                        </svg>
                        Select Product
                      </button>
                    </li>
                    <li>
                      <button
                        className="nav-link"
                        id="nav-step-2-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#nav-step-2"
                        type="button"
                        role="tab"
                        aria-controls="nav-step-2"
                        aria-selected="false"

                        style={{ display: 'none' }}></button>

                      <button
                        className="nav-link"
                        // id="nav-step-2-tab"
                        // data-bs-toggle="tab"
                        // data-bs-target="#nav-step-2"
                        type="button"
                        role="tab"
                        aria-controls="nav-step-2"
                        aria-selected="false"
                        onClick={(e) => this.changeTab(e, 2)}
                      >
                        <svg
                          width="19"
                          height="18"
                          viewBox="0 0 19 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M13.625 15.75C12.9288 15.75 12.2611 15.4734 11.7688 14.9812C11.2766 14.4889 11 13.8212 11 13.125C11 12.4288 11.2766 11.7611 11.7688 11.2688C12.2611 10.7766 12.9288 10.5 13.625 10.5C14.3212 10.5 14.9889 10.7766 15.4812 11.2688C15.9734 11.7611 16.25 12.4288 16.25 13.125C16.25 13.8212 15.9734 14.4889 15.4812 14.9812C14.9889 15.4734 14.3212 15.75 13.625 15.75ZM13.625 14.25C13.9234 14.25 14.2095 14.1315 14.4205 13.9205C14.6315 13.7095 14.75 13.4234 14.75 13.125C14.75 12.8266 14.6315 12.5405 14.4205 12.3295C14.2095 12.1185 13.9234 12 13.625 12C13.3266 12 13.0405 12.1185 12.8295 12.3295C12.6185 12.5405 12.5 12.8266 12.5 13.125C12.5 13.4234 12.6185 13.7095 12.8295 13.9205C13.0405 14.1315 13.3266 14.25 13.625 14.25ZM5.375 7.5C5.03028 7.5 4.68894 7.4321 4.37046 7.30018C4.05198 7.16827 3.7626 6.97491 3.51884 6.73116C3.27509 6.4874 3.08173 6.19802 2.94982 5.87954C2.8179 5.56106 2.75 5.21972 2.75 4.875C2.75 4.53028 2.8179 4.18894 2.94982 3.87046C3.08173 3.55198 3.27509 3.2626 3.51884 3.01884C3.7626 2.77509 4.05198 2.58173 4.37046 2.44982C4.68894 2.3179 5.03028 2.25 5.375 2.25C6.07119 2.25 6.73887 2.52656 7.23116 3.01884C7.72344 3.51113 8 4.17881 8 4.875C8 5.57119 7.72344 6.23887 7.23116 6.73116C6.73887 7.22344 6.07119 7.5 5.375 7.5ZM5.375 6C5.67337 6 5.95952 5.88147 6.1705 5.6705C6.38147 5.45952 6.5 5.17337 6.5 4.875C6.5 4.57663 6.38147 4.29048 6.1705 4.0795C5.95952 3.86853 5.67337 3.75 5.375 3.75C5.07663 3.75 4.79048 3.86853 4.5795 4.0795C4.36853 4.29048 4.25 4.57663 4.25 4.875C4.25 5.17337 4.36853 5.45952 4.5795 5.6705C4.79048 5.88147 5.07663 6 5.375 6ZM14.8032 2.63625L15.8638 3.69675L4.1975 15.3638L3.137 14.3032L14.8025 2.63625H14.8032Z"
                            fill="current"
                          />
                        </svg>
                        Build Deal
                      </button>
                    </li>
                    <li>

                      <button
                        className="nav-link"
                        id="nav-step-3-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#nav-step-3"

                        type="button"
                        role="tab"
                        aria-controls="nav-step-3"
                        aria-selected="false"

                        style={{ display: 'none' }}></button>

                      <button
                        className="nav-link"
                        // id="nav-step-3-tab"
                        // data-bs-toggle="tab"
                        // data-bs-target="#nav-step-3"
                        type="button"
                        role="tab"
                        aria-controls="nav-step-3"
                        aria-selected="false"
                        onClick={(e) => this.changeSecondTab(e, 3)}
                      >
                        <svg
                          width="25"
                          height="24"
                          viewBox="0 0 25 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M2.54983 13H4.56183C4.80586 14.9331 5.74691 16.7107 7.2084 17.9993C8.6699 19.2879 10.5514 19.9989 12.4998 19.9989C14.4483 19.9989 16.3298 19.2879 17.7913 17.9993C19.2527 16.7107 20.1938 14.9331 20.4378 13H22.4508C21.9488 18.053 17.6848 22 12.4998 22C7.31483 22 3.05083 18.053 2.54983 13ZM2.54983 11C3.04983 5.947 7.31383 2 12.4998 2C17.6858 2 21.9488 5.947 22.4498 11H20.4378C20.1938 9.0669 19.2527 7.28927 17.7913 6.00068C16.3298 4.71208 14.4483 4.00108 12.4998 4.00108C10.5514 4.00108 8.6699 4.71208 7.2084 6.00068C5.74691 7.28927 4.80586 9.0669 4.56183 11H2.54883H2.54983ZM12.4998 14C11.9694 14 11.4607 13.7893 11.0856 13.4142C10.7105 13.0391 10.4998 12.5304 10.4998 12C10.4998 11.4696 10.7105 10.9609 11.0856 10.5858C11.4607 10.2107 11.9694 10 12.4998 10C13.0303 10 13.539 10.2107 13.914 10.5858C14.2891 10.9609 14.4998 11.4696 14.4998 12C14.4998 12.5304 14.2891 13.0391 13.914 13.4142C13.539 13.7893 13.0303 14 12.4998 14Z"
                            fill="current"
                          />
                        </svg>
                        Select Targets
                      </button>
                    </li>

                    <li>
                      <button
                        className="nav-link"
                        id="nav-step-5-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#nav-step-5"
                        type="button"
                        role="tab"
                        aria-controls="nav-step-5"
                        aria-selected="false"

                        style={{ display: 'none' }}></button>

                      <button
                        className="nav-link"
                        // id="nav-step-5-tab"
                        // data-bs-toggle="tab"
                        // data-bs-target="#nav-step-5"
                        type="button"
                        role="tab"
                        aria-controls="nav-step-5"
                        aria-selected="false"
                        onClick={(e) => this.changeFourthTab(e, 5)}
                      >
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22ZM12 20C14.1217 20 16.1566 19.1571 17.6569 17.6569C19.1571 16.1566 20 14.1217 20 12C20 9.87827 19.1571 7.84344 17.6569 6.34315C16.1566 4.84285 14.1217 4 12 4C9.87827 4 7.84344 4.84285 6.34315 6.34315C4.84285 7.84344 4 9.87827 4 12C4 14.1217 4.84285 16.1566 6.34315 17.6569C7.84344 19.1571 9.87827 20 12 20ZM11.003 16L6.76 11.757L8.174 10.343L11.003 13.172L16.659 7.515L18.074 8.929L11.003 16Z"
                            fill="current"
                          />
                        </svg>
                        Preview
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <form method="post" className="Offer_Form">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div
                    className="tab-content Wizard_Content mb-30"
                    id="nav-tabContent"
                  >
                    <div
                      className="tab-pane fade show active"
                      id="nav-step-1"
                      role="tabpanel"
                      aria-labelledby="nav-step-1-tab"
                    >
                      <CreateCoupon
                        UpcList={this.state.UpcList}
                        tableData={this.state.tableData}
                        camp_wholesalername={this.state.camp_wholesalername}
                        createCouponLoader={this.state.createCouponLoader}
                        reward_amount={this.state.formControls.reward_amount}
                        products={this.state.checkboxlength}
                        start_date={this.state.global_prev_start_date}
                        end_date={this.state.global_prev_end_date}
                        wholesalerlogo={this.state.wholesalerlogo}
                        handlerowChange={this.handlerowChange}
                        changeStep={this.changeTab}
                      />
                    </div>
                    <div
                      className="tab-pane fade"
                      id="nav-step-2"
                      role="tabpanel"
                      aria-labelledby="nav-step-2-tab"
                    >
                      <Builddeal
                        offer_type={this.state.offer_type}
                        checkboxlength={this.state.checkboxlength}
                        reward_amount={this.state.formControls.reward_amount}
                        selectedFile={this.state.selectedFile}
                        title={this.state.formControls.title}
                        description={this.state.formControls.description}
                        onchangeOffertype={this.onchangeOffertype}
                        onchangeFlex={this.onchangeFlex}
                        changeHandler={this.changeHandler}
                        changePrevTab={this.changePrevTab}
                        changeSecondTab={this.changeSecondTab}
                        setStartDate={this.setStartDate}
                        setEndDate={this.setEndDate}
                        setShutoffDate={this.setShutoffDate}
                        onFileChange={this.onFileChange}
                      />
                    </div>
                    <div
                      className="tab-pane fade"
                      id="nav-step-3"
                      role="tabpanel"
                      aria-labelledby="nav-step-3-tab"
                    >
                      <SelectTargets
                        wholeSalerLoader={this.state.wholeSalerLoader}
                        WholeSalerlist={this.state.WholeSalerlist}
                        flag={this.state.flag}
                        retailerLoader={this.state.retailerLoader}
                        retailer_array={this.state.retailer_array}
                        selectWholesalerCheckbox={this.selectWholesalerCheckbox}
                        selectRetailerCheckbox={this.selectRetailerCheckbox}
                        changePrevTab={this.changePrevTab}
                        changeThirdTab={this.changeThirdTab}
                        selectWholesaler={this.selectWholesaler}
                        selectRetailer={this.selectRetailer}
                        warehouseDetails={this.state.warehouseDetails}
                        handleSelectwarehouse={this.handleSelectwarehouse}
                        options={this.state.options}
                        handleWarehouse={this.handleWarehouse}
                        showwarehouse={this.state.showwarehouse}
                        selectAllRetailerCheckboxes={this.selectAllRetailerCheckboxes}
                        checkedListAll={this.state.retailer_camp_array}

                      />
                    </div>
                    <div
                      className="tab-pane fade"
                      id="nav-step-4"
                      role="tabpanel"
                      aria-labelledby="nav-step-4-tab"
                    >
                      <CampaignDetails
                        camp_wholesalername={this.state.camp_wholesalername}
                        camp_wholesaler_logo={this.state.camp_wholesaler_logo}
                        retailer_camp_array={this.state.retailer_camp_array}
                        retailerlogo={this.state.retailerlogo}
                        camp_minDate={this.state.camp_minDate}
                        camp_maxDate={this.state.camp_maxDate}
                        camp_shutoffDate={this.state.camp_shutoffDate}
                        onchangeSetdate={this.onchangeSetdate}
                        onchangeSetcampEnddate={this.onchangeSetcampEnddate}
                        onchangesetRetailerstartdate={this.onchangesetRetailerstartdate}
                        onchangesetRetailerenddate={this.onchangesetRetailerenddate}
                        onchangesetRetailershuroffdate={this.onchangesetRetailershuroffdate}
                        onchangeretailerCliplimit={this.onchangeretailerCliplimit}
                        changePrevTab={this.changePrevTab}
                        changeFourthTab={this.changeFourthTab}
                        onchangeShutoffdate={this.onchangeShutoffdate}
                        onchangeCliplimit={this.onchangeCliplimit}
                      />
                    </div>
                    <div
                      className="tab-pane fade"
                      id="nav-step-5"
                      role="tabpanel"
                      aria-labelledby="nav-step-5-tab"
                    >
                      <Preview
                        selectedFile={this.state.selectedFile}
                        title={this.state.formControls.title}
                        description={this.state.formControls.description}
                        camp_wholesalername={this.state.camp_wholesalername}
                        wholesaler_start_date={this.state.wholesaler_start_date}
                        wholesaler_end_date_show={this.state.wholesaler_end_date_show}
                        retailer_start_date={this.state.retailer_start_date}
                        retailer_end_date={this.state.retailer_end_date}
                        camp_wholesaler_logo={this.state.camp_wholesaler_logo}
                        retailer_camp_array={this.state.retailer_camp_array}
                        global_prev_start_date={this.state.global_prev_start_date}
                        global_prev_end_date={this.state.global_prev_end_date}
                        onchangeoffercheckbox={this.onchangeoffercheckbox}
                        buildCoupon={this.buildCoupon}
                        buildCouponRetailer={this.buildCouponRetailer}
                        retailerlogo={this.state.retailerlogo}
                        warehousename={this.state.warehousename}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </section>
        <FooterStrict />
      </>
    );
  }
}
export default Deal;
