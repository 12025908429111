import React, { useEffect, useState } from "react";
import { Form, Row, Col, Button, Spinner } from "react-bootstrap";
import Select from "react-select";
import Papa from "papaparse";
import CSVFileValidator from "csv-file-validator";
import NextArrow from "../../../Assets/images/next-arrow.svg";
import DownloadArrow from "../../../Assets/images/download-animation3.gif";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import XLSX from "xlsx";

function Step1({
  companyData,
  handleStep,
  info,
  handleInput,
  companyOptions,
  setInfo,
  setFormError,
  formError
}) {
  const [data, setData] = useState([]);
  const [invalidData, setInvalidData] = useState([]);
  const updatedData = [];
  let requiredError = (headerName, rowNumber, columnNumber) => {
    return `${headerName} is required in the ${rowNumber} row ${columnNumber} column`;
  };
  let config = {
    headers: [
      {
        name: "Offer Space",
        inputName: "Offer Space",
        required: true,
        requiredError
      },
      {
        name: "Offer Title - WILL be on coupon (Include Amount Off, Purchase Quantity, Brand, Item, Size)",
        inputName: "Offer Title - WILL be on coupon (Include Amount Off, Purchase Quantity, Brand, Item, Size)",
        required: true,
        requiredError
      },
      {
        name: "Offer Description - WILL be on coupon (Include Amount Off, Purchase Quantity, Brand, Item, Size)",
        inputName: "Offer Description",
        required: true,
        requiredError
      },
      {
        name: "UPC - ONE per cell (No check digit, no dash, no space, no special characters or formating)",
        inputName: "UPC",
        required: true,
        //requiredError,
        validate: function (UPC) {
          let filteredZero = UPC?.split(",").map((each) => parseInt(each));
          const filteredUPC =
            UPC?.toString()?.length >= 4
              ? filteredZero
                  ?.toString()
                  ?.split(",")
                  .filter((each, i) => {
                    if (each?.length >= 4) {
                      return each;
                    }
                  })
              : UPC?.toString()?.length >= 4;

          let filterData =
            filteredUPC?.length === UPC?.toString()?.split(",")?.length &&
            UPC !== "0" &&
            filteredUPC;
          return filterData;
        },
        validateError: function (headerName, rowNumber, columnNumber) {
          return `UPC length should be minimum 4 digits in the  ${rowNumber} row ${columnNumber} column`;
        }
      },
      {
        name: "Product Category",
        inputName: "Product Category",
        required: true,
        requiredError
      },
      {
        name: "Offer Brand",
        inputName: "Offer Brand",
        required: true,
        requiredError
      },
      {
        name: "Offer Value (Amount Only)",
        inputName: "OfferValue",
        required: true,
        requiredError
      },
      {
        name: "Offer Clip Start Date (M/D/Y)",
        inputName: "Offer Clip Start Date (M/D/Y)"
        // required: true,
        // requiredError
      },
      {
        name: "Offer Clip End Date (M/D/Y)",
        inputName: "Offer Clip End Date (M/D/Y)"
        // required: true,
        // requiredError
      },
      {
        name: "Offer Expiration Date (M/D/Y)",
        inputName: "Offer Expiration Date (M/D/Y)"
        // required: true,
        // requiredError
      },
      {
        name: "Miniumum Qty Required",
        inputName: "MiniumumQtyRequired",
        required: true,
        validate: function (MiniumumQtyRequired) {
          return !isNaN(parseInt(+MiniumumQtyRequired));
        },
        validateError: function (headerName, rowNumber, columnNumber) {
          return ` Please enter numbers only in the  ${rowNumber} row ${columnNumber} column   `;
        }
        //requiredErro
      },
      // {
      //   name: "Offer Type (Amount Off, BOGO, Free, Multiple such as: Buy x AND y save $)",
      //   inputName: "Offer Type",
      //   required: true,
      //   requiredError
      // },
      {
        name: "Offer Max Clip Count (Min 5,000) NO MAX if in print ad",
        inputName: "OfferMaxClip",
        required: true,
        validate: function (OfferMaxClip) {
          return !isNaN(parseInt(+OfferMaxClip));
        },
        validateError: function (headerName, rowNumber, columnNumber) {
          return ` Please enter numbers only in the  ${rowNumber} row ${columnNumber} column   `;
        }

        //requiredError
      },
      // {
      //   name: "Max Clip/Person (# of times one shopper can clip coupon)",
      //   inputName: "MaxClipPerson",
      //   required: true,
      //   validate: function (MaxClipPerson) {
      //     return !isNaN(parseInt(+MaxClipPerson));
      //   },
      //   validateError: function (headerName, rowNumber, columnNumber) {
      //     return ` Please enter numbers only in the  ${rowNumber} row ${columnNumber} column   `;
      //   }
      //   //requiredError
      // },
      {
        name: "# of Redemptions per Transaction",
        inputName: "# of Redemptions per Transaction",
        required: true,
        requiredError
      },
      {
        name: "Image Name",
       
        inputName: "ImageName",
        // required: true,
        validate: function (ImageName) {
          let url = ImageName.toString().includes("https://");
          return url ? ImageName : null; 
        },
        validateError: function (headerName, rowNumber, columnNumber) {
          return ` Please enter valid  ${headerName} url in ${rowNumber} row   `;
        }
        //requiredError
      },
      {
        name: "addtional_details",
        inputName: "addtional_details",
        required: true,
        requiredError
      },
      {
        name: "disclaimer",
        inputName: "disclaimer",
        required: true,
        requiredError
      }
    ]
  };
  const handleStepNext = () => {
    // if (info?.companyName === "" || info?.couponData?.length === 0) {
    //   if (info?.companyName === "") {
    //     setFormError({
    //       ...formError,
    //       companyError: "Please Select Company Name"
    //     });
    //   } else if (info?.couponData?.length === 0) {
    //     setFormError({
    //       ...formError,
    //       csvFileError: "Please Choose A CSV File"
    //     });
    //   } else {
    //     setFormError({
    //       ...formError,
    //       companyError: "",
    //       csvFileError: ""
    //     });
    //   }
    // } else {
    //   handleStep("next");
    // }
    if (info?.couponData?.length === 0) {
        setFormError({
          ...formError,
          csvFileError: "Please Choose A CSV File"
        });
      } else {
      setFormError({
        ...formError,
        companyError: "",
        csvFileError: ""
      });
      handleStep("next");
    }
  };
  useEffect(() => {}, [companyData]);

  const updatedData1 = [];
  const filteredErrors = [];
  const filterMessage = [];
  const [error, setErrors] = useState();

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file.name.includes(".xlsx") || file.name.includes(".xls")) {
      const reader = new FileReader();
      reader.onload = (event) => {
        // Parse data
        const bstr = event.target.result;
        const wb = XLSX.read(bstr, { type: "binary" });

        // Convert to CSV
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const csv = XLSX.utils.sheet_to_csv(ws);

        // Write file
        const csvFile = new File([csv], `${file.name}.csv`, {
          type: "text/csv"
        });
        console.log("Converted CSV", csvFile);
        handleChange(csvFile);
      };
      reader.readAsBinaryString(file);
    } else {
      handleChange(file);
    }
  };

  const handleChange = (e) => {
    CSVFileValidator(e, config)
      .then((csvData) => {
        console.log(csvData);
        setInvalidData(csvData.inValidData);
        if (csvData?.data) {
          var datesData = csvData?.data?.map((each, i) => {
            return {
              startDate: new Date(each["Offer Clip Start Date (M/D/Y)"]),
              endDate: new Date(each["Offer Clip End Date (M/D/Y)"])
            };
          });
        }
        if (datesData) {
          datesData?.map((each, i) => {
            if (each.startDate > each.endDate) {
              var errMsg = {
                columnIndex: "",
                message: `End Date Must be Greate than Start Date IN CSV file Row Number${
                  i + 2
                }`,
                rowIndex: ""
              };
            }
            if (errMsg !== undefined) {
              filteredErrors.push(errMsg);
            }
          });
        }
        const filteredData = csvData?.data?.map((each) => {
          if (
            !Object.values(each).every(
              (each1) => each1 === null || each1 === ""
            )
          ) {
            updatedData.push(each);
          }
        });

        let filterUPC = updatedData?.map((each, i) => {
          if (each.UPC) {
            const upc = [...new Set(each.UPC.split(","))];
            const filteredZero = upc?.map((each) => {
              return parseInt(each);
            });
            let updatedUpcs =
              filteredZero?.length > 0 &&
              filteredZero?.filter((each) => each !== 0);
            updatedData[i].UPC = updatedUpcs?.toString();
          }
        });
        let removed$ = updatedData?.map((each, i) => {
          if (each.OfferValue) {
            let filter$ = each.OfferValue.replace("$", "");
            return (updatedData[i].OfferValue = filter$);
          }
        });

        setData(updatedData);
        setInfo({
          ...info,
          couponData: updatedData
        });
        const filteredArrayOfInvalidData = csvData.data.map(
          (each) =>
            !Object.values(each).every(
              (each1) => each1 === null || each1 === ""
            )
        );
        const finaldata = filteredArrayOfInvalidData.filter((each, i) => {
          if (each === true) {
            filteredErrors.push(
              ...csvData.inValidData.filter((each1) => each1.rowIndex === i + 2)
            );
            setErrors(filteredErrors);
          }
        });
        if (filteredErrors?.length > 0) {
          toast.error("Please reupload excel");
        }
      })
      .catch((err) => {});
  };

  return (
    <React.Fragment>
      <div className="Wizard_Content_Card Rule_Type_Info">
        <Form>
          <Row>
            {/* <Col xs={12}>
              <Form.Group style={{ display: "flex", flexDirection: "row" }}>
                <Col lg={2} md={2} sm={3} xs={3}>
                  <Form.Label className="mt-8 form-heading">
                    Company Name
                  </Form.Label>
                </Col>
                <Col lg={9} md={8} sm={7} xs={7} className="ml-80">
                  <Select
                    name="companyName"
                    value={info.companyName}
                    options={companyOptions}
                    placeholder={"Please select Company Name"}
                    className="react-select-container select"
                    noOptionsMessage={() => (
                      <Spinner animation="border" variant="primary" />
                    )}
                    onChange={(e) => {
                      handleInput(e, "companyName");
                      if (e.target !== "") {
                        setFormError({
                          companyError: ""
                        });
                      }
                    }}
                  />
                  {formError.companyError && (
                    <p className="error-message">{formError.companyError}</p>
                  )}
                </Col>
              </Form.Group>
            </Col> */}
            <Col xs={12}>
              <Form.Group style={{ display: "flex", flexDirection: "row" }}>
                <Col lg={2} md={2} sm={3} xs={3}>
                  <Form.Label className="mt-48 form-heading">
                    Upload Coupons
                  </Form.Label>
                </Col>
                <Col lg={9} md={8} sm={7} xs={7} className="ml-80 mt-40 pr-15">
                  <Form.Control
                    type="file"
                    size="lg"
                    accept=".csv, .xlsx,.xls"
                    style={{ color: "grey" }}
                    onClick={(e) => (e.target.value = null)}
                    onChange={(e) => {
                      //handleChange(e);
                      handleFileUpload(e);
                      if (e.target.files[0] !== "") {
                        setFormError({
                          ...formError,
                          csvFileError: ""
                        });
                      }
                    }}
                  />
                  {formError.csvFileError && (
                    <p className="error-message">{formError.csvFileError}</p>
                  )}
                </Col>
              </Form.Group>

              <br />
              {error &&
                error?.slice(0, 3)?.map((each, i) => (
                  <Col style={{ marginLeft: "240px" }}>
                    <p className="error-message">{each.message}</p>
                  </Col>
                ))}
            </Col>
            <Col lg={5} md={3} sm={3} xs={3} style={{ position: "absolute", marginTop: "130px" }}>
              <a
                href="https://aogmedia.s3.amazonaws.com/MultiCouponSample.csv"
                style={{ color: "#3D8ECC" }}
              >
                Download Sample File
                <img
                  src={DownloadArrow}
                  style={{ height: "20px", width: "20px", marginLeft: "10px" }}
                />
              </a>
            </Col>
          </Row>
        </Form>
        <div className="flex align-items-center justify-content-end text-end pr-15">
          <Col lg={12}>
          <Button
            className="BlueButton ml-10 mt-40"
            style={{ backgroundColor: "#3D8ECC" }}
            onClick={() => {
              handleStepNext();
            }}
            disabled={error?.length > 0}
          >
            Next
            <img src={NextArrow} alt="Next Arrow" className="img-fluid ml-5" />
          </Button>
          </Col>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Step1;
