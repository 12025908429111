 
const MySubscription = ({ brandWholesaler, brandRetailer, overviewdetails, AllWholesaler, AllRetailer, getStoredetails, Stores, 
     }) => {

 
return (

    <div className="profile-box">
    <h1 className="account-head">My Subscriptions</h1>
    {
      Object.keys(brandWholesaler).length > 0 &&

      <div className="Card ">
        <div className="Card_Header  Steps_Header">
          <h5>Wholesalers</h5>
        </div>
        <div className="Card_Body p-3">
          {brandWholesaler ? (
            <>
              <ul className=" d-flex  Retailer">
                {Object.keys(brandWholesaler).map(
                  (val, idx) => {
                    return (
                      //console.log(AllWholesaler?AllWholesaler[brandWholesaler[val].WholesalerNumber][0].wholesaler_name:'')
                      <li key={idx}>
                        <div className="Retailer_Block">
                          <div className="RetailerImage">
                            <img
                              className="img-fluid"
                              alt="Retailer"
                              src={
                                AllWholesaler
                                  ? AllWholesaler[
                                    
                                      brandWholesaler[val]
                                      .WholesalerNumber
                                  ][0].logo
                                  : ""
                              }
                            />
                          </div>
                          <h5>
                            {AllWholesaler
                              ? AllWholesaler[
                                brandWholesaler[
                                  val
                                ].WholesalerNumber
                              ][0].wholesaler_name
                              : ""}{" "}
                          </h5>
                        </div>
                      </li>
                    )
                  }
                )}
              </ul>
            </>
          ) : (
            "NO Wholesaler Available"
          )
          }
        </div>
      </div>
    }
    {Object.keys(brandRetailer).length > 0 && <div className="Card mt-20">
      <div className="Card_Header Steps_Header">
        <h5>Retailers</h5>
      </div>
      <div className="Card_Body p-3">
        {Object.keys(brandRetailer).length > 0 ? (
          <>
            <ul className=" mt-15 d-flex  Retailer">
              {Object.keys(brandRetailer).map(
                (val, idx) => {
                  return (
                    // console.log(AllRetailer?AllRetailer[parseInt(brandRetailer[val])][0].retailer_name:'')

                    <li key={idx}>
                      <div className="Retailer_Block">
                        <div className="RetailerImage">
                          <img
                            className="img-fluid"
                            alt="Retailer"
                            src={
                              AllRetailer
                                ? AllRetailer[
                                  parseInt(
                                    
                                      brandRetailer[val]
                                  )
                                ][0].logo
                                : ""
                            }
                          />
                        </div>
                        <h5>
                          {AllRetailer
                            ? AllRetailer[
                              parseInt(
                                brandRetailer[
                                val
                                ]
                              )
                            ][0].retailer_name
                            : ""}{" "}
                        </h5>
                      </div>
                    </li>
                  );
                }
              )}
            </ul>
          </>
        ) : (
          "No Retailers Available"
        )}
      </div>
    </div>}
    <div className="Card mt-20 Overview  Subscription_Stores">
      <div className="Card_Header  Steps_Header">
        <h5>Stores</h5>
      </div>
      <div className="Card_Body p-3">
        <ul className="OverviewList row">

          <li className="newOverview w-auto mb-0 col-md-6">
            <div className="Icon">
              <img
                src={Stores}
                className="img-fluid"
                alt="Retailer"
              />
            </div>
            <div className="OverviewList_Info">
              <p>Stores</p>
              <span className="d-block">{overviewdetails ? overviewdetails.Stores : ''}</span>
              <small className="d-block">Selling UPCs</small>

            </div>
          </li>

          <li className="newOverview w-auto mb-0">
            <div className="Icon">
              <img
                src={Stores}
                className="img-fluid"
                alt="Retailer"
              />
            </div>
            <div className="OverviewList_Info">
              <p>Stores</p>
              <span className="d-block">{getStoredetails ? getStoredetails.SubscribedStores : ''}</span>
              <small className="d-block">Subscribed </small>

            </div>


          </li>

        </ul>
      </div>
    </div>
  </div>
)

}
export default MySubscription;