import React from "react";
import RV from "simple-react-validator";
import ls from "local-storage";
import axios from "axios";
import * as CONFIG from "../../Components/_config";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


class ChangePassword extends React.Component {

    constructor(props) {
        super(props);

        toast.configure();
        this.validator = new RV();
        this.validator.visibleFields = [];

        this.state = {

            formControls: {},

        };

    }

    updatePassword = async (e) => {
        e.preventDefault();

        if (!this.validator.allValid()) {
            //toast.error("Please fill all the fields.")
            this.validator.showMessages();

            this.forceUpdate();
            return false;
        } else {

            var user_guid = this.props.user_guid;

            var pattern = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])([!@#\$%\^&\*]?)(?=.{8,})/);

            if (!pattern.test(this.state.formControls.new_password)) {
                toast.error('New Password should be 8 characters long, contain a number, a capital letter and a special character')
            }
            else {

                this.props.showLoader();
                var data = JSON.stringify({
                    old_password: this.state.formControls.old_password.trim(),
                    new_password: this.state.formControls.new_password.trim()
                });

                var config = {
                    method: "post",
                    url: CONFIG.API_URL + `user/password-reset/${user_guid}`,
                    headers: CONFIG.config_head.headers,
                    data: data
                };

                axios(config)
                    .then((response) => {
                        if (response.status === 204) {
                            this.props.hideLoader();
                            toast.success("Password Sent Successfully");
                            window.location.reload();
                        }
                    })
                    .catch((error) => {
                        if (error.response) {
                            if (error.response.status === 502) {
                                this.props.hideLoader();
                                toast.error("Bad Request!");
                            } else if (error.response.status === 400) {
                                this.props.hideLoader();
                                toast.error(error.response.data.Message);
                            } else if (error.response.status === 403) {
                                this.props.hideLoader();

                                toast.error(error.response.data.Message);
                                ls.clear("token");
                                ls.clear("brands");
                                window.location.href = "/login";
                            } else {
                                this.props.hideLoader();
                                toast.error(error.response.data.Message);
                            }
                        }
                    });
            }
        }
    };

    changeHandler = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        this.setState({
            formControls: {
                ...this.state.formControls,
                [name]: value
            }
        });
    };


    render() {
        return (

            <div
                className="tab-pane fade"
                id="v-pills-settings"
                role="tabpanel"
                aria-labelledby="v-pills-settings-tab"
            >
                <div className="profile-box">
                    <h1 className="account-head">Change Password</h1>
                    <form className="row gx-4 gy-3" method="post">
                        <div className="col-md-12">
                            <label
                                htmlFor="inputEmail4"
                                className="form-label ml-10 main-form-profile"
                            >
                                Current Password
                            </label>
                            <input
                                type="password"
                                className="form-control profile-input-control"
                                placeholder="Enter Old Password"
                                name="old_password"
                                onChange={this.changeHandler}
                                value={this.state.formControls.old_password}

                            />
                            <samp className="text-danger">
                                {this.validator.message(
                                    "Old Passoword",
                                    this.state.formControls.old_password,
                                    `required`
                                )}
                            </samp>
                        </div>
                        <div className="col-md-12">
                            <label
                                htmlFor="inputEmail4"
                                className="form-label ml-10 main-form-profile"
                            >
                                New Password
                            </label>
                            <input
                                type="password"
                                className="form-control profile-input-control"
                                placeholder="Enter New Password"
                                name="new_password"
                                onChange={this.changeHandler}
                                value={this.state.formControls.new_password}
                            />
                            <samp className="text-danger">
                                {this.validator.message(
                                    "New Passoword",
                                    this.state.formControls.new_password,
                                    `required`
                                )}

                            </samp>
                        </div>
                        <div className="col-md-12">
                            <label
                                htmlFor="inputEmail4"
                                className="form-label ml-10 main-form-profile"
                            >
                                Confirm Password
                            </label>
                            <input
                                type="password"
                                className="form-control profile-input-control"
                                placeholder="Enter  New Password Again"
                                name="confirm_password"
                                onChange={this.changeHandler}
                                value={this.state.formControls.confirm_password}
                            />
                            <samp className="text-danger">
                                {this.validator.message(
                                    "Confirm Passoword",
                                    this.state.formControls.confirm_password,
                                    `required`
                                )}
                            </samp>
                        </div>

                        <div className="col-12 mt-25">
                            <button
                                type="submit"
                                className="btn btn-primary profile-update"
                                onClick={(e) => {
                                    this.updatePassword(e);
                                }}
                            >
                                Update Password
                            </button>
                        </div>
                    </form>
                </div>
            </div>

        )
    }
}

export default ChangePassword;