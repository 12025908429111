import React from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//import * as CONFIG from "../_config";
const Loader = () => <div id="cover-spin"></div>;

class AsofRetailerModal extends React.Component {

  constructor(props) {
    super(props);
    toast.configure();

    this.state = {
      asOfRetailers: this.props.asofDatadetails,

    };
  }

  render() {

     
    return (
      <>
        {this.state.loading ? <Loader /> : null}

        <div
          className="modal fade"
          id="asofretailer-sel-drop"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-custom modal-dialog-scrollable">
            <div className="modal-content whole-content">
              <div className="modal-header borderemove brand-whole-hd">
                <h5 className="modal-title" id="exampleModalLabel">
                  Retailers
                </h5>
                <button
                  type="button"
                  className="btn-close wholeseler-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>

              <div className="modal-body CustomScroll whole-seler-body">
                <div className="tab-content" id="nav-tabContent">
                  <div
                    className="tab-pane fade show active tab-text"
                    id="nav-home"
                    role="tabpanel"
                    aria-labelledby="nav-home-tab"
                  >
                    <div className="Common_brand_detail">
                      <div className="pr-12 pl-12 pt-20">

                        <div className="profile-box">
                          <div className="Card ">
                            <div className="Card_Header  Steps_Header">
                              <h5>Retailers Selling List</h5>
                            </div>
                            <div className="Card_Body p-3">
                              <ul className=" d-flex  Retailer">
                                {
                               
                                Object.keys(this.state.asOfRetailers.slice(0, Object.keys(this.state.asOfRetailers).length -1)).map(
                                  (val, idx) =>
                               {
                                 return(

                                Object.keys(this.state.asOfRetailers[val].RetailersSelling).map((key,index)=>{
                                return(
                                <li key={index} style={{ width: '24.1%' }}>
                                    <button type="button" className="btn btn-outline-success" style={{ pointerEvents: 'none', width: '100%', color: '#000' }}  >
                                      {this.state.asOfRetailers[val].RetailersSelling[key].RetailerName}

                                    </button>
                                    </li>
                                )
                               }
                                )
                                 )
                              }

                                )
                              }
                              </ul>

                            </div>
                          </div>


                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
             </div>
          </div>
        </div>
      </>
    );
  }
}

export default AsofRetailerModal;
