import React, { useEffect, useState } from "react";
import Header from "../../Components/Wholesaler/layout/header";

import WhHeader from "../../Components/Admin/layout/header";

import { getUser } from "../../API/Profile"
import ls from "local-storage";
import AccountDetails from './AccountDetails';
import ChangePassword from './ChangePassword';
import ClipLoader from "react-spinners/ClipLoader";
import { useParams } from "react-router-dom";
import MySubscriptions from "./MySubscriptions";
import { getBrand, getBrandWholesaler, updateWholesaleruser } from "../../API/Wholesalerapi";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const WholesalerProfile = () => {

  const { user_guid } = useParams();
  const [users, SetUser] = useState('');
  const [brandwholesalers, SetBrandwholesalers] = useState([]);

  const [accountLoader, setAccountLoader] = useState(false);
  const [userguid, setUserguid] = useState('');
  const [loading, setLoading] = useState(false);


  const brnad_guid = ls.get('brands') ? ls.get('brands')[0].guid : '';
  const brands = ls.get('brands') ? ls.get('brands')  : '';

  useEffect(() => {
    getUser(user_guid).then(res => {
      SetUser(res.Message);
      //setAccountLoader(!accountLoader);
    })
  }, [user_guid])// eslint-disable-line




  useEffect(() => {
    getBrandWholesaler(brnad_guid).then((response) => {
      SetBrandwholesalers(response.data.Message)

    });
  }, [])// eslint-disable-line

  useEffect(() => {
    getBrand(brnad_guid).then((response) => {
      //SetSubscribeStore(response.Message)
    });
  }, [])// eslint-disable-line




  const showLoader = () => {
    setLoading(true);
  }
  const hideLoader = () => {
    setLoading(false);
  };

  const handleChange = (event) => {

    SetUser({ ...users, [event.target.name]: event.target.value });

  };


  console.log('brands',brands)
  //   e.preventDefault();

  //   var user_guid = this.props.match.params.user_guid;
  //   //var b_guid = ls.get("brands") ? ls.get("brands")[0].guid : "";
  //   const b_guid = ls.get("selectedbrand") ? ls.get("selectedbrand")[0].guid : "";

  //   var role_guid = ls.get("brands") ? ls.get("brands")[0].role_guid : "";

  //   if ($("#primary_phone").val().trim().length > 10) {
  //     toast.error("Phone No must be with a maximum length of 10", {
  //       theme: "colored"
  //     });
  //     return false;
  //   } else {

  //     var data = JSON.stringify({
  //       first_name: this.state.formControls.first_name
  //         ? this.state.formControls.first_name
  //         : this.state.userinfo.first_name,
  //       last_name: this.state.formControls.last_name
  //         ? this.state.formControls.last_name
  //         : this.state.userinfo.last_name,
  //       primary_phone: this.state.formControls.primary_phone
  //         ? this.state.formControls.primary_phone
  //         : this.state.userinfo.primary_phone,
  //       email: this.state.formControls.email
  //         ? this.state.formControls.email
  //         : this.state.userinfo.email,
  //       secondary_phone: "",
  //       role: role_guid,
  //       brand_job_title: this.state.userinfo.brand_job_title,
  //       state: this.state.userinfo.user_status
  //     });

  //     var config = {
  //       method: "put",
  //       url: CONFIG.API_URL + `user/${user_guid}/${b_guid}`,
  //       headers: CONFIG.config_head.headers,
  //       data: data
  //     };


  //     axios(config)
  //       .then((response) => {
  //         // console.log(response)
  //         if (response.status === 201) {
  //           this.hideLoader();
  //           toast.success("Updated Successfully");
  //         }
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //       });
  //   }
  // };



  const updateUser = (event, data) => {

    event.preventDefault();
    var role_guid = ls.get("brands") ? ls.get("brands")[0].role_guid : "";
    var brand_guid = ls.get("brands") ? ls.get("brands")[0].guid : "";

    //console.log('data', users.primary_phone.length); return;

    if (users.primary_phone.length > 10) {
      toast.error("Phone No must be with a maximum length of 10", { theme: "colored" });
      return false;
    }
    else {

      var wholesalerdata = JSON.stringify({
        first_name: users.first_name,
        last_name: users.last_name,
        primary_phone: users.primary_phone,
        email: users.email,
        secondary_phone: "",
        role: role_guid,
        brand_job_title: users.brand_job_title,
        state: users.user_status
      });

      this.showLoader();
      updateWholesaleruser(wholesalerdata, user_guid, brand_guid).then((response) => {
        //SetSubscribeStore(response.Message)

        if (response.status === 201) {
          this.hideLoader();
          toast.success('Profile updated Successfully');
          setTimeout(() => { window.location.reload() }, 2400);
        }
        else {

          this.hideLoader();
          toast.error(response.data.Message);
          setTimeout(() => { window.location.reload() }, 2400);
        }
      });


    }
  }

  const Loader = () => <div id="cover-spin"></div>;

  return (
    <>
    {
    brands[0].scopes.includes('all')?
    
    <WhHeader location={`adminProfile`} />
    :
      <Header location={`companyProfile`} />
    }
      {loading ? <Loader /> : null}

      <section className="ContentBlock pt-24 pb-24 Main_Block">
        <div className="container-fluid ">
          <div className="row justify-content-center ">
            <div className="col-xxl-2 col-lg-3 col-12">
              <div
                className="nav flex-column nav-pills"
                id="v-pills-tab"
                role="tablist"
                aria-orientation="vertical"
              >
                <button
                  className="nav-link profile-tab active"
                  id="v-pills-home-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#v-pills-home"
                  type="button"
                  role="tab"
                  aria-controls="v-pills-home"
                  aria-selected="true"
                >
                  Account Details
                </button>
                <button
                  className="nav-link profile-tab"
                  id="v-pills-settings-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#v-pills-settings"
                  type="button"
                  role="tab"
                  aria-controls="v-pills-settings"
                  aria-selected="false"
                >
                  Change Password
                </button>
                <button
                  className="nav-link profile-tab"
                  id="v-pills-susbscription-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#v-pills-susbscription"
                  type="button"
                  role="tab"
                  aria-controls="v-pills-susbscription"
                  aria-selected="false"
                >
                  My Subscriptions
                </button>


              </div>
            </div>


            <div className="col-xxl-7 col-lg-9 col-12">
              <div className="tab-content mb-30" id="v-pills-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="v-pills-home"
                  role="tabpanel"
                  aria-labelledby="v-pills-home-tab"
                >
                  {
                    users ? (
                      <AccountDetails
                        ClipLoader={ClipLoader}
                        accountLoader={accountLoader} userinfo={users}
                        updateUser={updateUser} handleChange={handleChange} />
                    ) :
                      <div className="Clip_Loader" style={{ minHeight: "280px" }}>
                        <ClipLoader
                          color={"rgb(0, 143, 251)"}
                          loading={true}
                          size={50}
                        />
                      </div>
                  }
                </div>

                <div
                  className="tab-pane fade"
                  id="v-pills-susbscription"
                  role="tabpanel"
                  aria-labelledby="v-pills-susbscription-tab"
                >
                  {brandwholesalers && (

                    <MySubscriptions brandwholesalers={brandwholesalers}
                    />)
                  }
                </div>
                {
                  <ChangePassword user_guid={user_guid} showLoader={showLoader} hideLoader={hideLoader}/>
                }
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default WholesalerProfile;