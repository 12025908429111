import React from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import Select from "react-select";
import NextArrow from "../../../Assets/images/next-arrow.svg";
import PrevArrow from "../../../Assets/images/prev-arrow.svg";
import Table from "react-bootstrap/Table";
import { useEffect } from "react";

function Step2({
  handleStep,
  info,
  setInfo,
  couponWithDate,
  setCouponWithDate,
  couponWithoutDate,
  setCouponWithoutDate
}) {
  const handleStepNext = () => {
    handleStep("next");
  };
  useEffect(() => {
    info?.couponData?.map((each) => {
      if (
        each[`Offer Clip End Date (M/D/Y)`] != null &&
        each[`Offer Clip End Date (M/D/Y)`] != "" &&
        each[`Offer Clip Start Date (M/D/Y)`] != null &&
        each[`Offer Clip Start Date (M/D/Y)`] != ""
      ) {
        setCouponWithDate((prev) => [...prev, each]);
      }
      if (
        each[`Offer Clip End Date (M/D/Y)`] == null ||
        each[`Offer Clip End Date (M/D/Y)`] == "" ||
        each[`Offer Clip Start Date (M/D/Y)`] == null ||
        each[`Offer Clip Start Date (M/D/Y)`] == ""
      ) {
        setCouponWithoutDate((prev) => [...prev, each]);
      }
    });
  }, []);

  return (
    <div>
      <Form>
        <Row>
          <Col xs={6} lg={6} md={6} sm={6}>
            <Form.Group style={{ display: "flex", flexDirection: "row" }}>
              <div
                className="Wizard_Content_Card Rule_Type_Info mr-10 table-height"
                style={{ boxShadow: "2px 2px 2px 2px grey" }}
              >
                <Col lg={12} className="mr-10">
                  <Table striped bordered hover size="sm">
                    <thead
                      className="card-heading"
                      style={{ position: "sticky", top: "0" }}
                    >
                      <tr>Coupons with fixed dates from File</tr>
                    </thead>
                    <tbody>
                      {couponWithDate?.length < 1 && (
                        <tr>
                          <td>No coupon to display</td>
                        </tr>
                      )}
                      {couponWithDate?.map((each) => (
                        <tr>
                          <td>{each[`Offer Description`]}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Col>
              </div>
            </Form.Group>
          </Col>
          <Col lg={6} md={6} sm={6} xs={6}>
            <Form.Group>
              <div
                className="Wizard_Content_Card Rule_Type_Info ml-10 table-height"
                style={{ boxShadow: "2px 2px 2px 2px grey" }}
              >
                <Col lg={12}>
                  <Table striped bordered hover size="sm">
                    <thead
                      className="card-heading"
                      style={{ position: "sticky", top: "0" }}
                    >
                      <tr>Coupons without Dates from File</tr>
                    </thead>
                    <tbody>
                      {/* {info?.couponData?.map(
                        (each) =>
                          each[`Offer Clip End Date (M/D/Y)`] == null ||
                          (each[`Offer Clip End Date (M/D/Y)`] == "" && (
                            <tr>
                              <td>{each[`Offer Description`]}</td>
                            </tr>
                          ))
                      )} */}
                      {couponWithoutDate?.length < 1 && (
                        <tr>
                          <td>No coupon to display</td>
                        </tr>
                      )}
                      {couponWithoutDate?.map((each) => (
                        <tr>
                          <td>{each[`Offer Description`]}</td>
                        </tr>
                      ))}
                    </tbody>
                    <tfoot
                      style={{
                        position: "sticky",
                        bottom: "0",
                        backgroundColor: "white"
                      }}
                      className="d-flex flex-column justify-content-end align-items-center  text-end"
                    >
                      <Button
                        hidden={couponWithoutDate?.length <= 0 ? true : false}
                        className="BlueButton ml-10 mt-20"
                        style={{ backgroundColor: "#3D8ECC" }}
                        onClick={() => {
                          handleStepNext();
                        }}
                      >
                        Schedule Coupon
                      </Button>
                    </tfoot>
                  </Table>
                  <div className="d-flex flex-column justify-content-end align-items-center  text-end">
                    {/* <Button
                      className="BlueButton ml-10"
                      style={{ backgroundColor: "#3D8ECC", position:"absolute" }}
                      onClick={() => {
                        handleStepNext();
                      }}
                    >
                      Schedule Coupon
                </Button> */}
                  </div>
                </Col>
              </div>
            </Form.Group>
          </Col>
        </Row>
      </Form>
      <div className="flex align-items-center justify-content-end text-end">
        <Button
          className="GrayButton mt-40"
          style={{ backgroundColor: "#3D8ECC", color: "white", border: "none" }}
          type="submit"
          onClick={() => {
            handleStep("previous");
            setCouponWithDate([]);
            setCouponWithoutDate([]);
          }}
        >
          <img src={PrevArrow} alt="Prev Arrow" className="img-fluid mr-5" />
          Previous
        </Button>

        <Button
          hidden={couponWithoutDate?.length <= 0 ? false : true}
          className="GrayButton mt-40"
          style={{ backgroundColor: "#3D8ECC", color: "white", border: "none" }}
          type="submit"
          onClick={() => {
            handleStep("next");
          }}
        >
          Next
          <img src={NextArrow} alt="Prev Arrow" className="img-fluid  ml-5" />
        </Button>
      </div>
    </div>
  );
}

export default Step2;
