import React from "react";

import ClipLoader from "react-spinners/ClipLoader";
import Shoppers from "../../../Assets/images/shoppers.svg";
import Dollar from "../../../Assets/images/doller.svg";

const CompanySalesSummary = ({ companyData, summaryLoader, retailerarr }) => {


    return (
      
            summaryLoader ? (
                <div
                    className="Clip_Loader"
                    style={{ minHeight: "280px" }}
                >
                    <ClipLoader
                        color={"rgb(0, 143, 251)"}
                        loading={true}
                        size={50}
                    />
                </div>
            ) :
                <div className=" h-100">
                    <div className="OverView_Outer_Block ">
                        <div className=" Overview">
                            <div className="Card_Body p-0">
                                <div className="table-responsive Sales_Summery_Table">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th width="40%">
                                                </th>
                                                <th>
                                                    <div className="Sales_Summery_Table_Head_Ttl">
                                                        <div className="Icon">
                                                            <img
                                                                src={Shoppers}
                                                                className="img-fluid"
                                                                alt="Retailer"
                                                            />
                                                        </div>
                                                        <span>Users</span>
                                                    </div>
                                                </th>
                                                <th>
                                                    <div className="Sales_Summery_Table_Head_Ttl">
                                                        <div className="Icon">
                                                            <img
                                                                src={Shoppers}
                                                                className="img-fluid"
                                                                alt="Retailer"
                                                            />
                                                        </div>
                                                        <span>Shoppers</span>
                                                    </div>
                                                </th>
                                                <th>
                                                    <div className="Sales_Summery_Table_Head_Ttl">
                                                        <div className="Icon">
                                                            <img
                                                                src={Dollar}
                                                                className="img-fluid"
                                                                alt="Retailer"
                                                            />
                                                        </div>
                                                        <span>Units Sold</span>
                                                    </div>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {retailerarr.length > 0 ? (
                                                <>
                                                    {Object.keys(companyData.GUS90).map(
                                                        (val, idx) => {
                                                            return parseInt(
                                                                companyData.GUS90[val].Users
                                                            ) > 0 ? (
                                                                <tr key={idx}>
                                                                    <td>

                                                                        <span>
                                                                            {companyData.GUS90[val]
                                                                                .RetailerName
                                                                                ? companyData.GUS90[val]
                                                                                    .RetailerName
                                                                                : companyData.GUS90[val]
                                                                                    .WholeSalerName}
                                                                        </span>

                                                                    </td>
                                                                    <td >

                                                                        <span>
                                                                            {companyData.GUS90[
                                                                                val
                                                                            ].Users.toString().replace(
                                                                                /\B(?=(\d{3})+(?!\d))/g,
                                                                                ","
                                                                            )}
                                                                        </span>

                                                                    </td>
                                                                    <td className="newOverview">


                                                                        <span>
                                                                            {
                                                                                '$' + parseFloat(companyData.GUS90[val]
                                                                                    .TotalSales.replace(/,/g, '').replace(/,/g, '').substring(1)).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                                                            }
                                                                        </span>

                                                                    </td>
                                                                </tr>
                                                            ) : (
                                                                ""
                                                            );
                                                        }
                                                    )}</>
                                            ) : (
                                                <>{Object.keys(companyData.GUS90).map(
                                                    (val, idx) => {
                                                        return parseInt(
                                                            companyData.GUS90[val].Users
                                                        ) > 0 ? (
                                                            <tr key={idx}>
                                                                <td >


                                                                    <span>
                                                                        {companyData.GUS90[val]
                                                                            .RetailerName
                                                                            ? companyData.GUS90[val]
                                                                                .RetailerName
                                                                            : companyData.GUS90[val]
                                                                                .WholeSalerName}
                                                                    </span>

                                                                </td>
                                                                <td >


                                                                    <span>
                                                                        {companyData.GUS90[
                                                                            val
                                                                        ].TotalUsers.toString().replace(
                                                                            /\B(?=(\d{3})+(?!\d))/g,
                                                                            ","
                                                                        )}{" "}
                                                                    </span>

                                                                </td>
                                                                <td >


                                                                    <span>
                                                                        {companyData.GUS90[
                                                                            val
                                                                        ].Users.toString().replace(
                                                                            /\B(?=(\d{3})+(?!\d))/g,
                                                                            ","
                                                                        )}{" "}
                                                                    </span>

                                                                </td>
                                                                <td>
                                                                    <span>
                                                                        {
                                                                            companyData.GUS90[val].UnitsSold.toLocaleString()
                                                                        }
                                                                    </span>

                                                                </td>
                                                            </tr>
                                                        ) : (
                                                            ""
                                                        );
                                                    }
                                                )}</>
                                            )}
                                        </tbody>
                                    </table>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            
        
    )

};

export default CompanySalesSummary;