import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import Header from "../../../Components/Wholesaler/layout/header";
import Step1 from "./Step1";
import { getCompany, getRetailers } from "../../../API/Wholesalerapi";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import ls from "local-storage";

function WizardBulk() {
  const [step, setStep] = useState(1);
  const [companyData, setCompanyData] = useState([]);
  const [retailersData, setRetailersData] = useState([]);
  const [retailerArray, setRetailerArray] = useState([]);
  const [couponWithDate, setCouponWithDate] = useState([]);
  const [couponWithoutDate, setCouponWithoutDate] = useState([]);
  const [filteredRetailerOption, setfilteredRetailerOption] = useState([]);
  const [retailerInitialObject, setRetailerInitialObject] = useState({
    startDate: "",
    endDate: "",
    retailer: ""
  });
  const handleInputRetailerArray = (e, name) => {
    setRetailerInitialObject({
      ...retailerInitialObject,
      [name]: e
    });
  };
  const [value, setValue] = useState({
    companyName: "",
    couponData: [],
    startDate: "",
    endDate: "",
    brand_guid: ls.get("brands") ? ls.get("brands")[0].guid : "",
    retailer: []
  });

  const [formError, setFormError] = useState({
    companyError: "",
    csvFileError: "",
    startDate: "",
    endDate: "",
    retailer: ""
  });

  const getCompanyName = async () => {
    const response = await getCompany();
    response?.data?.Message?.length > 1
      ? setCompanyData(response.data.Message)
      : setCompanyData([]);
  };
  const getRetailersName = async () => {
    const responseData = await getRetailers();
    responseData?.data?.Message?.length > 1
      ? setRetailersData(responseData.data.Message)
      : setRetailersData([]);
  };

  useEffect(() => {
    getCompanyName();
    getRetailersName();
  }, []);

  const handleStep = (value) => {
    if (value === "next") {
      setStep(step + 1);
      if (step == 2 && couponWithoutDate.length < 1) {
        setStep(step + 2);
      } else {
        setStep(step + 1);
      }
    } else {
      setStep(step - 1);
      if (step == 4 && couponWithoutDate.length < 1) {
        setStep(step - 2);
      } else {
        setStep(step - 1);
      }
    }
  };

  const handleInput = (e, name) => {
    setValue({
      ...value,
      [name]: e
    });
  };

  const companyOptions =
    companyData &&
    companyData?.map((each) => {
      return {
        value: each.company_id,
        label: each.company_name
      };
    });

  const retailerOptions =
    retailersData &&
    retailersData?.map((each) => {
      return {
        value: each.retailer_id,
        label: each.retailer_name
      };
    });

  const [multiOptins, setMultiOptions] = useState(retailerOptions);
  return (
    <>
      <React.Fragment>
        <div className="Content_Wrapper_Wizard  mt-170">
          <div className="Wizard mt-30">
            <div className="Wizard_Tab pb-77">
              <Container fluid>
                <Row className="justify-content-center">
                  <Col xs={12}>
                    <ul
                      className="nav nav-tabs wizard pb-70"
                      role="tablist"
                      id="pills-tab"
                    >
                      <li
                        className={step == 1 ? "active" : "completed"}
                        role="presentation"
                      >
                        <button id="nav-step-1-tab" type="button">
                          Upload Coupons
                        </button>
                      </li>
                      <li
                        className={
                          step == 2 ? "active" : step > 2 ? "completed" : ""
                        }
                        role="presentation"
                      >
                        <button
                          id="nav-step-1-tab"
                          style={{
                            background:
                              "linear-gradient(105.16deg, #2a80c6 39.34%, #1fb3cd 83.14%) !important"
                          }}
                          type="button"
                        >
                          Review Coupon List
                        </button>
                      </li>
                      <li
                        className={
                          step == 3 ? "active" : step > 3 ? "completed" : ""
                        }
                        role="presentation"
                      >
                        <button
                          id="nav-step-1-tab"
                          style={{
                            background:
                              "linear-gradient(105.16deg, #2a80c6 39.34%, #1fb3cd 83.14%) !important"
                          }}
                          type="button"
                        >
                          Add Retailer
                        </button>
                      </li>
                      <li
                        className={
                          step == 4 ? "active" : step > 4 ? "completed" : ""
                        }
                        role="presentation"
                      >
                        <button
                          id="nav-step-1-tab"
                          style={{
                            background:
                              "linear-gradient(105.16deg, #2a80c6 39.34%, #1fb3cd 83.14%) !important"
                          }}
                          type="button"
                        >
                          Preview
                        </button>
                      </li>
                    </ul>
                  </Col>
                </Row>
              </Container>
            </div>
            <div className="Wizard_Content mb-100">
              <Container fluid>
                <Row className="justify-content-center">
                  <Col xxl={8} xl={10} xs={12}>
                    <div>
                      {step === 1 && (
                        <Step1
                          handleStep={handleStep}
                          companyData={companyData}
                          info={value}
                          setInfo={setValue}
                          handleInput={handleInput}
                          companyOptions={companyOptions}
                          formError={formError}
                          setFormError={setFormError}
                        />
                      )}

                      {step === 2 && (
                        <Step2
                          info={value}
                          setInfo={setValue}
                          handleInput={handleInput}
                          handleStep={handleStep}
                          couponWithDate={couponWithDate}
                          setCouponWithDate={setCouponWithDate}
                          couponWithoutDate={couponWithoutDate}
                          setCouponWithoutDate={setCouponWithoutDate}
                        />
                      )}
                      {step === 3 && (
                        <Step3
                          info={value}
                          setInfo={setValue}
                          handleInput={handleInput}
                          handleStep={handleStep}
                          retailerOptions={retailerOptions}
                          multiOptins={multiOptins}
                          setMultiOptions={setMultiOptions}
                          handleInputRetailerArray={handleInputRetailerArray}
                          setRetailerArray={setRetailerArray}
                          retailerArray={retailerArray}
                          retailerInitialObject={retailerInitialObject}
                          setRetailerInitialObject={setRetailerInitialObject}
                          formError={formError}
                          setFormError={setFormError}
                          setCouponWithDate={setCouponWithDate}
                          setCouponWithoutDate={setCouponWithoutDate}
                          filteredRetailerOption={filteredRetailerOption}
                          setfilteredRetailerOption={setfilteredRetailerOption}
                        />
                      )}
                      {step === 4 && (
                        <Step4
                          handleStep={handleStep}
                          info={value}
                          setInfo={setValue}
                          retailerArray={retailerArray}
                          couponWithDate={couponWithDate}
                          setCouponWithDate={setCouponWithDate}
                          couponWithoutDate={couponWithoutDate}
                          setCouponWithoutDate={setCouponWithoutDate}
                        />
                      )}
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        </div>
      </React.Fragment>
    </>
  );
}

export default WizardBulk;
