import React from "react";
import $ from "jquery";
import ls from "local-storage";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as CONFIG from "../../Components/_config";
const Loader = () => <div id="cover-spin"></div>;

class EditbrokerModal extends React.Component {

  constructor(props) {
    super(props);
    toast.configure();

    this.state = {
      AllRetailer: this.props.allRetailer,
      brandRetailer: this.props.brandRetailer,
      brandWholesaler: this.props.brandWholesaler,
      AllWholesaler: this.props.allWholesaler,
      retailer_number: [],
      wholesaler_number: [],
      formControlinfo: this.props.formControl,
      role_guid: this.props.role_guid,
      brand_job_title: this.props.brand_job_title,
      masterRoles: this.props.allRoles

    };
  }



  selectRetailer(e, wholesaler_id, retailer_id) {
    if (e.target.checked) {

      let obj = {
        wholesaler_id: wholesaler_id,
        retailer_id: retailer_id
      };
      this.state.retailer_number.push(obj);
    } else {
      let removeIndex = this.state.retailer_number.findIndex(
        (itm) => itm.retailer_id === retailer_id
      );

      if (removeIndex > -1) {
        this.state.retailer_number.splice(removeIndex, 1); // 2nd parameter means remove one item only
      }
    }
  }

  selectWholesaler(e, wholesaler_id, wholesaler_guid) {
    if (e.target.checked) {
      let wholesaler_number_arr = [
        ...this.state.wholesaler_number, wholesaler_id
      ];
      this.setState({ wholesaler_number: wholesaler_number_arr });
    } else {
      let removeIndex = this.state.wholesaler_number.findIndex(
        (itm) => itm === wholesaler_id
      );
      if (removeIndex > -1) {
        this.state.wholesaler_number.splice(removeIndex, 1); // 2nd parameter means remove one item only
      }
    }
  }
  SaveUser() {

    var guid = ls.get("brands") ? ls.get("brands")[0].guid : "";

    $("#subscribe-sel-drop").css("display", "none");
    $(".modal-backdrop").first().css("display", "none");

    this.showLoader();

    var groups = Object.create(null);

    this.state.masterRoles.forEach(function (a) {
      groups[a.role_name] = groups[a.role_name] || [];
      groups[a.role_name].push(a);
    });

    var role_guid = groups[this.state.edit_role_data.role][0].brandiq_user_role_guid;


    var data = JSON.stringify({

      first_name: this.state.edit_role_data.first_name ? this.state.edit_role_data.first_name : this.state.formControlinfo.first_name,
      last_name: this.state.edit_role_data.last_name ? this.state.edit_role_data.last_name : this.state.formControlinfo.last_name,
      email: this.state.edit_role_data.email ? this.state.edit_role_data.email : this.state.formControlinfo.email,

      primary_phone: this.state.edit_role_data.primary_phone ? this.state.edit_role_data.primary_phone : this.state.formControlinfo.primary_phone,
      secondary_phone: "",
      brand: guid,
      role: role_guid,

      brand_job_title: this.state.edit_role_data.role ? this.state.edit_role_data.role : this.state.formControlinfo.role,
      role_name: this.state.edit_role_data.role_name ? this.state.edit_role_data.role_name : this.state.formControlinfo.role_name,

      wholesalers: this.state.wholesaler_number,
      retailers: this.state.retailer_number,
      state: 1
    });

    var config = {
      method: "put",
      url: CONFIG.API_URL + `user/${this.state.formControlinfo.user_guid}/${guid}`,
      headers: CONFIG.config_head.headers,
      data: data
    };
    axios(config)
      .then((res) => {
        if (res.status === 201) {
          this.hideLoader();
          toast.success('User Updated Successfully')
          setTimeout(
            () => window.location.reload(),
            2500
          );
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 403) {
            this.hideLoader();
            toast.error(error.response.data.Message);
            ls.clear('brands');
            ls.clear('selectedbrand');
            ls.clear('token');
            window.location.href = '/login';

          }
          else {
            this.hideLoader();
            toast.error(error.response.data.Message);
            setTimeout(
              () => window.location.reload(), 2000
            );
          }
        }
      });

  }
  showLoader = () => {
    this.setState({ loading: true });
  }
  hideLoader = () => {
    this.setState({ loading: false });

  };

  componentDidUpdate(prevProps, nextProps) {
    if (prevProps !== this.props) {

      this.setState({
        edit_role_data: prevProps.formControlinfo,
      });
    }
  }


  componentWillReceiveProps(nextProps) {
    this.setState({
      formControlinfo: nextProps.formControl,
      masterRoles: nextProps.allRoles,
      changeInputs: nextProps.formControlinfo

    });
  }

  render() {

    return (
      <>
        {this.state.loading ? <Loader /> : null}

        <div
          className="modal fade"
          id="subscribe-sel-drop"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-custom modal-dialog-scrollable">
            <div className="modal-content whole-content">
              <div className="modal-header borderemove brand-whole-hd">
                <h5 className="modal-title" id="exampleModalLabel">
                  Subscribed Retailer/Wholesaler
                </h5>
                <button
                  type="button"
                  className="btn-close wholeseler-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>

              <div className="modal-body CustomScroll whole-seler-body">
                <div className="tab-content" id="nav-tabContent">
                  <div
                    className="tab-pane fade show active tab-text"
                    id="nav-home"
                    role="tabpanel"
                    aria-labelledby="nav-home-tab"
                  >
                    <div className="Common_brand_detail">
                      <div className="pr-12 pl-12 pt-20">

                        <div className="profile-box">
                          <h1 className="account-head">My Subscriptions</h1>
                          {
                            Object.keys(this.state.brandWholesaler).length > 0 &&

                            <div className="Card ">
                              <div className="Card_Header  Steps_Header">
                                <h5>Wholesalers</h5>
                              </div>
                              <div className="Card_Body p-3">
                                {this.state.brandWholesaler ? (
                                  <>
                                    <ul className=" d-flex  Retailer">
                                      {Object.keys(this.state.brandWholesaler).map(
                                        (val, idx) => {
                                          return (
                                            //console.log(this.state.AllWholesaler?this.state.AllWholesaler[this.state.brandWholesaler[val].WholesalerNumber][0].wholesaler_name:'')
                                            <li key={idx}>
                                              <div className="Retailer_Block">
                                                <input
                                                  type="checkbox"
                                                  onChange={(e) =>
                                                    this.selectWholesaler(
                                                      e,
                                                      this.state.AllWholesaler[
                                                        this.state
                                                          .brandWholesaler[val]
                                                          .WholesalerNumber
                                                      ][0].wholesaler_id,
                                                      this.state.AllWholesaler[
                                                        this.state
                                                          .brandWholesaler[val]
                                                          .WholesalerNumber
                                                      ][0].wholesaler_guid
                                                    )
                                                  }

                                                  defaultValue={this.state.AllWholesaler[
                                                    this.state
                                                      .brandWholesaler[val]
                                                      .WholesalerNumber
                                                  ][0].wholesaler_id}
                                                />

                                                <div className="RetailerImage">
                                                  <img
                                                    className="img-fluid"
                                                    alt="Retailer"
                                                    src={
                                                      this.state.AllWholesaler
                                                        ? this.state.AllWholesaler[
                                                          this.state
                                                            .brandWholesaler[val]
                                                            .WholesalerNumber
                                                        ][0].logo
                                                        : ""
                                                    }
                                                  />
                                                </div>
                                                <h5>
                                                  {this.state.AllWholesaler
                                                    ? this.state.AllWholesaler[
                                                      this.state.brandWholesaler[
                                                        val
                                                      ].WholesalerNumber
                                                    ][0].wholesaler_name
                                                    : ""}{" "}
                                                </h5>
                                              </div>
                                            </li>
                                          )
                                        }
                                      )}
                                    </ul>
                                  </>
                                ) : (
                                  "NO Wholesaler Available"
                                )
                                }
                              </div>
                            </div>
                          }
                          {Object.keys(this.state.brandRetailer).length > 0 && <div className="Card mt-20">
                            <div className="Card_Header Steps_Header">
                              <h5>Retailers</h5>
                            </div>
                            <div className="Card_Body p-3">
                              {Object.keys(this.state.brandRetailer).length > 0 ? (
                                <>
                                  <ul className=" mt-15 d-flex  Retailer">
                                    {Object.keys(this.state.brandRetailer).map(
                                      (val, idx) => {
                                        return (
                                          // console.log(this.state.AllRetailer?this.state.AllRetailer[parseInt(this.state.brandRetailer[val])][0].retailer_name:'')

                                          <li key={idx}>
                                            <div className="Retailer_Block">
                                              <input
                                                type="checkbox"
                                                onChange={(e) =>
                                                  this.selectRetailer(
                                                    e,
                                                    this.state.AllRetailer[
                                                      parseInt(
                                                        this.state.brandRetailer[
                                                        val
                                                        ]
                                                      )
                                                    ][0].wholesaler_id,
                                                    this.state.AllRetailer[
                                                      parseInt(
                                                        this.state.brandRetailer[
                                                        val
                                                        ]
                                                      )
                                                    ][0].retailer_id
                                                  )
                                                }


                                              />

                                              <div className="RetailerImage">
                                                <img
                                                  className="img-fluid"
                                                  alt="Retailer"
                                                  src={
                                                    this.state.AllRetailer
                                                      ? this.state.AllRetailer[
                                                        parseInt(
                                                          this.state
                                                            .brandRetailer[val]
                                                        )
                                                      ][0].logo
                                                      : ""
                                                  }
                                                />
                                              </div>
                                              <h5>
                                                {this.state.AllRetailer
                                                  ? this.state.AllRetailer[
                                                    parseInt(
                                                      this.state.brandRetailer[
                                                      val
                                                      ]
                                                    )
                                                  ][0].retailer_name
                                                  : ""}{" "}
                                              </h5>
                                            </div>
                                          </li>
                                        );
                                      }
                                    )}
                                  </ul>
                                </>
                              ) : (
                                "No Retailers Available"
                              )}
                            </div>
                          </div>}

                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer brand-whole-footer">
                <button
                  type="button"
                  className="btn btn-primary Btn_blue_modal"
                  onClick={() => this.SaveUser()}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default EditbrokerModal;
