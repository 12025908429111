import React from "react";
import "leaflet/dist/leaflet.css";
import ClipLoader from "react-spinners/ClipLoader";
import Markerimg from "../../../Assets/images/marker.png";
import L from "leaflet";


import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
const markerIcon = new L.Icon({
    iconUrl: Markerimg,
    iconSize: [40, 40],
    iconAnchor: [17, 46], //[left/right, top/bottom]
    popupAnchor: [0, -46] //[left/right, top/bottom]
  });
  
const StoreByState = ({StoresDetails,storeLoader}) => {

    return (
      
        <div className="Card_Body ">
          <div className="Map text-center ">
            {/* <img src={Map} className='img-fluid' alt="Map"/> */}
            {storeLoader ? (
              <div
                className="Clip_Loader"
                style={{ minHeight: "320px" }}
              >
                <ClipLoader
                  color={"rgb(0, 143, 251)"}
                  loading={true}
                  size={50}
                />
              </div>
            ) : Object.keys(StoresDetails).length > 0 ? (
              <MapContainer
                className="markercluster-map"
                center={[32.741646, -89.678696]}
                zoom={3}
                maxZoom={18}
              >
                <TileLayer
                  url="https://api.maptiler.com/maps/topographique/256/{z}/{x}/{y}.png?key=Jwldj418wFeWAGThBHQF"
                  attribution=' <a href="http://osm.org/copyright"></a>'
                />
  
                {StoresDetails.map((city, idx) => (
                  <Marker
                    position={[city.Latitude, city.Longitude]}
                    icon={markerIcon}
                    key={idx}
                  >
                    <Popup>
                      <b>
                        Stores: {city.Stores}, State: {city.State}
                      </b>
                    </Popup>
                  </Marker>
                ))}
              </MapContainer>
            ) : (
              "No Stores Available"
            )}
        
          </div>
        </div>
    

    )

};

export default StoreByState;