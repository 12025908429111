import ls from "local-storage";
import axios from 'axios';
import * as CONFIG from "./Components/_config";

let token = ls.get("token") ? ls.get("token") : "";

export default class Auth {
    isAuthenticated = () => {
        const expiresAt = ls.get("expiresAt")
        if (!!expiresAt) {
            return new Date().getTime() < JSON.parse(expiresAt)
        }
        return false
    }

    getNewToken = () => {
        let config = {
            method: "get",
            url: CONFIG.API_URL + `login/refresh`,
            headers: {
                Authorization: token,
            }
        };
        return axios(config).then(res => {
            return res
        })
            .catch(error => {
                ls.clear("token");
                ls.clear("brands");
                localStorage.removeItem("footerDate")
                window.location.href = "/login";
            })
    }
}