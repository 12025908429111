//import $ from "jquery";
import React from "react";
//import { Button, Form } from "react-bootstrap";
//import { Link } from "react-router-dom";
// import { render } from "sass";

class TermsCondition extends React.Component {
  
  constructor(props) {
    super(props);
  }
  
  render() {
    return (
      <>
        <div
          className="modal fade"
          id="exampleModal"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-xl modal-dialog-scrollable">
            <div className="modal-content pd-right">
              <div className="modal-header borderemove">
                <nav className="w-100">
                  <div className="nav nav-tabs" id="nav-tab" role="tablist">
                    <button
                      className="nav-link tab-terms active"
                      id="nav-home-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#nav-home"
                      type="button"
                      role="tab"
                      aria-controls="nav-home"
                      aria-selected="true"
                    >
                      Privacy Policy
                    </button>
                    <button
                      className="nav-link tab-terms"
                      id="nav-profile-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#nav-profile"
                      type="button"
                      role="tab"
                      aria-controls="nav-profile"
                      aria-selected="false"
                    >
                      Terms and conditions
                    </button>
                  </div>
                </nav>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body CustomScroll">
                <div className="tab-content" id="nav-tabContent">
                  <div
                    className="tab-pane fade show active tab-text"
                    id="nav-home"
                    role="tabpanel"
                    aria-labelledby="nav-home-tab"
                  >
                    <h5 className="term-head">About the Site</h5>
                    <p>
                      The Site is operated by BrandIQ PTY. LTD., ABN 51 608 870
                      542 (Brand IQ). Access to and use of the Site, or any of
                      its associated Products or Services, is provided by
                      BrandIQ. Please read these terms and conditions (Terms)
                      carefully. By using, browsing and/or reading the Site, you
                      indicate that you have read, understood and agree to be
                      bound by the Terms.
                    </p>
                    <p>
                      If you do not agree with the Terms, you must stop using
                      the Site and any Services immediately.
                    </p>
                    <p>
                      Online Virtuoso reserves the right to review and change
                      any of the Terms by updating this page at its sole
                      discretion. When BrandIQ updates the Terms, it will use
                      reasonable endeavours to provide you with notice of
                      updates to the Terms. Any changes to the Terms take
                      immediate effect from the date of their publication.
                    </p>
                    <h5 className="term-head">Acceptance of the Privacy</h5>
                    <p>
                      You accept the Terms by remaining on the Site. You may
                      also accept the Terms by clicking to accept or agree to
                      the Terms where this option is made available to you by
                      BrandIQ in the user interface.
                    </p>
                    <h5 className="term-head">
                      Subscription to use the Services
                    </h5>
                    <p>
                      These terms must be read subject to any guarantee,
                      condition or warranty (such as the consumer guarantees
                      implied by the Competition and Consumer Act 2010 (Cth)),
                      which cannot by law be excluded{" "}
                      <b>(Non-excludable Condition)</b>.
                    </p>
                    <p>
                      To access the Services, you must first purchase a
                      Subscription through the Site and pay the applicable
                      Subscription Fee.
                    </p>
                    <p>Subscriptions are available for purchase monthly.</p>
                    <p>
                      Subject to the Non-excludable Conditions, Subscriptions
                      are non-refundable and non-transferrable
                    </p>
                    <p>
                      Once you have purchased the Subscription, you will be
                      required to register for an Account through the Site
                      before you can access the Services.
                    </p>
                    <p>
                      Once you have completed the registration process, you will
                      be a registered as a member of the Site <b>(Member)</b>.
                      As a Member you will be granted access to the Services
                      from the time your first payment has been processed until
                      the subscription period expires{" "}
                      <b>(Subscription Period)</b>.
                    </p>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="nav-profile"
                    role="tabpanel"
                    aria-labelledby="nav-profile-tab"
                  >
                    <h5 className="term-head">Acceptance of the Terms</h5>
                    <p>
                      You accept the Terms by remaining on the Site. You may
                      also accept the Terms by clicking to accept or agree to
                      the Terms where this option is made available to you by
                      BrandIQ in the user interface.
                    </p>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-primary Btn_blue_modal"
                  onClick={() => this.props.simplifiedFunction()}
                >
                  Accept Terms
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default TermsCondition;
