import React from 'react'
import Arrow from "../../Assets/images/arrow.gif";
import { Button, Form, Spinner } from "react-bootstrap";
import BlockImgBlock from "../../Assets/images/blockbg.png";
import BlockImg from "../../Assets/images/Brandiq.gif";
import LogoBig from "../../Assets/images/logo-big.png";
import Arrowsvg from "../../Assets/images/arrow.svg";
import CalenderSvgImage from "../../Assets/images/calender.svg";
import CalenderSvgRedImage from "../../Assets/images/calenderRed.svg";

class Preview extends React.Component {
    constructor(props) {
        super(props)
        this.state = { camp_wholesalername: [], retailer_camp_array: [] }
    }

    componentDidUpdate(prevProps, nextProps) {
        if (prevProps !== this.props) {
            this.setState({
                camp_wholesalername: prevProps.camp_wholesalername,
                retailer_camp_array: prevProps.retailer_camp_array
            });
        }
    }

    render() {
        return (
            <div className="row  g-3 justify-content-center">
                <div className="col-12">
                    <div className="Preview_Card Common_Block">
                        <div className="Common_Block_Body">
                            <h5>
                                <span>
                                    Your offers update will be sent to retailers
                                </span>{" "}
                                <b>within</b>{" "}
                            </h5>
                            <h4>2 hours</h4>
                            <div className="Preview_Outer_Block">
                                <ul className="Preview_Card_Row">
                                    <li className=" text-center Coupons_Image" style={{ margin: '0 auto', width: '50%' }}>
                                        <div className="Coupons_Outer_Block">
                                            {this.props.selectedFile ? (
                                                <div className="Coupons_Card">

                                                    <img
                                                        src={this.props.selectedFile}
                                                        className="img-fluid"
                                                        alt="Coupon"
                                                    />
                                                    <h2 className="Price">
                                                        {this.props.title}
                                                        <span></span>
                                                    </h2>
                                                    <p>
                                                        {this.props.description}
                                                    </p>
                                                </div>
                                            ) : (
                                                <div className="ImgBlock position-relative text-center">
                                                    <img
                                                        src={BlockImgBlock}
                                                        className="img-fluid"
                                                        alt="Bakckground"
                                                    />
                                                    <img
                                                        src={LogoBig}
                                                        className="img-fluid LogoBig"
                                                        alt="Bakckground"
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    </li>
                                    {/* <li className="BgImage d-flex align-items-center">
                                        <div className="Arrow d-flex align-items-center">
                                            <img
                                                src={Arrow}
                                                className="img-fluid ArrowImage"
                                                alt="Arrow"
                                            />
                                        </div>
                                        <div className="ImgBlock position-relative text-center">
                                            <img
                                                src={BlockImg}
                                                className="img-fluid"
                                                alt="Bakckground"
                                            />

                                        </div>
                                    </li> */}
                                    {/* <li className="d-flex align-items-center Brand_Outer_Block">
                                        <div className="Arrow d-flex align-items-center">

                                            <img
                                                src={Arrow}
                                                className="img-fluid ArrowImage ArrowImageCustom"
                                                alt="Arrow"
                                            />
                                        </div>

                                        <div className="Brand_Logo_Group">
                                           
                                        </div>
                                    </li> */}
                                </ul>

                                <div className="row  ">
                                    <div className="col-lg-6 col-12 mt-45">
                                        <div className="date d-flex align-items-center ">
                                            <Form.Group
                                                className=""
                                                controlId="exampleForm.ControlInput1"
                                            >
                                                <img
                                                    src={CalenderSvgImage}
                                                    className="img-fluid"
                                                    alt="Calender"
                                                />
                                                <Form.Label>
                                                    &nbsp;Start: &nbsp;
                                                </Form.Label>
                                                <span className="fw-bold">
                                                    {this.props.global_prev_start_date}
                                                </span>
                                            </Form.Group>
                                            <Form.Group
                                                className="ml-12"
                                                controlId="exampleForm.ControlInput2"
                                            >
                                                <img
                                                    src={CalenderSvgRedImage}
                                                    className="img-fluid"
                                                    alt="Calender"
                                                />
                                                <Form.Label>
                                                    &nbsp; End: &nbsp;
                                                </Form.Label>
                                                <span className="fw-bold">
                                                    {" "}
                                                    {this.props.global_prev_end_date}
                                                </span>
                                            </Form.Group>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-12 mt-45">
                                        <Form.Check
                                            type="checkbox"
                                            name="make_offer"
                                            label="By clicking, I understand that offer targets and dates cannot be changed after release and I confirm to release the offer. I also accept BrandIQ terms and condition"
                                            onChange={(e) =>
                                                this.props.onchangeoffercheckbox(e)
                                            }
                                        />
                                        {this.props.selectedOption === "retailer" ?
                                            <>
                                                <Button
                                                    variant=""
                                                    type="button"
                                                    className=" Btn_blue mt-20 w-50 ms-auto"
                                                    disabled={this.props.subscribeButtonLoader}
                                                    //onClick={(e) => this.props.buildCoupon(e)}
                                                    onClick={(e) => this.props.buildCouponretailer(e)}
                                                >
                                                    Save and Send Offer
                                                    {
                                                        this.props.subscribeButtonLoader ?
                                                            <Spinner
                                                                as="span"
                                                                animation="border"
                                                                size="sm"
                                                                role="status"
                                                                aria-hidden="true"
                                                                className={"float-end"}
                                                            />
                                                            :
                                                            <img
                                                                src={Arrowsvg}
                                                                className="img-fluid float-end"
                                                                alt="Arrow"
                                                            />
                                                    }
                                                </Button>
                                            </> : <>
                                                <Button
                                                    variant=""
                                                    type="button"
                                                    className=" Btn_blue mt-20 w-50 ms-auto"
                                                    disabled={this.props.subscribeButtonLoader}
                                                    //onClick={(e) => this.props.buildCoupon(e)}
                                                    onClick={(e) => this.props.buildCoupon(e)}
                                                >
                                                    Save and Send Offer
                                                    {
                                                        this.props.subscribeButtonLoader ?
                                                            <Spinner
                                                                as="span"
                                                                animation="border"
                                                                size="sm"
                                                                role="status"
                                                                aria-hidden="true"
                                                                className={"float-end"}
                                                            />
                                                            :
                                                            <img
                                                                src={Arrowsvg}
                                                                className="img-fluid float-end"
                                                                alt="Arrow"
                                                            />
                                                    }
                                                </Button>
                                            </>
                                        }


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Preview