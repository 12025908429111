import React from "react";
//import { Link } from "react-router-dom";
//import * as CONFIG from "../../Components/_config";
import AddOnes from "../../Assets/images/add-ones.svg";
import NearByIcon from "../../Assets/images/nearbyicon.svg";
import Digital from "../../Assets/images/media.svg";
import InsightsIcon from "../../Assets/images/insightsicon.svg";
import Ecom from "../../Assets/images/ecom.svg";


const DashBoardFooter = () => {

  return (
    <div className="row g-3 mt-0">
      <div className="col-12">
        <div className="Card Add_Ons">
          <div className="Card_Header">
            <div className="d-flex align-items-center">
              <img
                src={AddOnes}
                className="img-fluid"
                alt="Digial Coupons"
              />
              <h5> Get More From BrandIQ Add-ons</h5>
            </div>
          </div>
          <div className="Card_Body">
            <div className="row">
              <div className="col-lg-6 col-12 p-0">
                <div className="AddOnesList d-flex align-items-center">
                  <div className="Icon">
                    <img
                      src={NearByIcon}
                      className="img-fluid"
                      alt=" NearBy"
                    />
                  </div>
                  <div className="AddOnesList_Content">
                    <h5>Nearby.ai</h5>
                    <p>
                      Connect shoppers to offers and products in realtime
                      with a click using geo-location techlology.
                    </p>
                    <div className="LinkBlock">
                      {/* <Link to="">Learn More</Link> */}
                      {/* <Link to="" className='ml-60'>Active</Link> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-12 p-0">
                <div className="AddOnesList d-flex align-items-center">
                  <div className="Icon">
                    <img
                      src={Digital}
                      className="img-fluid"
                      alt=" NearBy"
                    />
                  </div>
                  <div className="AddOnesList_Content">
                    <div>
                      <h5>
                        Digital Media Support{" "}
                        <span className="badge bg-success">Coming Soon</span>
                      </h5>
                    </div>
                    <p>
                      Connect customers with product videos and drive them
                      to eocmmerce site.
                    </p>
                    <div className="LinkBlock">
                      {/* <Link to="">Learn More</Link> */}
                      {/* <Link to="" className='ml-60'>Active</Link> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-12 p-0">
                <div className="AddOnesList d-flex align-items-center">
                  <div className="Icon">
                    <img
                      src={InsightsIcon}
                      className="img-fluid"
                      alt=" NearBy"
                    />
                  </div>
                  <div className="AddOnesList_Content">
                    <h5>
                      Insights{" "}
                      <span className="badge bg-success">
                        Coming Soon
                      </span>
                    </h5>
                    <p>
                      Only platform to receive store level data in
                      realtime and provide insights and trends. You see
                      trends as they unfold
                    </p>
                    <div className="LinkBlock">
                      {/* <Link to="">Learn More</Link> */}
                      {/* <Link to="" className='ml-60'>Active</Link> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-12 p-0">
                <div className="AddOnesList d-flex align-items-center">
                  <div className="Icon">
                    <img src={Ecom} className="img-fluid" alt=" NearBy" />
                  </div>
                  <div className="AddOnesList_Content">
                    <div>
                      <h5>
                        Ecommmerce
                        <span className="badge bg-success">New</span>
                      </h5>
                    </div>
                    <p>
                      Seamlessly deliver digital offers to ecommerce sites
                      in real time. Reach hundreds of retailer sites.
                    </p>
                    <div className="LinkBlock">
                      {/* <Link to="">Learn More</Link> */}
                      {/* <Link to="" className='ml-60'>Active</Link> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )

};

export default DashBoardFooter;