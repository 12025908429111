import React, { useState, useEffect } from "react";
import './second-header.scss';
import ls from "local-storage";
import { Dropdown, DropdownButton,Spinner } from 'react-bootstrap';
import CompanyModel from "../CompanyModal";
const SecondHeader = ({ Avtar, decodetoken, brands, changeBrandsheader, onchangeBrandswizard, onchangeBrandUpclist, location,
  title, changeallBrands }) => {

  const branddropdown = ls.get("companyData")?.brand;
  //console.log("branddropdown",branddropdown)
  const companyLogo = ls.get("companyData")?.logo;
  const selectedbrand = ls.get("selectedbrand") ? ls.get("selectedbrand") : "";
  const user = ls.get("user");
  const disableLocationarr = ['Wizard', 'deal', 'category', 'editCategory', 'upcDetails', 'notification',
    'editCoupon', 'addUser', 'Order', 'editdeal', 'helpcenterdetails', 'helpcenter'];

  const changeBrands = (e) => {

    if (location === 'upclist' || location === 'listdeal' || location === 'coupon' || 'companyProfile' || 'profile') {

      const brands = ls.get('brands') ? ls.get('brands') : '';
      var filter = "name";
      var keyword = e;

      const dropdownSelectbrand = brands.length > 0 ? brands.filter(function (obj) {
        return obj[filter] === keyword;
      }) : '';

      localStorage.removeItem("selectedbrand")
      ls.set('selectedbrand', dropdownSelectbrand);

      window.location.href = '/dashboard';
    }

    if (location === 'dashboard') {
      changeBrandsheader(e);
    }
  }

  const changeallBrand = () => {
    localStorage.removeItem("selectedbrand")
    ls.set("selectedbrand", []);
    changeallBrands();
  }
  const [companyValue, setCompanyValue] = useState({
    companyName: ""
  })

  const selectedCompanyName = ls.get("selectedCompanyName")
  const companyLabel = selectedCompanyName?.companyName

  useEffect(() => {
    if (companyLabel !== "") {
      setCompanyValue({
        companyName: companyLabel
      })
    }
  }, [])

  const [show, setShow] = useState(false);
  const openModel = () => {
    if (companyValue.companyName.label === companyLabel.label) {
      setShow(true);
    } else setShow(false);
  }

  return (
    <div className="Second_Header">
      {
        (ls.get("user") == "broker" && ls.get("selectedCompanyName") == undefined || show === true)
        &&
        <CompanyModel mode={true} companyValue={companyValue} setCompanyValue={setCompanyValue} show={show} setShow={setShow}/>
      }
      <div className="container-fluid">
        <div className="Second_Header_InnerBlock d-flex align-items-center justify-content-between ">
          <div className="Profile col-7">
            <img src={Avtar} className="img-fluid" alt="Avtar" />
            <span>
              Welcome Back, {decodetoken.first_name.toUpperCase()}
            </span>
          </div>
          {
            user === "broker" && (
              <div className="col-1.5 mr-10" style={{ display: "flex", alignItems: "center", justifyContent: "end",width:"100%" }} >
                {
                  companyValue?.companyName === undefined || companyValue?.companyName === "" ? <  span onClick={openModel} style={{ padding: "6px", fontSize: '18px', border: "1px solid #3d8ecc", color: "black", borderRadius: '5px' }}>
                      Select Company
                  </span> :
                    <span onClick={openModel} style={{ padding: "6px", fontSize: '18px', border: "1px solid #3d8ecc", color: "black", borderRadius: '5px' }}>
                      {companyValue?.companyName?.label}
                    </span>
                }

              </div>
            )
          }
          {brands[0].scopes[0] === "all" ? (
            <div className="Second_Header_Logo"></div>
          ) : (
            <div className="user-nav">
              <div className='user-nav__user' onClick={location === 'dashboard' ? brands.length === 1 ? (e) => e.preventDefault() : changeallBrand : (e) => e.preventDefault()} >
                <img src={companyValue?.companyName !== "" ? companyLogo : decodetoken?.company_log} className='user-nav__user_photo' />
              </div>



              <div className='user-nav__icon-box'>
                {user == "broker" ?

                 branddropdown === undefined || branddropdown === null || branddropdown.length === 0 || branddropdown === []  || branddropdown === ""
                 ?
                   <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        className={"margin-left-spinner"}
                      />
                 :
                  (branddropdown &&
                    <DropdownButton
                      title={selectedbrand.length > 0 ? selectedbrand[0].name : 'All Brands'}
                      id="dropdown-menu-align-right"
                      onSelect={changeBrands}
                      disabled={(disableLocationarr.includes(location)) ? true : false}>
                      {
                        branddropdown.length > 0 ?
                          branddropdown.map((brand, index) => {
                            return (
                              <Dropdown.Item eventKey={brand.brand_name} data-guid={brand.brand_name} key={index}>{brand.brand_name}</Dropdown.Item>
                            )
                          })
                          : ''
                      }

                    </DropdownButton>

                  ) :
                  
                  (
                    <DropdownButton
                      title={brands.length === 1 ? brands[0].name : selectedbrand.length > 0 ? selectedbrand[0].name : 'All Brands'}
                      id="dropdown-menu-align-right"
                      onSelect={changeBrands}
                      disabled={(disableLocationarr.includes(location)) || (brands.length === 1) ? true : false}>
                      {
                        brands.length > 1 ?
                          brands.map((brand, index) => {
                            return (
                              <Dropdown.Item eventKey={brand.name} data-guid={brand.name} key={index}>{brand.name}</Dropdown.Item>
                            )
                          })
                          : ''
                      }

                    </DropdownButton>
                  )
                }

              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
};

export default SecondHeader;