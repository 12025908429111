import React from "react";
import { Link } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import { toast } from "react-toastify";
import ls from "local-storage";
import { Dropdown, DropdownButton } from 'react-bootstrap';

import ProductMoment from "../../Assets/images/product-movement.svg";
import ProductChart from "../../Components/Chart/ProductChart";
import CompanyProductChart from "../../Components/Chart/CompanyProductChart";

import Overview from "./Overview/Overview";
import CompanyOverview from "./Overview/CompanyOverview";

import SignInmodal from "../../Components/Common/SignInmodal";

import "react-toastify/dist/ReactToastify.css";
import $ from "jquery";
import * as CONFIG from "../../Components/_config";
import axios from "axios";

import { postQuery, getBrandCategory, getBrandStore, getListOfCoupon, getUPCbyCategory } from "../../API/DashBoard";
import { getCompany, getListOfCompanyCoupon, getCompanyBrandStore } from "../../API/Company";
import { getDeal, getCompanyDeal } from "../../API/CreateDeal";

import Coupon from "./Coupon/Coupon";
import BrandCoupon from "./Coupon/BrandCoupon";

import BrandDeal from "./Deal/index";
import CompanyDeal from "./Deal/CompanyDeal";

import UserSaleSummary from "./Gus/UserSalesSummary";
import CompanySalesSummary from "./Gus/CompanySalesSummary";
import Summary from "./Summary";
import DashBoardFooter from "./DashboardFooter";
import StoreByState from "./Store/StoreByState";
import StoreByStateCompany from "./Store/StoreByStateCompany";
import Header from "./Header";
import ConfirmationModal from "../../Components/Common/ConfirmationModal/ConfirmationModal";
import ProductMovementModal from "../../Components/Common/ProductMovementModal";
import ErrorModal from "../../Components/Common/Company/ErrorModal";

//const Loader = () => <div id="cover-spin"></div>;

class Content extends React.Component {

  constructor(props) {

    super(props);
    toast.configure();
    this.state = {
      StoresDetails: {},
      storeLoader: true,
      upcarray: [],
      companyupcarray: [],
      UpcList: "",
      ListOfCoupons: "",
      couponLoader: true,
      wholesalerarray: [],
      wholesalerArray: "",
      retailerarr: "",
      summaryLoader: true,
      wholesalerfeedDetails: "",
      retailerfeed: [],
      alertBox: true,
      title: 'All Brands',
      dropdownID: 0,
      dropDownName: "All",
      brandCategorylist: [],
      productMovementType : "1"
    };

    this.expireBrandcoupon = this.expireBrandcoupon.bind(this)
  }

  async componentDidMount() {
    const selectedbrand = ls.get("selectedbrand") ? ls.get("selectedbrand") : "";
    const user = ls.get("user") ? ls.get("user") : "wholesaler"
    //console.log(user)
    if (!!selectedbrand && selectedbrand.length > 0) {

      await getCompany().then((response) => {

        localStorage.removeItem("companyData")
        ls.set('companyData', response.Message);

        response.Message.CompanyUPCs.map((val, idx) =>
          this.state.companyupcarray.push(val.upc11)
        );

        var filter = "brand_name";
        var keyword = selectedbrand[0].name;
        const dropdownSelectbrand = response.Message.brand.length > 0 ? response.Message.brand.filter(function (obj) {
          return obj[filter] === keyword;
        }) : '';


        this.setState({
          retailerfeed: [],
          upcarray: [],
          wholesalerArray: [],
          retailerarr: [],
          StoresDetails: [],
          storeLoader: true,
          summaryLoader: true,
          UpcList: '',
          showOverview: false,
          wholesalerarray: [],

        })
        const upcarray = [];
        dropdownSelectbrand[0].upc.map((val, idx) =>
          upcarray.push(val.upc11)
        );
        var retailer = [];
        for (const obj of dropdownSelectbrand[0].retailers) {
          retailer.push(parseInt(obj));
        }
        if (dropdownSelectbrand[0].wholesalers.length > 0) {
          dropdownSelectbrand[0].wholesalers.map((val, idx) => {
            return val.has_full_access === true
              ? this.state.wholesalerarray.push(val.WholesalerNumber)
              : "";
          });
        }
        this.setState(
          {
            wholesalerArray: this.state.wholesalerarray,
            UpcList: upcarray,
            retailerarr: retailer,
            summaryLoader: false,
            showOverview: true
          },
          () => {
            this.getFeed();
            this.getBrandStore(selectedbrand[0].guid);
            this.getOverViewDetails();
            this.getFeedTotalSales();
            this.getProductChart();
            this.getListOfCoupon(selectedbrand[0].guid)
            this.getBrandCategory(selectedbrand[0].guid)
            this.getDeal(selectedbrand[0].guid)
          }
        );
      })
    }


    else {

      await getListOfCompanyCoupon().then((response) => {
        this.setState({
          ListOfCoupons: response.Message.entries,
          couponLoader: false
        });

      });


      await getCompanyDeal().then((response) => {
        this.setState(
          {
            dealData: response.Message.entries,
          },
        );
      });

      await getCompany().then((response) => {

        if (response.status === 504) {

          $("#btnCompanyModal").trigger("click");

        }

        else {
          response.Message.CompanyUPCs.map((val, idx) =>
            this.state.companyupcarray.push(val.upc11)
          );
          localStorage.removeItem("companyData")
          ls.set('companyData', response.Message)
          this.setState({
            companyData: response.Message,
            storeLoader: false,
            summaryLoader: false,
            loader: false,
            companyUpcList: this.state.companyupcarray
          })
        }
      })


      await getCompanyBrandStore().then((response) => {
        this.setState({
          companyStore: response.Message
        });
      });

    }

  }

  AlertModal = (e, coupon_id) => {
    e.preventDefault();
    $("#confirmModal").trigger("click");
    this.setState({ couponId: coupon_id });
  };

  showLoader = () => {
    this.setState({ loading: true });
  }

  hideLoader = () => {
    this.setState({ loading: false });

  };

  numberWithCommas(number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  expireBrandcoupon(cid) {

    var c_guid = cid;
    $("#staticBackdrop").css("display", "none");
    $(".modal-backdrop").first().css("display", "none");
    this.showLoader();
    var config = {
      method: "get",
      url: CONFIG.API_URL + `coupon/expire/${c_guid}`,
      headers: CONFIG.config_head.headers
    };

    axios(config)
      .then((response) => {
        if (response.status === 204) {

          this.hideLoader();
          toast.success("Expired Succesfully");

          setTimeout(() => window.location.reload(), 2500)

        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 502) {
            this.hideLoader();
            toast.error("Bad Request!");
          } else if (error.response.status === 403) {
            this.hideLoader();
            toast.error(error.response.data.Message);
            ls.clear('brands');
            ls.clear('selectedbrand');
            window.location.href = '/login';

          } else {
            this.hideLoader();
            toast.error(error.response.data.Message);
            console.log(error);
          }
        }
      });
  }

  handleChangeBrands = (e, value) => {
    this.setState({
      graphData: "",
      dropDownName: "All"
    });
    this.setState({ title: e });

    var companyFilter = "brand_name";
    var companykeyword = e;
    const lscompanyData = ls.get('companyData') ? ls.get('companyData') : '';

    const companySelectbrand = lscompanyData.brand.length > 0 ? lscompanyData.brand.filter(function (obj) {
      return obj[companyFilter] === companykeyword;
    }) : '';


    const brands = ls.get('brands') ? ls.get('brands') : '';
    var filter = "name";
    var keyword = e;
    const dropdownSelectbrand = brands.length > 0 ? brands.filter(function (obj) {
      return obj[filter] === keyword;
    }) : '';

    if (!!dropdownSelectbrand) {

      this.setState({
        // retailerfeed: [],
        // upcarray: [],
        // wholesalerArray: [],
        retailerarr: [],
        StoresDetails: [],
        storeLoader: true,
        summaryLoader: true,
        UpcList: '',
        showOverview: false,
        wholesalerarray: [],
        loader: true
      });


      localStorage.removeItem("selectedbrand")
      ls.set('selectedbrand', dropdownSelectbrand);

      const upcarray = [];
      companySelectbrand[0].upc.map((val, idx) =>
        upcarray.push(val.upc11)
      );
      var retailer = [];
      for (const obj of companySelectbrand[0].retailers) {
        retailer.push(parseInt(obj));
      }

      if (companySelectbrand[0].wholesalers.length > 0) {
        companySelectbrand[0].wholesalers.map((val) => {
          return val.has_full_access === true
            ? this.state.wholesalerarray.push(val.WholesalerNumber)
            : "";
        });
      }
      this.setState(
        {
          wholesalerArray: this.state.wholesalerarray,
          UpcList: upcarray,
          retailerarr: retailer,
          summaryLoader: false,
          showOverview: true
        },
        () => {
          this.getFeed();
          this.getBrandStore(companySelectbrand[0].brand_guid);
          this.getOverViewDetails();
          this.getFeedTotalSales();
          this.getProductChart();
          this.getListOfCoupon(companySelectbrand[0].brand_guid)
          this.getBrandCategory(companySelectbrand[0].brand_guid)
          this.getDeal(companySelectbrand[0].brand_guid)
        }
      );
    }
  }

  async getFeed() {
    let endDate = new Date().toISOString().split("T")[0];
    let startDate = new Date(new Date().valueOf() - 90 * 24 * 60 * 60 * 1000)
      .toISOString()
      .split("T")[0];

    await postQuery({
      wholesalerArray: this.state.wholesalerArray,
      UpcList: this.state.UpcList,
      retailerarr: this.state.retailerarr,
      endDate: endDate,
      startDate: startDate,
      type: "GUS",
      orderBy: "calMonthYear"
    }).then((response) => {


      if (response.status === 400 || response.status === 403) {
        this.setState({
          storeLoader: false,
          retailerfeed: [],
        })
      }
      else {
        this.setState({
          retailerfeed: response.Message.DisplayResult
        },
          () => this.getStoreDetails()
        );
      }
    });

  }

  async getStoreDetails() {
    var today = new Date().toISOString().split("T")[0];
    var dateParts = today.split("-");
    dateParts[0] = parseInt(dateParts[0], 10) - 1;
    var pastYear = dateParts.join("-");
    await postQuery({
      wholesalerArray: this.state.wholesalerArray,
      UpcList: this.state.UpcList,
      retailerarr: this.state.retailerarr,
      endDate: today,
      startDate: pastYear,
      type: "Stores",
      orderBy: "QtySOld"
    }).then((response) => {


      if (response.status === 400 || response.status === 403) {
        this.setState({
          StoresDetails: {},
          storeLoader: false
        });
      }
      else {
        this.setState({
          StoresDetails: response.Message,
          storeLoader: false
        });
      }
    });
  }

  async getBrandStore(brandguid) {

    await getBrandStore({
      guid: brandguid,
    }).then((response) => {

      this.setState({
        subscribeStore: response.Message
      });
    })

  };

  async getOverViewDetails() {
    var today = new Date().toISOString().split("T")[0];
    var dateParts = today.split("-");
    dateParts[0] = parseInt(dateParts[0], 10) - 1;
    var pastYear = dateParts.join("-");

    await postQuery({
      wholesalerArray: this.state.wholesalerArray,
      UpcList: this.state.UpcList,
      retailerarr: this.state.retailerarr,
      endDate: today,
      startDate: pastYear,
      type: "Overview",
      orderBy: "QtySOld"
    }).then((response) => {

      if (response.status === 400 || response.status === 403) {
        this.setState({
          OverviewDetails: [],
          loader: false
        });
      }
      else {
        this.setState({
          OverviewDetails: response.Message[0],
          loader: false
        });
      }
    });
  }

  async getFeedTotalSales() {

    var endDate = new Date().toISOString().split("T")[0];
    var dateParts = endDate.split("-");
    dateParts[0] = parseInt(dateParts[0], 10) - 1;
    var startDate = dateParts.join("-");

    await postQuery({
      wholesalerArray: this.state.wholesalerArray,
      UpcList: this.state.UpcList,
      retailerarr: this.state.retailerarr,
      endDate: endDate,
      startDate: startDate,
      type: "GUS",
      orderBy: "calMonthYear"
    }).then((response) => {
      if (response.status === 400 || response.status === 403) {
        this.setState({
          feedresult: [],

        });
      }
      else {

        this.setState(
          {
            feedresult: response.Message.DisplayResult
          },

        );
      }
    });
  }

  async getProductChart() {
    if (this.state.UpcList.length === 0) {
      let emptyGrpah = { series: [], xaxis: { type: "Wholesaler", categories: [] } };
      this.setState({
        graphData: emptyGrpah
      });
    }
    else {

      let data = {};
      let today = new Date().toISOString().split("T")[0];
      let dateParts = today.split("-");
      dateParts[0] = parseInt(dateParts[0], 10) - 1;
      let pastYear = dateParts.join("-");

      if (this.state.wholesalerArray.length > 0) {
        data = {
          type: "PMByWS",
          wholesaler: this.state.wholesalerArray
        };
      } else {
        data = {
          type: "PMByR",
          wholesaler: []
        };
      }
      await postQuery({
        wholesalerArray: data.wholesaler,
        UpcList: this.state.UpcList,
        retailerarr: this.state.retailerarr,
        endDate: today,
        startDate: pastYear,
        type: data.type,
        orderBy: "calMonthYear"
      }).then((res) => {
        this.setState({
          graphData: res.Message
        });
      })
    }
  }

  async getListOfCoupon(brandguid) {
    await getListOfCoupon({
      guid: brandguid,
    }).then((response) => {
      this.setState(
        {
          ListOfBrandCoupons: response.Message.entries,
          couponLoader: false
        },
      );
    });
  }

  async getDeal(brandguid) {

    await getDeal({
      guid: brandguid,
    }).then((response) => {

      this.setState(
        {
          dealData: response.Message.entries,
        },
      );
    });
  }



  async getBrandCategory(brandguid) {
    await getBrandCategory(brandguid).then((response) => {
      this.setState(
        {
          brandCategorylist: response.Message,
        },


      );
    });

  }

  handleChangeallBrands = (e, value) => {
    ls.remove('selectedbrand');
    //return;
    ls.set('selectedbrand', [])

    this.setState({
      loader: true,
      brandCategorylist: []
    })


    const companyupcarray = [];
    const companyData = ls.get('companyData') ? ls.get('companyData') : [];

    //console.log('companyData',companyData);return;

    companyData.CompanyUPCs.map((val, idx) => {
      return (
        companyupcarray.push(val.upc11)
      )
    });

    this.setState({
      subscribeStore: [],
      feedresult: [],
      OverviewDetails: [],
      companyData: companyData,
      storeLoader: false,
      summaryLoader: false,
      loader: false,
      companyUpcList: companyupcarray
    });


    // getCompany().then((response) => {

    //   response.Message.CompanyUPCs.map((val, idx) =>
    //     companyupcarray.push(val.upc11)

    //     );

    //   localStorage.removeItem("companyData")
    //   ls.set('companyData', response.Message)

    //   this.setState({
    //     subscribeStore: [],
    //     feedresult: [],
    //     OverviewDetails: [],
    //     companyData: response.Message,
    //     storeLoader: false,
    //     summaryLoader: false,
    //     loader: false,
    //     companyUpcList: companyupcarray
    //   })
    // })

    getListOfCompanyCoupon().then((response) => {
      this.setState({
        ListOfCoupons: response.Message.entries,
        couponLoader: false
      });
    })

    getCompanyDeal().then((response) => {
      this.setState(
        {
          dealData: response.Message.entries,
        },
      );
    });

    getCompanyBrandStore().then((response) => {
      this.setState({
        companyStore: response.Message
      });
    });
  }

  handleCategoryClick = (e) => {
    this.setState({
      graphData: ""
    });
    const selectedBrandguid = ls.get("selectedbrand") ? ls.get("selectedbrand")[0].guid : "";
    this.setState({
      dropdownID: e
    })
    getUPCbyCategory(selectedBrandguid, e).then((response) => {
      const categoryUPCarray = [];
      if (response.Message.length > 0) {
        response.Message.map((val) =>
          categoryUPCarray.push(val.upc11)
        );
      }
      this.setState(
        {
          UpcList: categoryUPCarray
        },
        () => {
          this.getProductChart();
        }
      );
    });

  }
  changeProductMovementType =(e) => {
    this.setState({
      productMovementType: e.target.value
    });
  }
  render() {
    const selectedbrand = ls.get("selectedbrand") ? ls.get("selectedbrand") : [];
    const brands = ls.get('brands') ? ls.get('brands') : [];

    return (
      <>
        <Header changebrandDashboard={this.handleChangeBrands} title={this.state.title}
          onchangeallBrands={this.handleChangeallBrands} location={`dashboard`} />

        <section className="ContentBlock pt-40 pb-24 Main_Block">
          <SignInmodal />

          <button
            id="mysigninModal"
            data-bs-toggle="modal"
            data-bs-target="#signinModal"
            style={{ display: "none" }}
          >
            Modal
          </button>

          <div className="container-fluid">
            <div className="row g-3">
              <div className="col-xl-8 col-12">
                <div className="row g-3">
                  <div className="col-xxl-7 col-xl-6 col-12" style={{ width: '56.333333% !important' }}>
                    <div className="Card Overview">
                      <div className="Card_Header">
                        <h5>Overview</h5>
                        <div className="Card_Header_Right">
                          <span>Data from last 365 days</span>

                        </div>
                      </div>
                      <div className="Card_Body">
                        {
                          selectedbrand.length > 0 ?
                            this.state.subscribeStore && this.state.OverviewDetails && this.state.feedresult ?
                              <Overview
                                subscribeStore={this.state.subscribeStore}
                                OverviewDetails={this.state.OverviewDetails}
                                feedresult={this.state.feedresult}
                                loader={this.state.loader}
                              />
                              : (
                                <div
                                  className="Clip_Loader"
                                  style={{ minHeight: "280px" }}
                                >
                                  <ClipLoader
                                    color={"rgb(0, 143, 251)"}
                                    loading={true}
                                    size={50}
                                  />
                                </div>
                              ) :
                            this.state.companyData ? (
                              <CompanyOverview
                                wholesalerArray={this.state.wholesalerArray}
                                UpcList={this.state.UpcList}
                                retailerarr={this.state.retailerarr}
                                companyData={this.state.companyData}
                                companyStore={this.state.companyStore}
                                laoder={this.state.loader}
                              />
                            ) : (
                              <div
                                className="Clip_Loader"
                                style={{ minHeight: "280px" }}
                              >
                                <ClipLoader
                                  color={"rgb(0, 143, 251)"}
                                  loading={true}
                                  size={50}
                                />
                              </div>
                            )
                        }
                      </div>
                    </div>
                  </div>

                  <Summary title={this.state.title} selectedbrand={selectedbrand} />

                  <div className="col-12">
                    <div className="Card Product_Movement">
                      <div className="Card_Header d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center">
                          <div className="card_header_title d-flex align-items-center">
                          <img
                            src={ProductMoment}
                            className="img-fluid"
                            alt="Digial Coupons"
                          />
                          <h5>Product Movement</h5>
                          </div>
                          <div className="category_div" style={{ margin: '0 auto', padding: '1px 10px' }}>
                            {
                              this.state.brandCategorylist.length > 0 ?
                                <>
                                  <DropdownButton
                                    title={this.state.brandCategorylist.length === 1 ? brands[0].name : this.state.dropDownName}
                                    id="dropdown-menu-align-right"
                                    value={this.state.dropdownID}
                                    onSelect={
                                      (e) => {
                                        e === "All"
                                          ?
                                          this.setState({
                                            dropDownName: "All"
                                          }, () => {
                                            this.handleChangeBrands(selectedbrand.length > 0 ? selectedbrand[0].name : brands[0].name, "Upper")
                                          })
                                          :
                                          this.state.brandCategorylist &&
                                          this.state.brandCategorylist.filter(data => e === data.upc_category_id.toString()).map((info, index) => {
                                            this.setState({
                                              dropDownName: info.category_name
                                            }, () => {
                                              this.state.dropDownName && this.state.dropDownName !== "All" ? this.handleCategoryClick(e) : this.handleChangeBrands(selectedbrand.length > 0 ? selectedbrand[0].name : brands[0].name, "Neeche")
                                            })
                                          })
                                      }
                                    }
                                    disabled={this.state.brandCategorylist.length === 1 ? true : false}
                                  >
                                    {
                                      this.state.dropDownName !== "All" &&
                                      <Dropdown.Item
                                        eventKey={"All"}
                                        data-guid={"All"}
                                      >
                                        All
                                      </Dropdown.Item>
                                    }
                                    {
                                      this.state.brandCategorylist &&
                                      this.state.brandCategorylist.map((category, index) => {
                                        return (
                                          <Dropdown.Item
                                            eventKey={category.upc_category_id}
                                            data-guid={category.category_name}
                                            key={index}
                                          >
                                            {category.category_name}
                                          </Dropdown.Item>
                                        )
                                      })
                                    }
                                  </DropdownButton>
                                </>
                                :
                                ""
                            }
                          </div>
                          <div className="category_selection">
                          <select className="form-select" 
                                    value={this.state.productMovementType}
                                    onChange={(e) => this.changeProductMovementType(e)} id="productMovementType" >
                                    <option value="1">Amount</option>
                                    <option value="2">Quantity</option>
                                    <option value="3">Shopper</option>
                                  </select>
                          </div>
                        </div>

                        <div className="Card_Header_Right">
                        </div>
                      </div>
                      <div className="Card_Body">
                        {

                          selectedbrand.length > 0 ?

                            this.state.graphData ? (
                              <ProductChart
                                graphData={this.state.graphData}
                                productMovementType={this.state.productMovementType}
                              />
                            ) : (
                              <div className="Clip_Loader">
                                <ClipLoader
                                  color={"rgb(0, 143, 251)"}
                                  loading={true}
                                  size={50}
                                />
                              </div>
                            )
                            :
                            this.state.companyData && this.state.companyupcarray ? (
                              <CompanyProductChart
                                wholesalerArray={this.state.companyData.CompanyWS}
                                UpcList={this.state.companyUpcList}
                                retailerarr={this.state.companyData.CompanyRet}
                              />
                            ) : (
                              <div className="Clip_Loader">
                                <ClipLoader
                                  color={"rgb(0, 143, 251)"}
                                  loading={true}
                                  size={50}
                                />
                              </div>
                            )
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>


              <div className="col-xl-4 col-12">
                <div className="Card Stores h-auto">
                  <div className="Card_Header d-flex align-items-center justify-content-between">
                    <h5>User Sales Summary</h5>
                    <span className="text-white"><i className="text-lowercase">(Last 90 Days)</i></span>
                  </div>
                  {
                    selectedbrand.length > 0 ?
                      this.state.retailerfeed ? (
                        <UserSaleSummary retailerfeed={this.state.retailerfeed} summaryLoader={this.state.summaryLoader} retailerarr={this.state.retailerarr} />
                      )
                        : (
                          <div className="Clip_Loader">
                            <ClipLoader
                              color={"rgb(0, 143, 251)"}
                              loading={true}
                              size={50}
                            />
                          </div>
                        )
                      :
                      this.state.companyData ? (

                        <CompanySalesSummary companyData={this.state.companyData} summaryLoader={this.state.summaryLoader} retailerarr={this.state.retailerarr} />

                      )
                        : (
                          <div className="Clip_Loader">
                            <ClipLoader
                              color={"rgb(0, 143, 251)"}
                              loading={true}
                              size={50}
                            />
                          </div>
                        )

                  }
                </div>

                <div className="Card Stores h-auto">
                  <div className="Card_Header">
                    <h5>Stores By State</h5>
                  </div>
                  {
                    selectedbrand.length > 0 ?
                      this.state.StoresDetails ? (
                        <StoreByState StoresDetails={this.state.StoresDetails} storeLoader={this.state.storeLoader} />
                      )
                        : (
                          <div className="Clip_Loader">
                            <ClipLoader
                              color={"rgb(0, 143, 251)"}
                              loading={true}
                              size={50}
                            />
                          </div>
                        )
                      :
                      this.state.companyData ? (
                        <StoreByStateCompany companyData={this.state.companyData} storeLoader={this.state.storeLoader} />
                      ) : (
                        <div className="Clip_Loader">
                          <ClipLoader
                            color={"rgb(0, 143, 251)"}
                            loading={true}
                            size={50}
                          />
                        </div>
                      )
                  }
                </div>

              </div>
            </div>

            {
              selectedbrand.length > 0 ?
                this.state.ListOfBrandCoupons && (
                  <BrandCoupon ListOfBrandCoupons={this.state.ListOfBrandCoupons} couponLoader={this.state.couponLoader}
                    AlertModal={this.AlertModal} />
                )
                :
                this.state.ListOfCoupons && (
                  <Coupon ListOfCoupons={this.state.ListOfCoupons} couponLoader={this.state.couponLoader}
                    AlertModal={this.AlertModal} />
                )
            }

            {
              selectedbrand.length > 0 ?
                this.state.dealData && (
                  <BrandDeal dealData={this.state.dealData} />
                )
                :
                this.state.dealData && (
                  <CompanyDeal dealData={this.state.dealData} />
                )
            }

            <DashBoardFooter />
          </div>

          {/* BEGIN: PRODUCT MOVEMENT MODAL */}

          <ProductMovementModal />

          {/* BEGIN: STATICBACKDROP MODAL */}

          <button
            className="btn btn-success"
            data-bs-toggle="modal"
            data-bs-target="#staticBackdrop"
            id="confirmModal"
            style={{ display: "none" }}
          >
            Alert
          </button>
          <ConfirmationModal couponId={this.state.couponId} expireBrandcoupon={this.expireBrandcoupon} />

          <button className="btn btn-success" data-bs-toggle="modal" data-bs-target="#errorComapnyModal" id="btnCompanyModal" style={{ display: "none" }}>
            Alert
          </button>

          <ErrorModal />

          {/* END: STATICBACKDROP MODAL */}
        </section>
      </>
    );
  }
}
export default Content;
