import React from 'react';
import * as CONFIG from "../../Components/_config";
import axios from "axios";
import { toast } from "react-toastify";
import ls from "local-storage";
import $ from "jquery";
import "react-toastify/dist/ReactToastify.css";
import EditbrokerModal from "../../Components/Common/EditbrokerModal";
const Loader = () => <div id="cover-spin"></div>;

class EditUserModal extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      formControls: {},
      edit_user_data: {}
    }
    this.handleSave = this.handleSave.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
  }


  componentDidMount() {

    var guid = ls.get("selectedbrand") ? ls.get("selectedbrand")[0].guid : "";
    const token_config = {
      headers: CONFIG.config_head.headers
    };

    const requestOne = axios.get(
      CONFIG.API_URL + `master/${guid}?type=role&search=''`,
      token_config
    );

    axios
      .all([requestOne])
      .then(
        axios.spread((...responses) => {
          let responseOne = responses[0];
          this.setState({
            masterRoles: responseOne.data.Message,
          });

        })
      )
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 400) {
            toast.error(error.response.data.Message);
          }
          else if (error.response.status === 403) {
            toast.error(error.response.data.Message);
            ls.clear("token");
            ls.clear("brands");

            window.location.href = "/login";
          } else {
            toast.error(error.response.data.Message);
          }
        }
      });
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      allRetailer: nextProps.allRetailer,
      brandRetailer: nextProps.brandRetailer,
      brandWholesaler: nextProps.brandWholesaler,
      allWholesaler: nextProps.allWholesaler,
    });
  }

  changeHandler = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    if (value === 'broker') {


      $("#wholesalerselectcheckbox").prop("checked", false)

      this.setState({ role: '' });
      this.setState({
        modalclick: true,
        formControls: {
          ...this.state.formControls,
          [name]: value
        }
        // ...this.state.formControls,
        // [name]: value
      })
      $("#subscribemodal").trigger("click");
    }
    else {
      this.setState({
        formControls: {
          ...this.state.formControls,
          [name]: value
        }
      });
    }
  };

  rolechangeHandler = (e) => {
    var text = $("#role option:selected").text();
    var selectValue = e.target.value;
    if (selectValue === '51371542-2364-4110-AEAB-2C4A03A2F2A7') {

      this.setState({ modalclick: true, hideButton: true, role_guid: selectValue, brand_job_title: text })

    }
    else {
      this.setState({ hideButton: false, role_guid: selectValue, brand_job_title: text })
    }
  }


  updateModalUser() {

    const item = this.state.formControls;
    var groups = Object.create(null);

    this.state.masterRoles.forEach(function (a) {
      groups[a.role_name] = groups[a.role_name] || [];
      groups[a.role_name].push(a);
    });



    var b_guid = ls.get("selectedbrand") ? ls.get("selectedbrand")[0].guid : "";

    if (item.role) {

      if ((item.primary_phone.trim().length > 10) || (item.primary_phone.trim().length < 10)) {

        toast.error("Phone No must be with a maximum length of 10", { theme: "colored" });
        return false;
      }

      else {

        var role_guid = groups[item.role ? item.role : this.state.edit_user_data.role][0].brandiq_user_role_guid;

        $("#exampleModal").css("display", "none");
        $(".modal-backdrop").first().css("display", "none");
        this.showLoader();

        var data = JSON.stringify({

          first_name: item.first_name ? item.first_name : this.state.edit_user_data.first_name,
          last_name: item.last_name ? item.last_name : this.state.edit_user_data.last_name,
          primary_phone: item.primary_phone ? item.primary_phone : this.state.edit_user_data.primary_phone,
          email: item.email ? item.email : this.state.edit_user_data.email,
          secondary_phone: "",
          role: role_guid,
          role_name: item.role ? item.role : this.state.edit_user_data.role,
          brand_job_title: item.role_name ? item.role_name : this.state.edit_user_data.role_name,
          state: 1

        });

        var config = {
          method: "put",
          url: CONFIG.API_URL + `user/${item.user_guid}/${b_guid}`,
          headers: CONFIG.config_head.headers,
          data: data
        };

        axios(config)
          .then((response) => {
            if (response.status === 201) {
              toast.success("Updated Successfully");
              this.hideLoader();
              setTimeout(() => window.location.reload(), 2500);
            }
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.status === 400) {
                this.hideLoader();
                toast.error(error.response.data.Message);
                setTimeout(
                  () => window.location.reload(), 2000
                );
              }
              else {
                this.hideLoader();
                toast.error(error.response.data.Message);
                setTimeout(
                  () => window.location.reload(), 2000
                );
              }
            }
          });

      }

    }

    else {

      role_guid = groups[item.role_name ? item.role_name : this.state.edit_user_data.role_name][0].brandiq_user_role_guid;

      $("#exampleModal").css("display", "none");
      $(".modal-backdrop").first().css("display", "none");
      this.showLoader();

      data = JSON.stringify({
        first_name: item.first_name ? item.first_name : this.state.edit_user_data.first_name,
        last_name: item.last_name ? item.last_name : this.state.edit_user_data.last_name,
        primary_phone: item.primary_phone ? item.primary_phone : this.state.edit_user_data.primary_phone,
        email: item.email ? item.email : this.state.edit_user_data.email,
        secondary_phone: "",
        role: role_guid,
        brand_job_title: item.role_name ? item.role_name : this.state.edit_user_data.role_name,
        state: 1

      });

      config = {
        method: "put",
        url: CONFIG.API_URL + `user/${item.user_guid ? item.user_guid : this.state.edit_user_data.user_guid}/${b_guid}`,
        headers: CONFIG.config_head.headers,
        data: data
      };
      axios(config)
        .then((response) => {
          if (response.status === 201) {
            toast.success("Updated Successfully");
            this.hideLoader();
            setTimeout(() => window.location.reload(), 2500);
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 400) {
              this.hideLoader();
              toast.error(error.response.data.Message);
              setTimeout(
                () => window.location.reload(), 2000
              );
            }
            else {
              this.hideLoader();
              toast.error(error.response.data.Message);
              setTimeout(
                () => window.location.reload(), 2000
              );
            }
          }
        });

    }
  }

  componentDidUpdate(prevProps, nextProps) {
    if (prevProps !== this.props) {

      this.setState({
        edit_user_data: prevProps.edit_user_modal_data,
      });
    }
  }


  handleSave() {
    const item = this.state;
    this.props.updateModalUser(item)
  }

  showLoader = () => {
    this.setState({ loading: true });
  }
  hideLoader = () => {

    this.setState({ loading: false });

  };

  render() {

    return (
      <>
        {this.state.loading ? <Loader /> : null}

        <div className="modal fade" id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" data-bs-backdrop="static"
          data-bs-keyboard="false"
          aria-hidden="true">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">Edit User</h5>
                <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <p><span className="modal-lable">First Name:</span>

                  <input defaultValue={this.state.edit_user_data.first_name} className="form-control"
                    name="first_name" onChange={this.changeHandler} /></p>

                <p><span className="modal-lable">Last Name:</span>

                  <input defaultValue={this.state.edit_user_data.last_name} className="form-control"
                    name="last_name" onChange={this.changeHandler} /></p>

                <p><span className="modal-lable">Email:</span><input className="form-control"
                  value={this.state.edit_user_data.email} onChange={this.changeHandler} disabled={true} /></p>

                <p><span className="modal-lable">Phone:</span>

                  <input className="form-control" defaultValue={this.state.edit_user_data.primary_phone} name="primary_phone" onChange={this.changeHandler} /></p>

                <p><span className="modal-lable">Role:</span></p>

                <select className="form-select" aria-label="Default select example" id="role" name="role" onChange={this.changeHandler} disabled={`true`}>

                  {this.state.masterRoles ?
                    <>
                      {this.state.role_name === 'broker' ?
                        this.state.masterRoles.map((val, idx) => {
                          return (val.role_name !== 'rsa admin') && (val.role_name !== 'broker') ?
                            <option defaultValue={val.brandiq_user_role_guid}>{val.role_name}</option>
                            : ''
                        })
                        : 
                        this.state.masterRoles.map((val, idx) => {
                          let select = val.role_name === this.state.edit_user_data.role_name ? 'selected' : ''
                          return val.role_name !== 'rsa admin' ?
                            <option defaultValue={val.brandiq_user_role_guid} selected={select} key={idx}>{val.role_name}</option>
                            :''
                        })
                      }
                    </>
                    : ''
                  }
                </select>

                <input type="hidden" className="form-control"
                  value={this.state.edit_user_data.user_guid} onChange={this.changeHandler} />
              </div>

              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                <button type="button" className="btn Btn_blue w-fit-content px-5 py-2 rounded-2" onClick={() => { this.updateModalUser() }}>Save Changes</button>
              </div>
            </div>
          </div>
          
          {
            this.state.allWholesaler && (
              <>
                <button className="Digital_Brand_Button approve" data-bs-toggle="modal" id="subscribemodal" data-bs-target="#subscribe-sel-drop"
                  style={{ display: "none" }}>
                  Add
                </button>

                <EditbrokerModal
                  allRetailer={this.state.allRetailer}
                  brandRetailer={this.state.brandRetailer}
                  brandWholesaler={this.state.brandWholesaler}
                  allWholesaler={this.state.allWholesaler}
                  formControl={this.state.edit_user_data}
                  role_guid={this.state.role_guid}
                  brand_job_title={this.state.brand_job_title}
                  allRoles={this.state.masterRoles}
                  formControlinfo={this.state.formControls}
                />
              </>
            )}
        </div>
      </>
    );
  }
}

export default EditUserModal;
