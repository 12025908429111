import React from "react";

import Header from "../../Pages/Dashboard/Header";

import CardImage from "../../Assets/images/card.svg";
//import Celebration from "../../Assets/images/celebration.png";
import { Link } from "react-router-dom";

 
//import { render } from "sass";

class Confirmdealmessage extends React.Component {
  // React.useEffect(() => {
  //   // document.body.style.background="black"
  // }, []);

  render() {
    return (
      <>
        <Header />
        <section className="Confirmation_Message mt-160">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="Common_Block">
                  <div className="Common_Block_Body pr-62 pl-62 pt-62 pb-62 radius-5 text-center CelebrationBg">
                    {/* <img src={Celebration} alt="Celebration" className='img-fluid' /> */}
                    <span>Deal will activated in</span>
                    <h4>24 hours</h4>
                    <img src={CardImage} alt="" className="img-fluid mt-30" />
                    <ul className="d-flex CountDown align-items-center justify-content-center mt-25">
                      {/* <li>
                      <span id="hours">00</span>Hours
                    </li>
                    <span className="separator">:</span>
                    <li>
                      <span id="minutes">00</span>Minutes
                    </li>
                    <span className="separator">:</span>
                    <li>
                      <span id="seconds">00</span>Seconds
                    </li> */}
                    </ul>
                    <Link to="/listdeal">
                      <button className="Btn_blue mt-20">
                        Get To My Deals
                      </button>
                    </Link>

                    {/* <p className="Instruction">
                    Wooooo....Coupons distribution started, Click on the button
                    above!
                  </p> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default Confirmdealmessage;
