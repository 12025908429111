import React from "react";
class ItemRetailer extends React.Component {

  constructor(props) {
    super(props);
    this.state = { checkedListAll: [] }
  }
  componentDidUpdate(prevProps, nextProps) {
    if (prevProps !== this.props) {
      this.setState({
        checkedListAll: prevProps.checkedListAll,
       });
    }
  }
  
  render() {
    const {
      items,
      selectedItems,
    } = this.props;

    return (
      <>
        <li key={items.retailer_name}>
          <div className="Retailer_Block">
            <input
              type="checkbox"
              selectedItems={selectedItems}
              value={items.retailer_id}
              data-wholesaler_id = {items.wholesaler_id}
              checked={this.state.checkedListAll.some(el => el.retailer_id === items.retailer_id) }
              onChange={this.props.handleCheckboxClick}
            />
            <div className="RetailerImage">
              <img
                className="img-fluid"
                alt={items.retailer_name}
                src={
                  items.logo
                }
              />
            </div>
            <h5>
              {items.retailer_name}
            </h5>
          </div>
        </li>
      </>
    );
  }
}
export default ItemRetailer;