import ls from "local-storage";

let domain_name = "";
let axiosConfig = {};
var token = ls.get("token") ? ls.get("token") : "";
let scope_coupon_write = ls.get('brands') ? ls.get('brands')[0]['scopes'] : '';
let scope_user_write = ls.get('brands') ? ls.get('brands')[0]['scopes'] : '';

export const getHostName = (url) => {
  var match = url.match(/:\/\/(www[0-9]?\.)?(.[^/:]+)/i);
  if (
    match !== null &&
    match.length > 2 &&
    typeof match[2] === "string" &&
    match[2].length > 0
  ) {
    return match[2];
  } else {
    return null;
  }
}

const hostname = getHostName(window.location.href) || "";
if (
  window.location.hostname === "localhost" ||
  hostname === "dev.dcbzsxgtdyea0.amplifyapp.com"
) {
  domain_name = `https://api.brandiqapi.com/`;

  axiosConfig = {
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
      "x-api-key": "KNeEYFr1sCaTbAuDYhBi33UXmf3gzMtm7Cv0k95O"
    }
  };
} else {
  domain_name = `https://n7abjx8kn1.execute-api.us-east-1.amazonaws.com/Prod/`;

  axiosConfig = {
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    }
  };
}
export const API_URL = `${domain_name}`;
export const config_head = axiosConfig;
export const config_coupon_scope = scope_coupon_write;
export const config_user_scope = scope_user_write;

