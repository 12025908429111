import React from "react";
import CalenderSvgImage from "../../../Assets/images/calender.svg";
import CalenderSvgRedImage from "../../../Assets/images/calenderRed.svg";
import Blockbg from "../../../Assets/images/blockbg.png";
import Arrowsvg from "../../../Assets/images/arrow.svg";
import { Button, Form ,Spinner} from "react-bootstrap";
import {  DateFormatter } from "../../../Components/Helper";

  
class Preview extends React.Component {
    constructor() {
      super();
      this.state = {
        checkboxlength: ""
      };
    }
  
    render() {
      return (
        <React.Fragment>
        <div
        className="tab-pane fade"
        id="nav-step-5"
        role="tabpanel"
        aria-labelledby="nav-step-5-tab"
      >
        <div className="row  g-3 justify-content-center">
          <div className="col-12">
            <div className="Preview_Card Common_Block">
              <div className="Common_Block_Body">
                <h5>
                  <span>
                    Your offers update will be sent to retailers
                  </span>{" "}
                  <b>within</b>{" "}
                </h5>
                <h4>24 hours</h4>
                <div className="Preview_Outer_Block">
                  <ul className="Preview_Card_Row">
                    <li
                      className=" text-center Coupons_Image"
                      style={{ margin: "0 auto", width: "50%" }}
                    >
                      <div className="Coupons_Outer_Block">
                        <div className="Coupons_Card">
                          <img
                            src={
                              this.props.selectedFile
                                ? this.props.selectedFile
                                : Blockbg
                            }
                            className="img-fluid"
                            alt="Coupon"
                          />
                          <h2 className="Price">
                            {this.props.formControls.title}
                            <span></span>
                          </h2>
                          <p>
                            {this.props.formControls.description}
                          </p>
                        </div>
                      </div>
                    </li>
                  </ul>

                  <div className="row  ">
                    <div className="col-lg-6 col-12 mt-45">
                      <div className="date d-flex align-items-center ">
                        <Form.Group
                          className=""
                          controlId="exampleForm.ControlInput1"
                        >
                          <img
                            src={CalenderSvgImage}
                            className="img-fluid"
                            alt="Calender"
                          />

                          <Form.Label>
                            &nbsp;Start: &nbsp;
                          </Form.Label>

                          <span className="fw-bold">
                            {this.props.global_prev_start_date?this.props.global_prev_start_date
                              : DateFormatter(
                                  new Date(
                                    this.props.couponsDetails.coupon_start_date
                                  )
                                )
                              }
                          </span>
                        </Form.Group>

                        <Form.Group
                          className="ml-12"
                          controlId="exampleForm.ControlInput2"
                        >
                          <img
                            src={CalenderSvgRedImage}
                            className="img-fluid"
                            alt="Calender"
                          />

                          <Form.Label>
                            &nbsp; End: &nbsp;
                          </Form.Label>

                          <span className="fw-bold">
                            {" "}

                            {
                            this.props.global_prev_end_date?this.props.global_prev_end_date:
                             DateFormatter(
                                  new Date(
                                    this.props.couponsDetails.coupon_end_date
                                  )
                                )
                              }
                          </span>
                        </Form.Group>
                      </div>
                    </div>
                    {/* <div className="col-lg-6 col-12 mt-45">
                      <Link to="/wholesalerlist">
                        <Button
                          variant=""
                          type="button "
                          className=" Btn_blue mt-20 w-50 ms-auto"
                        >
                          Back To Coupon List
                          <img
                            src={Arrowsvg}
                            className="img-fluid float-end"
                            alt="Arrow"
                          />
                        </Button>
                      </Link>
                    </div> */}


                    <div className="col-lg-6 col-12 mt-45">
                                        <Form.Check
                                            type="checkbox"
                                            name="make_offer"
                                            label="By clicking, I understand that offer targets and dates cannot be changed after release and I confirm to release the offer. I also accept BrandIQ terms and condition"
                                            onChange={(e) =>
                                                this.props.onchangeoffercheckbox(e)
                                            }
                                        />

                                        <Button
                                            variant=""
                                            type="button"
                                            className=" Btn_blue mt-20 w-50 ms-auto"
                                            disabled={this.props.subscribeButtonLoader}
                                            onClick={this.props.retailersData.length<1 ?(e) => this.props.buildCoupon(e):(e) => this.props.buildCouponretailer(e) }
                                        >
                                            Save and Send Offer
                                            {
                                                this.props.subscribeButtonLoader ?
                                                    <Spinner
                                                        as="span"
                                                        animation="border"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                        className={"float-end"}
                                                    />
                                                    :
                                                    <img
                                                        src={Arrowsvg}
                                                        className="img-fluid float-end"
                                                        alt="Arrow"
                                                    />
                                            }
                                        </Button>

                                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </React.Fragment>
    
      )
} 
}

export default Preview;