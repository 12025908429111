import React from "react";
import { Button, Spinner } from "react-bootstrap";
import { Form, Row, Col } from "react-bootstrap";
import NextArrow from "../../../Assets/images/next-arrow.svg";
import PrevArrow from "../../../Assets/images/prev-arrow.svg";
import BrandImg from "../../../Assets/images/brandiq.png";
import { ToastContainer, toast } from "react-toastify";
import { useState, useEffect } from "react";
import { buildCouponMultiple } from "../../../API/CreateCoupon";

function Step4({
  handleStep,
  info,
  setInfo,
  retailerArray,
  couponWithDate,
  couponWithoutDate,
  setCouponWithDate,
  setCouponWithoutDate
}) {
  const [checked, setChecked] = useState(false);
  const [couponArray, setCouponArray] = useState([]);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const handleChecked = (e) => {
    setChecked(e.target.checked);
  };
  var mergedArray;
  const toastErrorMsg = () => {
    setButtonDisabled(false);
    toast.error("Please confirm the offer");
  };

  const handleCheckLength = () => {
    if (couponWithoutDate.length < 1) {
      setCouponWithDate([]);
      setCouponWithoutDate([]);
    }
    handleStep("previous");
  };

  const handleStepNext = () => {
    handleStep("next");
  };
  var isoStartDate;
  var isoEndDate;
  if (couponWithoutDate?.length > 0) {
    const max = retailerArray?.reduce(function (prev, current) {
      return prev.endDate > current.endDate ? prev : current;
    });

    const min = retailerArray?.reduce(function (prev, current) {
      return prev.startDate < current.startDate ? prev : current;
    });

    const startDateStr = min?.startDate;
    const startDateObj = new Date(startDateStr);
    isoStartDate = startDateObj.toISOString();

    const endDateStr = max?.endDate;
    const endDateObj = new Date(endDateStr);
    isoEndDate = endDateObj.toISOString();
  }

  //console.log("endDate", max.endDate);

  // console.log("startdate", min.startDate);

  useEffect(() => {
    //console.log(couponWithDate);
  }, []);
  const handleBuildCoupon = () => {
    setButtonDisabled(true);
    if (checked) {
      var retailer_arr = [];

      var retailer_arr2 = retailerArray
        .map((retailerArrayElement) => {
          const innerElements = retailerArrayElement?.retailer?.map(
            (innerElement) => ({
              retailer_id: innerElement.value,
              start_date: new Date(
                retailerArrayElement.startDate
              ).toISOString(),
              end_date: new Date(retailerArrayElement.endDate).toISOString(),
              shutoff_date: new Date(
                retailerArrayElement.endDate
              ).toISOString(),
              clip_limit: 1000000,
              redeem_limit: 0
            })
          );
          return innerElements;
        })
        .flat();
      // console.log("retailerArray",retailer_arr2);
      let updatedCouponArray;
      var CouponArray;
      var Wholesaler_arr = [
        {
          wholesaler_id: 5,
          start_date: "2023-04-26T00:00:00.000Z",
          end_date: "2023-05-26T00:00:00.000Z",
          shutoff_date: "2023-05-26T00:00:00.000Z",
          clip_limit: 1000000,
          redeem_limit: 0,
          retailer: retailer_arr
        }
      ];

      var Wholesaler_arr2 = [
        {
          wholesaler_id: 5,
          start_date: isoStartDate,
          end_date: isoEndDate,
          shutoff_date: isoEndDate,
          clip_limit: 1000000,
          redeem_limit: 0,
          retailer: retailer_arr2
        }
      ];
      if (couponWithDate?.length > 0) {
        updatedCouponArray = couponWithDate?.map((each) => ({
          // ...each,
          title: each[`Offer Title - WILL be on coupon (Include Amount Off, Purchase Quantity, Brand, Item, Size)`],
          description: each[`Offer Description`],
          offer_gs1: "",
          //reward_amount: 5,
          reward_amount: Number(each[`OfferValue`]),
          min_purchase_amount: 3,
          manufactuer_id: 0,
          coupon_category: 2,
          start_date: each[`Offer Clip Start Date (M/D/Y)`],
          end_date: each[`Offer Clip End Date (M/D/Y)`],
          shutoff_date: each[`Offer Clip End Date (M/D/Y)`],
          clip_limit: Number(each[`OfferMaxClip`]),
          redeem_limit: 0,
          clip_limit_amount: 0,
          redeem_limit_amount: 0,
          coupon_type: 1,
          reward_quantity: 0,
          min_quantity: Number(each[`MiniumumQtyRequired`]),
          coupon_code: "",
          is_flex: each[`# of Redemptions per Transaction`] >1 ? 0 :1,
          coupon_target_type_id: 1,
          is_discount_percentage: 0,
          max_reward_limit: 0,
          limit_per_customer: 1,
          addtional_details: each[`addtional_details`],
          disclaimer: each[`disclaimer`],
          image_1: each[`Image Name`]
            ? each[`Image Name`]
            : "https://s3.amazonaws.com/aogmedia/noimage.jpg",
          image_1_thumbnail: "string",
          media_url: "string",
          brand_guid: info.brand_guid,
          coupon_condition: {
            per_customer_limit: 1,
            per_transaction_limit: Number(
              each[`# of Redemptions per Transaction`]
            )
          },
          wholesalers: [
            {
              wholesaler_id: 5,
              start_date: each[`Offer Clip Start Date (M/D/Y)`],
              end_date: each[`Offer Clip End Date (M/D/Y)`],
              shutoff_date: each[`Offer Clip End Date (M/D/Y)`],
              clip_limit: 1000000,
              redeem_limit: 0,
              retailer: []
            }
          ],
          //upc: ["672CBFE1-8154-40F9-916D-81A13EC7729A"]
          upc: each[`UPC`].split(",")
        }));
      }
      let updatedCouponArray2;
      if (couponWithoutDate?.length > 0) {
        updatedCouponArray2 = couponWithoutDate.map((each) => ({
          title: each[`Offer Title - WILL be on coupon (Include Amount Off, Purchase Quantity, Brand, Item, Size)`],
          description: each[`Offer Description`],
          offer_gs1: "",
          //reward_amount: 5,
          reward_amount: Number(each[`OfferValue`]),
          min_purchase_amount: 3,
          manufactuer_id: 0,
          coupon_category: 2,
          start_date: isoStartDate,
          end_date: isoEndDate,
          shutoff_date: isoEndDate,
          clip_limit: Number(each[`OfferMaxClip`]),
          redeem_limit: 0,
          clip_limit_amount: 0,
          redeem_limit_amount: 0,
          coupon_type: 1,
          reward_quantity: 0,
          min_quantity: Number(each[`MiniumumQtyRequired`]),
          coupon_code: "",
          is_flex: each[`# of Redemptions per Transaction`] >1 ? 0 :1,
          coupon_target_type_id: 2,
          is_discount_percentage: 0,
          max_reward_limit: 0,
          limit_per_customer: 1,
          addtional_details: each[`addtional_details`],
          disclaimer: each[`disclaimer`],
          image_1: each[`Image Name`]
            ? each[`Image Name`]
            : "https://s3.amazonaws.com/aogmedia/noimage.jpg",
          image_1_thumbnail: "string",
          media_url: "string",
          brand_guid: info.brand_guid,
          coupon_condition: {
            per_customer_limit: 1,
            per_transaction_limit: Number(
              each[`# of Redemptions per Transaction`]
            )
          },
          wholesalers: Wholesaler_arr2,
          //upc: ["672CBFE1-8154-40F9-916D-81A13EC7729A"]
          upc: each[`UPC`].split(",")
        }));
      }

      const createMultipleCoupons = async () => {
        if (couponWithoutDate?.length > 0 && couponWithDate?.length > 0) {
          mergedArray = JSON.stringify(
            updatedCouponArray.concat(updatedCouponArray2)
          );
        } else if (couponWithoutDate?.length > 0) {
          mergedArray = JSON.stringify(updatedCouponArray2);
        } else {
          mergedArray = JSON.stringify(updatedCouponArray);
        }

        const response = await buildCouponMultiple(mergedArray);

        setButtonDisabled(false);

        if (response.status === 200) {
          window.location.href = "/coupon-confirmation/whcoupon";
        }
        if (response.status === 404) {
          window.location.href = "/login";
        }
      };
      createMultipleCoupons();
    } else {
      toastErrorMsg();
    }
  };

  return (
    <>
      <div className="Wizard_Content_Card">
        <Form>
          <Row>
            <Col lg={12}>
              <h5
                style={{
                  color: "#3D8ECC",
                  textAlign: "center",
                  marginTop: "40px"
                }}
              >
                Your offers will be sent to retailers{" "}
                <span style={{ color: "black", fontWeight: "700" }}>
                  within
                </span>
              </h5>
              <h3
                style={{
                  color: "#3D8ECC",
                  textAlign: "center",
                  fontWeight: "600"
                }}
              >
                2 hours
              </h3>
            </Col>
            <Col lg={12}>
              <div className="d-flex justify-content-center mt-50">
                <img
                  style={{ height: "100px", width: "300px" }}
                  src={BrandImg}
                />
              </div>
            </Col>
            <Col lg={12}></Col>
          </Row>
          <React.Fragment>
            <Row className=" mt-50 justify-content-center">
              <Col lg={8}>
                <Form.Check
                  type="checkbox"
                  onChange={(e) => handleChecked(e)}
                  label="By clicking, I understand that offer targets and dates cannot be changed after release and I confirm to release the offer. I also accept BrandIQ terms and condition"
                />
              </Col>
            </Row>
          </React.Fragment>
        </Form>
        <div className="d-flex flex-column justify-content-end align-items-center  text-end mb-50">
          <Button
            className="GrayButton mt-40"
            style={{
              backgroundColor: "#3D8ECC",
              color: "white",
              border: "none"
            }}
            type="submit"
            onClick={handleBuildCoupon}
            disabled={buttonDisabled}
          >
            Save and Send Offer
            {buttonDisabled ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                className={"img-fluid ml-5"}
              />
            ) : (
              <React.Fragment>
                {" "}
                <img
                  src={NextArrow}
                  alt="Next Arrow"
                  className="img-fluid ml-5"
                />
              </React.Fragment>
            )}
          </Button>
          {/* <Button
            className="GrayButton mt-40"
            style={{
              backgroundColor: "#3D8ECC",
              color: "white",
              border: "none"
            }}
            type="submit"
            onClick={handleBuildCoupon}
            disabled={buttonDisabled}
          >
            {buttonDisabled ? (
              <Spinner animation="border" variant="primary" />
            ) : (
              <React.Fragment>
                {" "}
                Save and Send Offer{" "}
                <img
                  src={NextArrow}
                  alt="Next Arrow"
                  className="img-fluid ml-5"
                />{" "}
              </React.Fragment>
            )}
          </Button> */}
        </div>
      </div>
      <div className="flex align-items-center justify-content-end text-end">
        <Button
          className="GrayButton mt-40"
          style={{
            backgroundColor: "#3D8ECC",
            color: "white",
            border: "none"
          }}
          type="submit"
          onClick={() => {
            handleCheckLength();
          }}
        >
          <img src={PrevArrow} alt="Prev Arrow" className="img-fluid mr-5" />
          Previous
        </Button>
      </div>
    </>
  );
}

export default Step4;
