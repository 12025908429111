import React from "react";
import Header from "../Dashboard/Header";
import { Link } from "react-router-dom";
import ls from "local-storage";
import * as CONFIG from "../../Components/_config";
import axios from "axios";
import DataTable from "react-data-table-component";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import $ from "jquery";
import ClipLoader from "react-spinners/ClipLoader";
import FooterStrict from "../Dashboard/FooterStrict";
import { renderTooltip } from "../../Components/Helper";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

const Loader = () => <div id="cover-spin"></div>;

class Categories extends React.Component {
  constructor(props) {
    super(props);
    toast.configure();

    this.state = {
      UpcList: "",
      categoryLoader: true,
      loading: false,
      columns: [
        {
          name: "CATEGORY",
          // eslint-disable-next-line no-useless-escape
          selector: (row) => row.category_name.replace(/\\\\'/g, "\'"),
          sortable: true
        },
        {
          name: "EDIT",
          selector: (row) => row.category_name,
          cell: row => (
            <OverlayTrigger
              placement="top"
              delay={{ show: 250, hide: 400 }}
              overlay={renderTooltip('Edit')}
            >
              <Link
                to={{
                  pathname: "/editcategory",
                  state: {
                    params: {
                      id1: row.upc_category_guid,
                      id2: row.category_name,
                    }
                  }
                }}
              >
                {'EDIT'}
              </Link>
            </OverlayTrigger>

          )
        },
        {
          name: "DELETE",
          selector: (row) => row.category_name,
          cell: row => (
            <OverlayTrigger
            placement="top"
            delay={{ show: 250, hide: 400 }}
            overlay={renderTooltip('Delete')}
          >
             <a href={() => false} onClick={(e) => this.AlertModal(e)} data-categoryguid={row.upc_category_guid} 
             data-categoryname={row.category_name}>
              {'DELETE'}
            </a>
            </OverlayTrigger>

          )
        }
      ]
    };
  }

  changeHandler = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    this.setState({
      formControls: {
        ...this.state.formControls,
        [name]: value
      }
    });
  };

  async componentDidMount() {
    //var guid = ls.get("brands") ? ls.get("brands")[0].guid : "";

    const guid = ls.get('selectedbrand') ? ls.get('selectedbrand')[0].guid : '';
    var data = "";
    var config = {
      method: "get",
      url: CONFIG.API_URL + `brand/allcategory/${guid}`,
      headers: CONFIG.config_head.headers,
      data: data
    };
    axios(config)
      .then((response) => {
        this.setState({ tableData: response.data.Message, Categorylist: response.data.Message, categoryLoader: false });
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 400) {
            this.setState({ tableData: '', Categorylist: '', categoryLoader: false })
            toast.error(error.response.data.Message);

          } else if (error.response.status === 403) {
            toast.error(error.response.data.Message);
            ls.clear("token");
            ls.clear("brands");
            window.location.href = "/login";
          } else {
            toast.error(error.response.data.Message);
          }
        }
      });

  }

  AlertModal(e) {

    var categoryguid = e.target.getAttribute("data-categoryguid");
    var categoryname = e.target.getAttribute("data-categoryname");
    $("#confirmcategoryModal").trigger("click");
    this.setState({ categoryguid: categoryguid, categoryname: categoryname });
  }

  CreateCategory = async (e) => {

    e.preventDefault();
    //var b_guid = ls.get("brands") ? ls.get("brands")[0].guid : "";
    const b_guid = ls.get('selectedbrand') ? ls.get('selectedbrand')[0].guid : '';

    if ($("#add_category_name").val().length <= 0) {
      toast.error("First Name can't be blank", { theme: "colored" });
      return false;
    }
    else {

      $("#exampleModal").css("display", "none");
      $(".modal-backdrop").first().css("display", "none");
      this.showLoader();

      var data = JSON.stringify({
        category_name: this.state.formControls.add_category_name.trim(),
      });

      var config = {
        method: "post",
        url: CONFIG.API_URL + `brand/addcategory/${b_guid}`,
        headers: CONFIG.config_head.headers,
        data: data
      };
      axios(config)
        .then((res) => {
          if (res.status === 200) {
            toast.success('Category Added Successfully')
            this.hideLoader();
            window.location.reload();
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 403) {
              this.hideLoader();
              toast.error(error.response.data.Message);
              ls.clear("token");
              ls.clear("brands");
              window.location.href = "/login";
            }
            else {
              this.hideLoader();
              toast.error(error.response.data.Message);
            }
          }
        });
    }
  }


  RemoveCategory(category_guid, category_name) {
    //var guid = ls.get("brands") ? ls.get("brands")[0].guid : "";
    const guid = ls.get('selectedbrand') ? ls.get('selectedbrand')[0].guid : '';

    $("#staticBackdropcategory").css("display", "none");
    $(".modal-backdrop").first().css("display", "none");

    this.showLoader();
    var data = JSON.stringify({
      category_name: category_name,
      category_status: 2,
    });
    var config = {
      method: "put",
      url: CONFIG.API_URL + `brand/category/${guid}/${category_guid}`,
      headers: CONFIG.config_head.headers,
      data: data
    };

    axios(config)
      .then((res) => {
        if (res.status === 200) {
          this.hideLoader()
          toast.success("Removed Successfully");
          setTimeout(() => { window.location.href = "/categories" }, 3000);
        }
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 502) {
            this.hideLoader()
            toast.error("Bad Request, Please Try Again!");
          } else if (error.response.status === 403) {
            this.hideLoader()
            toast.error(error.response.data.Message);
            ls.clear("token");
            ls.clear("brands");
            window.location.href = "/login";
          } else {
            this.hideLoader()
            toast.error(error.response.data.Message);
          }
        }
      });
  }


  showLoader = () => {
    this.setState({ loading: true });
  }
  hideLoader = () => {
    this.setState({ loading: false });
  };

  render() {
    return (
      <>
        <Header location={`category`} />
        {this.state.loading ? <Loader /> : null}

        <section className="ContentBlock  pt-24 pb-24 Main_Block">
          <div className="container-fluid">
            <div className="row justify-content-center">
              <div className=" col-12">
                <div className="Coupons_Block mt-21">
                  <div className="Common_Block_Coupons_Header d-flex align-items-center justify-content-between">
                    <h5>CATEGORIES</h5>

                    <a href="/#" className="Add_User_Button " data-bs-toggle="modal" data-bs-target="#exampleModal">
                      <i className="fas fa-plus mr-3"></i> ADD CATEGORY
                    </a>

                    <Link to="/upclist"> <h5 style={{ float: "right" }}>BACK TO UPC LIST</h5></Link>

                  </div>
                  <div className="Common_Block_Body">
                    {
                      this.state.categoryLoader ? (
                        <div className="Clip_Loader" style={{ minHeight: "280px" }}>
                          <ClipLoader color={"rgb(0, 143, 251)"} loading={true} size={50} />
                        </div>
                      ) :
                        <div className="table-responsive Coupons_Table">
                          {this.state.Categorylist ? (
                            <>
                              <DataTable
                                columns={this.state.columns}
                                data={this.state.tableData}
                                pagination
                              />
                            </>
                          ) : (
                            <span>NO CATEGORY AVAILABLE FOR THIS BRAND</span>
                          )}
                        </div>
                    }
                    <div className="d-flex align-items-center justify-content-end Pagination_Block"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>


          <button
            className="btn btn-success"
            data-bs-toggle="modal"
            data-bs-target="#staticBackdropcategory"
            id="confirmcategoryModal"
            style={{ display: "none" }}
          >
            Alert
          </button>

          <div
            className="modal fade Confirmation_Modal"
            id="staticBackdropcategory"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex="-1"
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-body">
                  <i className="fas fa-exclamation-triangle text-center "></i>
                  <h4 className="mt-20 fw-bold text-center">Are you sure?</h4>
                  <p className="mt-10 text-center">
                    You want to remove this selected category.
                  </p>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={(e) =>
                      this.RemoveCategory(
                        this.state.categoryguid ? this.state.categoryguid : "", this.state.categoryname ? this.state.categoryname : ""
                      )
                    }
                  >
                    Yes
                  </button>
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                  >
                    No
                  </button>
                </div>
              </div>
            </div>
          </div>


          {/* BEGIN:ADD USER MODAL */}

          <div className="modal fade Add_User_Modal" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">ADD CATEGORY</h5>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>

                <form className="row gx-4 gy-3" method="post">
                  <>
                    <div className="modal-body">
                      <div className="col-12">
                        <label
                          htmlFor="inputEmail4"
                          className="form-label"
                        >
                          Category Name
                        </label>

                        <input type="text" className="form-control" placeholder="Category Name" name="add_category_name"
                          id="add_category_name" onChange={this.changeHandler} />
                      </div>
                    </div>

                    <div className="modal-footer justify-content-center">
                      <button type="submit" className="btn Btn_blue w-fit-content px-4 py-2 rounded-2" onClick={(e) => { this.CreateCategory(e); }}>Save</button>


                    </div>
                  </>
                </form>
              </div>

            </div>
          </div>

          {/* END:ADD USER MODAL */}

        </section>
        <FooterStrict />
      </>
    );
  }
}
export default Categories;
