import React from 'react';
import ClipLoader from "react-spinners/ClipLoader";
import BlockImgBlock from "../../Assets/images/blockbg.png";

class SelectTargets extends React.Component {
   
    constructor(props){
        super(props)
        this.state= {checkedListAll:[],checkedListAllRetailer:[]}
    }

    componentDidUpdate(prevProps, nextProps) {
        if (prevProps !== this.props) {
          this.setState({
            checkedListAll: prevProps.checkedListAll,
            checkedListAllRetailer:prevProps.checkedListAllRetailer
     
        });
        }
      }
    render() {

       // console.log('checkedListAllRetailer',this.state.checkedListAllRetailer)
       
        return (
            <div className="row  g-3">
                <div className=" col-12">
                    <div className="Common_Block">
                        <div className="Common_Block_Header d-flex align-items-center bg-white text-black justify-content-center">
                            <h5 className="text-black mr-10">
                                <input
                                    type="checkbox"
                                    className="mr-7"
                                    id="wholesalercheckbox"
                                    onChange={(e) =>
                                        this.props.selectWholesalerCheckbox(e)
                                    }
                                />
                                Wholesalers
                            </h5>
                            <h5 className="text-black">
                                <input
                                    type="checkbox"
                                    className="mr-7"
                                    id="retailercheckbox"
                                    onChange={(e) =>
                                        this.props.selectRetailerCheckbox(e)
                                    }
                                />
                                Retailers
                            </h5>
                            {/* <p className="ShowLink">Show Retailers</p> */}
                        </div>
                        <div className="Common_Block_Body pr-12 pl-12 pt-20 pb-20">
                            {this.props.wholeSalerLoader ? (
                                <div
                                    className="Clip_Loader"
                                    style={{ minHeight: "280px" }}
                                >
                                    <ClipLoader
                                        color={"rgb(0, 143, 251)"}
                                        loading={true}
                                        size={50}
                                    />
                                </div>
                            ) : this.props.WholeSalerlist &&
                                this.props.flag === 1 ? (
                                    <>
                                    <div className="header_right" style={{ float: 'right', marginTop: '-14px', color: '#000' }}>
                                    <input type="checkbox" onClick={this.props.selectAllWholesalerCheckboxes.bind(this)} value="checkedall"
                                      style={{ marginRight: '5px',width:'18px',height:'18px' }} />
                                    {" "}
                                    Check / Uncheck All
                                  </div>
  
                                <ul className=" mt-15 d-flex  Retailer">
                      
                                    {this.props.WholeSalerlist.map((val, idx) => {
                                        return val.has_full_access === true ? (
                                            <li key={idx} style={{marginTop:'5px'}}>
                                                <div className="Retailer_Block">
                                                    <input
                                                        type="checkbox"
                                                        value={val.wholesaler_name}
                                                        name="wholesalername"
                                                        checked={this.state.checkedListAll.some(el => el.wholesaler_id === val.wholesaler_id)}
          
                                                        onChange={(e) =>
                                                            this.props.selectWholesaler(
                                                                e,
                                                                val.logo,
                                                                val.wholesaler_id,
                                                                val.wholesaler_guid
                                                            )
                                                        }
                                                        style={{ zoom: "1.5" }}
                                                    />
                                                    <div className="RetailerImage">
                                                        <img
                                                            src={val.logo}
                                                            className="img-fluid "
                                                            alt={val.wholesaler_name}
                                                        />
                                                    </div>
                                                    <h5> {val.wholesaler_name}</h5>
                                                </div>
                                            </li>
                                         
                                        ) : (
                                            ""
                                        );
                                    })}
                                    
                                </ul>
                                </>
                            ) : (
                                ""
                            )}
                            {this.props.retailerLoader ? (
                                <div
                                    className="Clip_Loader"
                                    style={{ minHeight: "280px" }}
                                >
                                    <ClipLoader
                                        color={"rgb(0, 143, 251)"}
                                        loading={true}
                                        size={50}
                                    />
                                </div>
                            ) : this.props.retailer_array &&
                                this.props.flag === 2 ? (
                                <>
                                    <div className="Retailer_Block">
                                        
                                        <div className="header_right" style={{ float: 'right', marginTop: '-15px', color: '#000' }}>
                                            <input type="checkbox" onClick={this.props.selectAllRetailerCheckboxes.bind(this)} value="checkedall"
                                                style={{ marginRight: '5px' }} />
                                            {" "}
                                            Check / Uncheck All
                                        </div>

                                        <div className="Common_Block_Header d-flex align-items-center bg-white text-black justify-content-end"></div>
                                        {
                                            <ul className=" mt-15 d-flex  Retailer">
                                                {this.props.retailer_array.map(
                                                    (val, idx1) => (
                                                        <>
                                                            <li key={idx1}>
                                                                <div className="Retailer_Block">
                                                                    <input
                                                                        type="checkbox"
                                                                        name="retailername"
                                                                        value={val.wholesaler_id}
                                                                        checked={this.state.checkedListAllRetailer.some(el => el.retailer_id === val.retailer_id)}
          
                                                                        onChange={(e) =>
                                                                            this.props.selectRetailer(
                                                                                e,
                                                                                val.retailer_name,
                                                                                val.retailer_id,
                                                                                val.wholesaler_id,
                                                                                val.logo
                                                                            )
                                                                        }
                                                                        style={{
                                                                            margin: "9px",
                                                                            zoom: "1.2",
                                                                            float: "right"
                                                                        }}
                                                                    />
                                                                    <div className="RetailerImage">
                                                                        <img
                                                                            src={val.logo ? val.logo : BlockImgBlock}
                                                                            className="img-fluid"
                                                                            alt={val.retailer_name}
                                                                        />
                                                                    </div>
                                                                    <h5> {val.retailer_name}</h5>
                                                                </div>
                                                            </li>
                                                        </>
                                                    )
                                                )}
                                            </ul>
                                        }
                                    </div>
                                </>
                            ) : (
                                ""
                            )}
                            <div className="row mt-10">
                                <div className="col-12 d-flex justify-content-end">
                                    <div className="TableButton">
                                        <button
                                            type="button"
                                            className="mr-10 btn btn-primary"
                                            onClick={(e) => {
                                                this.props.changePrevTab(e, 2);
                                            }}
                                        >
                                            <i
                                                className="fas fa-arrow-left mr-10"
                                                aria-hidden="true"
                                            ></i>{" "}
                                            Prev
                                        </button>
                                        <button
                                            type="button"
                                            className="btn btn-primary"
                                            onClick={(e) => {
                                                this.props.changeThirdTab(e, 5);
                                            }}
                                        >
                                            Next{" "}
                                            <i
                                                className="fas fa-arrow-right ml-10"
                                                aria-hidden="true"
                                            ></i>{" "}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default SelectTargets