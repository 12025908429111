import React, { useEffect } from 'react';
import Auth from '../../auth';
import ls from "local-storage";
import axios from "axios";
import jwt_decode from "jwt-decode";
import Logo from "../../Assets/images/logo-small.png";
import * as CONFIG from "../../Components/_config";
import "react-toastify/dist/ReactToastify.css";

const NewToken = () => {
    const auth = new Auth();

    const getBrands = async (token, expiresAt) => {
        const hostname = CONFIG.getHostName(window.location.href) || "";
        var headers;
        if (window.location.hostname === "localhost" || hostname === "dev.dcbzsxgtdyea0.amplifyapp.com") {
            headers = {
                "Content-Type": "application/json",
                Authorization: token,
                "x-api-key": "KNeEYFr1sCaTbAuDYhBi33UXmf3gzMtm7Cv0k95O"
            }
        }
        else {
            headers = {
                "Content-Type": "application/json",
                Authorization: token,
            }
        }
        var config = {
            method: "get",
            url: CONFIG.API_URL + "user/authorizations/",
            headers: headers
        };
        await axios(config)
            .then((response) => {
                let data = response.data.Message.brands;
                var decoded = jwt_decode(token);
                if (data[0].scopes[0] === "all") {
                    ls.remove("token");
                    ls.remove("username");
                    ls.remove("password");
                    ls.remove("brands");
                    ls.set("token", token);
                    ls.set("brands", data);
                    ls.set("decodetoken", decoded);
                    ls.set("expiresAt", expiresAt);

                } else {
                    if (decoded.is_consent_provided === false) {
                        ls.set("token", token);
                        ls.set("decodetoken", decoded);
                        ls.set("brands", data);
                        ls.set("expiresAt", expiresAt)
                        return false;
                    } else {
                        ls.remove("token");
                        ls.remove("brands");
                        ls.remove("username");
                        ls.remove("password");
                        ls.set("token", token);
                        ls.set("decodetoken", decoded);
                        ls.set("brands", data);
                        ls.set("expiresAt", expiresAt);
                    }
                }
                window.location.href = window.location.pathname
            })
            .catch((error) => {
                window.location.href = "/login"
            });
    }

    useEffect(() => {
        auth.getNewToken().then(res => {
            if (!!res) {
                let expiresAt = JSON.stringify(
                    res.data.Message.expires_in * 1000 + new Date().getTime()
                )
                getBrands(res.data.Message.access_token, expiresAt);
            }
        })
    }, [])

    return (
        <React.Fragment>
            <header className="Header">
                <div className="Main_Header" style={{ backgroundColor: "none" }}>
                    <nav className="Navbar  ">
                        <div className="container-fluid">
                            <div className="d-flex align-items-center justify-content-between">
                                <div className="Navbar-Brand" >
                                    <img src={Logo} className="img-fluid" alt="Logo" />
                                </div>
                            </div>
                        </div>
                    </nav>
                </div>
            </header>
            <div className='New-token-container'>
                <div className="New-token-inner-container">
                    <h2 className='New-token-content'>Please wait... we are refreshing your session</h2>
                </div>
            </div>
        </React.Fragment>
    )
}

export default NewToken