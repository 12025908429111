import React, { useState ,useEffect} from "react";
import { Modal, Button, Form ,Spinner} from "react-bootstrap";
import Select from "react-select";
import 'bootstrap/dist/css/bootstrap.min.css';
import ls from "local-storage";
import * as CONFIG from "../../Components/_config";
import { toast } from "react-toastify";
import axios from "axios";

function CompanyModel({companyValue,setCompanyValue ,mode,show,setShow}) {


  const [isShow, invokeModal] = useState(mode);
  const initModal = () => {
    setShow(!false)
    return invokeModal(!false);
  };

             
  const closeModal = () => {  
     
    invokeModal(false);
    setShow(false); 
    ls.remove("selectedbrand");
    ls.set("selectedCompanyName", companyValue);
    ls.set("companyData" , [])
    window.location.href = "/dashboard"
  };

    const companyDataBroker = ls.get("companyDataBroker")
       
       let optionsArray =  companyDataBroker && companyDataBroker.map((Each) =>{
            return{
              value : Each.company_guid ,
              label : Each.company_name,
            }
          })
          
         const handleChange = (e,name) =>{
          setCompanyValue({
            ...companyValue,
            [name] : e
          })
         }
  return (
    <>
      <button
        style={{ border: "none", backgroundColor: "transparent" }}
        onClick={initModal}></button>
      <Modal
         className="d-flex flex-column justify-content-center "
        show={isShow || show}
        style={{ padding: 15}}>
        <div style={{marginTop:"50px", marginLeft:"50px", marginRight:"50px" }}>
          <Modal.Title style={{ fontSize: 16}}>
            <Form.Group >
                <Form.Label style={{fontWeight:"bold"}} >Please Select Company</Form.Label>
                 <Select
                  className="basic-single"
                  classNamePrefix="select"
                  placeholder="Please Select Company"
                  name="companyName"
                  value={companyValue?.companyName}
                  options= {optionsArray}
                  onChange={(e) =>{
                    handleChange(e,"companyName")
                  }}
                   noOptionsMessage={() => (
                      <Spinner animation="border" variant="primary" />
                    )}
                  />                             
            </Form.Group>
          </Modal.Title>
        </div>

        <div style={{  marginBottom:"50px" , marginTop:"20px" }}>
        
          <Button  style={{marginLeft:"250px" ,marginTop:"40px" , marginRight:"50px",backgroundColor:"#3d8ecc"}} onClick={closeModal} disabled={companyValue?.companyName === undefined ? true : false}  > Continue</Button>
        </div> 
      </Modal>
    </>
  );
}

export default CompanyModel;
