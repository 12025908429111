import React from "react";
import $ from "jquery";
import { Link } from "react-router-dom";
import Header from "../Dashboard/Header";
import { Form } from "react-bootstrap";
import * as CONFIG from "../../Components/_config";
import axios from "axios";
import { toast } from "react-toastify";
import ls from "local-storage";
import "react-toastify/dist/ReactToastify.css";
import FooterStrict from "../Dashboard/FooterStrict";

const Loader = () => <div id="cover-spin"></div>;

class Editcategory extends React.Component {
  constructor(props) {
    super(props);
    toast.configure();
    this.state = {
        loading:false,
        formControls: {}
    };
  }

  changeHandler = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    this.setState({
      formControls: {
        ...this.state.formControls,
        [name]: value
      }
    });
  };

  componentDidMount() {
    $(document).ready(function () {
      $(".Edit_Icon").on("click", function () {
        $(".Edit_Icon").parent().parent().removeClass("Edit_Field");
        $(this).parent().parent().addClass("Edit_Field");
        $("input").attr("disabled", true);
        $(this).siblings("input").removeAttr("disabled");
      });
    });
  }

  saveProductinfo = async (e) => {
    e.preventDefault();

    //var guid = ls.get("brands") ? ls.get("brands")[0].guid : "";
    const guid = ls.get('selectedbrand') ? ls.get('selectedbrand')[0].guid : '';

    var category_guid = this.props.location.state.params.id1;
   // var category_name = this.props.location.state.params.id2;
    
    this.showLoader();
    var data = JSON.stringify({
        category_name: this.state.formControls.category_name
        ? this.state.formControls.category_name
        : this.props.location.state.params.id2,
      category_status: 1,
     });


    var config = {
      method: "put",
      url: CONFIG.API_URL + `brand/category/${guid}/${category_guid}`,
      headers: CONFIG.config_head.headers,
      data: data
    };

    axios(config)
      .then((res)=> {
        if (res.status === 200) {

            this.hideLoader()
            toast.success("Updated Successfully");

          setTimeout(()=>{window.location.href = "/categories"}, 3000);
        }
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 502) {
            this.hideLoader()
            
            toast.error("Bad Request, Please Try Again!");
          } else if (error.response.status === 400) {
            this.hideLoader()
            
            toast.error(error.response.data.Message);
          } else {

            this.hideLoader()
            toast.error(error.response.data.Message);
          }
        }
      });
  };

  showLoader = () => {
    this.setState({ loading: true });
  }
  hideLoader = () => {

    this.setState({ loading: false });

  };


  render() {
    return (
      <>
        <Header location={`editCategory`}/>
        {this.state.loading ? <Loader /> : null}

        <section className="ContentBlock  pt-24 pb-24 Main_Block">
          <div className="container-fluid">
            <div className="row justify-content-center">
              <div className=" col-12">
                <div className="Common_Block mt-21">
                  <div className="Common_Block_Body">
                    <div className="UpcInfo_Head">
                      <h5>Edit Category</h5>
                    </div>
                    <div className="Upc_List">
                      <div className="row ">
                        {/* <div className="col-lg-6 col-12">
                         
                        </div>
                          */}
                        <div className="col-lg-6 col-12">
                          <Form.Group
                            className="Form_Group"
                            controlId="Product"
                          >
                            <Form.Label>Catgory Name</Form.Label>
                            <div className="Input_Icon">
                              <Form.Control
                                type="text"
                                placeholder="Enter Product Name"
                                name="category_name"
                                defaultValue={
                                  this.props.location.state.params.id2
                                }
                                onChange={this.changeHandler}
                                disabled
                              />
                              <div className="Edit_Icon">
                                <svg
                                  width="18"
                                  height="19"
                                  viewBox="0 0 18 19"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M12.728 7.36146L11.314 5.91684L2 15.4326V16.8772H3.414L12.728 7.36146ZM14.142 5.91684L15.556 4.47221L14.142 3.02758L12.728 4.47221L14.142 5.91684ZM4.242 18.9205H0V14.5856L13.435 0.859614C13.6225 0.668083 13.8768 0.560486 14.142 0.560486C14.4072 0.560486 14.6615 0.668083 14.849 0.859614L17.678 3.74989C17.8655 3.94148 17.9708 4.2013 17.9708 4.47221C17.9708 4.74311 17.8655 5.00293 17.678 5.19452L4.243 18.9205H4.242Z"
                                    fill="current"
                                  />
                                </svg>
                              </div>
                            </div>
                          </Form.Group>
                        </div>
                      </div>
                    </div>

                    <div className="Upc_Button text-end">
                      <Link to="/categories">
                        <button type="submit" className="Light_Gray btn">
                          Cancel
                        </button>
                      </Link>
                      <button
                        type="submit"
                        className="Blue btn ml-10"
                        onClick={(e) => {
                          this.saveProductinfo(e);
                        }}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
         <FooterStrict />
      </>
    );
  }
}

export default Editcategory;
