import ls from "local-storage";
import { toast } from "react-toastify";
import * as CONFIG from "../Components/_config";
import axios from "axios";

let guid = ls.get("brands") ? ls.get("brands")[0].guid : "";
//let token = ls.get("token") ? ls.get("token") : "";

export const getBrands = (brandguid) => {
  let config = {
    method: "get",
    url: CONFIG.API_URL + `brand/${brandguid}`,
    headers: CONFIG.config_head.headers
  };
  return axios(config)
    .then((response) => {
      return response.data;
    })
    .catch( (error)=> {
      if (error.response.status === 400) {
        toast.error(error.response.data.Message);
       
      } else if (error.response.status === 403) {
        toast.error(error.response.data.Message);
        ls.clear("token");
        ls.clear("brands");
        window.location.href = "/login";
      } else {
        toast.error(error.response.data.Message);
      }
    });
};

export const getBrandCategory = (brandguid) => {
  let config = {
    method: "get",
    url: CONFIG.API_URL + `brand/allcategory/${brandguid}`,
    headers: CONFIG.config_head.headers
  };
  return axios(config)
    .then((response) => {
      return response.data;
    })
    .catch(  (error)=> {
      if (error.response.status === 400) {
        toast.error(error.response.data.Message);
       
      } else if (error.response.status === 403) {
        toast.error(error.response.data.Message);
       
        ls.clear("token");
        ls.clear("brands");
        ls.clear("selectedbrand");
        
       window.location.href = "/login";
      
        
      } else {
        toast.error(error.response.data.Message);
      }
    });
};


export const postQuery = (info) => {
  let data = JSON.stringify({
    BrandID: 0,
    EndDate: info.endDate,
    OrderBy: info.orderBy,
    StartDate: info.startDate,
    UPC: info.UpcList,
    type: info.type,
    Wholesaler: info.wholesalerArray,
    Retailer: info.retailerarr
  });
  let config = {
    method: "post",
    url: CONFIG.API_URL + "query",
    headers: CONFIG.config_head.headers,
    data: data
  };
  return axios(config)
    .then((response) => {
      return response.data;
    })
    .catch((error)=> {
      if(error.response){
        if (error.response.status === 400) {   
             return error.response
        } else if (error.response.status === 403) {
         // return error.response
         toast.error(error.response.data.Message);
       
         ls.clear("token");
         ls.clear("brands");
         ls.clear("selectedbrand");
         window.location.href = "/login";
          } else {
            return error.response
        }
      
    }
    });
};

export const getListOfCoupon = (info) => {
  let data = JSON.stringify({
    brandId: info.guid,
    active: 1,
    page: 1,
    count: 10,
    sort: "-name",
    "include-clip": true,
    "include-redemption": true,
    search: "",
    "coupon-start-date": "",
    "coupon-end-date": ""
  });

  let config = {
    method: "post",
    url: CONFIG.API_URL + "coupon/search",
    headers: CONFIG.config_head.headers,
    data: data
  };

  return axios(config)
    .then((response) => {
      return response.data;
    })
    .catch(function (error) {
      if(error.response){
        if (error.response.status === 400) {   
           return error.response.data
          
        } else if (error.response.status === 403) {
    
          console.log(error)
    
          } else {
            console.log(error)
    
        }
      
    }
    });
};

export const getBrandStore = (info) => {
  let config = {
    method: "get",
    url: CONFIG.API_URL + `brand/getstores/${info.guid}`,
    headers: CONFIG.config_head.headers
  };
  return axios(config)
    .then((response) => {
      return response.data;
    })
    .catch( (error)=> {
     
      if(error.response){
        if (error.response.status === 400) {
        
           return error.response.data
          
        } else if (error.response.status === 403) {
    
          console.log(error)
    
          } else {
            console.log(error)
    
        }
      
    }
    });
};


export const getCompanyBrandStore = () => {
  let config = {
    method: "get",
    url: CONFIG.API_URL + `company/getstores/${guid}`,
    headers: CONFIG.config_head.headers
  };
  return axios(config)
    .then((response) => {
      return response.data;
    })
    .catch( (error)=> {
     
      if(error.response){
        if (error.response.status === 400) {   
           return error.response.data
          
        } else if (error.response.status === 403) {
    
          console.log(error)
    
          } else {
            console.log(error)
    
        }
      
    }
    });
};


export const getUPCbyCategory = (brand_guid,category_id) => {
  let config = {
    method: "get",
    url: CONFIG.API_URL + `brand/upcbycategory/${brand_guid}/${category_id}`,
    headers: CONFIG.config_head.headers
  };
  return axios(config)
    .then((response) => {
      return response.data;
    })
    .catch( (error)=> {
     
      if(error.response){
        if (error.response.status === 400) {
        
           return error.response.data
          
        } else if (error.response.status === 403) {
    
          console.log(error)
    
          } else {
            console.log(error)
    
        }
      
    }
    });
};