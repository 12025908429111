import React from "react";
import Chart from "react-apexcharts";
import ClipLoader from "react-spinners/ClipLoader";
import { postQuery } from "../../API/DashBoard";

class CompanyProductChart extends React.Component {
  constructor(props) {
    
    super(props);
    this.state = {
      UpcList: [],
      wholesalerArray: [],
      loader: true,
      graphData: "",
      retailerarr: [],
      series: [
        {
          name: "PRODUCT A",
          data:[]
        },
        {
          name: "PRODUCT B",
          data:[]
        },
        {
          name: "PRODUCT C",
          data:[]
        },
        {
          name: "PRODUCT D",
          data:[]
        }
      ],
      options: {
        chart: {
          type: "bar",
          height: 350,
          stacked: true,
          toolbar: {
            show: true
          },
          zoom: {
            enabled: true
          }
        },
        dataLabels: {
          enabled: true,
          enabledOnSeries: [4],
          textAnchor: "left",
          formatter: function (_val, opt) {
            let series = opt.w.config.series
            let idx = opt.dataPointIndex
            const total = series.reduce((total, self) => total + self.data[idx], 0)
            return total + "K"
          },
          style: {
            colors: ["#000"]
          }
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: "bottom",
                offsetX: -10,
                offsetY: 0
              }
            }
          }
        ],
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 0,
            dataLabels: {
              position: "top", // top, center, bottom
              formatter: function (val) {
                return "$" + val;
              }
            }
          }
        },
        dataLabels: {
            enabled: true,
           
                offsetY: -35,
                position: "top", // top, center, bottom
          formatter: function (_val, opt) {
            let series = opt.w.config.series
            let idx = opt.dataPointIndex
            const total = series.reduce((total, self) => total + self.data[idx], 0)
            return  "$" + total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","); 
          },
          
            style: {
            colors: ["#777"]
          }
        },
        xaxis: {
          type: "",
          categories: []
        },
        legend: {
          position: "right",
          offsetY: 40
        },
        fill: {
          opacity: 1
        }
      },
 
    };
  }

  async componentDidMount() {

     this.setState(
      {
        wholesalerArray: this.props.wholesalerArray,
        UpcList: this.props.UpcList,
        retailerarr: this.props.retailerarr
      },
      () => {
        this.getProductChart();
      }
    );
  }

  async getProductChart() {
    const Stores = [];
    const WholeSalerName = [];
    const Message1 = [];
    const State1 = [];
    let data = {};
    let today = new Date().toISOString().split("T")[0];
    let dateParts = today.split("-");
    dateParts[0] = parseInt(dateParts[0], 10) - 1;
    let pastYear = dateParts.join("-");
    if (this.state.wholesalerArray.length > 0) {
      data = {
        type: "PMByWS",
        wholesaler: this.state.wholesalerArray
      };
    } else {
      data = {
        type: "PMByR",
        wholesaler: []
      };
    }
    await postQuery({
      wholesalerArray: data.wholesaler,
      UpcList: this.state.UpcList,
      retailerarr: this.state.retailerarr,
      endDate: today,
      startDate: pastYear,
      type: data.type,
      orderBy: "calMonthYear"
    }).then((res) => {

        this.setState({
          graphData: res.Message.series
        });


        for(const obj of res.Message.series) {
            
            parseFloat(Stores.push(obj.data));
            WholeSalerName.push(obj.Wholesaler);
        }

        for(let j = 0; j < res.Message.xaxis.categories.length; j++) {
            State1.push(res.Message.xaxis.categories[j]);
        }
        
      
        for (let i = 0; i < WholeSalerName.length; i++) {
          Message1.push({
            data:  Stores[i] ,
            name: WholeSalerName[i]
          });
        }

      this.setState({
        loader: false,
        series: Message1,
        options: {
          chart: {
            type: "bar",
            height: 350,
            stacked: true,
            toolbar: {
              show: true
            },
            zoom: {
              enabled: true
            }
          },
        
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: "55%",
              dataLabels: {
                
                textAnchor: "start",
                offsetY: 0,
                position: "top", // top, center, bottom
                // formatter: function (val) {
                //   return (
                //     "$" + val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                //   );
                // },
                style: {
                    marginTop:"70px",
                    colors: ["#777"]
                  }
              }
            }
          },
          xaxis: {
            type: "category",
            categories: State1
          },
          yaxis: {
            axisBorder: {
              show: false
            },
            axisTicks: {
              show: false
            },
            labels: {
              show: true,
              formatter: function (val) {
                return (
                  "$" + val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                );
              }
            }
          },
          colors:['#008FFB', '#00E396','#FEB019','#FF4560', "#575757","#c5b5b5","#0b61a5","#f702a8","#966132"],
          legend: {
            position: "right",
            offsetY: 40
          },
          fill: {
            opacity: 1
          }
        }
      });
    });
  }

  render() {
    return (
      <>
        <div
          className="ProductChart"
          style={{ minHeight: "400px", position: "relative" }}
        >
          {this.state.loader ? (
            <div className="Clip_Loader">
              <ClipLoader color={"rgb(0, 143, 251)"} loading={true} size={50} />
            </div>
          ) : this.state.graphData.length > 0 ? (
            <Chart
              options={this.state.options}
              series={this.state.series}
              type="bar"
              height="400"
            />
          ) : (
            <span className="Clip_Loader">No Data Present</span>
          )}
        </div>
      </>
    );
  }
}

export default CompanyProductChart;
