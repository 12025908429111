import ls from "local-storage";
import { toast } from "react-toastify";
import * as CONFIG from "../Components/_config";
import axios from "axios";

//let guid = ls.get("brands") ? ls.get("brands")[0].guid : "";
//let guid = ls.get("selectedbrand").length>0 ? ls.get("selectedbrand")[0].guid : "";

export const buildCouponRetailer = (data) => {
  var config = {
    method: "post",
    url: CONFIG.API_URL + "deal/",
    headers: CONFIG.config_head.headers,
    data: data
  };
  return axios(config)
    .then((response) => {

      if (response.status === 200) {
        return response;
      }
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 400) {
          setTimeout(() => { toast.error(error.response.data.Message) }, 2500)

        } else if (error.response.status === 403) {
          setTimeout(() => { toast.error(error.response.data.Message) }, 2500)
          ls.clear("token");
          ls.clear("brands");
          ls.clear("selectedbrand");

          window.location.href = "/login";

        } else {
          //console.log(error.response)
          setTimeout(() => { toast.error(error.response.data.Message) }, 2500)

        }
      }

    });
};

export const getWholsalerList = (guid) => {
  var masterconfig = {
    method: "get",
    url: CONFIG.API_URL + `master/${guid}?type=wholesaler&search=''`,
    headers: CONFIG.config_head.headers
  };
  return axios(masterconfig)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response.status === 502) {
        toast.error("Bad Request!");
      } else if (error.response.status === 400) {
        setTimeout(() => { toast.error(error.response.data.Message) }, 2500)
      } else if (error.response.status === 403) {
        setTimeout(() => { toast.error(error.response.data.Message) }, 2500)
        ls.clear("token");
        ls.clear("brands");
        ls.clear("selectedbrand");

        window.location.href = "/login";

      } else {
        setTimeout(() => { toast.error(error.response.data.Message) }, 2500)

      }
    });
};

export const getRetailerList = (guid) => {
  var config = {
    method: "get",
    url: CONFIG.API_URL + `master/${guid}?type=retailer&search=''`,
    headers: CONFIG.config_head.headers
  };
  return axios(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response.status === 502) {
        toast.error("Bad Request!");
      } else if (error.response.status === 400) {
        setTimeout(() => { toast.error(error.response.data.Message) }, 2500)
      } else if (error.response.status === 403) {
        setTimeout(() => { toast.error(error.response.data.Message) }, 2500)
        ls.clear("token");
        ls.clear("brands");
        ls.clear("selectedbrand");

        window.location.href = "/login";

      } else {
        setTimeout(() => { toast.error(error.response.data.Message) }, 2500)

      }
    });
};

export const buildCoupon = (data) => {
  var config = {
    method: "post",
    url: CONFIG.API_URL + "deal/",
    headers: CONFIG.config_head.headers,
    data: data
  };
  return axios(config)
    .then((response) => {
      if (response.status === 200) {
        return response;
      }
    })
    .catch((error) => {
      if (error.response) {

        if (error.response.status === 400) {
          setTimeout(() => { toast.error(error.response.data.Message) }, 2500)
        } else if (error.response.status === 403) {
          toast.error(error.response.data.Message);
          ls.clear("token");
          ls.clear("brands");
          ls.clear("selectedbrand");

          window.location.href = "/login";

        } else {
          setTimeout(() => { toast.error(error.response.data.Message) }, 2500)

        }
      }
    });
};


export const getDeal = (info) => {
  var data = JSON.stringify({
    brandId: info.guid,
    active: 1,
    page: 1,
    count: 10,
    sort: "-name",
    "include-clip": true,
    "include-redemption": true,
    search: "",
    "deal-start-date": "",
    "deal-end-date": ""
  });

  var config = {
    method: "post",
    url: CONFIG.API_URL + "deal/search",
    headers: CONFIG.config_head.headers,
    data: data
  };

  return axios(config)
    .then((response) => {
      return response.data;
    })
    .catch(function (error) {
      if (error.response) {
        if (error.response.status === 502) {
          toast.error("Bad Request!");
        } else if (error.response.status === 400) {
          //setTimeout(() => { toast.error(error.response.data.Message) }, 2500)
          console.log(error.response.data.Message);

        } else if (error.response.status === 403) {
          
          setTimeout(() => { toast.error(error.response.data.Message) }, 2500)
          ls.clear("token");
          ls.clear("brands");
          ls.clear("selectedbrand");

          window.location.href = "/login";

        } else {
          setTimeout(() => { toast.error(error.response.data.Message) }, 2500)

        }

      }
    });
}


export const getCompanyDeal = (info) => {
  var data = JSON.stringify({
    active: 1,
    page: 1,
    count: 10,
    sort: "-name",
    "include-clip": true,
    "include-redemption": true,
    search: "",
    "deal-start-date": "",
    "deal-end-date": ""
  });

  var config = {
    method: "post",
    url: CONFIG.API_URL + "deal/searchbycompany",
    headers: CONFIG.config_head.headers,
    data: data
  };

  return axios(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 502) {
          console.log(error.response.data.Message);

        } else if (error.response.status === 400) {
          
          console.log(error.response.data.Message);

        } else if (error.response.status === 403) {
          
          setTimeout(() => { toast.error(error.response.data.Message) }, 2500)
          ls.clear("token");
          ls.clear("brands");
          ls.clear("selectedbrand");

          window.location.href = "/login";

        } else {

           console.log(error.response.data.Message);

        }

      }
    });
}
