import { toast } from "react-toastify";
import * as CONFIG from "../Components/_config";
import axios from "axios";

export const downloadCoupon=()=> {
 
    var config = {
      method: "get",
      url: CONFIG.API_URL + `coupon/download-all?WSNo=0&WSGuid=`,
      headers: CONFIG.config_head.headers
    };

    return axios(config)
      .then((response) => {
        if (response.status === 200) {
         
          return response ;
        }
      })
      .catch(function (error) {
        if (error.response.status === 502) {
          toast.error("Bad Request!");
        } else if (error.response.status === 400) {
          // toast.error('Bad Request!');
          return error.response;
          //toast.error(error.response.data.Message);
        } else {
            return error.response;  
        }
      });
  }

   