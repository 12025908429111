import React from "react";
import ReactPaginate from "react-paginate";

function Paginate({handlePageClick,pageCount}) {
  return (
    <div>
      <div className="Table_Pagination">
        <div className="mb-0 justify-content-end">
          <ReactPaginate
            nextLabel={<i className="fas fa-chevron-right" style={{color:"black"}}></i>}
            onPageChange={handlePageClick}
            pageRangeDisplayed={2}
            marginPagesDisplayed={2}
            pageCount={pageCount}
            previousLabel={<i className="fas fa-chevron-left" style={{color:"black"}}></i>}
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination  mb-0 d-flex flex-row justify-content-end"
            activeClassName="active"
            renderOnZeroPageCount={null}
          />
        </div>
      </div>
    </div>
  );
}

export default Paginate;
