import React from 'react'
import Arrow from "../../Assets/images/arrow.gif";
import { Button, Form } from "react-bootstrap";
import BlockImgBlock from "../../Assets/images/blockbg.png";
import BlockImg from "../../Assets/images/Brandiq.gif";
import LogoBig from "../../Assets/images/logo-big.png";
import Arrowsvg from "../../Assets/images/arrow.svg";
import CalenderSvgImage from "../../Assets/images/calender.svg";
import CalenderSvgRedImage from "../../Assets/images/calenderRed.svg";
 
import calander from "../../Assets/images/date-icon.svg"
//import retailerlogo from "../../Assets/images/retailer-9.png"
class Preview extends React.Component {

    constructor(props){
        super(props)

        this.state= {retailer_camp_array:[]}
    }

    componentDidUpdate(prevProps, nextProps) {
        if (prevProps !== this.props) {
            this.setState({
                retailer_camp_array: prevProps.retailer_camp_array,
                 
            });
        }
      }
    render() {
        return (
            <div className="row  g-3 justify-content-center">
                <div className="col-12">
                    <div className="Preview_Card Common_Block">
                        <div className="Common_Block_Body">
                            <h5>
                                <span>
                                    Your Deal update will be sent to retailers
                                </span>{" "}
                                <b>within</b>{" "}
                            </h5>
                            <h4>24 hours</h4>
                            <div className="Preview_Outer_Block">
                                <ul className="Preview_Card_Row">
                                    <li className=" text-center Coupons_Image">
                                        <div className="Coupons_Outer_Block">
                                            {this.props.selectedFile ? (
                                                <div className="Coupons_Card">
                                                    {/* <img src={scissors} className='img-fluid scissors' alt="Products" /> */}
                                                    <img
                                                        src={this.props.selectedFile}
                                                        className="img-fluid"
                                                        alt="Coupon"
                                                    />
                                                    <h2 className="Price">
                                                        {this.props.title}
                                                        <span></span>
                                                    </h2>
                                                    <p>
                                                        {this.props.description}
                                                    </p>
                                                </div>
                                            ) : (
                                                <div className="ImgBlock position-relative text-center">
                                                    <img
                                                        src={BlockImgBlock}
                                                        className="img-fluid"
                                                        alt="Bakckground"
                                                    />
                                                    <img
                                                        src={LogoBig}
                                                        className="img-fluid LogoBig"
                                                        alt="Bakckground"
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    </li>
                                    <li className="BgImage d-flex align-items-center">
                                        <div className="Arrow d-flex align-items-center">
                                           
                                            <img
                                                src={Arrow}
                                                className="img-fluid ArrowImage"
                                                alt="Arrow"
                                            />
                                        </div>
                                        <div className="ImgBlock position-relative text-center">
                                            <img
                                                src={BlockImg}
                                                className="img-fluid"
                                                alt="Bakckground"
                                            />
                                            
                                        </div>
                                    </li>
                                    <li className="d-flex align-items-center Brand_Outer_Block">
                                        <div className="Arrow d-flex align-items-center">
                                            
                                            <img
                                                src={Arrow}
                                                className="img-fluid ArrowImage ArrowImageCustom"
                                                alt="Arrow"
                                            />
                                        </div>
                                        {/* <div className="Brand_Logo_Group">
                                            {Object.keys(
                                                this.props.camp_wholesalername
                                            ).length > 0 ? (
                                                <ul className="row g-3 justify-content-center p-0">
                                                    <div className="col-xl-12 col-12">
                                                        <div className="Common_Block">
                                                            <div className="Common_Block_Body p-0">
                                                                <div className="table-responsive Campaign_Table  Preview_Page_Table">
                                                                    <table className="table text-center">
                                                                        <thead className=''>
                                                                            <tr >
                                                                                <th className="text-white">
                                                                                    Wholesaler
                                                                                </th>
                                                                                <th className="text-white">
                                                                                    Start
                                                                                </th>
                                                                                <th className="text-white">
                                                                                    Expiration
                                                                                </th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {Object.keys(
                                                                                this.props.camp_wholesalername
                                                                            ).map((val, idx) => (
                                                                                <tr key={idx}>
                                                                                    <td>
                                                                                        <div className="Image_Outer_Block">
                                                                                            <div className="Campaign_Logo">
                                                                                                <img
                                                                                                    src={
                                                                                                        this.props.camp_wholesaler_logo[val]
                                                                                                    }
                                                                                                    className="img-fluid"
                                                                                                    alt="Coupon"
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td>
                                                                                        {this.props.wholesaler_start_date[val]}
                                                                                    </td>
                                                                                    <td>
                                                                                        {this.props.wholesaler_end_date_show[val]}
                                                                                    </td>
                                                                                </tr>
                                                                            ))}
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </ul>
                                            ) : this.props.retailer_camp_array ? (
                                                <ul className="row g-3 justify-content-center p-0">
                                                    <div className="col-xl-12 col-12">
                                                        <div className="Common_Block">
                                                            <div className="Common_Block_Body p-0">
                                                                <div className="table-responsive Campaign_Table Preview_Page_Table">
                                                                    <table className="table text-center">
                                                                        <thead className=''>
                                                                            <tr >
                                                                                <th className="text-white">
                                                                                    Retailer
                                                                                </th>
                                                                                <th className="text-white">
                                                                                    Start
                                                                                </th>
                                                                                <th className="text-white">
                                                                                    Expiration
                                                                                </th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {Object.keys(
                                                                                this.props.retailer_camp_array
                                                                            ).map((val, idx) => (
                                                                                <tr key={idx}>
                                                                                    <td>
                                                                                        <div className="Image_Outer_Block">
                                                                                            <div className="Campaign_Logo">
                                                                                                <img
                                                                                                    src={this.props.retailerlogo[val]}
                                                                                                    className="img-fluid "
                                                                                                    alt="campaign"
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td>
                                                                                        {this.props.retailer_start_date[val]}
                                                                                    </td>
                                                                                    <td>
                                                                                        {this.props.retailer_end_date[val]}{" "}
                                                                                    </td>
                                                                                </tr>
                                                                            ))}
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </ul>
                                            ) : (
                                                ""
                                            )}
                                        </div> */}
                                               {/* <div className="Brand_Logo_Group d-none">
                                               {(Object.keys(this.props.camp_wholesalername).length > 0 ) &&  ( Object.keys(this.props.warehousename).length > 0) ? (
                                          
                                                            <ul className="row g-3 justify-content-center p-0">
                                                            
                                                            {Object.keys(this.props.camp_wholesalername).map((val, idx) => {

                                                                console.log(val)


                                                                return (
       
                                                                <li className=" col-lg-10 col-md-6 col-12">
                                                                    <div className="Image_Outer_Block">
                                                                   
                                                                    <img src={val.wholesalerlogo}className="img-fluid"alt="Coupon"/>
                                                                    
                                                                    </div>

                                                                    <div class="inner-right" >

                                                                    <span >Start: {this.props.global_prev_start_date}</span>
                                                                    <span >End: {this.props.global_prev_end_date}</span>

                                                                    </div> 
                                                                    {
                                                                    Object.keys(this.props.warehousename).length > 0?    
                                                                    
                                                                    <div class="inner-right row" >
                                                                    {

                                                                    Object.keys(this.props.warehousename).map((name,index)=>{
                                                                    return (
                                                                 
                                                                    <span style={{marginTop:'5px',width:'50%'}} className="col-md-5">
                                                                        
                                                                        {this.props.warehousename[name].warehouse_name}</span>
                                                                     ) })
                                                                     }
                                                                     
                                                                    </div> 
                                                                    :''
                                                                    }    
                                                                                  
                                                                </li>
                                                              )  })}
                                                            
                                                            </ul>)
                                                            :

                                                            <ul className="row g-3 justify-content-center p-0">
                                                            
                                                            {Object.keys(this.props.retailer_camp_array).map((val, idx) => (
                                                                          
                                                                <li className=" col-lg-6 col-md-6 col-12">
                                                                    <div className="Image_Outer_Block">
                                                                   
                                                                    <img src={this.props.retailerlogo[val]}className="img-fluid"alt="Coupon"/>
                                                                    
                                                                    </div>

                                                                    <div class="inner-right" >

                                                                    <span >Start: {this.props.global_prev_start_date}</span>
                                                                    <span >End: {this.props.global_prev_end_date}</span>

                                                                    </div>                   
                                                                </li>
                                                            ))}
                                                         
                                                         </ul>


                                               }
                                        </div> */}
                                        {/* WHOLESALES & WHAREHOUSES */}

                                            { 
                                                (Object.keys(this.props.camp_wholesalername).length > 0 ) &&  
                                                ( Object.keys(this.props.warehousename).length > 0) ? (
                                           
                                                    Object.keys(this.props.camp_wholesalername).map((val, idx) => {
                                                        console.log(val)

                                            return (
                                                <div className="Wholesales_Wharehouse_Outer w-100">    
                                      
                                                 <div className='Wholesales_Wharehouse_Head d-flex align-items-center justify-content-between'>
                                              
                                              <div>
                                                  <img src={calander} className="img-fluid mr-4" alt="calander" />
                                                  <span>Start:{this.props.global_prev_start_date}</span>
                                              </div>
                                               <span>End:{this.props.global_prev_end_date}</span>
                                          </div>
                                         
                                            <div className='Wholesales_Wharehouse_body d-flex align-items-center justify-content-between'>
                                                <div>
                                                    <img src={this.props.camp_wholesalername[val].wholesalerlogo } className="img-fluid mr-4" alt="wholesaler" />
                                                    
                                                </div>
                                                 <h4>{this.props.camp_wholesalername[val].wholesalername}</h4>
                                            </div>
                                                                 
                                            <div className='Wholesales_Wharehouse_Deal'>
                                                
                                                <h5>Deal valid at below warehouses only</h5>
                                                    {                
                                                    Object.keys(this.props.warehousename).length > 0?    
                                           
                                                <ul>
                                                {Object.keys(this.props.warehousename).map((name,index)=>{
                                                    
                                                    return (
                                                 
                                                    <li>
                                                        {this.props.warehousename[name].warehouse_name}
                                                    </li>

                                                    )
                                                    })}
                                                    
                                                </ul>
                                                 :''
                                                }
                                            </div>
                                            </div>
                                             )})

                                            )
                                               
                                            :
                                            <div className="Brand_Logo_Group"> 
                                           <ul className="row g-3 justify-content-center p-0">
                                                            
                                            {this.state.retailer_camp_array.map((val, idx) => (
                                                          
                                                <li className=" col-lg-6 col-md-6 col-12">
                                                    <div className="Image_Outer_Block">
                                                   
                                                    <img src={val.retailerlogo?val.retailerlogo:BlockImg}className="img-fluid"alt="Coupon"/>
                                                    
                                                    </div>

                                                    <div class="inner-right" >

                                                    <span >Start: {this.props.global_prev_start_date}</span>
                                                    <span >End: {this.props.global_prev_end_date}</span>

                                                    </div>                   
                                                </li>
                                            ))}
                                         
                                         </ul>
                                                </div>

                                            }
                                        
                                        
                                    </li>
                                </ul>

                                <div className="row  ">
                                    <div className="col-lg-6 col-12 mt-45">
                                        <div className="date d-flex align-items-center ">
                                            <Form.Group
                                                className=""
                                                controlId="exampleForm.ControlInput1"
                                            >
                                                <img
                                                    src={CalenderSvgImage}
                                                    className="img-fluid"
                                                    alt="Calender"
                                                />
                                                <Form.Label>
                                                    &nbsp;Start: &nbsp;
                                                </Form.Label>
                                                <span className="fw-bold">
                                                    {this.props.global_prev_start_date}
                                                </span>
                                            </Form.Group>
                                            <Form.Group
                                                className="ml-12"
                                                controlId="exampleForm.ControlInput2"
                                            >
                                                <img
                                                    src={CalenderSvgRedImage}
                                                    className="img-fluid"
                                                    alt="Calender"
                                                />
                                                <Form.Label>
                                                    &nbsp; End: &nbsp;
                                                </Form.Label>
                                                <span className="fw-bold">
                                                    {" "}
                                                    {this.props.global_prev_end_date}
                                                </span>
                                            </Form.Group>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-12 mt-45">
                                        <Form.Check
                                            type="checkbox"
                                            name="make_offer"
                                            label="By clicking, I understand that deal targets and dates cannot be changed after release and I confirm to release the deal. I also accept BrandIQ terms and condition."
                                            onChange={(e) =>
                                                this.props.onchangeoffercheckbox(e)
                                            }
                                        />
                                        {Object.keys(this.props.camp_wholesalername)
                                            .length > 0 ? (
                                            <Button
                                                variant=""
                                                type="button"
                                                className=" Btn_blue mt-20 w-50 ms-auto"
                                                onClick={(e) => this.props.buildCoupon(e)}
                                            >
                                                Save and Send Deal
                                                <img
                                                    src={Arrowsvg}
                                                    className="img-fluid float-end"
                                                    alt="Arrow"
                                                />
                                            </Button>
                                        ) : (
                                            <Button
                                                variant=""
                                                type="button"
                                                className=" Btn_blue mt-20 w-50 ms-auto"
                                                onClick={(e) =>
                                                    this.props.buildCouponRetailer(e)
                                                }
                                            >
                                                Save and Send Deal
                                                <img
                                                    src={Arrowsvg}
                                                    className="img-fluid float-end"
                                                    alt="Arrow"
                                                />
                                            </Button>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Preview