import React from "react";
// import $ from "jquery";

// //import { Link } from "react-router-dom";
// import ls from "local-storage";
// import axios from "axios";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//import Blockbg from "../../Assets/images/blockbg.png";

//import * as CONFIG from "../../Components/_config";

class AddretailerModal extends React.Component {
  constructor(props) {
    super(props);
    this.handleSave = this.handleSave.bind(this);
    this.selectRetailer = this.selectRetailer.bind(this);
    toast.configure();

    this.state = {
      retailerdata: this.props.Allretailer,
      retailer_id: this.props.existRetailerid,
      retailer_number: [],
      brand_guid: this.props.brandguidata
    };
  }

  selectRetailer(e, wholesaler_id, retailer_id) {
    if (e.target.checked) {
      let obj = {
        wholesaler_id: wholesaler_id,
        retailer_id: retailer_id
      };

      this.state.retailer_number.push(obj);
    } else {

      let removeIndex = this.state.retailer_number.findIndex(
        (itm) => itm.retailer_id === retailer_id
      );

      if (removeIndex > -1) {
        this.state.retailer_number.splice(removeIndex, 1); // 2nd parameter means remove one item only
      }
    }
    const item = this.state;
    this.props.selectEditretailer(item)
  }

  handleSave() {
    const item = this.state;
    this.props.updateDeal(item)
  }



  componentDidMount() {
    // console.log('>>>>wlist',this.props.WholeSalerlist)
  }

  componentDidUpdate(prevProps) {
    // compare props
  }
  render() {
    //console.log('>>retailerinfo>>>>>',typeof this.state.retailerdata)

    return (
      <>
        <div
          className="modal fade"
          id="selectretailer-sel-drop"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-custom modal-dialog-scrollable">
            <div className="modal-content whole-content">
              <div className="modal-header borderemove brand-whole-hd">
                <h5 className="modal-title" id="exampleModalLabel">
                  Select Retailer
                </h5>
                <button
                  type="button"
                  className="btn-close wholeseler-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>

              <div className="modal-body CustomScroll whole-seler-body">
                <div className="tab-content" id="nav-tabContent">
                  <div
                    className="tab-pane fade show active tab-text"
                    id="nav-home"
                    role="tabpanel"
                    aria-labelledby="nav-home-tab"
                  >
                    <div className="Common_brand_detail">
                      <div className="pr-12 pl-12 pt-20">
                        {/* <div className="Search">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Retailer Name"
                />
    
                <div className="searchImg">
                  <img
                    src={Search}
                    className="img-fluid "
                    alt="search"
                  />
                </div>

              </div> */}

                        <ul className=" mt-15 d-flex  Retailer">
                          {this.state.retailerdata.map(
                            (val, idx1) => {

                              let check =
                                this.state.retailer_id.includes(
                                  val.retailer_id
                                )
                                  ? "true"
                                  : "";

                              return !check ? (
                                <li key={idx1}>
                                  <div className="Retailer_Block">
                                    <input
                                      type="checkbox"
                                      name="retailername"
                                      value={val.wholesaler_id}
                                      onChange={(e) =>
                                        this.selectRetailer(
                                          e,
                                          val.wholesaler_id,
                                          val.retailer_id,
                                          val.retailer_name
                                        )
                                      }
                                      style={{
                                        margin: "9px",
                                        zoom: "1.2",
                                        float: "right"
                                      }}
                                    />
                                    <div className="RetailerImage">
                                      <img
                                        src={val.logo}
                                        className="img-fluid "
                                        alt="Wholesaler"
                                      />
                                    </div>
                                    <h5>
                                      {" "}
                                      {val.retailer_name}
                                    </h5>
                                  </div>
                                </li>
                              ) : (
                                ""
                              );
                            }
                          )}

                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer brand-whole-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default AddretailerModal;
