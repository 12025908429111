const ProductMovementModal =()=>{


    return(

        <div
        className="modal fade Confirmation_Modal"
        id="EditModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="EditModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <div className="modal-body p-4">
              <h4 className="fw-bold text-center">More Information</h4>
              <p className="mt-10 text-center">
                Please contact your Account Manager.
              </p>
            </div>
            <div className="modal-footer">
              {/* <button type="button" className="btn btn-primary">Save</button> */}
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      

    )
}

export default ProductMovementModal;