import React, { useEffect } from "react";
import Error from "../../../Assets/images/error.svg";
import { CalenderDateFormatter } from "../../../Components/Helper";
function EditCampaignDetails({
  changePrevTab,
  changeRetailerFourthTab,
  selectedOption,
  wholesalersData,
  retailersData,
  setStartDateRetailerWholesaler,
  setEndDateRetailerWholesaler,
  setShutoffDateRetailerWholesaler,
  cliplimitChange,
  start_date,
  end_date,
  shutoff_date
}) {
  console.log("wholersaler",wholesalersData);
  console.log("start",start_date);

  const PastDisableDate = (val) => {
    var today = new Date(val);

    today.setDate(today.getDate());
    var yyyy = today.getFullYear();
    var mm = "" + (today.getMonth() + 1);
    var dd = "" + today.getDate();

    if (mm.length < 2) mm = "0" + mm;
    if (dd.length < 2) dd = "0" + dd;

    var disablePast = yyyy + "-" + mm + "-" + dd;

    return disablePast;
  };
  const FutureDisableDate = (val) => {
    var today = new Date(val);
    today.setDate(today.getDate());
    var yyyy = today.getFullYear();
    var mm = "" + (today.getMonth() + 1);
    var dd = "" + today.getDate();

    if (mm.length < 2) mm = "0" + mm;
    if (dd.length < 2) dd = "0" + dd;

    var disablePast = yyyy + "-" + mm + "-" + dd;

 

    return disablePast;
  };

  const DateFormat = (val) => {
    var today = new Date(val);
    today.setDate(today.getDate()+1);
    var yyyy = today.getFullYear();
    var mm = "" + (today.getMonth() + 1);
    var dd = "" + today.getDate();

    if (mm.length < 2) mm = "0" + mm;
    if (dd.length < 2) dd = "0" + dd;

    var disablePast = yyyy + "-" + mm + "-" + dd;
    return disablePast;
  };

  const dateFormat = (date) => {
    date.setDate(date.getDate());
    var month = date.getMonth() + 1;
    var day = date.getDate();
    var year = date.getFullYear();

    if (month < 10) month = "0" + month.toString();
    if (day < 10) day = "0" + day.toString();

    let calculate_date = year + "-" + month + "-" + day;

    return calculate_date;
  };

  return (
    <div>
      <div className="Steps_Header ">
        <h5 className="">Copy Coupon</h5>
      </div>
      {selectedOption === "wholesaler" ? (
        <>
          {wholesalersData.map((val, idx) => {
            return (
              <div className=" col-12" key={idx}>
                <div className="Campaign_Details_Card">
                  <div className="Campaign_Details_Card_Header d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center ">
                      <div className="Campaign_Logo">
                        <img src={val.logo} className="img-fluid " alt={val} />
                      </div>
                      <h5 className="ml-16">{val.wholesaler_name}</h5>
                    </div>
                    <span>
                      {/* <img src={Plus} className="img-fluid " alt="Plus" /> */}
                    </span>
                  </div>
                  <div className="Campaign_Details_Card_Body">
                    <ul>
                      <li>
                        <div className="d-flex align-items-center Title_Block">
                          <h5>Start Date</h5>
                          <img
                            src={Error}
                            className="img-fluid ml-5"
                            alt="Error"
                          />
                        </div>
                        <div className="Content_Block">
                          <div className="Date_Picker">
                            <input
                              type="date"
                              onKeyDown={(e) => e.preventDefault()}
                              name="campaign_start_date"
                              id="campaign_start_date"
                              // value={CalenderDateFormatter(
                              //   new Date(val.wholesaler_start_date)
                              // )}
                              value = {val.wholesaler_start_date.slice(0,10)}
                              min={
                                new Date(
                                  new Date(
                                    PastDisableDate(
                                      start_date
                                        ? start_date.slice(0,10)
                                        : val.wholesaler_start_date
                                    )
                                  ).getTime() +
                                    1 * 24 * 60 * 60 * 1000
                                )
                                  .toISOString()
                                  .split("T")[0]
                              }
                              // defaultValue={
                              //   wholesalersData
                              //     ? CalenderDateFormatter(
                              //       new Date(
                              //         val.wholesaler_start_date
                              //       )
                              //     )
                              //     : ""
                              // }
                              // min={
                              //   new Date(
                              //     new Date(PastDisableDate(val.wholesaler_start_date)).getTime() + 1 * 24 * 60 * 60 * 1000
                              //   )
                              //     .toISOString()
                              //     .split("T")[0]

                              // }

                              // min={
                              //   camp_minDate
                              //     ? camp_minDate
                              //     : camp_minDate
                              // }
                              onChange={(e) =>
                                setStartDateRetailerWholesaler(e, val)
                              }
                            />
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="d-flex align-items-center Title_Block">
                          <h5>Expiration Date</h5>
                          <img
                            src={Error}
                            className="img-fluid ml-5"
                            alt="Error"
                          />
                        </div>
                        <div className="Content_Block">
                          <input
                            type="date"
                            name="camp_end_date"
                            onKeyDown={(e) => e.preventDefault()}
                            //   id="campaign_end_date"
                            // value={CalenderDateFormatter(
                            //   new Date(val.wholesaler_end_date)
                            // )}
                            value={val.wholesaler_end_date.slice(0,10)}
                            max={
                              new Date(
                                new Date(
                                  FutureDisableDate(
                                    end_date
                                      ? end_date.slice(0,10)
                                      : val.wholesaler_end_date
                                  )
                                ).getTime() +
                                  1 * 24 * 60 * 60 * 1000
                              )
                                .toISOString()
                                .split("T")[0]
                            }
                            min={
                              new Date(
                                new Date(
                                  PastDisableDate(
                                    val.wholesaler_start_date.slice(0,10)
                                    )
                                ).getTime() +
                                  1 * 24 * 60 * 60 * 1000
                              )
                                .toISOString()
                                .split("T")[0]
                            }
                            // defaultValue={
                            //   wholesalersData
                            //     ? CalenderDateFormatter(
                            //       new Date(
                            //         val.wholesaler_end_date
                            //       )
                            //     )
                            //     : ""
                            // }
                            // max={
                            //   new Date(
                            //     new Date(FutureDisableDate(val.wholesaler_end_date)).getTime() + 1 * 24 * 60 * 60 * 1000
                            //   )
                            //     .toISOString()
                            //     .split("T")[0]
                            // }
                            // min={
                            //   new Date(
                            //     new Date(PastDisableDate(val.wholesaler_start_date)).getTime() + 1 * 24 * 60 * 60 * 1000
                            //   )
                            //     .toISOString()
                            //     .split("T")[0]

                            // }
                            //   max={
                            //     camp_maxDate
                            //       ? camp_maxDate
                            //       : ""
                            //   }
                            onChange={(e) =>
                              setEndDateRetailerWholesaler(e, val)
                            }
                          />
                        </div>
                      </li>
                      <li>
                        <div className="d-flex align-items-center Title_Block">
                          <h5>Shutoff Date</h5>
                          <img
                            src={Error}
                            className="img-fluid ml-5"
                            alt="Error"
                          />
                        </div>
                        <div className="Content_Block">
                          <div className="Date_Picker">
                            <input
                              type="date"
                              name="shut_off_date"
                              onKeyDown={(e) => e.preventDefault()}
                              // defaultValue={
                              //   retailersData
                              //     ? CalenderDateFormatter(
                              //       new Date(
                              //         val.wholesaler_shutoff_date
                              //       )
                              //     )
                              //     : ""
                              // }
                              // max={
                              //   new Date(
                              //     new Date(FutureDisableDate(val.wholesaler_shutoff_date)).getTime() + 1 * 24 * 60 * 60 * 1000
                              //   )
                              //     .toISOString()
                              //     .split("T")[0]
                              // }
                              // min={
                              //   new Date(
                              //     new Date(PastDisableDate(val.wholesaler_start_date)).getTime() + 1 * 24 * 60 * 60 * 1000
                              //   )
                              //     .toISOString()
                              //     .split("T")[0]

                              // }

                              // value={CalenderDateFormatter(
                              //   new Date(val.wholesaler_shutoff_date)
                              // )}
                              value={val.wholesaler_shutoff_date.slice(0,10)}
                              max={
                                new Date(
                                  new Date(
                                    FutureDisableDate(
                                      
                                      val.wholesaler_shutoff_date.slice(0,10)
                                    )
                                  ).getTime() +
                                    1 * 24 * 60 * 60 * 1000
                                )
                                  .toISOString()
                                  .split("T")[0]
                              }
                              min={
                                new Date(
                                  new Date(
                                    PastDisableDate(
                                      val.wholesaler_start_date.slice(0,10)
                                      )
                                  ).getTime() +
                                    1 * 24 * 60 * 60 * 1000
                                )
                                  .toISOString()
                                  .split("T")[0]
                              }
                              // max={
                              //   camp_maxDate
                              //     ? camp_maxDate
                              //     : ""
                              // }
                              onChange={(e) =>
                                setShutoffDateRetailerWholesaler(e, val)
                              }
                            />
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="d-flex align-items-center Title_Block">
                          <h5>Max Clips</h5>
                          <img
                            src={Error}
                            className="img-fluid ml-5"
                            alt="Error"
                          />
                        </div>
                        <div className="Content_Block">
                          <input
                            type="number"
                            defaultValue={val.clip_limit}
                            //   name="clip_limit"
                            //   // id={`clip_limit-` + val}
                            onChange={(e) => cliplimitChange(e, val)}
                          />
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            );
          })}
        </>
      ) : retailersData ? (
        <>
          {retailersData.map((val1, idx1) => {
            return (
              <div className=" col-12" key={idx1}>
                <div className="Campaign_Details_Card">
                  <div className="Campaign_Details_Card_Header d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center ">
                      <div className="Campaign_Logo">
                        <img
                          src={val1.logo}
                          className="img-fluid "
                          alt="campaign"
                        />
                      </div>
                      <h5 className="ml-16">{val1.retailer_name}</h5>
                    </div>
                    <span></span>
                  </div>
                  <div className="Campaign_Details_Card_Body">
                    <ul>
                      <li>
                        <div className="d-flex align-items-center Title_Block">
                          <h5>Start Date</h5>
                          <img
                            src={Error}
                            className="img-fluid ml-5"
                            alt="Error"
                          />
                        </div>
                        <div className="Content_Block">
                          <div className="Date_Picker">
                            <input
                              type="date"
                              onKeyDown={(e) => e.preventDefault()}
                              name="camp_retailer_start_date"
                              id="camp_retailer_start_date"
                             // value={CalenderDateFormatter(new Date(val1.start_date))}
                             value={val1.start_date.slice(0,10)}
                             min={
                              new Date(
                                new Date(
                                  PastDisableDate(
                                    start_date
                                      ? start_date.slice(0,10)
                                      : val1.start_date
                                  )
                                ).getTime() +
                                  1 * 24 * 60 * 60 * 1000
                              )
                                .toISOString()
                                .split("T")[0]
                            }
                              // min={
                              //   camp_minDate
                              //     ? camp_minDate
                              //     : camp_minDate
                              // }
                              onChange={(e) =>
                                setStartDateRetailerWholesaler(e, val1)
                              }
                            />
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="d-flex align-items-center Title_Block">
                          <h5>Expiration Date</h5>
                          <img
                            src={Error}
                            className="img-fluid ml-5"
                            alt="Error"
                          />
                        </div>
                        <div className="Content_Block">
                          <input
                            type="date"
                            name="camp_retailer_end_date"
                            onKeyDown={(e) => e.preventDefault()}
                            id="camp_retailer_end_date"
                            //value={CalenderDateFormatter(new Date(val1.end_date))}
                            value={val1.end_date.slice(0,10)}
                            max={
                              new Date(
                                new Date(
                                  FutureDisableDate(
                                    end_date
                                      ? end_date.slice(0,10)
                                      : val1.end_date
                                  )
                                ).getTime() +
                                  1 * 24 * 60 * 60 * 1000
                              )
                                .toISOString()
                                .split("T")[0]
                            }
                            min={
                              new Date(
                                new Date(
                                  PastDisableDate(
                                    val1.start_date.slice(0,10)
                                    )
                                ).getTime() +
                                  1 * 24 * 60 * 60 * 1000
                              )
                                .toISOString()
                                .split("T")[0]
                            }
                            //   max={
                            //     camp_maxDate
                            //       ? camp_maxDate
                            //       : ""
                            //   }
                            onChange={(e) =>
                              setEndDateRetailerWholesaler(e, val1)
                            }
                          />
                        </div>
                      </li>
                      <li>
                        <div className="d-flex align-items-center Title_Block">
                          <h5>Shutoff Date</h5>
                          <img
                            src={Error}
                            className="img-fluid ml-5"
                            alt="Error"
                          />
                        </div>
                        <div className="Content_Block">
                          <div className="Date_Picker">
                            <input
                              type="date"
                              name="camp_retailer_shutoff_date"
                              id="camp_retailer_shutoff_date"
                              onKeyDown={(e) => e.preventDefault()}
                            //  value={CalenderDateFormatter(new Date(val1.shutoff_date))}
                            value={val1.shutoff_date.slice(0,10)}
                              

                            max={
                              new Date(
                                new Date(
                                  FutureDisableDate(
                                     val1.shutoff_date.slice(0,10)
                                  )
                                ).getTime() +
                                  1 * 24 * 60 * 60 * 1000
                              )
                                .toISOString()
                                .split("T")[0]
                            }
                            min={
                              new Date(
                                new Date(
                                  PastDisableDate(
                                    val1.start_date.slice(0,10)
                                    )
                                ).getTime() +
                                  1 * 24 * 60 * 60 * 1000
                              )
                                .toISOString()
                                .split("T")[0]
                            }
                              // max={
                              //   camp_maxDate
                              //     ? camp_maxDate
                              //     : ""
                              // }
                              onChange={(e) =>
                                setShutoffDateRetailerWholesaler(e, val1)
                              }
                            />
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="d-flex align-items-center Title_Block">
                          <h5>Max Clips</h5>
                          <img
                            src={Error}
                            className="img-fluid ml-5"
                            alt="Error"
                          />
                        </div>
                        <div className="Content_Block">
                          <input
                            type="text"
                            defaultValue={val1.clip_limit}
                            //   name="retailer_clip_limit"
                            //   // id={"retailer_clip_limit-" + val1}
                            onChange={(e) => cliplimitChange(e, val1)}
                          />
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            );
          })}
        </>
      ) : (
        ""
      )}
      <div className="row mt-23 mb-20">
        <div className="col-12 d-flex justify-content-end">
          <div className="TableButton">
            <button
              type="button"
              className="mr-10 btn btn-primary"
              onClick={(e) => {
                changePrevTab(e, 3);
              }}
            >
              <i className="fas fa-arrow-left mr-10" aria-hidden="true"></i>{" "}
              Prev
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={(e) => {
                changeRetailerFourthTab(e, 5);
              }}
            >
              Next{" "}
              <i className="fas fa-arrow-right ml-10" aria-hidden="true"></i>{" "}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditCampaignDetails;
