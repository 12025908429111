import React from "react";
import { Link } from "react-router-dom";
import HelpCenter from ".";
import HelpCenterimg from "../../Assets/images/help-center.svg";
import Lefticonhelpimg from "../../Assets/images/left-icon-help.svg";
import RightBannerimg from "../../Assets/images/right-banner-help.svg";
import FooterStrict from "../Dashboard/FooterStrict";
import Header from "../Dashboard/Header";
import ls from "local-storage";


class Helpcenter extends React.Component {
  render() {
    const isLogged = ls.get("token");
    const is_consent_provided = ls.get("decodetoken") ? ls.get("decodetoken").is_consent_provided : "";   
    
    return (
      <>
        
       { !isLogged || is_consent_provided == false?  
    
        <header className="Header">
          <div className="Second_Header_help">
            <div className="d-flex align-items-center Coupons_Head sec-header-head justify-content-between">
              <div class="d-flex align-items-center mb-0">
                <img
                  src={HelpCenterimg}
                  className="img-fluid mr-7"
                  alt="Add Icon"
                />
                <span>Help Center</span>
              </div>
              <div class="mt-btn d-flex">
                <div className="dropdown Profile_Dropdown">
                  <button
                    className="helpcent-head dropdown-toggle"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    English (US)
                    <svg
                      width="14"
                      class="help-icon ml-7"
                      height="8"
                      viewBox="0 0 14 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.00072 5.17205L11.9507 0.222046L13.3647 1.63605L7.00072 8.00005L0.636719 1.63605L2.05072 0.222046L7.00072 5.17205Z"
                        fill="current"
                      />
                    </svg>
                  </button>
                  <ul
                    className="dropdown-menu "
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <li>
                    <a href="/" data-bs-toggle="modal" data-bs-target="#inactivemodal" className="dropdown-item">
            
                      {/* <Link className="dropdown-item" to="">
                         */}
                         English(US)
                      </a>
                    </li>
                    {/* <li>
                      <Link className="dropdown-item" to="/">
                        English(Uk)
                      </Link>
                    </li> */}
                  </ul>
                </div>
                <div>
                <a href="/" data-bs-toggle="modal" data-bs-target="#inactivemodal">
                  <button className="helpcent-head">
                    <svg
                      width="22"
                      height="13"
                      class="help-icon mr-7"
                      viewBox="0 0 22 13"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10.602 8.76L12.014 10.172L20.48 1.706L21.894 3.12L12.014 13L5.65 6.636L7.064 5.222L9.189 7.347L10.602 8.759V8.76ZM10.604 5.932L15.556 0.979004L16.966 2.389L12.014 7.342L10.604 5.932ZM7.777 11.587L6.364 13L0 6.636L1.414 5.222L2.827 6.635L2.826 6.636L7.777 11.587V11.587Z"
                        fill="current"
                      />
                    </svg>
                    Submit a request
                  </button>
                  </a>
                </div>

                <div>
                <a href="/login">
                  <button className="helpcent-head" >
                    <svg
                      width="12"
                      height="17"
                      viewBox="0 0 12 17"
                      fill="none"
                      class="help-icon mr-7"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12 16.5H10.5V15C10.5 14.4033 10.2629 13.831 9.84099 13.409C9.41903 12.9871 8.84674 12.75 8.25 12.75H3.75C3.15326 12.75 2.58097 12.9871 2.15901 13.409C1.73705 13.831 1.5 14.4033 1.5 15V16.5H0V15C0 14.0054 0.395088 13.0516 1.09835 12.3483C1.80161 11.6451 2.75544 11.25 3.75 11.25H8.25C9.24456 11.25 10.1984 11.6451 10.9016 12.3483C11.6049 13.0516 12 14.0054 12 15V16.5ZM6 9.75C5.40905 9.75 4.82389 9.6336 4.27792 9.40746C3.73196 9.18131 3.23588 8.84984 2.81802 8.43198C2.40016 8.01412 2.06869 7.51804 1.84254 6.97208C1.6164 6.42611 1.5 5.84095 1.5 5.25C1.5 4.65905 1.6164 4.07389 1.84254 3.52792C2.06869 2.98196 2.40016 2.48588 2.81802 2.06802C3.23588 1.65016 3.73196 1.31869 4.27792 1.09254C4.82389 0.866396 5.40905 0.75 6 0.75C7.19347 0.75 8.33807 1.22411 9.18198 2.06802C10.0259 2.91193 10.5 4.05653 10.5 5.25C10.5 6.44347 10.0259 7.58807 9.18198 8.43198C8.33807 9.27589 7.19347 9.75 6 9.75V9.75ZM6 8.25C6.79565 8.25 7.55871 7.93393 8.12132 7.37132C8.68393 6.80871 9 6.04565 9 5.25C9 4.45435 8.68393 3.69129 8.12132 3.12868C7.55871 2.56607 6.79565 2.25 6 2.25C5.20435 2.25 4.44129 2.56607 3.87868 3.12868C3.31607 3.69129 3 4.45435 3 5.25C3 6.04565 3.31607 6.80871 3.87868 7.37132C4.44129 7.93393 5.20435 8.25 6 8.25V8.25Z"
                        fill="current"
                      />
                    </svg>
                    Sign In
                  </button>

                  </a>
                </div>
              </div>
            </div>
          </div>
        </header>
        
        :
        <Header location={`helpcenter`}/>
        }



        <section className="ContentBlock  pt-24 pb-24 Main_Block mb-50">
          <div className="Tab_Header pb-70">
            <div className="container-fluid">
              <div className="banner-box">
                <div class="row justify-content-center">
                  <div class="col-lg-8 col-12 help-banner-text">
                    <h5>
                      It looks like you aren’t signed in <br></br>to the
                      <font color="#73FFDD"> Help center</font>.
                    </h5>
                    <p>
                      For the best support experience and access to all the{" "}
                      <br></br>help info. please log in to your{" "}
                      <b class="banner-b-text">BrandIQ</b> account.
                    </p>
                    <span>How to do this: </span>
                    <ul class="helpbanner-ul">
                      <li>
                        Go to your{" "}
                        <Link to="/">https://www.brandiq.ai</Link>
                      </li>
                      <li>Sign in using your email and password</li>
                      <li>
                        Click on the <b>“Help”</b> link in the upper right hand
                        corner of your dashboard.
                      </li>
                      <li>Get full access to all the info you need!</li>
                    </ul>
                  </div>
                  <div class="col-lg-4 col-12">
                    <div class="img-right-banner mt-20">
                      <img
                        src={RightBannerimg}
                        className="img-fluid help-icon mr-7"
                        alt="Add Icon"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 ">
                  <h5 class="how-help-center">How can we help?</h5>
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row justify-content-center">
              <div className="col-xl-5 col-md-10 col-12">
                <div className="Search_Bar position-relative">
                  <input
                    type="text"
                    className="form-control "
                    placeholder="Search here"
                    aria-label="Search"
                    aria-describedby="search-addon"
                  />
                  <span className="Input_Icon position-absolute top-50 right-ban-search translate-middle-y">
                    <svg
                      width="19"
                      height="18"
                      viewBox="0 0 19 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.7 14.9C12.4003 14.9 15.4 11.9003 15.4 8.2C15.4 4.49969 12.4003 1.5 8.7 1.5C4.99969 1.5 2 4.49969 2 8.2C2 11.9003 4.99969 14.9 8.7 14.9Z"
                        stroke="#AFAFAF"
                        stroke-width="1.5"
                        stroke-miterlimit="3.3333"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M16.9984 16.5L13.3984 12.9"
                        stroke="#AFAFAF"
                        stroke-width="1.5"
                        stroke-miterlimit="3.3333"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row justify-content-center mt-50">
              <div class="col-xl-8 col-lg-12 col-12">
                <div class="">
                  <form class="row gx-4 gy-3 mp-top">
                    <div class="col-md-6 mb-5">
                      <div class="support-box">
                        <p class="d-flex">
                          <img
                            src={Lefticonhelpimg}
                            className="img-fluid help-icon mr-7"
                            alt="Add Icon"
                          />
                          <span class="ml10">Technical Support</span>
                        </p>
                        <span>
                          I'm having difficulties using the site, where can I
                          get help? <br></br> Browser support
                        </span>
                      </div>
                    </div>
                    <div class="col-md-6 mb-5">
                      <div class="support-box">
                        <p class="d-flex">
                          <img
                            src={Lefticonhelpimg}
                            className="img-fluid help-icon mr-7"
                            alt="Add Icon"
                          />
                          <span class="ml10">Technical Support</span>
                        </p>
                        <span>
                          I'm having difficulties using the site, where can I
                          get help? <br></br> Browser support
                        </span>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="support-box">
                        <p class="d-flex">
                          <img
                            src={Lefticonhelpimg}
                            className="img-fluid help-icon mr-7"
                            alt="Add Icon"
                          />
                          <span class="ml10">Technical Support</span>
                        </p>
                        <span>
                          I'm having difficulties using the site, where can I
                          get help? <br></br> Browser support
                        </span>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="support-box">
                        <p class="d-flex">
                          <img
                            src={Lefticonhelpimg}
                            className="img-fluid help-icon mr-7"
                            alt="Add Icon"
                          />
                          <span class="ml10">Technical Support</span>
                        </p>
                        <span>
                          I'm having difficulties using the site, where can I
                          get help? <br></br> Browser support
                        </span>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div class=""></div>
          </div>
                     {/* BEGIN: INACTIVE MODAL */}
                     <div
          className="modal fade Confirmation_Modal"
          id="inactivemodal"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex="-1"
          aria-labelledby="ViewModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered ">
            <div className="modal-content">
              <div className="modal-body p-4">
                <h4 className="fw-bold text-center">Not available for your current subscription.</h4>
                <p className="mt-10 text-center">
                  .
                </p>
                </div>
              <div className="modal-footer">
                <button
                  type="button"
                  class="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  OK
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* END: INACTIVE MODAL */}
        </section>
      
      </>
    );
  }
}
export default Helpcenter;
