import React from 'react';
import ls from "local-storage";

class UserInactive extends React.Component {

    componentDidMount() {
        ls.clear("token");
        ls.clear("brands");
        window.location.href = "/login/?userInactive"
    }

    render() {
        return (
            <React.Fragment>
            </React.Fragment>
        )
    }
}

export default UserInactive