import React from 'react'
import FooterStrict from '../Dashboard/FooterStrict'
import Header from '../Dashboard/Header'

export default function Notification() {
  return (
      <>
          <Header location={`notification`}/>
          <div className='ContentBlock pt-40 pb-24 Notification_List'>
              <div className='container'>
                  <div className='row justify-content-center '>
                      <div className='col-xl-8 col-12'>
                          <h4 className='mb-15'>Notification List</h4>
                          <ul className='Notification_Outer_Block'>
                              <li >
                                  <div className='Notification_Box'>
                                      <h5>New inspection assigned</h5>
                                      <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
                                     
                                  </div>
                              </li>
                              <li >
                                  <div className='Notification_Box'>
                                      <h5>New inspection assigned</h5>
                                      <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
                                     
                                  </div>
                              </li>
                              <li >
                                  <div className='Notification_Box'>
                                      <h5>New inspection assigned</h5>
                                      <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
                                     
                                  </div>
                              </li>
                              <li >
                                  <div className='Notification_Box'>
                                      <h5>New inspection assigned</h5>
                                      <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
                                     
                                  </div>
                              </li>
                              <li >
                                  <div className='Notification_Box'>
                                      <h5>New inspection assigned</h5>
                                      <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
                                     
                                  </div>
                              </li>
                              <li >
                                  <div className='Notification_Box'>
                                      <h5>New inspection assigned</h5>
                                      <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
                                     
                                  </div>
                              </li>
                              <li >
                                  <div className='Notification_Box'>
                                      <h5>New inspection assigned</h5>
                                      <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
                                     
                                  </div>
                              </li>
                             
                          </ul>
                      </div>
                  </div>
              </div>
          </div>
           <FooterStrict />
    </>
  )
}
