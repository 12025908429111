import React from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Loader = () => <div id="cover-spin"></div>;

class AddBrandRetailer extends React.Component {

  constructor(props) {
    super(props);
    toast.configure();

    this.state = {
      retailerdata: this.props.retailerData,
      retailer_number: [],
      checkedListAll: []
    };

  }

  componentDidUpdate(prevProps, nextProps) {
    if (prevProps !== this.props) {
      this.setState({
        checkedListAll: prevProps.checkedListAll,

      });
    }
  }

  render() {

    return (
      <>
        {this.state.loading ? <Loader /> : null}

        <div
          className="modal fade"
          id="selectretailer-sel-drop"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-custom modal-dialog-scrollable">
            <div className="modal-content whole-content">
              <div className="modal-header borderemove brand-whole-hd">
                <h5 className="modal-title" id="exampleModalLabel">
                  Select Retailer
                </h5>

                <div className="header_right" style={{ float: 'right', marginTop: '-2px', marginRight: '80px', color: '#fff' }}>
                  <input type="checkbox" onClick={this.props.selectAllRetailerCheckboxes.bind(this)} value="checkedall" />{" "}Check / Uncheck All
                </div>

                <button
                  type="button"
                  className="btn-close wholeseler-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>

              <div className="modal-body CustomScroll whole-seler-body">
                <div className="tab-content" id="nav-tabContent">
                  <div
                    className="tab-pane fade show active tab-text"
                    id="nav-home"
                    role="tabpanel"
                    aria-labelledby="nav-home-tab"
                  >
                    <div className="Common_brand_detail">
                      <div className="pr-12 pl-12 pt-20">


                        <ul className=" mt-15 d-flex  Retailer">
                          {Object.keys(this.state.retailerdata).length > 0 ? (
                            <>
                              {this.state.retailerdata.map((val, idx) => {
                                return (

                                  <li key={val.retailer_name}>
                                    <div className="Retailer_Block">
                                      <input
                                        type="checkbox"
                                        value={val.retailer_id}
                                        data-wholesaler_id={val.wholesaler_id}
                                        checked={this.state.checkedListAll.some(el => el.retailer_id === val.retailer_id)}
                                        onChange={this.props.selectRetailer}
                                      />
                                      <div className="RetailerImage">
                                        <img
                                          className="img-fluid"
                                          alt={val.retailer_name}
                                          src={
                                            val.logo
                                          }
                                        />
                                      </div>
                                      <h5>
                                        {val.retailer_name}
                                      </h5>
                                    </div>
                                  </li>
                                );
                              })}
                            </>
                          ) : (
                            "No Retailers to choose"
                          )}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer brand-whole-footer">
              <button
                  type="button"
                  className="btn btn-primary Btn_blue_modal"
                      data-bs-dismiss="modal"
                  aria-label="Close"
             
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default AddBrandRetailer;
