import ls from "local-storage";
import { toast } from "react-toastify";
import * as CONFIG from "../Components/_config";
import axios from "axios";

let decodetoken = ls.get("decodetoken") ? ls.get("decodetoken") : "";
var companyguid;
if (ls.get("user") == "broker" && ls.get("selectedCompanyName") != undefined) {
  let guidcompany = ls.get("selectedCompanyName");
  companyguid = guidcompany.companyName.value;
} else {
  companyguid = decodetoken.company_guid;
}
export const getCompany = () => {
  let config = {
    method: "get",
    url: CONFIG.API_URL + `company/${companyguid}`,
    headers: CONFIG.config_head.headers
  };
  return axios(config)
    .then((response) => {

         return response.data;
    
        })

    .catch(function (error) {
      if (error.response.status === 400) {
        toast.error(error.response.data.Message);
        return error.response;
        
      } 
      else if (error.response.status === 504) {

        return error.response;

        //toast.error('Something went wrong - please try again in few minutes..')
      
      }
      else if (error.response.status === 403) {
        toast.error(error.response.data.Message);
        ls.clear("token");
        ls.clear("brands");
        window.location.href = "/login";
      } else {
        
        toast.error(error.response.data.Message);
      }
    });
};


export const getCompanyBrandStore = () => {
    let config = {
      method: "get",
      url: CONFIG.API_URL + `company/getstores/${companyguid}`,
      headers: CONFIG.config_head.headers
    };
    return axios(config)
      .then((response) => {
         return response.data;
      })
      .catch( (error)=> {
       
        if(error.response){
          if (error.response.status === 400) { 
             return error.response.data
          } else if (error.response.status === 403) {
            toast.error(error.response.data.Message);
            ls.clear("token");
            ls.clear("brands");
            window.location.href = "/login";
      
            } else {
                console.log(error)
      
          }
        
      }
      });
  };


  export const getListOfCompanyCoupon = () => {
    let data = JSON.stringify({
       active: 1,
      page: 1,
      count: 10,
      sort: "-name",
      "include-clip": true,
      "include-redemption": true,
      search: "",
      "coupon-start-date": "",
      "coupon-end-date": ""
    });
  
    let config = {
      method: "post",
      url: CONFIG.API_URL + "coupon/searchbycompany",
      headers: CONFIG.config_head.headers,
      data: data
    };
  
    return axios(config)
      .then((response) => {
        return response.data;
      })
      .catch( (error)=> {
        if(error.response){
          if (error.response.status === 400) {
          
           // console.log('err',error.response)
            return error.response.data
            
          } else if (error.response.status === 403) {
            toast.error(error.response.data.Message);
            ls.clear("token");
            ls.clear("brands");
            window.location.href = "/login";
      
            } else {
              console.log(error)
      
          }
        
      }
      });
  };
  
 