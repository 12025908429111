import React from "react";
//import $ from "jquery";
import { Form } from 'react-bootstrap';

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ls from "local-storage";
import axios from "axios";
import Select from 'react-select'
 
import * as CONFIG from "../../Components/_config";

class AddwholesalerModal extends React.Component {
  constructor(props) {
    super(props);
    this.handleSave = this.handleSave.bind(this);
  
    this.selectWholesaler = this.selectWholesaler.bind(this);
    
    toast.configure();

    this.state = {
      retailerdata: this.props.Allretailer,
      retailer_id: this.props.existRetailerid,
      warehouse_arr: [],
      brand_guid: this.props.brandguidata,
      warehouse:'',
    };
  }

  selectWholesaler = async (e) => {
  
    
    //master/wholesalerGUID?type=warehouse&search
    var wholesaler_guid = e.target.value;
    
    var wholesalername1 =[];
    wholesalername1.push({'wholesalername':e.target.options[e.target.selectedIndex].dataset.wholesalername,'wholesalerlogo':e.target.options[e.target.selectedIndex].dataset.logo})
    
    const token_config = {
      headers: CONFIG.config_head.headers
    };

    const requestOne = axios.get(
      CONFIG.API_URL + `master/${wholesaler_guid}?type=warehouse&search=''`,
      token_config
    );
    axios
    .all([requestOne  ])
    .then(
      axios.spread((...responses) => {
        let responseOne = responses[0];

        var  options = responseOne.data.Message.map(item => {
          return {
              label: item.warehouse_name,
              value: item.warehouse_id,
              wholesaler_id:item.wholesaler_id    
          }
      })
  
            
        this.setState({
          warehouseDetails: responseOne.data.Message,
           options:options,
          showwarehouse:true,

         });
 
      })
    )
    .catch( (error)=> {
      if (error.response) {
        if (error.response.status === 400) {
           toast.error(error.response.data.Message);
           
           this.setState({
            warehouseDetails: {},
            showwarehouse:false,
            
           })
         

         } else if (error.response.status === 403) {
          toast.error(error.response.data.Message);
          ls.clear("token");
          ls.clear("brands");

          window.location.href = "/login";
        } else {
          toast.error(error.response.data.Message);
        }
      }
    });

   
  
  };

  
  handleCheck(val) {

    return this.state.warehouse_arr.some(item => val.warehouse_id === item.warehouse_id);
}

 
  handleSave() {
    const item = this.state;
    this.props.updateDeal(item)
  }



  componentDidMount() {
    // console.log('>>>>wlist',this.props.WholeSalerlist)
  }

  
  render() {
   
    return (
      <>
          <div className="modal fade" id="wholesalerModal" tabIndex="-1" role="dialog" aria-labelledby="wholesalerModalLabel"   data-bs-backdrop="static"
            data-bs-keyboard="false"
          aria-hidden="true">
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Select Wholesaler</h5>
                            <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                        <select   className="form-control " onChange={this.selectWholesaler}>  
<option value={0}>Select Please</option>
{this.props.Allwholesaler.map((val, idx) => {
 return val.has_full_access === true ? (

<option data-logo={val.logo} data-wholesalername={val.wholesaler_name} value={val.wholesaler_guid}>{val.wholesaler_name}</option>
) : (
    ""
);
})}


</select>


{
        
        (this.state.showwarehouse=== true) ?                        
        Object.keys(this.state.warehouseDetails).length > 0 ?
    
        <div className='row mt-15  justify-content-center' id="warehouseid">
        <div className='col-xl-12 col-lg-12 col-12'>
                    <Form.Group controlId="exampleForm.ControlTextarea1">
                        <Form.Label>Select Warehouse:</Form.Label>
                        <Select isMulti options={Object.keys(this.state.warehouseDetails).length > 0?this.state.options:''}  onChange={(e)=>this.props.handleWarehouse(e)}     className="react-select-container" />
                    </Form.Group>
                </div>
        </div>
          :''
          :''
          }
    
                        
                         </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                         
                        </div>
                    </div>
                </div>
          
        </div>
      </>
    );
  }
}

export default AddwholesalerModal;
