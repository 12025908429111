const ErrorModal = ({ }) => {


  return (
    <div
      className="modal fade Confirmation_Modal"
      id="errorComapnyModal"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-body">
            <i className="fas fa-exclamation-triangle text-center "></i>
            <p className="mt-10 text-center">
              Something went wrong - please try again in few minutes...
            </p>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-primary"
              onClick={(e) => window.location.reload()
              }
            >
              Retry
            </button>

          </div>
        </div>
      </div>
    </div>
  )
}
export default ErrorModal;