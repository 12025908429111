import React from "react";
import Header from "../Dashboard/Header";
import { Link } from "react-router-dom";
import ls from "local-storage";
import * as CONFIG from "../../Components/_config";
import axios from "axios";
import DataTable from "react-data-table-component";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FooterStrict from "../Dashboard/FooterStrict";
import edit_pencil from "../../Assets/images/edit_pencil.png";
import { renderTooltip } from "../../Components/Helper";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

class UpcList extends React.Component {
  constructor(props) {
    super(props);
    toast.configure();

    this.state = {
      UpcList: "",
      columns: [
        {
          name: "UPC11",
          selector: (row) => row.upc11,
          sortable: true
        },
        {
          name: "PRODUCT NAME",
          selector: (row) => row.product_name,
        },
        {
          name: "ITEM CODE",
          selector: (row) => row.item_code === 'None' ? '' : row.item_code,
          sortable: true
        },
        {
          name: "CATEGORY",
          selector: (row) => row.category_name,
          sortable: true
        },
        {
          name: "EDIT",
          selector: (row) => row.category_name,
          cell: row => (
            <>
              <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltip('Edit')}
              >
                <Link
                  to={{
                    pathname: "/UpcDetails",
                    state: {
                      params: {
                        id1: row.upc11,
                        id2: row.upc_guid,
                        id3: row.product_name,
                        id4: row.category_name,
                        id5: row.item_code
                      }
                    }
                  }}
                >
                  <img src={edit_pencil} height={'23px'} width={'22px'} style={{ marginRight: '12px' }} alt={row.upc11} />
                </Link>
              </OverlayTrigger>
            </>
          )
        },

      ]
    };
  }
  async componentDidMount() {
    //var guid = ls.get("brands") ? ls.get("brands")[0].guid : "";

    const selectedbrand = ls.get("selectedbrand")? ls.get("selectedbrand") : ls.get("brands")?ls.get("brands"):''  ;


    var data = "";
    var config = {
      method: "get",
      url: CONFIG.API_URL + `brand/${selectedbrand[0].guid}`,
      headers: CONFIG.config_head.headers,
      data: data
    };

    axios(config)
      .then((response) => {
        this.setState({ tableData: response.data.Message.upc, UpcList: response.data.Message });
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 400) {
            toast.error(error.response.data.Message);
          } else if (error.response.status === 403) {
            toast.error(error.response.data.Message);
            ls.clear("token");
            ls.clear("brands");
            window.location.href = "/login";
          } else {
            toast.error(error.response.data.Message);
          }
        }
      });

  }

  render() {
    return (
      <>
        <Header location={`upclist`} changebrandUpclist={this.handleChangeBrand} />

        <section className="ContentBlock pt-24 pb-24 Main_Block">
          <div className="container-fluid">
            <div className="row justify-content-center">
              <div className=" col-12">
                <div className="Card  Coupons mt-21">
                  <div className="Card_Header  d-flex align-items-center justify-content-between">
                    <h5>UPC List</h5>

                    <Link to="/categories"> <h5 style={{ float: "right" }}>View Categories </h5></Link>

                  </div>
                  <div className="Card_Body">
                    <div className="table-responsive Coupons_Table">
                      {this.state.UpcList ? (

                        <>
                          <DataTable
                            columns={this.state.columns}
                            data={this.state.tableData}
                            pagination
                          />
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="d-flex align-items-center justify-content-end Pagination_Block"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <FooterStrict />
      </>
    );
  }
}
export default UpcList;
