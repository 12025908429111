import React from "react";
import Error from "../../Assets/images/error.svg"
import { CalenderDateFormatter } from "../../Components/Helper";

function CampaignDetail({
  retailer,
  wholesaler,
  changeTargetTab,
    changePrevTab,
    selectedOption,
    camp_minDate,
    camp_maxDate,
    camp_shutoffDate,
    onchangeSetdate,
    onchangesetEndDate,
    onchangeShutOffDate,
    cliplimitChange,
    cliplimitChangeRetailer,
    onchangeSetdateRetailer,
    onchangeSetEnddateRetailer,
    onchangeSetShutoffdateRetailer,
    changeTabCompaignDetails

}) {

  const PastDisableDate = (val) => {
    var today = new Date(val);
    today.setDate(today.getDate()); 
    var yyyy = today.getFullYear();
    var mm = "" + (today.getMonth() + 1);
    var dd = "" + today.getDate();

    if (mm.length < 2) mm = "0" + mm;
    if (dd.length < 2) dd = "0" + dd;

    var disablePast = yyyy + "-" + mm + "-" + dd;

    return disablePast;
  };
  const FutureDisableDate = (val) => {
    var today = new Date(val);
    today.setDate(today.getDate());
    var yyyy = today.getFullYear();
    var mm = "" + (today.getMonth() + 1);
    var dd = "" + today.getDate();

    if (mm.length < 2) mm = "0" + mm;
    if (dd.length < 2) dd = "0" + dd;

    var disablePast = yyyy + "-" + mm + "-" + dd;

    // console.log("Disable Future", disablePast)

    return disablePast;
  };
  
  return (
    <React.Fragment>
      <div className="row  g-3">
        {selectedOption === "wholesaler" ? (
          <>
            {wholesaler.map((val, idx) => {
              //console.log("wholesaler",wholesaler)
              return (
                <div className=" col-12" key={idx}>
                  <div className="Campaign_Details_Card">
                    <div className="Campaign_Details_Card_Header d-flex align-items-center justify-content-between">
                      <div className="d-flex align-items-center ">
                        <div className="Campaign_Logo">
                          <img
                            src={val.logo}
                            className="img-fluid "
                            alt={val}
                          />
                        </div>
                        <h5 className="ml-16">{val.wholesaler_name}</h5>
                      </div>
                      <span>
                        {/* <img src={Plus} className="img-fluid " alt="Plus" /> */}
                      </span>
                    </div>
                    <div className="Campaign_Details_Card_Body">
                      <ul>
                        <li>
                          <div className="d-flex align-items-center Title_Block">
                            <h5>Start Date</h5>
                            <img
                              src={Error}
                              className="img-fluid ml-5"
                              alt="Error"
                            />
                          </div>
                          <div className="Content_Block">
                            <div className="Date_Picker">
                              <input
                                type="date"
                                onKeyDown={(e) => e.preventDefault()}
                                name="campaign_start_date"
                                id="campaign_start_date"
                                // defaultValue={camp_minDate}
                                value = {val.camp_iso_wholesaler_start_date.split("T")[0]}
                                min={
                                  camp_minDate
                                    ? camp_minDate
                                    : camp_minDate
                                }
                                onChange={(e) =>
                                  onchangeSetdate(e, val)
                                }
                              />
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="d-flex align-items-center Title_Block">
                            <h5>Expiration Date</h5>
                            <img
                              src={Error}
                              className="img-fluid ml-5"
                              alt="Error"
                            />
                          </div>
                          <div className="Content_Block">
                            <input
                              type="date"
                              name="camp_end_date"
                              onKeyDown={(e) => e.preventDefault()}
                              id="campaign_end_date"
                             // defaultValue={camp_maxDate}
                             value = {val.camp_iso_wholesaler_end_date.split("T")[0]}
                              // max={
                              //   camp_maxDate
                              //     ? camp_maxDate
                              //     : ""
                              // }
                              max={
                                camp_maxDate
                                  ? camp_maxDate
                                  : 
                                new Date(
                                  new Date(
                                    FutureDisableDate(
                                     val.camp_iso_wholesaler_end_date
                                    )
                                  ).getTime() +
                                    1 * 24 * 60 * 60 * 1000
                                )
                                  .toISOString()
                                  .split("T")[0]
                              }
                              min={
                                new Date(
                                  new Date(
                                    PastDisableDate(val.camp_iso_wholesaler_start_date)
                                  ).getTime() +
                                    1 * 24 * 60 * 60 * 1000
                                )
                                  .toISOString()
                                  .split("T")[0]
                              }

                              onChange={(e) =>
                                onchangesetEndDate(e, val)
                              }
                            />
                          </div>
                        </li>
                        <li>
                          <div className="d-flex align-items-center Title_Block">
                            <h5>Shutoff Date</h5>
                            <img
                              src={Error}
                              className="img-fluid ml-5"
                              alt="Error"
                            />
                          </div>
                          <div className="Content_Block">
                            <div className="Date_Picker">
                              <input
                                type="date"
                                name="shut_off_date"
                                onKeyDown={(e) => e.preventDefault()}
                               //defaultValue={camp_shutoffDate}
                              value = {val.camp_iso_wholesaler_shutoff_date.split("T")[0]}
                                // max={
                                //   camp_maxDate
                                //     ? camp_maxDate
                                //     : ""
                                // }
                                max={
                                 
                                  new Date(
                                    new Date(
                                      FutureDisableDate(
                                        val.camp_iso_wholesaler_shutoff_date
                                      )
                                    ).getTime() +
                                      1 * 24 * 60 * 60 * 1000
                                  )
                                    .toISOString()
                                    .split("T")[0]
                                }
                                min={
                                  new Date(
                                    new Date(
                                      PastDisableDate(val.camp_iso_wholesaler_start_date)
                                    ).getTime() +
                                      1 * 24 * 60 * 60 * 1000
                                  )
                                    .toISOString()
                                    .split("T")[0]
                                }
                                onChange={(e) =>
                                  onchangeShutOffDate(e, val)
                                 
                                }
                              />
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="d-flex align-items-center Title_Block">
                            <h5>Max Clips</h5>
                            <img
                              src={Error}
                              className="img-fluid ml-5"
                              alt="Error"
                            />
                          </div>
                          <div className="Content_Block">
                            <input
                              type="number"
                              defaultValue={1000000}
                              name="clip_limit"
                              // id={`clip_limit-` + val}
                              onChange={(e) =>
                                cliplimitChange(e, val)
                              }
                            />
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              );
            })}
          </>
        ) : retailer ? (
          <>
            {retailer.map((val1, idx1) => {
              //console.log("retailer",retailer)
              return (
                <div className=" col-12" key={idx1}>
                  <div className="Campaign_Details_Card">
                    <div className="Campaign_Details_Card_Header d-flex align-items-center justify-content-between">
                      <div className="d-flex align-items-center ">
                        <div className="Campaign_Logo">
                          <img
                            src={val1.retailerlogo}
                            className="img-fluid "
                            alt="campaign"
                          />
                        </div>
                        <h5 className="ml-16">{val1.retailer_name}</h5>
                      </div>
                      <span></span>
                    </div>
                    <div className="Campaign_Details_Card_Body">
                      <ul>
                        <li>
                          <div className="d-flex align-items-center Title_Block">
                            <h5>Start Date</h5>
                            <img
                              src={Error}
                              className="img-fluid ml-5"
                              alt="Error"
                            />
                          </div>
                          <div className="Content_Block">
                            <div className="Date_Picker">
                              <input
                                type="date"
                                onKeyDown={(e) => e.preventDefault()}
                                name="camp_retailer_start_date"
                                id="camp_retailer_start_date"
                               // defaultValue={camp_minDate}
                               value={val1.camp_iso_retailer_start_date.split("T")[0]}
                                min={
                                  camp_minDate
                                    ? camp_minDate
                                    : camp_minDate
                                }
                                onChange={(e) =>
                                    onchangeSetdateRetailer(
                                    e,
                                    val1
                                  )
                                }
                              />
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="d-flex align-items-center Title_Block">
                            <h5>Expiration Date</h5>
                            <img
                              src={Error}
                              className="img-fluid ml-5"
                              alt="Error"
                            />
                          </div>
                          <div className="Content_Block">
                            <input
                              type="date"
                              name="camp_retailer_end_date"
                              onKeyDown={(e) => e.preventDefault()}
                              id="camp_retailer_end_date"
                             // defaultValue={camp_maxDate}
                             value={val1.camp_iso_retailer_end_date.split("T")[0]}
                              // max={
                              //   camp_maxDate
                              //     ? camp_maxDate
                              //     : ""
                              // }
                              max={
                                camp_maxDate ? camp_maxDate :
                                new Date(
                                 new Date(
                                   FutureDisableDate(
                                     val1.camp_iso_retailer_end_date
                                   )
                                 ).getTime() +
                                   1 * 24 * 60 * 60 * 1000
                               )
                                 .toISOString()
                                 .split("T")[0]
                             }
                             min={
                               new Date(
                                 new Date(
                                   PastDisableDate( val1.camp_iso_retailer_start_date)
                                 ).getTime() +
                                   1 * 24 * 60 * 60 * 1000
                               )
                                 .toISOString()
                                 .split("T")[0]
                             }
 
                              onChange={(e) =>
                                onchangeSetEnddateRetailer(e, val1)
                              }
                            />
                          </div>
                        </li>
                        <li>
                          <div className="d-flex align-items-center Title_Block">
                            <h5>Shutoff Date</h5>
                            <img
                              src={Error}
                              className="img-fluid ml-5"
                              alt="Error"
                            />
                          </div>
                          <div className="Content_Block">
                            <div className="Date_Picker">
                              <input
                                type="date"
                                name="camp_retailer_shutoff_date"
                                id="camp_retailer_shutoff_date"
                                onKeyDown={(e) => e.preventDefault()}
                               // defaultValue={camp_shutoffDate}
                               value={val1.camp_iso_retailer_shutoff_date.split("T")[0]
                               }
                                // max={
                                //   camp_maxDate
                                //     ? camp_maxDate
                                //     : ""
                                // }
                                max={
                                  new Date(
                                    new Date(
                                      FutureDisableDate(
                                        val1.camp_iso_retailer_shutoff_date
                                      )
                                    ).getTime() +
                                      1 * 24 * 60 * 60 * 1000
                                  )
                                    .toISOString()
                                    .split("T")[0]
                                }
                                min={
                                  new Date(
                                    new Date(
                                      PastDisableDate( val1.camp_iso_retailer_start_date)
                                    ).getTime() +
                                      1 * 24 * 60 * 60 * 1000
                                  )
                                    .toISOString()
                                    .split("T")[0]
                                }
  
                                onChange={(e) =>
                                  onchangeSetShutoffdateRetailer(
                                    e,
                                    val1
                                  )
                                }
                              />
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className="d-flex align-items-center Title_Block">
                            <h5>Max Clips</h5>
                            <img
                              src={Error}
                              className="img-fluid ml-5"
                              alt="Error"
                            />
                          </div>
                          <div className="Content_Block">
                            <input
                              type="text"
                              defaultValue={1000000}
                              name="retailer_clip_limit"
                              // id={"retailer_clip_limit-" + val1}
                              onChange={(e) =>
                                cliplimitChangeRetailer(e, val1)
                              }
                            />
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              );
            })}
          </>
        ) : (
          ""
        )}
      </div>
      <div className="row mt-23 mb-20">
        <div className="col-12 d-flex justify-content-end">
          <div className="TableButton">
            <button
              type="button"
              className="mr-10 btn btn-primary"
              onClick={(e) => {
                changePrevTab(e, 3);
              }}
            >
              <i className="fas fa-arrow-left mr-10" aria-hidden="true"></i>{" "}
              Prev
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={(e) => {
                //changeTargetTab(e, 5);
                changeTabCompaignDetails(e,5)
              }}
            >
              Next{" "}
              <i className="fas fa-arrow-right ml-10" aria-hidden="true"></i>{" "}
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default CampaignDetail;
