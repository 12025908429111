import React from 'react';
import { Button } from "react-bootstrap";
import DataTable from "react-data-table-component";
import ClipLoader from "react-spinners/ClipLoader";
import ProductsImage from "../../Assets/images/products.svg";
import OfferValueImage from "../../Assets/images/offer-value.svg";
// search functionality added on upc list page of create coupon
class RewardUPC extends React.Component {
    constructor() {
        super()
        this.state = {
            checkboxlength: "",
            searchQuery: "",
            columns: [
                {
                    name: "BRAND",
                    selector: (row) => this.props.UpcList.brand_name
                },
                {
                    name: "UPC",
                    selector: (row) => row.upc11,
                    sortable: true
                },
                {
                    name: "PRODUCT NAME",
                    selector: (row) => row.product_name
                },
                {
                    name: "CATEGORY",
                    selector: (row) => row.category_name,
                    sortable: true
                }
            ],
        }
    }

    handleInputChange = (e) => {
        const searchQuery = e.target.value;
        this.setState({ searchQuery })
    }

    filterData = (e) => {
        const { searchQuery } = this.state

        if (!searchQuery) {
            return this.props.tableData
        }

        const filteredDataList = this.props.tableData?.filter((eachData) => {
            return (
                eachData?.product_name?.toLocaleLowerCase().includes(searchQuery.toLocaleLowerCase())
            )
        })
        return filteredDataList
    }

    render() {
        const filteredDataList = this.filterData()
        return (
            <React.Fragment>
                <div
                    className="tab-pane fade show active"
                    id="nav-step-1"
                    role="tabpanel"
                    aria-labelledby="nav-step-1-tab"
                >
                    <div className="row  g-3">
                        <div className="col-xxl-9 col-xl-8 col-12">
                            <div className="Steps_Header d-flex align-items-center justify-content-between">
                                <h5 className="">Select Rewards</h5>
                                <div className="Search_Box">
                                    <input
                                        type="text"
                                        className="form-control Search_Toggle"
                                        placeholder="Search"
                                        aria-label="Search"
                                        aria-describedby="search-addon"
                                        onChange={this.handleInputChange}
                                    />
                                    <span className="Input_Icon">
                                        <svg
                                            width="17"
                                            height="18"
                                            viewBox="0 0 17 18"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M7.7 14.9C11.4003 14.9 14.4 11.9003 14.4 8.2C14.4 4.49969 11.4003 1.5 7.7 1.5C3.99969 1.5 1 4.49969 1 8.2C1 11.9003 3.99969 14.9 7.7 14.9Z"
                                                stroke="white"
                                                strokeWidth="1.5"
                                                strokeMiterlimit="3.3333"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                            <path
                                                d="M16.0004 16.5L12.4004 12.9"
                                                stroke="white"
                                                strokeWidth="1.5"
                                                strokeMiterlimit="3.3333"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                        </svg>
                                    </span>
                                </div>
                            </div>

                            <div className="table-responsive Create_Coupons_Table">
                                {this.props.createCouponLoader ? (
                                    <div
                                        className="Clip_Loader"
                                        style={{ minHeight: "280px" }}
                                    >
                                        <ClipLoader
                                            color={"rgb(0, 143, 251)"}
                                            loading={true}
                                            size={50}
                                        />
                                    </div>
                                ) : this.props.UpcList ? (
                                    <>
                                        <DataTable
                                            columns={this.state.columns}
                                            data={filteredDataList}
                                            selectableRows
                                            onSelectedRowsChange={(e) =>
                                                this.props.handlerewardChange(e)
                                            }
                                            pagination
                                        />
                                        <table className="table mb-0">
                                            <tfoot>
                                                <tr className="Bottom_Block">
                                                    <td className="" colSpan="3">
                                                        <div className="d-flex align-items-center justify-content-end">
                                                            <div className="TableButton">
                                                                <button
                                                                    type="button"
                                                                    className="mr-10 btn btn-primary"
                                                                    onClick={(e) => {
                                                                        this.props.changePrevTab(e, 1);
                                                                    }}
                                                                >
                                                                    <i
                                                                        className="fas fa-arrow-left mr-10"
                                                                        aria-hidden="true"
                                                                    ></i>{" "}
                                                                    Prev
                                                                </button>
                                                                <Button
                                                                    onClick={(e) =>
                                                                        this.props.changeStep(e, 3)
                                                                    }
                                                                >
                                                                    Next{" "}
                                                                    <i className="fas fa-arrow-right ml-10"></i>{" "}
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tfoot>
                                        </table>
                                    </>
                                ) : (
                                    ""
                                )}
                            </div>
                        </div>
                        <div className="col-xxl-3 col-xl-4 col-12">
                            <div className="Common_Block">
                                <div className="Common_Block_Header d-flex align-items-center justify-content-between">
                                    <h5 className="">
                                        Digital Coupon Campaign Summary
                                    </h5>
                                </div>
                                <div className="Common_Block_Body">
                                    <ul className="Campaign_Summery">
                                        <li>
                                            <img
                                                src={ProductsImage}
                                                className="img-fluid"
                                                alt="Products"
                                            />
                                            <span>Products</span>
                                            <h5>{this.props.products}</h5>
                                        </li>
                                        <li>
                                            <img
                                                src={OfferValueImage}
                                                className="img-fluid"
                                                alt="Offer Value"
                                            />
                                            <span>Offer Value</span>
                                            <h5>
                                                {this.props.reward_amount}
                                            </h5>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className="Common_Block mt-21">
                                <div className="Common_Block_Header d-flex align-items-center justify-content-between">
                                    <h5 className="">Digital Coupon Campaigns (0)</h5>
                                </div>

                                <div className="Common_Block_Body">
                                    <div className="table-responsive Gray_Header_Table">
                                        {this.props.camp_wholesalername ? (
                                            <table className="table text-center">
                                                <thead>
                                                    <tr>
                                                        <th >
                                                            Wholesaler
                                                        </th>
                                                        <th>Start</th>
                                                        <th>Expiration</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {Object.keys(
                                                        this.props.camp_wholesalername
                                                    ).map((val, idx) => (
                                                        <tr>
                                                            <td>
                                                                <img
                                                                    src={
                                                                        this.props.wholesalerlogo[val]
                                                                            ? this.props.wholesalerlogo[
                                                                            val
                                                                            ]
                                                                            : ""
                                                                    }
                                                                    className="img-fluid"
                                                                    alt={val}
                                                                />
                                                            </td>
                                                            <td>
                                                                {this.props.start_date}
                                                            </td>
                                                            <td>
                                                                {this.props.end_date}
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default RewardUPC