import React from "react";
import './second-header.scss';

const SecondHeader = ({ Avtar, decodetoken, brands, changeBrandsheader, onchangeBrandswizard, onchangeBrandUpclist, location,
  title, changeallBrands }) => {

  return (

    <div className="Second_Header">
      <div className="container-fluid">
        <div className="Second_Header_InnerBlock d-flex align-items-center justify-content-between">
          <div className="Profile">
            <img src={Avtar} className="img-fluid" alt="Avtar" />
            <span>
              Welcome Back, {decodetoken.first_name.toUpperCase()}
            </span>
          </div>

        </div>
      </div>
    </div>
  )
};

export default SecondHeader;