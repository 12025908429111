import $ from "jquery";
import React from "react";
import { Button, Form } from "react-bootstrap";
import Header from "../Dashboard/Header";
import scissors from "../../Assets/images/scissors.png";
import ProductsImage from "../../Assets/images/products.svg";
import OfferValueImage from "../../Assets/images/offer-value.svg";
//import Error from "../../Assets/images/error.svg";
//import BlockImgBlock from "../../Assets/images/blockbg.png";
import BlockImg from "../../Assets/images/Brandiq.gif";
//import LogoBig from "../../Assets/images/logo-big.png";
import Arrowsvg from "../../Assets/images/arrow.svg";
import Arrow from "../../Assets/images/arrow.gif";
import FileIcon from "../../Assets/images/file-icon.png";
import CalenderSvgImage from "../../Assets/images/calender.svg";
import CalenderSvgRedImage from "../../Assets/images/calenderRed.svg";
import { Link } from "react-router-dom";
import ls from "local-storage";
import * as CONFIG from "../../Components/_config";
import axios from "axios";
import RV from "simple-react-validator";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Redirect } from "react-router";
import DataTable from "react-data-table-component";
import FooterStrict from "../Dashboard/FooterStrict";
import AddretailerModal from "../../Components/DealModal/AddretailerModal";
import AddwholesalerModal from "../../Components/DealModal/AddwholesalerModal";
import caseImage from "../../Assets/images/case.png";
import Pallet from "../../Assets/images/pallet.png";
import Truck from "../../Assets/images/truck.png";
import Shipper from "../../Assets/images/shipper.png";
import Unit from "../../Assets/images/unit.png";


//import calander from "../../Assets/images/date-icon.svg"



const Loader = () => <div id="cover-spin"></div>;

//var masterdata = "";
//export default function Wizard() {
class Editdeal extends React.Component {
  constructor(props) {
    super(props);
    this.selectEditretailer = this.selectEditretailer.bind(this);
    this.handleWarehouse = this.handleWarehouse.bind(this);

    this.reload = this.reload.bind(this);

    this.validator = new RV();
    this.validator.visibleFields = [];
    toast.configure();
    this.state = {
      upcList: "",
      upcGuid: "",
      upc_guid: [],
      upc_guid_list: "",
      new_coupon_start_date: "",
      wholesaler_number: "",
      master_wholesaler_number: "",
      WholeSalerLogo: "",

      searchQuery:"",

      offerType: "",
      offer_type: "",
      selectValue: "select",
      camp_minDate: "",
      camp_maxDate: "",
      camp_shutoffDate: "",

      wholesaler_start_date: "",
      iso_wholesaler_start_date: "",
      wholesaler_end_date: "",
      wholesaler_shutoff_date: "",
      wholesalerid: [],
      wholesaler_end_date_show: "",

      selectedFile: "",
      global_prev_end_date: "",

      signup: false,
      WholeSalerlist: "",
      camp_wholesalername: [],
      camp_wholesaler_start_date: [],
      camp_wholesaler_end_date: [],

      camp_wholesaler_end_date_show: [],
      camp_iso_wholesaler_start_date: [],
      camp_iso_wholesaler_end_date: [],
      camp_iso_wholesaler_shutoff_date: [],

      camp_wholesaler_logo: [],
      camp_retailer_logo: [],

      camp_wholesaler_id: [],
      wholesaler_guid: [],

      camp_clip_limit: [],
      camp_clip_limit_val: "",
      camp_reedem_limit: [],
      camp_reedem_limit_val: "",

      global_build_start_date: "",
      global_build_end_date: "",
      global_build_shutoff_date: "",
      global_prev_start_date: "",
      checkboxlength: "",

      new_api_start_date: [],
      new_api_end_date: [],
      new_api_shutoff_date: [],

      wholesalername1: [],
      wholesalerlogo: [],
      retailerlogo: [],

      checkeupc: false,
      upc11: "",

      target_type_id: "",
      retailer_array: "",
      retailer_id: "",
      retailername1: [],
      retailerid: [],

      new_api_retailer_start_date: [],
      new_api_retailer_end_date: [],
      new_api_retailer_shutoff_date: [],
      new_api_retailer_clip_limit: [],
      new_api_retailer_reedem_limit: [],

      camp_retailer_start_date: [],
      camp_retailer_end_date: [],

      camp_iso_retailer_start_date: [],
      iso_retailer_start_date: "",

      camp_iso_retailer_end_date: [],
      iso_retailer_end_date: "",

      camp_iso_retailer_shutoff_date: [],
      retailer_shutoff_date: "",
      retailerwholesalerid: [],
      checking1: "",
      checking: "",
      is_flex: "",
      disabled: "",
      dis: "",
      loading: false,
      editRetailer_arr: [],
      warehouse_arr: [],
      columns: [
        {
          name: "BRAND",
          selector: (row) => row.upc11
        },
        {
          name: "UPC",
          selector: (row) => row.upc11,
          sortable: true
        },
        {
          name: "PRODUCT NAME",
          selector: (row) => row.product_name
        },
        // {
        //   name: "ITEM CODE",
        //   selector: (row) => row.item_code
        // },
        {
          name: "CATEGORY",
          selector: (row) => row.category_name,
          sortable: true
        }
      ],

      formControls: {}
    };
    //this.selectWholesaler = this.selectWholesaler.bind(this);
  }


  //search functionality

  handleInputChange = (e) => {
    const searchQuery = e.target.value;
    this.setState({searchQuery})
}

filterData = (e) => {
    console.log("Data", this.state.tableData)
    const {searchQuery} = this.state

    if(!searchQuery) {
        return this.state.tableData
    }

    const filteredDataList = this.state.tableData?.filter((eachData) => {
        return(
            eachData?.product_name?.toLocaleLowerCase().includes(searchQuery.toLocaleLowerCase())
        )
    })
    return filteredDataList
}


  async componentDidMount() {
    $(".wizard li").click(function () {
      $(this).removeClass("completed");
      $(this).prevAll().addClass("completed");
      $(this).nextAll().removeClass("completed");
      $(".wizard li").removeClass("active");
      $(this).addClass("active");
    });
    $(".Offer_Type").on("click", function () {
      $(".Offer_Type").removeClass("Selected");
      $(this).addClass("Selected");
    });
    $(".Search_Box").on("click", function () {
      $(".Search_Box input").css("display", "block");
    });

    $(document).mouseup(function (e) {
      if ($(e.target).closest(".Search_Box input").length === 0) {
        $(".Search_Box input").css("display", "none");
      }
    });

    // var b_guid = ls.get("brands") ? ls.get("brands")[0].guid : "";

    const b_guid = ls.get("selectedbrand") ? ls.get("selectedbrand")[0].guid : "";

    var deal_guid = this.props.location.state.params.id1;
    const token_config = {
      headers: CONFIG.config_head.headers
    };


    const requestOne = axios.get(
      CONFIG.API_URL + `brand/${b_guid}`,
      token_config
    );
    const requestTwo = axios.get(
      CONFIG.API_URL + `deal/${deal_guid}`,
      token_config
    );

    const requestThree = axios.get(
      CONFIG.API_URL + `master/${b_guid}?type=retailer&search=''`,
      token_config
    );


    const requestFour = axios.get(
      CONFIG.API_URL + `master/${b_guid}?type=wholesaler&search=''`,
      token_config
    );

    axios
      .all([requestOne, requestTwo, requestThree, requestFour])
      .then(
        axios.spread((...responses) => {
          let responseOne = responses[0];
          let responseTwo = responses[1];
          let responseThree = responses[2];
          let responseFour = responses[3];

          const upc = [];
          const retailer_id = [];
          const retailer_arr = [];
          const wholesaler_arr = [];

          for (const obj of responseTwo.data.Message.upc) {
            upc.push(obj.upc11);
          }
          for (const obj1 of responseTwo.data.Message.retailer) {
            retailer_id.push(obj1.retailer_id);
          }


          for (const obj of responseTwo.data.Message.retailer) {

            retailer_arr.push({
              retailer_id: obj.retailer_id,
              start_date: obj.start_date,
              end_date: obj.end_date,
              wholesaler_id: obj.wholesaler_id,
              order_by_date: obj.order_by_date,
              deal_limit: 0,
              order_limit: 0
            })

          }

          for (const obj of responseTwo.data.Message.warehouse) {

            wholesaler_arr.push({
              warehouse_id: obj.warehouse_id,
              wholesaler_id: obj.wholesaler_id,

            })

          }

          var groups = Object.create(null);
          var wholesalergroups = Object.create(null);


          responseTwo.data.Message.warehouse.forEach(function (a) {
            groups[a.wholesaler_id] = groups[a.wholesaler_id] || [];
            groups[a.wholesaler_id].push(a);
          });

          responseFour.data.Message.forEach(function (a) {
            wholesalergroups[a.wholesaler_id] = wholesalergroups[a.wholesaler_id] || [];
            wholesalergroups[a.wholesaler_id].push(a);
          });

          this.setState({
            upcArr: upc,
            tableData: responseOne.data.Message.upc,
            dealDetails: responseTwo.data.Message,
            retailer_array: responseThree.data.Message,
            retailer_id: retailer_id,
            WholeSalerlist: responseFour.data.Message,
            warehouse: responseTwo.data.Message.warehouse,
            selectedFile: responseTwo.data.Message.image_1,
            editRetailer_arr: retailer_arr,
            groupwarehouse: groups,
            wholesalergroups: wholesalergroups,
            warehouse_arr: wholesaler_arr

          });

        })
      )
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 502) {
            toast.error("Bad Request!");
          } else if (error.response.status === 400) {

            toast.error(error.response.data.Message);

          } else if (error.response.status === 403) {
            toast.error(error.response.data.Message);
            ls.clear("token");
            ls.clear("brands");
            window.location.href = "/login";
          } else {
            toast.error(error.response.data.Message);

            console.log(error);
          }
        }
      });
  }

  rowSelectCritera = (row) => this.state.upcArr.includes(row.upc11);


  handleDate(date) {
    var month = date.getMonth() + 1;
    var day = date.getDate();
    var year = date.getFullYear();

    if (month < 10) month = "0" + month.toString();
    if (day < 10) day = "0" + day.toString();

    let calculate_date = year + "-" + month + "-" + day;

    return calculate_date;
  }

  handleSeconDdate(date) {
    var month = date.getMonth() + 1;
    var day = date.getDate();
    var year = date.getFullYear();

    if (month < 10) month = "0" + month.toString();
    if (day < 10) day = "0" + day.toString();

    let calculate_date = month + "-" + day + "-" + year;
    return calculate_date;
  }

  changeHandler = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    this.setState({
      formControls: {
        ...this.state.formControls,
        [name]: value
      }
    });
  };


  onFileChange = (event) => {
    let files = event.target.files;
    let images = event.target.files[0];

    if (!images.name.match(/\.(jpg|jpeg|png|gif|PNG|GIF|JPG)$/)) {
      toast.error("Please Select a Valid Image!");
      return false;
    }

    for (var i = 0; i < files.length; i++) {
      let file = files[i];
      let reader = new FileReader();

      reader.readAsDataURL(file);
      reader.onload = () => {
        this.setState({ selectedFile: reader.result });
      };
    }
  };

  handlerowChange = ({ selectedRows, e }) => {
    // You can set state or dispatch with something like Redux so we can use the retrieved data

    this.setState({ checkboxlength: Object.keys(selectedRows).length });

    selectedRows.map(
      (val, idx) => (this.state.upc_guid[val.upc_guid] = val.upc_guid)
    );
    var upc_guid = [];
    for (const obj of selectedRows) {
      upc_guid.push(obj.upc_guid);
    }

    this.setState({ upc_guid_list: upc_guid });
    //console.log(this.state.upc_guid_list)
  };

  changeTab = async (e, key) => {
    var upc_guid_arr = [];

    Object.keys(this.state.upc_guid_list).map((val, idx) =>
      upc_guid_arr.push(val)
    );

    this.showLoader();
    var deal_guid = this.props.location.state.params.id1;

    var data = JSON.stringify({
      upc: this.state.upc_guid_list
    });
    var config = {
      method: "put",
      url: CONFIG.API_URL + `deal/products/${deal_guid}`,
      headers: CONFIG.config_head.headers,
      data: data
    };

    axios(config)
      .then((response) => {
        if (response.status === 200) {

          this.hideLoader();

          setTimeout(
            () => toast.success(response.data.Message), 1000)

          setTimeout(
            () => $("#nav-step-" + key + "-tab").trigger("click"), 3500)

            ;
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 502) {
            toast.error("Bad Request!");
          } else if (error.response.status === 400) {
            toast.error(error.response.data.Message);
          } else if (error.response.status === 403) {
            toast.error(error.response.data.Message);
          } else {
            toast.error(error.response.data.Message);
          }
        }
      });
  };

  changeSecondTab = async (e, key) => {
    // var z= /^\d*$/;
    $("#nav-step-" + key + "-tab").trigger("click");

  };

  changeThirdTab = async (e, key) => {
    // this.setState({signup:true})
    $("#nav-step-" + key + "-tab").trigger("click");
  };

  changeFourthTab = async (e, key) => {
    // var dateformat =
    //   /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/;
    $("#nav-step-" + key + "-tab").trigger("click");


  };

  changeRetailerFourthTab = async (e, key) => {
    //   var dateformat = /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/;

    $("#nav-step-" + key + "-tab").trigger("click");


  };

  changePrevTab = async (e, key) => {
    $("#nav-step-" + key + "-tab").trigger("click");
  };

  showLoader = () => {
    this.setState({ loading: true });
  }
  hideLoader = () => {

    this.setState({ loading: false });

  };

  reload() {
    this.forceUpdate();
  }

  selectEditretailer(item) {

    var retailer_arr1 = [];

    Object.keys(item.retailer_number).map((val, idx) => {

      retailer_arr1.push({
        retailer_id: item.retailer_number[val].retailer_id,
        start_date: this.state.dealDetails.deal_start_date,
        end_date: this.state.dealDetails.deal_end_date,
        wholesaler_id: item.retailer_number[val].retailer_id,
        order_by_date: this.state.dealDetails.deal_order_before_date,
        deal_limit: 0,
        order_limit: 0
      })
    }
    );

    this.setState({ editRetailer_arr: retailer_arr1 });
  }

  handleWarehouse = (selectedOption) => {

    const flavors = [];
    const warehousename_arr = [];

    for (let i = 0; i < selectedOption.length; i++) {

      warehousename_arr.push({ 'warehouse_name': selectedOption[i].label })

      flavors.push({ 'wholesaler_id': parseInt(selectedOption[i].wholesaler_id), 'warehouse_id': parseInt(selectedOption[i].value) });
    }

    //   let obj = [];

    //  for (let i=0; i< selectedOption.length; i++) {

    //        obj = {
    //        wholesaler_id: parseInt(selectedOption[i].wholesaler_id),
    //        warehouse_id: parseInt(selectedOption[i].value)
    //      };
    //  }

    this.setState({ warehouse_arr: flavors, retailers: [], warehousename: warehousename_arr })




    //   if(this.handleCheck(obj) === false){

    //    flavors.push(obj)
    //   // this.setState({warehouse_arr: obj })

    //  }


  }
  selectEditwholesaler(item) {

    console.log(item)

  }

  updateDeal(key) {
    var z = new RegExp(/^\d*\.?\d*$/);

    if (!z.test($("#reward_amount").val()) || $("#reward_amount").val().trim().length <= 0) {
      toast.error("Please Fill Offer Value, accept only numeric values", { theme: "colored" });
      return false;
    }
    else if ($("#title").val().length <= 0) {
      toast.error("Please Fill Title Field", { theme: "colored" });
      return false;
    }
    else if ($("#description").val().trim().length <= 0) {
      toast.error("Please Fill  Description field", { theme: "colored" });
      return false;

    } else if ($("input[type='date'][name='start_date']").val().length > 0 && $("input[type='date'][name='end_date']").val().length > 0) {


      this.showLoader();
      var guid = ls.get("brands") ? ls.get("brands")[0].guid : "";

      var data = JSON.stringify({

        title: this.state.formControls.title ? this.state.formControls.title : this.state.dealDetails.deal_title,

        description: this.state.formControls.description ? this.state.formControls.description : this.state.dealDetails.deal_description,
        start_date: this.state.dealDetails.deal_start_date ? this.state.dealDetails.deal_start_date : "",

        end_date: this.state.dealDetails.deal_end_date ? this.state.dealDetails.deal_end_date : "",
        order_before_date: this.state.dealDetails.deal_order_before_date ? this.state.dealDetails.deal_order_before_date : "",
        deal_limit: 0,
        deal_code: this.state.formControls.deal_code ? this.state.formControls.deal_code : this.state.dealDetails.deal_code,

        deal_unit: this.state.dealDetails.deal_unit,
        deal_type_id: 1,
        min_quantity: 1,
        deal_amount: this.state.formControls.reward_amount ? parseFloat(this.state.formControls.reward_amount) : this.state.dealDetails.deal_amount,

        is_deal_amount_percentage: 0,
        limit_per_retailer: 0,

        addtional_details: this.state.formControls.additional_details ? this.state.formControls.additional_details : this.state.dealDetails.addtional_details,

        disclaimer: this.state.formControls.disclaimer ? this.state.formControls.disclaimer
          : this.state.dealDetails.disclaimer,
        image_1: this.state.selectedFile,
        image_1_thumbnail: "string",
        media_url: "string",
        brand_guid: guid,
        deal_target_type_id: this.state.dealDetails
          ? this.state.dealDetails.deal_target_type_id
          : '',

        warehouses: this.state.warehouse_arr ? this.state.warehouse_arr : this.state.warehouse_arr,
        retailers: this.state.editRetailer_arr ? this.state.editRetailer_arr : this.state.editRetailer_arr,
        upc: this.state.upc_guid_list
      });

      //console.log(data);return;

      var deal_guid = this.props.location.state.params.id1;

      var config = {
        method: "put",
        url: CONFIG.API_URL + `deal/${deal_guid}`,
        headers: CONFIG.config_head.headers,
        data: data
      };

      axios(config)
        .then((response) => {
          if (response.status === 200) {

            setTimeout(() => this.hideLoader(), 1800)

            setTimeout(() => toast.success("Updated Sucessfully"), 2000)

            // setTimeout(() => window.top.location.reload(true), 2300)

            setTimeout(() => $("#nav-step-" + key + "-tab").trigger("click"), 2300)

            // $("#nav-step-" + key + "-tab").trigger("click");
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 502) {

              toast.error("Bad Request!");
            } else if (error.response.status === 400) {
              this.hideLoader();

              toast.error(error.response.data.Message);
            } else if (error.response.status === 403) {
              toast.error(error.response.data.Message);
              ls.clear("token");
              ls.clear("brands");
              window.location.href = "/login";
            } else {
              this.hideLoader();

              toast.error(error.response.data.Message);
            }
          }
        });
    } else {
      toast.error("Please Select Date", { theme: "colored" });
      return false;
    }

  }

  render() {

    const filteredDataList = this.filterData()
    
    const isLogged = ls.get("token");

    if (!isLogged) {
      return <Redirect to="/login" />;
    }


    return (
      <>
        <Header location={`editdeal`} />
        {this.state.loading ? <Loader /> : null}


        <section className="ContentBlock  pt-24 pb-24 Main_Block">
          <div className="Tab_Header">
            <div className="container-fluid">
              <div className="row ">
                <div className="col-12">
                  <ul className="nav nav-tabs wizard pb-70">
                    <li className="active">
                      <button
                        className="nav-link "
                        id="nav-step-1-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#nav-step-1"
                        type="button"
                        role="tab"
                        aria-controls="step-1"
                        aria-selected="true"
                      >
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M21 2.992V21.008C20.9979 21.2706 20.8926 21.5219 20.7068 21.7075C20.521 21.8931 20.2696 21.9982 20.007 22H3.993C3.72981 22 3.47739 21.8955 3.2912 21.7095C3.105 21.5235 3.00027 21.2712 3 21.008V2.992C3.00209 2.72938 3.10742 2.47813 3.29322 2.29251C3.47902 2.1069 3.73038 2.00183 3.993 2H20.007C20.555 2 21 2.444 21 2.992ZM19 4H5V20H19V4ZM11.293 13.121L15.536 8.879L16.95 10.293L11.293 15.95L7.403 12.06L8.818 10.646L11.293 13.121Z"
                            fill="current"
                          />
                        </svg>
                        Select Product
                      </button>
                    </li>
                    <li>
                      <button
                        className="nav-link"
                        id="nav-step-2-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#nav-step-2"
                        type="button"
                        role="tab"
                        aria-controls="nav-step-2"
                        aria-selected="false"
                      >
                        <svg
                          width="19"
                          height="18"
                          viewBox="0 0 19 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M13.625 15.75C12.9288 15.75 12.2611 15.4734 11.7688 14.9812C11.2766 14.4889 11 13.8212 11 13.125C11 12.4288 11.2766 11.7611 11.7688 11.2688C12.2611 10.7766 12.9288 10.5 13.625 10.5C14.3212 10.5 14.9889 10.7766 15.4812 11.2688C15.9734 11.7611 16.25 12.4288 16.25 13.125C16.25 13.8212 15.9734 14.4889 15.4812 14.9812C14.9889 15.4734 14.3212 15.75 13.625 15.75ZM13.625 14.25C13.9234 14.25 14.2095 14.1315 14.4205 13.9205C14.6315 13.7095 14.75 13.4234 14.75 13.125C14.75 12.8266 14.6315 12.5405 14.4205 12.3295C14.2095 12.1185 13.9234 12 13.625 12C13.3266 12 13.0405 12.1185 12.8295 12.3295C12.6185 12.5405 12.5 12.8266 12.5 13.125C12.5 13.4234 12.6185 13.7095 12.8295 13.9205C13.0405 14.1315 13.3266 14.25 13.625 14.25ZM5.375 7.5C5.03028 7.5 4.68894 7.4321 4.37046 7.30018C4.05198 7.16827 3.7626 6.97491 3.51884 6.73116C3.27509 6.4874 3.08173 6.19802 2.94982 5.87954C2.8179 5.56106 2.75 5.21972 2.75 4.875C2.75 4.53028 2.8179 4.18894 2.94982 3.87046C3.08173 3.55198 3.27509 3.2626 3.51884 3.01884C3.7626 2.77509 4.05198 2.58173 4.37046 2.44982C4.68894 2.3179 5.03028 2.25 5.375 2.25C6.07119 2.25 6.73887 2.52656 7.23116 3.01884C7.72344 3.51113 8 4.17881 8 4.875C8 5.57119 7.72344 6.23887 7.23116 6.73116C6.73887 7.22344 6.07119 7.5 5.375 7.5ZM5.375 6C5.67337 6 5.95952 5.88147 6.1705 5.6705C6.38147 5.45952 6.5 5.17337 6.5 4.875C6.5 4.57663 6.38147 4.29048 6.1705 4.0795C5.95952 3.86853 5.67337 3.75 5.375 3.75C5.07663 3.75 4.79048 3.86853 4.5795 4.0795C4.36853 4.29048 4.25 4.57663 4.25 4.875C4.25 5.17337 4.36853 5.45952 4.5795 5.6705C4.79048 5.88147 5.07663 6 5.375 6ZM14.8032 2.63625L15.8638 3.69675L4.1975 15.3638L3.137 14.3032L14.8025 2.63625H14.8032Z"
                            fill="current"
                          />
                        </svg>
                        Build Deal
                      </button>
                    </li>
                    <li>
                      <button
                        className="nav-link"
                        id="nav-step-3-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#nav-step-3"
                        type="button"
                        role="tab"
                        aria-controls="nav-step-3"
                        aria-selected="false"
                      >
                        <svg
                          width="25"
                          height="24"
                          viewBox="0 0 25 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M2.54983 13H4.56183C4.80586 14.9331 5.74691 16.7107 7.2084 17.9993C8.6699 19.2879 10.5514 19.9989 12.4998 19.9989C14.4483 19.9989 16.3298 19.2879 17.7913 17.9993C19.2527 16.7107 20.1938 14.9331 20.4378 13H22.4508C21.9488 18.053 17.6848 22 12.4998 22C7.31483 22 3.05083 18.053 2.54983 13ZM2.54983 11C3.04983 5.947 7.31383 2 12.4998 2C17.6858 2 21.9488 5.947 22.4498 11H20.4378C20.1938 9.0669 19.2527 7.28927 17.7913 6.00068C16.3298 4.71208 14.4483 4.00108 12.4998 4.00108C10.5514 4.00108 8.6699 4.71208 7.2084 6.00068C5.74691 7.28927 4.80586 9.0669 4.56183 11H2.54883H2.54983ZM12.4998 14C11.9694 14 11.4607 13.7893 11.0856 13.4142C10.7105 13.0391 10.4998 12.5304 10.4998 12C10.4998 11.4696 10.7105 10.9609 11.0856 10.5858C11.4607 10.2107 11.9694 10 12.4998 10C13.0303 10 13.539 10.2107 13.914 10.5858C14.2891 10.9609 14.4998 11.4696 14.4998 12C14.4998 12.5304 14.2891 13.0391 13.914 13.4142C13.539 13.7893 13.0303 14 12.4998 14Z"
                            fill="current"
                          />
                        </svg>
                        Select Targets
                      </button>
                    </li>

                    <li>
                      <button
                        className="nav-link"
                        id="nav-step-5-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#nav-step-5"
                        type="button"
                        role="tab"
                        aria-controls="nav-step-5"
                        aria-selected="false"
                      >
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22ZM12 20C14.1217 20 16.1566 19.1571 17.6569 17.6569C19.1571 16.1566 20 14.1217 20 12C20 9.87827 19.1571 7.84344 17.6569 6.34315C16.1566 4.84285 14.1217 4 12 4C9.87827 4 7.84344 4.84285 6.34315 6.34315C4.84285 7.84344 4 9.87827 4 12C4 14.1217 4.84285 16.1566 6.34315 17.6569C7.84344 19.1571 9.87827 20 12 20ZM11.003 16L6.76 11.757L8.174 10.343L11.003 13.172L16.659 7.515L18.074 8.929L11.003 16Z"
                            fill="current"
                          />
                        </svg>
                        Preview
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <form method="post" className="Offer_Form">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div
                    className="tab-content Wizard_Content mb-30"
                    id="nav-tabContent"
                  >
                    <div
                      className="tab-pane fade show active"
                      id="nav-step-1"
                      role="tabpanel"
                      aria-labelledby="nav-step-1-tab"
                    >
                      <div className="row  g-3">
                        <div className="col-xxl-9 col-xl-8 col-12">
                          <div className="Steps_Header d-flex align-items-center justify-content-between">
                            <h5 className="">Edit Deal</h5>
                            <div className="Search_Box">
                              <input
                                type="text"
                                className="form-control Search_Toggle"
                                placeholder="Search"
                                aria-label="Search"
                                aria-describedby="search-addon"
                                onChange={this.handleInputChange}
                              />
                              <span className="Input_Icon">
                                <svg
                                  width="17"
                                  height="18"
                                  viewBox="0 0 17 18"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M7.7 14.9C11.4003 14.9 14.4 11.9003 14.4 8.2C14.4 4.49969 11.4003 1.5 7.7 1.5C3.99969 1.5 1 4.49969 1 8.2C1 11.9003 3.99969 14.9 7.7 14.9Z"
                                    stroke="white"
                                    strokeWidth="1.5"
                                    strokeMiterlimit="3.3333"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M16.0004 16.5L12.4004 12.9"
                                    stroke="white"
                                    strokeWidth="1.5"
                                    strokeMiterlimit="3.3333"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </span>
                            </div>
                          </div>

                          <div className="table-responsive Create_Coupons_Table">
                            {this.state.upcArr ? (
                              <>
                                {
                                  <DataTable
                                    columns={this.state.columns}
                                    data={filteredDataList}
                                    selectableRows
                                    onSelectedRowsChange={(e) =>
                                      this.handlerowChange(e)
                                    }
                                    selectableRowSelected={
                                      this.rowSelectCritera
                                    }
                                    pagination
                                  />
                                }

                                <table className="table mb-0">
                                  <tfoot>
                                    <tr className="Bottom_Block">
                                      <td className="" colSpan="3">
                                        <div className="d-flex align-items-center justify-content-end">
                                          <div className="TableButton">
                                            <Button
                                              onClick={(e) =>
                                                this.changeTab(e, 2)
                                              }
                                            >
                                              Save & Next{" "}
                                              <i className="fas fa-arrow-right ml-10"></i>{" "}
                                            </Button>
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                  </tfoot>
                                </table>
                              </>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                        <div className="col-xxl-3 col-xl-4 col-12">
                          <div className="Common_Block">
                            <div className="Common_Block_Header d-flex align-items-center justify-content-between">
                              <h5 className="">
                                Digital Deal Campaign Summary
                              </h5>
                            </div>
                            <div className="Common_Block_Body">
                              <ul className="Campaign_Summery">
                                <li>
                                  <img
                                    src={ProductsImage}
                                    className="img-fluid"
                                    alt="Products"
                                  />
                                  <span>Products</span>
                                  <h5>{this.state.checkboxlength}</h5>
                                </li>
                                <li>
                                  <img
                                    src={OfferValueImage}
                                    className="img-fluid"
                                    alt="Offer Value"
                                  />
                                  <span>Deal Value</span>
                                  <h5>
                                    {this.state.dealDetails ? this.state.dealDetails.deal_amount : ''}

                                  </h5>
                                </li>
                              </ul>
                            </div>
                          </div>

                          <div className="Common_Block mt-21">
                            <div className="Common_Block_Header d-flex align-items-center justify-content-between">
                              <h5 className="">Digital Deal Campaigns(0)</h5>
                            </div>

                            <div className="Common_Block_Body">

                              <div className="table-responsive Gray_Header_Table">
                                {this.state.dealDetails ?

                                  this.state.dealDetails.deal_target_type_id === 2 ? (
                                    <table className="table text-center">
                                      <thead>
                                        <tr>
                                          <th className="text-dark">Retailer</th>
                                          <th>Start</th>
                                          <th>Expiration</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {Object.keys(
                                          this.state.dealDetails.retailer
                                        ).map((val, idx) => {

                                          return (
                                            <tr>
                                              <td>
                                                <img src={this.state.dealDetails.retailer[val].logo
                                                }
                                                  className="img-fluid"
                                                  alt={val}
                                                />

                                                {/* {this.state.dealDetails.retailer[val].retailer_name} */}
                                              </td>
                                              <td>
                                                {
                                                  this.handleSeconDdate(new Date(this.state.dealDetails.retailer[val].start_date.substring(
                                                    0,
                                                    this.state.dealDetails.retailer[val].start_date.length - 1
                                                  )))
                                                }
                                              </td>
                                              <td>
                                                {
                                                  this.handleSeconDdate(new Date(this.state.dealDetails.retailer[val].end_date.substring(
                                                    0,
                                                    this.state.dealDetails.retailer[val].end_date.length - 1
                                                  )))
                                                }
                                              </td>
                                            </tr>)

                                        })}
                                      </tbody>
                                    </table>

                                  ) : (
                                    <table className="table text-center">
                                      <thead>
                                        <tr>
                                          <th className="text-dark">
                                            Wholesaler
                                          </th>
                                          <th>Start</th>
                                          <th>Expiration</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {Object.keys(
                                          this.state.camp_wholesalername
                                        ).map((val, idx) => (
                                          <tr>
                                            <td>
                                              <img
                                                src={
                                                  this.state.wholesalerlogo[val]
                                                    ? this.state.wholesalerlogo[
                                                    val
                                                    ]
                                                    : this.state.WholeSalerLogo[
                                                    val
                                                    ]
                                                }
                                                className="img-fluid"
                                                alt={val}
                                              />
                                            </td>
                                            <td>
                                              {this.state.global_prev_start_date}
                                            </td>
                                            <td>
                                              {this.state.global_prev_end_date}
                                            </td>
                                          </tr>
                                        ))}
                                      </tbody>
                                    </table>

                                  )
                                  : ''
                                }
                              </div>
                              {/* </div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      className="tab-pane fade"
                      id="nav-step-2"
                      role="tabpanel"
                      aria-labelledby="nav-step-2-tab"
                    >
                      <div className="row  g-3">
                        <div className="col-xxl-9 col-xl-8 col-12">
                          <div className="Steps_Header_Gray ">
                            <h5 className="">Edit Deal</h5>
                          </div>

                          <div className="Common_Block">
                            <div className="Common_Block_Header d-flex align-items-center justify-content-between rounded-0">
                              <h5 className="">Deal Details</h5>
                            </div>

                            <div className="Common_Block_Body pt-17 pb-17 pr-14 pl-14 Build_Offer_Block">
                              <h5 className="Title">Deal Unit</h5>

                              <div className="row">

                                <div className="col-lg-3 col-md-6 col-12">
                                  <div
                                    className={`Offer_Type ${this.state.dealDetails ?
                                      this.state.dealDetails.deal_unit === 1
                                        ? `Selected`
                                        : ` `
                                      : ''
                                      }`}
                                    onClick={(e) =>
                                      this.onchangeOffertype(e, 1)
                                    }
                                    style={{
                                      pointerEvents: 'none'
                                    }}
                                  >
                                    <div className="position-relative Image_Block">
                                      <svg width="75" height="76" viewBox="0 0 75 76" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="37.7998" cy="37.875" r="25" class="DarkGray" fill='current' />
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M15.9374 7.74333C22.0607 3.19119 29.622 0.5 37.7998 0.5C45.9776 0.5 53.5389 3.19119 59.6622 7.74333C60.5486 8.40233 60.733 9.65516 60.074 10.5416C59.415 11.4281 58.1622 11.6124 57.2757 10.9534C51.8152 6.89402 45.0847 4.5 37.7998 4.5C30.5149 4.5 23.7844 6.89403 18.3239 10.9535C17.4374 11.6124 16.1846 11.4281 15.5256 10.5416C14.8666 9.65516 15.051 8.40233 15.9374 7.74333ZM65.0261 15.6001C65.9257 14.9592 67.1745 15.1688 67.8155 16.0684C72.2111 22.2377 74.7998 29.8164 74.7998 38C74.7998 46.1836 72.2111 53.7623 67.8155 59.9316C67.1745 60.8311 65.9257 61.0408 65.0261 60.3999C64.1265 59.7589 63.9168 58.5101 64.5578 57.6105C68.4841 52.0998 70.7998 45.3284 70.7998 38C70.7998 30.6716 68.4841 23.9002 64.5578 18.3895C63.9168 17.4899 64.1265 16.2411 65.0261 15.6001ZM10.5735 15.6001C11.4731 16.2411 11.6828 17.4899 11.0418 18.3895C7.11551 23.9002 4.7998 30.6716 4.7998 38C4.7998 45.3284 7.11551 52.0998 11.0418 57.6105C11.6828 58.5101 11.4731 59.7589 10.5735 60.3999C9.67393 61.0408 8.42508 60.8312 7.78413 59.9316C3.38855 53.7623 0.799805 46.1836 0.799805 38C0.799805 29.8164 3.38855 22.2377 7.78413 16.0684C8.42508 15.1689 9.67393 14.9592 10.5735 15.6001ZM15.5256 65.4584C16.1846 64.5719 17.4374 64.3876 18.3239 65.0466C23.7844 69.106 30.5149 71.5 37.7998 71.5C45.0847 71.5 51.8152 69.106 57.2757 65.0465C58.1622 64.3876 59.415 64.5719 60.074 65.4584C60.733 66.3448 60.5486 67.5977 59.6622 68.2567C53.5389 72.8088 45.9776 75.5 37.7998 75.5C29.622 75.5 22.0607 72.8088 15.9375 68.2567C15.051 67.5977 14.8666 66.3448 15.5256 65.4584Z" class="LightGray" fill='current' />
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M15.9373 7.74333C22.0606 3.19119 29.6218 0.5 37.7996 0.5C45.9775 0.5 53.5387 3.19119 59.662 7.74333C60.5484 8.40233 60.7328 9.65516 60.0738 10.5416C59.4148 11.4281 58.162 11.6124 57.2755 10.9535C51.815 6.89402 45.0845 4.5 37.7996 4.5C30.5147 4.5 23.7842 6.89403 18.3237 10.9535C17.4373 11.6125 16.1844 11.4281 15.5254 10.5416C14.8664 9.65516 15.0508 8.40233 15.9373 7.74333ZM65.0259 15.6001C65.9255 14.9592 67.1744 15.1688 67.8153 16.0684C72.2109 22.2377 74.7996 29.8164 74.7996 38C74.7996 46.1836 72.2109 53.7623 67.8153 59.9316C67.1744 60.8311 65.9255 61.0408 65.0259 60.3999C64.1263 59.7589 63.9167 58.5101 64.5576 57.6105C68.4839 52.0998 70.7996 45.3284 70.7996 38C70.7996 30.6716 68.4839 23.9002 64.5576 18.3895C63.9167 17.4899 64.1263 16.2411 65.0259 15.6001Z" class="LightGray" fill='current' />
                                      </svg>

                                      <img src={caseImage} className="img-fluid" alt="Case" />
                                    </div>

                                    <p>Case</p>
                                  </div>
                                </div>

                                <div className="col-lg-3 col-md-6 col-12">
                                  <div
                                    className={`Offer_Type ${this.state.dealDetails ?
                                      this.state.dealDetails.deal_unit === 2
                                        ? `Selected`
                                        : ` `
                                      : ''
                                      }`}
                                    onClick={(e) =>
                                      this.onchangeOffertype(e, 2)
                                    }
                                    style={{
                                      pointerEvents: 'none'
                                    }}

                                  >
                                    <div className="position-relative Image_Block">
                                      <svg width="75" height="76" viewBox="0 0 75 76" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="37.7998" cy="37.875" r="25" class="DarkGray" fill='current' />
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M15.9374 7.74333C22.0607 3.19119 29.622 0.5 37.7998 0.5C45.9776 0.5 53.5389 3.19119 59.6622 7.74333C60.5486 8.40233 60.733 9.65516 60.074 10.5416C59.415 11.4281 58.1622 11.6124 57.2757 10.9534C51.8152 6.89402 45.0847 4.5 37.7998 4.5C30.5149 4.5 23.7844 6.89403 18.3239 10.9535C17.4374 11.6124 16.1846 11.4281 15.5256 10.5416C14.8666 9.65516 15.051 8.40233 15.9374 7.74333ZM65.0261 15.6001C65.9257 14.9592 67.1745 15.1688 67.8155 16.0684C72.2111 22.2377 74.7998 29.8164 74.7998 38C74.7998 46.1836 72.2111 53.7623 67.8155 59.9316C67.1745 60.8311 65.9257 61.0408 65.0261 60.3999C64.1265 59.7589 63.9168 58.5101 64.5578 57.6105C68.4841 52.0998 70.7998 45.3284 70.7998 38C70.7998 30.6716 68.4841 23.9002 64.5578 18.3895C63.9168 17.4899 64.1265 16.2411 65.0261 15.6001ZM10.5735 15.6001C11.4731 16.2411 11.6828 17.4899 11.0418 18.3895C7.11551 23.9002 4.7998 30.6716 4.7998 38C4.7998 45.3284 7.11551 52.0998 11.0418 57.6105C11.6828 58.5101 11.4731 59.7589 10.5735 60.3999C9.67393 61.0408 8.42508 60.8312 7.78413 59.9316C3.38855 53.7623 0.799805 46.1836 0.799805 38C0.799805 29.8164 3.38855 22.2377 7.78413 16.0684C8.42508 15.1689 9.67393 14.9592 10.5735 15.6001ZM15.5256 65.4584C16.1846 64.5719 17.4374 64.3876 18.3239 65.0466C23.7844 69.106 30.5149 71.5 37.7998 71.5C45.0847 71.5 51.8152 69.106 57.2757 65.0465C58.1622 64.3876 59.415 64.5719 60.074 65.4584C60.733 66.3448 60.5486 67.5977 59.6622 68.2567C53.5389 72.8088 45.9776 75.5 37.7998 75.5C29.622 75.5 22.0607 72.8088 15.9375 68.2567C15.051 67.5977 14.8666 66.3448 15.5256 65.4584Z" class="LightGray" fill='current' />
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M15.9373 7.74333C22.0606 3.19119 29.6218 0.5 37.7996 0.5C45.9775 0.5 53.5387 3.19119 59.662 7.74333C60.5484 8.40233 60.7328 9.65516 60.0738 10.5416C59.4148 11.4281 58.162 11.6124 57.2755 10.9535C51.815 6.89402 45.0845 4.5 37.7996 4.5C30.5147 4.5 23.7842 6.89403 18.3237 10.9535C17.4373 11.6125 16.1844 11.4281 15.5254 10.5416C14.8664 9.65516 15.0508 8.40233 15.9373 7.74333ZM65.0259 15.6001C65.9255 14.9592 67.1744 15.1688 67.8153 16.0684C72.2109 22.2377 74.7996 29.8164 74.7996 38C74.7996 46.1836 72.2109 53.7623 67.8153 59.9316C67.1744 60.8311 65.9255 61.0408 65.0259 60.3999C64.1263 59.7589 63.9167 58.5101 64.5576 57.6105C68.4839 52.0998 70.7996 45.3284 70.7996 38C70.7996 30.6716 68.4839 23.9002 64.5576 18.3895C63.9167 17.4899 64.1263 16.2411 65.0259 15.6001Z" class="LightGray" fill='current' />
                                      </svg>
                                      <img src={Pallet} className="img-fluid" alt="Pallet" />
                                    </div>

                                    <p>Pallet</p>
                                  </div>
                                </div>

                                <div className="col-lg-2 col-md-6 col-12">
                                  <div
                                    className={`Offer_Type ${this.state.dealDetails ?
                                      this.state.dealDetails.deal_unit === 3
                                        ? `Selected`
                                        : ` `
                                      : ''
                                      }`}
                                    style={{
                                      pointerEvents: 'none'
                                    }}

                                  >
                                    <div className="position-relative Image_Block">
                                      <svg width="75" height="76" viewBox="0 0 75 76" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="37.7998" cy="37.875" r="25" class="DarkGray" fill='current' />
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M15.9374 7.74333C22.0607 3.19119 29.622 0.5 37.7998 0.5C45.9776 0.5 53.5389 3.19119 59.6622 7.74333C60.5486 8.40233 60.733 9.65516 60.074 10.5416C59.415 11.4281 58.1622 11.6124 57.2757 10.9534C51.8152 6.89402 45.0847 4.5 37.7998 4.5C30.5149 4.5 23.7844 6.89403 18.3239 10.9535C17.4374 11.6124 16.1846 11.4281 15.5256 10.5416C14.8666 9.65516 15.051 8.40233 15.9374 7.74333ZM65.0261 15.6001C65.9257 14.9592 67.1745 15.1688 67.8155 16.0684C72.2111 22.2377 74.7998 29.8164 74.7998 38C74.7998 46.1836 72.2111 53.7623 67.8155 59.9316C67.1745 60.8311 65.9257 61.0408 65.0261 60.3999C64.1265 59.7589 63.9168 58.5101 64.5578 57.6105C68.4841 52.0998 70.7998 45.3284 70.7998 38C70.7998 30.6716 68.4841 23.9002 64.5578 18.3895C63.9168 17.4899 64.1265 16.2411 65.0261 15.6001ZM10.5735 15.6001C11.4731 16.2411 11.6828 17.4899 11.0418 18.3895C7.11551 23.9002 4.7998 30.6716 4.7998 38C4.7998 45.3284 7.11551 52.0998 11.0418 57.6105C11.6828 58.5101 11.4731 59.7589 10.5735 60.3999C9.67393 61.0408 8.42508 60.8312 7.78413 59.9316C3.38855 53.7623 0.799805 46.1836 0.799805 38C0.799805 29.8164 3.38855 22.2377 7.78413 16.0684C8.42508 15.1689 9.67393 14.9592 10.5735 15.6001ZM15.5256 65.4584C16.1846 64.5719 17.4374 64.3876 18.3239 65.0466C23.7844 69.106 30.5149 71.5 37.7998 71.5C45.0847 71.5 51.8152 69.106 57.2757 65.0465C58.1622 64.3876 59.415 64.5719 60.074 65.4584C60.733 66.3448 60.5486 67.5977 59.6622 68.2567C53.5389 72.8088 45.9776 75.5 37.7998 75.5C29.622 75.5 22.0607 72.8088 15.9375 68.2567C15.051 67.5977 14.8666 66.3448 15.5256 65.4584Z" class="LightGray" fill='current' />
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M15.9373 7.74333C22.0606 3.19119 29.6218 0.5 37.7996 0.5C45.9775 0.5 53.5387 3.19119 59.662 7.74333C60.5484 8.40233 60.7328 9.65516 60.0738 10.5416C59.4148 11.4281 58.162 11.6124 57.2755 10.9535C51.815 6.89402 45.0845 4.5 37.7996 4.5C30.5147 4.5 23.7842 6.89403 18.3237 10.9535C17.4373 11.6125 16.1844 11.4281 15.5254 10.5416C14.8664 9.65516 15.0508 8.40233 15.9373 7.74333ZM65.0259 15.6001C65.9255 14.9592 67.1744 15.1688 67.8153 16.0684C72.2109 22.2377 74.7996 29.8164 74.7996 38C74.7996 46.1836 72.2109 53.7623 67.8153 59.9316C67.1744 60.8311 65.9255 61.0408 65.0259 60.3999C64.1263 59.7589 63.9167 58.5101 64.5576 57.6105C68.4839 52.0998 70.7996 45.3284 70.7996 38C70.7996 30.6716 68.4839 23.9002 64.5576 18.3895C63.9167 17.4899 64.1263 16.2411 65.0259 15.6001Z" class="LightGray" fill='current' />
                                      </svg>
                                      <img src={Truck} className="img-fluid" alt="Truck" />

                                    </div>

                                    <p>Truck</p>
                                  </div>
                                </div>

                                <div className="col-lg-2 col-md-6 col-12"  >
                                  <div
                                    className={`Offer_Type ${this.state.dealDetails ?
                                      this.state.dealDetails.deal_unit === 4
                                        ? `Selected`
                                        : ` `
                                      : ''
                                      }`}
                                    style={{
                                      pointerEvents: 'none'
                                    }}
                                  >
                                    <div className="position-relative Image_Block">
                                      <svg width="75" height="76" viewBox="0 0 75 76" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="37.7998" cy="37.875" r="25" class="DarkGray" fill='current' />
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M15.9374 7.74333C22.0607 3.19119 29.622 0.5 37.7998 0.5C45.9776 0.5 53.5389 3.19119 59.6622 7.74333C60.5486 8.40233 60.733 9.65516 60.074 10.5416C59.415 11.4281 58.1622 11.6124 57.2757 10.9534C51.8152 6.89402 45.0847 4.5 37.7998 4.5C30.5149 4.5 23.7844 6.89403 18.3239 10.9535C17.4374 11.6124 16.1846 11.4281 15.5256 10.5416C14.8666 9.65516 15.051 8.40233 15.9374 7.74333ZM65.0261 15.6001C65.9257 14.9592 67.1745 15.1688 67.8155 16.0684C72.2111 22.2377 74.7998 29.8164 74.7998 38C74.7998 46.1836 72.2111 53.7623 67.8155 59.9316C67.1745 60.8311 65.9257 61.0408 65.0261 60.3999C64.1265 59.7589 63.9168 58.5101 64.5578 57.6105C68.4841 52.0998 70.7998 45.3284 70.7998 38C70.7998 30.6716 68.4841 23.9002 64.5578 18.3895C63.9168 17.4899 64.1265 16.2411 65.0261 15.6001ZM10.5735 15.6001C11.4731 16.2411 11.6828 17.4899 11.0418 18.3895C7.11551 23.9002 4.7998 30.6716 4.7998 38C4.7998 45.3284 7.11551 52.0998 11.0418 57.6105C11.6828 58.5101 11.4731 59.7589 10.5735 60.3999C9.67393 61.0408 8.42508 60.8312 7.78413 59.9316C3.38855 53.7623 0.799805 46.1836 0.799805 38C0.799805 29.8164 3.38855 22.2377 7.78413 16.0684C8.42508 15.1689 9.67393 14.9592 10.5735 15.6001ZM15.5256 65.4584C16.1846 64.5719 17.4374 64.3876 18.3239 65.0466C23.7844 69.106 30.5149 71.5 37.7998 71.5C45.0847 71.5 51.8152 69.106 57.2757 65.0465C58.1622 64.3876 59.415 64.5719 60.074 65.4584C60.733 66.3448 60.5486 67.5977 59.6622 68.2567C53.5389 72.8088 45.9776 75.5 37.7998 75.5C29.622 75.5 22.0607 72.8088 15.9375 68.2567C15.051 67.5977 14.8666 66.3448 15.5256 65.4584Z" class="LightGray" fill='current' />
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M15.9373 7.74333C22.0606 3.19119 29.6218 0.5 37.7996 0.5C45.9775 0.5 53.5387 3.19119 59.662 7.74333C60.5484 8.40233 60.7328 9.65516 60.0738 10.5416C59.4148 11.4281 58.162 11.6124 57.2755 10.9535C51.815 6.89402 45.0845 4.5 37.7996 4.5C30.5147 4.5 23.7842 6.89403 18.3237 10.9535C17.4373 11.6125 16.1844 11.4281 15.5254 10.5416C14.8664 9.65516 15.0508 8.40233 15.9373 7.74333ZM65.0259 15.6001C65.9255 14.9592 67.1744 15.1688 67.8153 16.0684C72.2109 22.2377 74.7996 29.8164 74.7996 38C74.7996 46.1836 72.2109 53.7623 67.8153 59.9316C67.1744 60.8311 65.9255 61.0408 65.0259 60.3999C64.1263 59.7589 63.9167 58.5101 64.5576 57.6105C68.4839 52.0998 70.7996 45.3284 70.7996 38C70.7996 30.6716 68.4839 23.9002 64.5576 18.3895C63.9167 17.4899 64.1263 16.2411 65.0259 15.6001Z" class="LightGray" fill='current' />
                                      </svg>
                                      <img src={Shipper} className="img-fluid" alt="Shipper" />

                                    </div>

                                    <p>Shipper</p>

                                  </div>
                                </div>

                                <div className="col-lg-2 col-md-6 col-12">
                                  <div
                                    className={`Offer_Type ${this.state.dealDetails ?
                                      this.state.dealDetails.deal_unit === 5
                                        ? `Selected`
                                        : ` `
                                      : ''
                                      }`}
                                    style={{
                                      pointerEvents: 'none'
                                    }}
                                  >
                                    <div className="position-relative Image_Block">
                                      <svg width="75" height="76" viewBox="0 0 75 76" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="37.7998" cy="37.875" r="25" class="DarkGray" fill='current' />
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M15.9374 7.74333C22.0607 3.19119 29.622 0.5 37.7998 0.5C45.9776 0.5 53.5389 3.19119 59.6622 7.74333C60.5486 8.40233 60.733 9.65516 60.074 10.5416C59.415 11.4281 58.1622 11.6124 57.2757 10.9534C51.8152 6.89402 45.0847 4.5 37.7998 4.5C30.5149 4.5 23.7844 6.89403 18.3239 10.9535C17.4374 11.6124 16.1846 11.4281 15.5256 10.5416C14.8666 9.65516 15.051 8.40233 15.9374 7.74333ZM65.0261 15.6001C65.9257 14.9592 67.1745 15.1688 67.8155 16.0684C72.2111 22.2377 74.7998 29.8164 74.7998 38C74.7998 46.1836 72.2111 53.7623 67.8155 59.9316C67.1745 60.8311 65.9257 61.0408 65.0261 60.3999C64.1265 59.7589 63.9168 58.5101 64.5578 57.6105C68.4841 52.0998 70.7998 45.3284 70.7998 38C70.7998 30.6716 68.4841 23.9002 64.5578 18.3895C63.9168 17.4899 64.1265 16.2411 65.0261 15.6001ZM10.5735 15.6001C11.4731 16.2411 11.6828 17.4899 11.0418 18.3895C7.11551 23.9002 4.7998 30.6716 4.7998 38C4.7998 45.3284 7.11551 52.0998 11.0418 57.6105C11.6828 58.5101 11.4731 59.7589 10.5735 60.3999C9.67393 61.0408 8.42508 60.8312 7.78413 59.9316C3.38855 53.7623 0.799805 46.1836 0.799805 38C0.799805 29.8164 3.38855 22.2377 7.78413 16.0684C8.42508 15.1689 9.67393 14.9592 10.5735 15.6001ZM15.5256 65.4584C16.1846 64.5719 17.4374 64.3876 18.3239 65.0466C23.7844 69.106 30.5149 71.5 37.7998 71.5C45.0847 71.5 51.8152 69.106 57.2757 65.0465C58.1622 64.3876 59.415 64.5719 60.074 65.4584C60.733 66.3448 60.5486 67.5977 59.6622 68.2567C53.5389 72.8088 45.9776 75.5 37.7998 75.5C29.622 75.5 22.0607 72.8088 15.9375 68.2567C15.051 67.5977 14.8666 66.3448 15.5256 65.4584Z" class="LightGray" fill='current' />
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M15.9373 7.74333C22.0606 3.19119 29.6218 0.5 37.7996 0.5C45.9775 0.5 53.5387 3.19119 59.662 7.74333C60.5484 8.40233 60.7328 9.65516 60.0738 10.5416C59.4148 11.4281 58.162 11.6124 57.2755 10.9535C51.815 6.89402 45.0845 4.5 37.7996 4.5C30.5147 4.5 23.7842 6.89403 18.3237 10.9535C17.4373 11.6125 16.1844 11.4281 15.5254 10.5416C14.8664 9.65516 15.0508 8.40233 15.9373 7.74333ZM65.0259 15.6001C65.9255 14.9592 67.1744 15.1688 67.8153 16.0684C72.2109 22.2377 74.7996 29.8164 74.7996 38C74.7996 46.1836 72.2109 53.7623 67.8153 59.9316C67.1744 60.8311 65.9255 61.0408 65.0259 60.3999C64.1263 59.7589 63.9167 58.5101 64.5576 57.6105C68.4839 52.0998 70.7996 45.3284 70.7996 38C70.7996 30.6716 68.4839 23.9002 64.5576 18.3895C63.9167 17.4899 64.1263 16.2411 65.0259 15.6001Z" class="LightGray" fill='current' />
                                      </svg>
                                      <img src={Unit} className="img-fluid" alt="Unit" />

                                    </div>

                                    <p>Unit</p>

                                  </div>
                                </div>

                              </div>

                              {/* <form className="Offer_Form">  */}
                              <div className="row">

                                <div className="col-12 mt-28 d-flex align-items-center">
                                </div>


                                <div className="col-6 mt-28">
                                  <Form.Group
                                    controlId="reward_amount"
                                    className="form-group"
                                  >
                                    <Form.Label>Deal Value</Form.Label>
                                    <Form.Control
                                      type="text"
                                      placeholder="E.g. 1.00"
                                      name="reward_amount"
                                      onChange={(e) => this.changeHandler(e)}
                                      defaultValue={
                                        this.state.dealDetails
                                          ? this.state.dealDetails
                                            .deal_amount
                                          : ""
                                      }
                                      step=".01"
                                    />
                                  </Form.Group>
                                </div>

                                <div className="col-6 mt-28">
                                  <Form.Group
                                    controlId="reward_amount"
                                    className="form-group"
                                  >
                                    <Form.Label>Deal Code</Form.Label>
                                    <Form.Control
                                      type="text"
                                      placeholder="Deal Code"
                                      name="deal_code"
                                      onChange={(e) => this.changeHandler(e)}
                                      defaultValue={
                                        this.state.dealDetails
                                          ? this.state.dealDetails
                                            .deal_code
                                          : ""
                                      }

                                    />
                                  </Form.Group>
                                </div>


                                <div className="col-12 mt-28">
                                  <Form.Group
                                    controlId="title"
                                    className="form-group"
                                  >
                                    <Form.Label>Title</Form.Label>
                                    <Form.Control
                                      type="text"
                                      placeholder="Title"
                                      name="title"
                                      defaultValue={
                                        this.state.dealDetails
                                          ? this.state.dealDetails
                                            .deal_title.replace(/\\\\'/g, "\'")
                                          : ""
                                      }
                                      onChange={(e) => this.changeHandler(e)}
                                    />
                                  </Form.Group>
                                </div>
                                <div className="col-12 mt-28">
                                  <Form.Group className="form-group">
                                    <Form.Label>Description</Form.Label>
                                    <Form.Control
                                      placeholder="Description"
                                      as="textarea"
                                      rows={1}
                                      name="description"
                                      id="description"
                                      defaultValue={
                                        this.state.dealDetails
                                          ? this.state.dealDetails
                                            .deal_description.replace(/\\'/g, "\'")
                                          : ""
                                      }
                                      onChange={(e) => this.changeHandler(e)}
                                    />
                                  </Form.Group>
                                </div>

                                <div className="col-md-3 col-12 mt-28">
                                  <Form.Group
                                    className="form-group"
                                    controlId="exampleForm.ControlInput1"
                                    style={{ marginTop: '7px' }}>
                                    <Form.Label>Manufacturer ships between</Form.Label>

                                  </Form.Group>
                                </div>


                                <div className="col-md-3 col-12 mt-28">
                                  <Form.Group
                                    className="form-group"
                                    controlId="exampleForm.ControlInput1"
                                  >
                                    <Form.Label>Start Date</Form.Label>

                                    <Form.Control
                                      type="date"
                                      name="start_date"
                                      min={
                                        new Date().toISOString().split("T")[0]
                                      }
                                      onKeyDown={(e) => e.preventDefault()}
                                      defaultValue={
                                        this.state.dealDetails
                                          ? this.handleDate(new Date(this.state.dealDetails
                                            .deal_start_date)) : ''
                                      }
                                      disabled={true}
                                    />
                                  </Form.Group>
                                </div>

                                <div className="col-md-3 col-12 mt-28">
                                  <Form.Group
                                    className=" form-group"
                                    controlId="exampleForm.ControlInput2"
                                  >
                                    <Form.Label>End Date</Form.Label>

                                    <Form.Control
                                      type="date"
                                      name="end_date"
                                      onKeyDown={(e) => e.preventDefault()}
                                      onChange={(e) => this.setEndDate(e)}
                                      defaultValue={
                                        this.state.dealDetails
                                          ? this.handleDate(new Date(this.state.dealDetails
                                            .deal_end_date)) : ''
                                      }
                                      disabled={true}
                                    />
                                  </Form.Group>
                                </div>

                                <div className="col-md-3 col-12 mt-28">
                                  <Form.Group
                                    className=" form-group"
                                    controlId="exampleForm.ControlInput2"
                                  >
                                    <Form.Label>Order By Date</Form.Label>

                                    <Form.Control
                                      type="date"
                                      name="shutoff_date"
                                      onKeyDown={(e) => e.preventDefault()}
                                      onChange={(e) => this.setShutoffDate(e)}
                                      defaultValue={
                                        this.state.dealDetails
                                          ? this.handleDate(new Date(this.state.dealDetails
                                            .deal_order_before_date)) : ''
                                      }
                                      disabled={true}
                                    />
                                  </Form.Group>
                                </div>

                                <div className="col-12 mt-28">
                                  <Form.Group className="form-group">
                                    <Form.Label>
                                      Min Purchase Quantity
                                    </Form.Label>
                                    <Form.Control
                                      placeholder="ENter qty here. Max qty allowed is 10"
                                      type="text"
                                      name="min_purchase_amount"
                                      id="min_purchase_amount"
                                      defaultValue={
                                        this.state.dealDetails
                                          ? this.state.dealDetails
                                            .min_required_purchase_qty
                                          : ""
                                      }
                                      onChange={(e) => this.changeHandler(e)}
                                    />
                                  </Form.Group>
                                </div>

                                <div className="col-12 mt-28">
                                  <Form.Group
                                    controlId="Descripation"
                                    className="form-group"
                                  >
                                    <Form.Label>
                                      Purchase Requirement Description
                                    </Form.Label>
                                    <Form.Control
                                      placeholder="Purchase Requirement Description"
                                      as="textarea"
                                      rows={1}
                                      defaultValue={
                                        this.state.dealDetails
                                          ? this.state.dealDetails
                                            .addtional_details
                                          : ""
                                      }
                                      name="addtional_details"
                                      onChange={(e) => this.changeHandler(e)}
                                    />
                                  </Form.Group>
                                </div>

                                <div className="col-12 mt-28">
                                  <Form.Group
                                    controlId="Descripation"
                                    className="form-group"
                                  >
                                    <Form.Label>
                                      Deal Legal Disclaimer
                                    </Form.Label>
                                    <Form.Control
                                      placeholder="Coupon Legal Copy"
                                      as="textarea"
                                      rows={4}
                                      defaultValue={
                                        this.state.dealDetails
                                          ? this.state.dealDetails.disclaimer
                                          : ""
                                      }
                                      name="disclaimer"
                                      onChange={(e) => this.changeHandler(e)}
                                    />
                                  </Form.Group>
                                </div>
                              </div>
                              <div className="row ">
                                <div className="col-lg-4 col-md-6 col-12 mt-28">
                                  <Form.Group
                                    controlId="Description"
                                    className="form-group"
                                  >
                                    <Form.Label>Campaign Image</Form.Label>
                                    <p>
                                      File must be 250px * 250px, 150dpi png or
                                      jpg
                                    </p>

                                    <div className="File_Upload position-relative ml-7">
                                      <img
                                        src={FileIcon}
                                        className="img-fluid"
                                        alt="File Icon"
                                      />
                                      <p>
                                        <b>Drag & Drop Your</b> files here or{" "}
                                        <b>
                                          <span>browse</span>
                                        </b>{" "}
                                        your computer
                                      </p>
                                      <input
                                        id="file-upload"
                                        type="file"
                                        name="fileUpload"
                                        onChange={(e) => this.onFileChange(e)}
                                        defaultValue={
                                          this.state.dealDetails
                                            ? this.state.dealDetails
                                              .min_purchase_amount
                                            : ""
                                        }
                                        onClick={(event) => {
                                          event.target.value = null;
                                        }}
                                      />
                                    </div>
                                  </Form.Group>
                                </div>

                                <div className="col-lg-4 col-md-6 col-12 mt-28"></div>
                              </div>

                              <div className="row">

                              </div>
                              <div className="row mt-20">
                                <div className="col-12 d-flex justify-content-end">
                                  <div className="TableButton">
                                    <button
                                      type="button"
                                      className="mr-10 btn btn-primary"
                                      onClick={(e) => {
                                        this.changePrevTab(e, 1);
                                      }}
                                    >
                                      <i
                                        className="fas fa-arrow-left mr-10"
                                        aria-hidden="true"
                                      ></i>{" "}
                                      Prev
                                    </button>
                                    <button
                                      type="button"
                                      className="btn btn-primary"
                                      onClick={(e) => {
                                        this.changeSecondTab(e, 3);
                                      }}
                                    >
                                      Next{" "}
                                      <i
                                        className="fas fa-arrow-right ml-10"
                                        aria-hidden="true"
                                      ></i>{" "}
                                    </button>
                                  </div>
                                </div>
                              </div>
                              {/* </form>  */}
                            </div>
                          </div>
                        </div>
                        <div className="col-xxl-3 col-xl-4 col-12">
                          <div className="Common_Block">
                            <div className="Common_Block_Header d-flex align-items-center justify-content-between">
                              <h5 className="">
                                Digital Deal Campaign Summary
                              </h5>
                            </div>
                            <div className="Common_Block_Body">
                              <ul className="Campaign_Summery">
                                <li>
                                  <img
                                    src={ProductsImage}
                                    className="img-fluid"
                                    alt="Products"
                                  />
                                  <span>Products</span>
                                  <h5>{this.state.checkboxlength}</h5>
                                </li>
                                <li>
                                  <img
                                    src={OfferValueImage}
                                    className="img-fluid"
                                    alt="Offer Value"
                                  />
                                  <span>Deal Value</span>
                                  <h5>
                                    {this.state.dealDetails ? this.state.dealDetails.deal_amount : ''}

                                  </h5>
                                </li>
                              </ul>
                            </div>
                          </div>

                          <div className="Common_Block mt-21">
                            <div className="Common_Block_Header d-flex align-items-center justify-content-between">
                              <h5 className="">Deal preview</h5>
                            </div>
                            <div className="Common_Block_Body pt-12 pr-12 pb-12 pl-12">
                              <div className="Coupons_Card text-center">
                                <img
                                  src={scissors}
                                  className="img-fluid scissors"
                                  alt="Products"
                                />
                                <img
                                  src={
                                    this.state.selectedFile
                                      ? this.state.selectedFile
                                      : ""
                                  }
                                  className="img-fluid"
                                  alt=""
                                  id="img-id"
                                />
                                <h2 className="Price">
                                  {this.state.formControls.title
                                    ? this.state.formControls.title
                                    : ""}
                                  <span></span>
                                </h2>
                                <p>{this.state.formControls.description}</p>
                                {/* <button>Any Size</button> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      className="tab-pane fade"
                      id="nav-step-3"
                      role="tabpanel"
                      aria-labelledby="nav-step-3-tab"
                    >
                      <div className="row  g-3">
                        <div className=" col-12">
                          <div className="Steps_Header ">
                            <h5 className="">Edit Deal</h5>
                            <div className="" style={{ float: "right", marginTop: '-27px' }}>
                              {
                                this.state.dealDetails ?
                                  this.state.dealDetails.deal_target_type_id === 2 ? (

                                    <button className="Digital_Brand_Button" data-bs-toggle="modal" id="retailermodal" data-bs-target="#selectretailer-sel-drop"
                                      style={{
                                        borderColor: "#3d8ecc", backgroundColor: "#3d8ecc", color: "#fff", fontWeight: "600", paddingTop: "4px"
                                      }}
                                      type="Button"
                                    >
                                      Add
                                    </button>)
                                    : (

                                      <button className="Digital_Brand_Button" data-bs-toggle="modal" id="wholesalerBtn" data-bs-target="#wholesalerModal"
                                        style={{
                                          borderColor: "#3d8ecc", backgroundColor: "#3d8ecc", color: "#fff", fontWeight: "600", paddingTop: "4px"
                                        }}
                                        type="Button"
                                      >
                                        Add
                                      </button>)


                                  : ''

                              }

                            </div>
                          </div>

                          <div className="Common_Block">
                            <div className="Common_Block_Header d-flex align-items-center bg-white text-black justify-content-center">

                              {
                                this.state.dealDetails ?

                                  this.state.dealDetails.deal_target_type_id === 1 ? (
                                    <>
                                      <h5 className="text-black mr-10">
                                        <input
                                          className="mr-7"
                                          type="checkbox"
                                          id="wholesalercheckbox"
                                          onChange={(e) =>
                                            this.selectWholesalerCheckbox()
                                          }
                                          defaultChecked={true}

                                          disabled={true}
                                        />
                                        Wholesalers
                                      </h5>
                                    </>
                                  ) : (

                                    <h5 className="text-black">
                                      <input
                                        type="checkbox"
                                        className="mr-7"
                                        id="wholesalercheckbox"
                                        onChange={(e) =>
                                          this.selectWholesalerCheckbox()
                                        }
                                        disabled={true}
                                      />
                                      Wholesalers
                                    </h5>
                                  )
                                  : ''
                              }

                              {this.state.dealDetails ?

                                this.state.dealDetails.deal_target_type_id === 2 ? (
                                  <>
                                    <h5 className="text-black ml-10">
                                      <input
                                        type="checkbox"
                                        className="mr-7"
                                        id="retailercheckbox"
                                        name="retailercheckbox"
                                        onChange={(e) =>
                                          this.selectRetailerCheckbox()
                                        }
                                        defaultChecked={true}

                                        disabled={true}
                                      />
                                      Retailers
                                    </h5>

                                  </>
                                ) : (
                                  <h5 className="text-black ml-10">
                                    <input
                                      type="checkbox"
                                      className="mr-7"
                                      id="retailercheckbox"
                                      name="retailercheckbox"
                                      onChange={(e) =>
                                        this.selectRetailerCheckbox()
                                      }
                                      disabled={true}
                                    />
                                    Retailers
                                  </h5>
                                )
                                : ''
                              }


                              {/* } */}
                            </div>

                            <div className="Common_Block_Body pr-12 pl-12 pt-20 pb-20">
                              {this.state.dealDetails ? (
                                <>
                                  {this.state.dealDetails
                                    .deal_target_type_id === 1 ? (
                                    <>
                                      {this.state.warehouse ? (

                                        Object.keys(this.state.groupwarehouse).map((val, idx) => {

                                          return (
                                            <div className="Wholesales_Wharehouse_Outer col-md-7" style={{ width: '35% !important', boxShadow: 'none' }}>

                                              <div className='Wholesales_Wharehouse_Head d-flex align-items-center justify-content-between' style={{ background: '#fff', }}>

                                                <div className="col-md-3">
                                                  <img src={this.state.wholesalergroups[val][0].logo} className="img-fluid mr-4" alt="wholesaler" style={{ height: '140px', width: '140px' }} />
                                                  <br />
                                                  <h4 style={{ textAlign: 'center' }}>{this.state.wholesalergroups[val][0].wholesaler_name}</h4>

                                                </div>

                                                <div className='Wholesales_Wharehouse_Deal col-md-9'>

                                                  {/* <h5>Deal valid at below warehouses only</h5> */}
                                                  {
                                                    Object.keys(this.state.warehouse).length > 0 ?

                                                      <ul>
                                                        {this.state.warehouse.map((name, index) => {

                                                          return (

                                                            <li>
                                                              {name.warehouse_name}
                                                            </li>

                                                          )
                                                        })}

                                                      </ul>
                                                      : ''
                                                  }
                                                </div>
                                              </div>




                                            </div>)




                                        })

                                      ) : (
                                        ""
                                      )}
                                    </>
                                  ) : this.state.retailer_array ? (
                                    <>
                                      <div className="Retailer_Block">
                                        <ul className=" mt-15 d-flex  Retailer">
                                          {this.state.retailer_array.map(
                                            (val, idx1) => {

                                              let check =
                                                this.state.retailer_id.includes(
                                                  val.retailer_id
                                                )
                                                  ? "true"
                                                  : "";

                                              let dis =
                                                this.state.retailer_id.includes(
                                                  val.retailer_id
                                                )
                                                  ? "disabled"
                                                  : "";


                                              return check ? (
                                                <li key={idx1}>
                                                  <div className="Retailer_Block">
                                                    <input
                                                      type="checkbox"
                                                      name="retailername"
                                                      value={val.wholesaler_id}
                                                      onChange={(e) =>
                                                        this.selectRetailer(
                                                          e,
                                                          val.retailer_name,
                                                          val.retailer_id,
                                                          val.wholesaler_id
                                                        )
                                                      }
                                                      style={{
                                                        margin: "9px",
                                                        zoom: "1.2",
                                                        float: "right"
                                                      }}
                                                      defaultChecked={check}
                                                      disabled={true}
                                                    />
                                                    <div className="RetailerImage">
                                                      <img
                                                        src={val.logo}
                                                        className="img-fluid "
                                                        alt="Wholesaler"
                                                      />
                                                    </div>
                                                    <h5>
                                                      {" "}
                                                      {val.retailer_name}
                                                    </h5>
                                                  </div>
                                                </li>
                                              ) : (
                                                ""
                                              );
                                            }
                                          )}
                                        </ul>
                                      </div>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </>
                              ) : (
                                ""
                              )}

                              <div className="row ">
                                <div className="col-12 d-flex justify-content-end">
                                  <div className="TableButton">
                                    <button
                                      type="button"
                                      className="mr-10 btn btn-primary"
                                      onClick={(e) => {
                                        this.changePrevTab(e, 2);
                                      }}
                                    >
                                      <i
                                        className="fas fa-arrow-left mr-10"
                                        aria-hidden="true"
                                      ></i>{" "}
                                      Prev
                                    </button>
                                    <button
                                      type="button"
                                      className="btn btn-primary"
                                      onClick={(e) => {
                                        this.updateDeal(5);
                                      }}
                                    >
                                      Save & Next{" "}
                                      <i
                                        className="fas fa-arrow-right ml-10"
                                        aria-hidden="true"
                                      ></i>{" "}
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>


                    <div
                      className="tab-pane fade"
                      id="nav-step-5"
                      role="tabpanel"
                      aria-labelledby="nav-step-5-tab"
                    >
                      <div className="row  g-3 justify-content-center">
                        <div className="col-12">
                          <div className="Preview_Card Common_Block">
                            <div className="Common_Block_Body">
                              <h5>
                                <span>
                                  Your deal update will be sent to retailers
                                </span>{" "}
                                <b>within</b>{" "}
                              </h5>
                              <h4>24 hours</h4>
                              <div className="Preview_Outer_Block">
                                <ul className="Preview_Card_Row">
                                  <li className=" text-center Coupons_Image">
                                    <div className="Coupons_Outer_Block">
                                      <div className="Coupons_Card">
                                        {/* <img src={scissors} className='img-fluid scissors' alt="Products" /> */}
                                        <img
                                          src={
                                            this.state.selectedFile
                                              ? this.state.selectedFile
                                              : BlockImg
                                          }
                                          className="img-fluid"
                                          alt="Coupon"
                                        />
                                        <h2 className="Price">
                                          {this.state.dealDetails ? this.state.dealDetails.deal_title : ''}
                                          <span></span>
                                        </h2>
                                        <p>
                                          {this.state.dealDetails ? this.state.dealDetails.deal_description : ''}
                                        </p>
                                        {/* <button>Any Size</button> */}
                                      </div>
                                    </div>
                                  </li>
                                  <li className="BgImage d-flex align-items-center">
                                    <div className="Arrow d-flex align-items-center">


                                      <img
                                        src={Arrow}
                                        className="img-fluid ArrowImage"
                                        alt="Arrow"
                                      />
                                    </div>
                                    <div className="ImgBlock position-relative text-center">
                                      <img
                                        src={BlockImg}
                                        className="img-fluid"
                                        alt="Bakckground"
                                      />

                                    </div>
                                  </li>

                                  <li className="d-flex align-items-center Brand_Outer_Block">
                                    <div className="Arrow d-flex align-items-center">

                                      <img
                                        src={Arrow}
                                        className="img-fluid ArrowImage ArrowImageCustom"
                                        alt="Arrow"
                                      />
                                    </div>
                                    <div className="Brand_Logo_Group">

                                      <ul className="row g-3 justify-content-center p-0">
                                        {this.state.dealDetails ?

                                          this.state.dealDetails.deal_target_type_id === 1 ? (

                                            this.state.warehouse.map((val, index) => {
                                              return (
                                                <li className=" col-lg-6 col-md-6 col-12">
                                                  <div className="Image_Outer_Block">
                                                    <img src={BlockImg} className="img-fluid " alt="Logo" />
                                                    <br />


                                                  </div>
                                                  <span>{val.warehouse_name}</span>



                                                </li>
                                              )
                                            })

                                          )
                                            :
                                            this.state.retailer_array.map((val, idx1) => {

                                              let check = this.state.retailer_id.includes(val.retailer_id)
                                                ? "true"
                                                : "";

                                              let dis =
                                                this.state.retailer_id.includes(
                                                  val.retailer_id
                                                )
                                                  ? "disabled"
                                                  : "";
                                              return check ? (

                                                <li className=" col-lg-6 col-md-6 col-12">
                                                  <div className="Image_Outer_Block">
                                                    <img src={val.logo} className="img-fluid " alt="Logo" />
                                                  </div>
                                                  {val.retailer_name}
                                                </li>

                                              ) : ''

                                            })

                                          : ''
                                        }


                                      </ul>
                                    </div>

                                  </li>
                                </ul>
                                <div className="row  ">
                                  <div className="col-lg-6 col-12 mt-45">
                                    <div className="date d-flex align-items-center ">
                                      <Form.Group
                                        className=""
                                        controlId="exampleForm.ControlInput1"
                                      >
                                        <img
                                          src={CalenderSvgImage}
                                          className="img-fluid"
                                          alt="Calender"
                                        />

                                        <Form.Label>
                                          &nbsp;Start: &nbsp;
                                        </Form.Label>

                                        {/* <Form.Control type="date"  onChange={(e)=> this.setDate(e)}/>*/}

                                        <span className="fw-bold">
                                          {this.state.dealDetails ? this.handleDate(new Date(this.state.dealDetails.deal_start_date)) : ''}
                                        </span>
                                      </Form.Group>

                                      <Form.Group
                                        className="ml-12"
                                        controlId="exampleForm.ControlInput2"
                                      >
                                        <img
                                          src={CalenderSvgRedImage}
                                          className="img-fluid"
                                          alt="Calender"
                                        />

                                        <Form.Label>
                                          &nbsp; End: &nbsp;
                                        </Form.Label>

                                        {/* <Form.Control type="date"  onChange={(e)=> this.setDate(e)}/>
                                         */}
                                        <span className="fw-bold">
                                          {" "}
                                          {this.state.dealDetails ? this.handleDate(new Date(this.state.dealDetails.deal_start_date)) : ''}
                                        </span>
                                      </Form.Group>
                                    </div>
                                  </div>
                                  <div className="col-lg-6 col-12 mt-45">
                                    <Link to="/listdeal">
                                      <Button
                                        variant=""
                                        type="button "
                                        className=" Btn_blue mt-20 w-50 ms-auto"
                                      >
                                        Back To Deal List
                                        <img
                                          src={Arrowsvg}
                                          className="img-fluid float-end"
                                          alt="Arrow"
                                        />
                                      </Button>
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
          {
            this.state.dealDetails ?

              this.state.dealDetails.deal_target_type_id === 2 && this.state.retailer_array ? (

                <AddretailerModal Allretailer={this.state.retailer_array} existRetailerid={this.state.retailer_id} selectEditretailer={this.selectEditretailer} />

              ) : ''
              : ''
          }

          {
            this.state.dealDetails ?
              this.state.dealDetails.deal_target_type_id === 1 ? (
                <AddwholesalerModal Allwholesaler={this.state.WholeSalerlist} selectEditwholesaler={this.selectEditwholesaler} handleWarehouse={this.handleWarehouse} />)
                : ''
              : ''
          }

        </section>
        <FooterStrict />
      </>
    );
  }
}

export default Editdeal;
