import RetailershowModal from "../../Components/Common/RetailershowModal";
import AsofRetailerModal from "../../Components/Common/AsofRetailerModal";

const Journey =({currentsummarydetails,asofDatadetails,Sold,Retailer,Stores})=>{

return(
<>
      <div className="Card mt-20 Overview  Subscription_Stores">
            <div className="Card_Header  Steps_Header">
              <h5>Current Summary </h5>
              <h5 style={{ float: 'right' }}> {
              
              Object.keys(currentsummarydetails).length>0 ? currentsummarydetails.DataAsOf : ''}
              <br/> 
                <span style={{fontStyle:'italic',fontSize:'10px'}}>Showing prior 90 days data</span>
              </h5>

                          
            </div>
            {
        Object.keys(currentsummarydetails).length > 0 ?
      
              <div className="Card_Body p-3">
              <ul className="OverviewList row">

                <li className="newOverview w-auto  col-md-6">
                  <div className="Icon">
                    <img
                      src={Sold}
                      className="img-fluid"
                      alt="Sold"
                    />
                  </div>
                  <div className="OverviewList_Info">
                    <p>Total Qty Sold</p>
<span className="d-block">{currentsummarydetails ? currentsummarydetails.TotalQtySold.toString().replace(/\B(?=(\d{3})+(?!\d))/g,",") : ''}</span>

                  </div>
                </li>

                <li className="newOverview w-auto ">
                  <div className="Icon">
                    <img
                      src={Stores}
                      className="img-fluid"
                      alt="Retailer"
                    />
                  </div>
                  <div className="OverviewList_Info">
                    <p>Total Stores Subscribed</p>
                    <span className="d-block">{currentsummarydetails ? currentsummarydetails.TotalStoresSubscribed : ''}</span>

                  </div>
                </li>

                <li className="newOverview w-auto ">
                  <div className="Icon">
                    <img
                      src={Stores}
                      className="img-fluid"
                      alt="Stores"
                    />
                  </div>
                  <div className="OverviewList_Info">
                    <p>Total Stores Selling</p>
                    <span className="d-block">{currentsummarydetails ? currentsummarydetails.TotalStoresSelling : ''}</span>

                  </div>
                </li>

                <li className="newOverview w-auto ">
                  <div className="Icon">
                    <img
                      src={Retailer}
                      className="img-fluid"
                      alt="Retailer"
                    />
                  </div>
                  <div className="OverviewList_Info">
                    <p>Retailers Selling</p>
  <a href="/"   data-bs-toggle="modal" data-bs-target="#journey-sel-drop" id="journeyModal" >
      <span className="d-block">
      {currentsummarydetails ? Object.keys(currentsummarydetails.RetailersSelling).length : ''}</span> 
  </a>
                  </div>
                </li>
              </ul>
            </div>
              : 'No Details Available'
            }
     </div>
                  
                    {
            Object.keys(asofDatadetails).slice(0, Object.keys(asofDatadetails).length -1).length > 0 ?
             
            Object.keys(asofDatadetails).slice(0, Object.keys(asofDatadetails).length -1).map((val,idx)=>

            {
               
              return(
                    
                    <div className="Card mt-20 Overview  Subscription_Stores">
                    <div className="Card_Header  Steps_Header">
                      <h5>At the Beginning </h5>
                      <h5 style={{ float:'right'}}>Snap shot as of {asofDatadetails[val].DataAsOf }
                      <br/> 
                        <span style={{fontStyle:'italic',fontSize:'10px'}}>Showing prior 90 days data</span>
                           
                      </h5>

                    </div>
              
                     <div className="Card_Body p-3">
                      <ul className="OverviewList row">

                        <li className="newOverview w-auto  col-md-6">
                          <div className="Icon">
                            <img
                              src={Sold}
                              className="img-fluid"
                              alt="Sold"
                            />
                          </div>
                          <div className="OverviewList_Info">
                            <p>Total Qty Sold</p>
  <span className="d-block">{asofDatadetails[val].TotalQtySold.toString().replace(/\B(?=(\d{3})+(?!\d))/g,",") }</span>

                          </div>
                        </li>

                        <li className="newOverview w-auto ">
                          <div className="Icon">
                            <img
                              src={Stores}
                              className="img-fluid"
                              alt="Retailer"
                            />
                          </div>
                          <div className="OverviewList_Info">
                            <p>Total Stores Subscribed</p>
                            <span className="d-block">
                              {asofDatadetails[val].TotalStoresSubscribed}</span>

                          </div>
                        </li>

                        <li className="newOverview w-auto ">
                          <div className="Icon">
                            <img
                              src={Stores}
                              className="img-fluid"
                              alt="Stores"
                            />
                          </div>
                          <div className="OverviewList_Info">
                            <p>Total Stores Selling</p>
                            <span className="d-block">
                              {asofDatadetails[val].TotalStoresSelling}</span>

                          </div>
                        </li>

                        <li className="newOverview w-auto ">
                          <div className="Icon">
                            <img
                              src={Retailer}
                              className="img-fluid"
                              alt="Retailer"
                            />
                          </div>
                          <div className="OverviewList_Info">
                            <p>Retailers Selling</p>
                            
          <a href="/"   data-bs-toggle="modal" data-bs-target="#asofretailer-sel-drop" id="journeyModal" >
              <span className="d-block">
              {Object.keys(asofDatadetails[val].RetailersSelling).length }</span> 
         </a>
                          </div>
                        </li>
                      </ul>
                    </div>
                    </div>
              )
            }
                    )
                     : '' 
          }

                  {Object.keys(currentsummarydetails).length > 0 ?

          Object.keys(currentsummarydetails).length>0 && (
          <>   
          <RetailershowModal journeydetails={currentsummarydetails}/>
          </>
          )
          :''
          }

      {
      Object.keys(asofDatadetails).slice(0, Object.keys(asofDatadetails).length -1).length > 0 ?

        Object.keys(asofDatadetails).slice(0, Object.keys(asofDatadetails).length -1).length>0 && (
        <>   
        <AsofRetailerModal asofDatadetails={asofDatadetails}/>
        </>
      )
      :''
      }    
                     </>              
)

}

export default Journey;