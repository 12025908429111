import ls from "local-storage";
const AccountDetails = ({ role_name, Avtar1, ClipLoader, brandInfo, accountLoader, userinfo, inputChange, updateUser }) => {

  return (

    <>
      <div className="brand-user mb-20">
        <div className="d-flex align-items-center justify-content-between mb-pr-choose">
          <div className="Profile d-flex  align-items-center justify-content-between">
            {/* {
          role_name === 'brand admin'? */}
            <>
              <img
                src={ls.get("brands")[0].logo}
                className="img-fluid avtar-img"
                alt="Avtar"
              />
              <div className="ml-30 profil-text-item">
                <span className="d-block mb-5">Account #: {brandInfo ? brandInfo.account_number : ''}</span>
                <span>Brand Name: {brandInfo ? brandInfo.brand_name : ''} </span>
              </div>
            </>
            {/* :
            <img
              src={Avtar1}
              className="img-fluid avtar-img"
              alt="Avtar"
            />
             } */}


          </div>


          {
            role_name === 'brand admin' ?
              <>

                <div className="choose-btn-poss">
                  <span>Address: {brandInfo ? brandInfo.address1 : ''}</span>
                  <br />
                  <span>City: {brandInfo ? brandInfo.city : ''}</span>
                  <br />
                  <span>Zip: {brandInfo ? brandInfo.postal_code : ''}</span>

                </div>
              </>
              : ''
          }
        </div>
      </div>

      <div className="profile-box">
        <h1 className="account-head">Account Information</h1>
        {accountLoader ? (
          <div
            className="Clip_Loader"
            style={{ minHeight: "280px" }}
          >
            <ClipLoader
              color={"rgb(0, 143, 251)"}
              loading={true}
              size={50}
            />
          </div>
        ) :
          <form className="row gx-4 gy-3" onSubmit={updateUser}>
            <div className="col-md-6">
              <label
                htmlFor="inputEmail4"
                className="form-label ml-10 main-form-profile"
              >
                First Name
              </label>
              <input
                type="text"
                className="form-control profile-input-control"
                placeholder="Enter Name"
                name="first_name"
                id="first_name"
                defaultValue={
                  userinfo
                    ? userinfo.first_name
                    : ""
                }
                onChange={inputChange}
                disabled={true}
              />
            </div>
            <div className="col-md-6">
              <label
                htmlFor="inputEmail4"
                className="form-label ml-10 main-form-profile"
              >
                Last Name
              </label>
              <input
                type="text"
                className="form-control profile-input-control"
                placeholder="Last Name"
                name="last_name"
                id="last_name"
                defaultValue={
                  userinfo
                    ? userinfo.last_name
                    : ""
                }
                onChange={inputChange}
                disabled={true}
              />
            </div>
            <div className="col-md-6">
              <label
                htmlFor="inputEmail4"
                className="form-label ml-10 main-form-profile"
              >
                Email
              </label>
              <input
                type="email"
                className="form-control profile-input-control"
                placeholder="Enter Email"
                name="email"
                id="email"
                defaultValue={
                  userinfo
                    ? userinfo.email
                    : ""
                }
                onChange={inputChange}
                disabled={true}
              />
            </div>
            <div className="col-md-6">
              <label
                htmlFor="inputAddress2"
                className="form-label ml-10 main-form-profile"
              >
                Phone
              </label>
              <input
                type="text"
                className="form-control profile-input-control"
                name="primary_phone"
                placeholder="Enter Phone"
                id="primary_phone"
                defaultValue={
                  userinfo
                    ? userinfo.primary_phone
                    : ""
                }
                onChange={inputChange}
              />
            </div>

            {/* <div class="col-md-6">
                          <label for="inputCity" class="form-label ml-10 main-form-profile">Job Title</label>
                          <input type="text" class="form-control profile-input-control" id="inputCity" placeholder="Enter Job Title" defaultValue={this.state.userinfo?this.state.userinfo.brand_job_title:''}/>
                      </div> */}
            <div className="col-md-6">
              <label
                htmlFor="inputZip"
                className="form-label ml-10 main-form-profile"
              >
                BrandIQ Role{" "}
              </label>
              <input
                type="text"
                className="form-control profile-input-control"
                id="inputZip"
                placeholder="Brand Admin"
                defaultValue={
                  userinfo
                    ? userinfo.brand[0].role.name
                    : ""
                }
                disabled={true}
              />
            </div>

            <div className="col-12 mt-25">
              <button
                type="submit"
                className="btn btn-primary profile-update"
              // onClick={(e) => {
              //     updateUserInfo();
              // }}
              >
                Update Information
              </button>
            </div>
          </form>
        }
      </div>

    </>


  )

}

export default AccountDetails;