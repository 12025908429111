import React from "react";
import { Form, Row, Col, Button, Table } from "react-bootstrap";
import NextArrow from "../../../Assets/images/next-arrow.svg";
import PrevArrow from "../../../Assets/images/prev-arrow.svg";
import DeleteIcon from "../../../Assets/images/icons8-delete-30.png";
import Select from "react-select";
import { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";

function Step3({
  handleStep,
  info,
  handleInput,
  retailerOptions,
  handleInputRetailerArray,
  setRetailerArray,
  retailerArray,
  setInfo,
  retailerInitialObject,
  setRetailerInitialObject,
  formError,
  setFormError,
  setCouponWithDate,
  setCouponWithoutDate,
  filteredRetailerOption,
  setfilteredRetailerOption
}) {
  var today = new Date();
  today.setDate(today.getDate() + 1);
  var yyyy = today.getFullYear();
  var mm = "" + (today.getMonth() + 1);
  var dd = "" + today.getDate();

  if (mm.length < 2) mm = "0" + mm;
  if (dd.length < 2) dd = "0" + dd;

  var disablePast = yyyy + "-" + mm + "-" + dd;

  const [scheduleId, setScheduleId] = useState();
  const handleStepNext = () => {
    if (retailerArray?.length < 1) {
      toast.error("Please schedule the coupon");
    } else {
      handleStep("next");
    }
  };

  const handleChange = (name) => {
    setFormError((prev) => {
      return { ...prev, [name]: "" };
    });
  };

  const deleteSchedule = (i) => {
    setRetailerArray((prev) => prev.filter((_, j) => j !== i));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      retailerInitialObject.startDate === "" ||
      retailerInitialObject.endDate === "" ||
      retailerInitialObject.retailer === ""
    ) {
      if (retailerInitialObject.startDate === "") {
        setFormError((prev) => {
          return { ...prev, startDate: " Please Select Start Date" };
        });
      }
      if (retailerInitialObject.endDate === "") {
        setFormError((prev) => {
          return { ...prev, endDate: " Please Select End Date" };
        });
      }
      if (retailerInitialObject.retailer === "") {
        setFormError((prev) => {
          return { ...prev, retailer: " Please Select Retailer." };
        });
      }
    } else {
      e.preventDefault();
      setRetailerArray([...retailerArray, retailerInitialObject]);

      setRetailerInitialObject({
        startDate: "",
        endDate: "",
        retailer: ""
      });
    }
  };
  useEffect(() => {
    let filteredValue = retailerOptions.filter(
      (name) => !retailerArray.some((obj) => obj.retailer.includes(name))
    );
  }, []);

  useEffect(() => {
    let filteredValue = retailerOptions.filter(
      (name) => !retailerArray.some((obj) => obj.retailer.includes(name))
    );
  }, [retailerArray]);

  const [newOptions, setNewOptions] = useState([]);
  useEffect(() => {
    var removeEle = retailerArray?.map((each) => {
      return each.retailer;
    });
    const singleArr = removeEle.flat();
    var deletedOptins = retailerOptions.filter(function (item) {
      return (
        singleArr.filter(function (item2) {
          return item.value === item2.value;
        }).length == 0
      );
    });

    setNewOptions(deletedOptins);

    // var newOptions = retailerOptions.filter((ele) => !singleArr.includes(ele));
    // console.log(newOptions, "newOptions");
  }, [retailerArray]);
  //console.log(retailerArray);
  return (
    <>
      <div className="Wizard_Content_Card">
        <Form>
          <Row className="row g-3">
            <Col xs={12}>
              <Form.Group style={{ display: "flex", flexDirection: "row" }}>
                <Col lg={12} className="schedule-coupon-card">
                  <Form.Label className="mt-8">Schedule Coupon</Form.Label>
                </Col>
              </Form.Group>
            </Col>
            <Col lg={4} xs={12}>
              <Form.Group>
                <Form.Label className="form-heading">Start Date</Form.Label>
                <Form.Control
                  type="date"
                  style={{ height: "43px", color: "grey" }}
                  value={retailerInitialObject.startDate}
                  min={disablePast}
                  name="startDate"
                  onChange={(e) => {
                    handleInputRetailerArray(e.target.value, "startDate");
                    handleChange("startDate");
                  }}
                />
              </Form.Group>
              {formError.startDate && (
                <p className="error-message mt-9">{formError.startDate}</p>
              )}
            </Col>
            <Col lg={4} xs={12}>
              <Form.Group>
                <Form.Label className="form-heading">End Date</Form.Label>
                <Form.Control
                  type="date"
                  style={{ height: "43px", color: "grey" }}
                  value={retailerInitialObject.endDate}
                  min={
                    retailerInitialObject.startDate
                      ? retailerInitialObject.startDate
                      : disablePast
                  }
                  name="endDate"
                  onChange={(e) => {
                    handleInputRetailerArray(e.target.value, "endDate");
                    handleChange("endDate");
                  }}
                />
              </Form.Group>
              {formError.endDate && (
                <p className="error-message mt-9">{formError.endDate}</p>
              )}
            </Col>
            <Col lg={4} xs={12}>
              <Form.Group>
                <Form.Label className="form-heading">Retailers</Form.Label>
                <Select
                  isMulti
                  options={newOptions}
                  className="select text-dark"
                  placeholder={"Please select Retailer"}
                  onChange={(e) => {
                    handleInputRetailerArray(e, "retailer");
                    handleChange("retailer");
                  }}
                  value={retailerInitialObject.retailer}
                  name="retailer"
                />
              </Form.Group>
              {formError.retailer && (
                <p className="error-message">{formError.retailer}</p>
              )}
            </Col>
            <Col
              lg={12}
              className="d-flex flex-column justify-content-center align-items-center  text-end"
            >
              <Button
                className="BlueButton ml-10 mt-40"
                style={{ backgroundColor: "#3D8ECC" }}
                onClick={handleSubmit}
                type="submit"
              >
                Submit
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
      <div className="Wizard_Content_Card mt-40">
        {/* <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between"
          }}
        >
          <h5 className="mb-35 form-heading">Scheduled Coupon 1</h5>
          <button
            style={{
              backgroundColor: "transparent",
              border: "none",
              marginTop: "-25px"
            }}
          >
            <img src={DeleteIcon} />
          </button>
        </div> */}

        <Form>
          <Row className="retailers-data-table">
            {retailerArray?.length > 0 && (
              <Table>
                <thead style={{ position: "sticky", top: "0" }}>
                  <th
                    className="form-heading schedule-coupon-card"
                    style={{ textAlign: "center" }}
                  >
                    Start Date
                  </th>
                  <th
                    className="form-heading schedule-coupon-card"
                    style={{ textAlign: "center" }}
                  >
                    End Date
                  </th>
                  <th
                    className="form-heading schedule-coupon-card"
                    style={{ textAlign: "center" }}
                  >
                    Retailer
                  </th>
                  <th
                    className="form-heading schedule-coupon-card"
                    style={{ textAlign: "center" }}
                  >
                    Delete
                  </th>
                </thead>
                {retailerArray?.map((each, i) => (
                  <tbody>
                    <tr style={{ borderBottom: "1px solid" }} key={i}>
                      <td style={{ textAlign: "center" }}>{each.startDate}</td>
                      <td style={{ textAlign: "center" }}>{each.endDate}</td>
                      {each.retailer.map((eachretailer, j) => (
                        <td
                          className="d-flex flex-column align-items-center"
                          style={{ lineHeight: "0.3" }}
                        >
                          {eachretailer.label}
                        </td>
                      ))}
                      <td style={{ textAlign: "center" }}>
                        <button
                          style={{
                            backgroundColor: "transparent",
                            border: "none",
                            marginTop: "-25px"
                          }}
                          onClick={(e) => {
                            e.preventDefault();
                            setScheduleId(i);
                            deleteSchedule(i);
                          }}
                        >
                          <img src={DeleteIcon} />
                        </button>
                      </td>
                    </tr>
                  </tbody>
                ))}
              </Table>
              //console.log(retailerArray)
            )}
          </Row>
        </Form>
        <div className="flex align-items-center justify-content-end text-end">
          <Button
            className="GrayButton mt-40"
            style={{
              backgroundColor: "#3D8ECC",
              color: "white",
              border: "none"
            }}
            type="submit"
            onClick={() => {
              handleStep("previous");
              setCouponWithDate([]);
              setCouponWithoutDate([]);
            }}
          >
            <img src={PrevArrow} alt="Prev Arrow" className="img-fluid mr-5" />
            Previous
          </Button>
          <Button
            className="BlueButton ml-10 mt-40"
            style={{ backgroundColor: "#3D8ECC" }}
            onClick={() => {
              handleStepNext();
            }}
          >
            Next
            <img src={NextArrow} alt="Next Arrow" className="img-fluid ml-5" />
          </Button>
        </div>
      </div>
    </>
  );
}

export default Step3;
