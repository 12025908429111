import React from "react";
import Header from "../Dashboard/Header";
 import DataTable from "react-data-table-component";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FooterStrict from "../Dashboard/FooterStrict";
 import ls from "local-storage";




class CompanyUpc extends React.Component {

    constructor(props) {
        super(props);
        toast.configure();

        this.state = {
            UpcList: "",
            columns: [
                {
                    name: "UPC11",
                    selector: (row) => row.upc11,
                    sortable: true
                },
                {
                    name: "PRODUCT NAME",
                    selector: (row) => row.product_name,

                },
                {
                    name: "ITEM CODE",
                    selector: (row) => row.item_code === 'None' ? '' : row.item_code,
                    sortable: true
                },

                {
                    name: "CATEGORY",
                    selector: (row) => row.category_name,
                    sortable: true
                },
                //  {
                //     name: "EDIT",
                //     selector: (row) => row.category_name,
                //     cell: row => (
                //         <>
                //             <Link
                //                 to={{
                //                     pathname: "/UpcDetails",
                //                     state: {
                //                         params: {
                //                             id1: row.upc11,
                //                             id2: row.upc_guid,
                //                             id3: row.product_name,
                //                             id4: row.category_name,
                //                             id5: row.item_code
                //                         }
                //                     }
                //                 }}
                //             >
                //                 <img src={edit_pencil} height={'23px'} width={'22px'} style={{ marginRight: '12px' }} alt={row.upc11} />
                //             </Link>


                //         </>
                //     )
                // },

            ]
        }

    }
    async componentDidMount() {
     
        const companyData = ls.get('companyData')?ls.get('companyData'):[];
        this.setState({
            companyData: companyData,
            tableData: companyData.CompanyUPCs
         })

        // await getCompany().then((response) => {
          
        //     this.setState({
        //         companyData: response.Message,
        //         tableData: response.Message.CompanyUPCs
        //      })
       // })
        

        // const selectedbrand = ls.get("selectedbrand") ? ls.get("selectedbrand") : "";

        // var data = "";

        // var config = {
        //     method: "get",
        //     url: CONFIG.API_URL + `brand/${selectedbrand[0].guid}`,
        //     headers: CONFIG.config_head.headers,
        //     data: data
        // };

        // axios(config)
        //     .then((response) => {
        //         this.setState({ tableData: response.data.Message.upc, UpcList: response.data.Message });
        //     })
        //     .catch(function (error) {
        //         console.log(error);
        //         if (error.response) {

        //             if (error.response.status === 400) {
        //                 toast.error(error.response.data.Message);
        //             } else if (error.response.status === 403) {
        //                 toast.error(error.response.data.Message);
        //                 ls.clear("token");
        //                 ls.clear("brands");
        //                 window.location.href = "/login";
        //             } else {
        //                 toast.error(error.response.data.Message);
        //             }
        //         }
        //     });

    }

    render() {
        return (
            <>
                <Header location={`upclist`} changebrandUpclist={this.handleChangeBrand} />

                <section className="ContentBlock pt-24 pb-24 Main_Block">
                    <div className="container-fluid">
                        <div className="row justify-content-center">
                            <div className=" col-12">
                                <div className="Card  Coupons mt-21">
                                    <div className="Card_Header  d-flex align-items-center justify-content-between">
                                        <h5>UPC List</h5>

                                        {/* <Link to="/categories"> <h5 style={{ float: "right" }}>View Categories </h5></Link> */}

                                    </div>
                                    <div className="Card_Body">
                                        <div className="table-responsive Coupons_Table">
                                            {this.state.companyData ? (

                                                <>
                                                    <DataTable
                                                        columns={this.state.columns}
                                                        data={this.state.tableData}
                                                        pagination
                                                    />
                                                </>


                                            ) : (
                                                ""
                                            )}
                                        </div>
                                        <div className="d-flex align-items-center justify-content-end Pagination_Block"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <FooterStrict />
            </>
        );
    }
}
export default CompanyUpc;
