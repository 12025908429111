import React from 'react'
import Header from '../../Components/Admin/layout/header'
import * as CONFIG from "../../Components/_config";
import axios from "axios";
import { toast } from "react-toastify";
import ls from "local-storage";
import $ from "jquery";
import { Redirect } from "react-router";

import AddBrandWholesaler from '../../Components/Common/AddBrandModal/AddBrandWholesaler';
import AddBrandRetailer from '../../Components/Common/AddBrandModal/AddBrandRetailer';
import { Country, State } from "country-state-city";
import * as XLSX from "xlsx";

const HEADERS_MAP = new Map();
HEADERS_MAP.set(0, "UPC");
HEADERS_MAP.set(1, "PRODUCTNAME");
HEADERS_MAP.set(2, "CATEGORY");
HEADERS_MAP.set(3, "DO NOT REMOVE THIS ROW");

const Loader = () => <div id="cover-spin"></div>;

class AddBrand extends React.Component {
  constructor(props) {
    super(props);
    toast.configure();

    this.state = {
      userinfo: "",
      modalclick: false,
      hideButton: false,
      brand_job_title: '',
      role_guid: '',
      wholesaler_number: [],
      retailer_number: [],
      selectedFile: '',
      upc11Arr: [],
      file: "https://my-brandiqpub.s3.amazonaws.com/UPC.xlsx",
      formControls: {
      }

    }

    this.selectAllCheckboxes = this.selectAllCheckboxes.bind(this);
    this.selectWholesaler = this.selectWholesaler.bind(this);
    this.selectRetailer = this.selectRetailer.bind(this);
    this.selectAllRetailerCheckboxes = this.selectAllRetailerCheckboxes.bind(this);

  }


  changeHandler = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    this.setState({
      formControls: {
        ...this.state.formControls,
        [name]: value
      }
    });
  };

  selectWholesaler(e) {

    const { value } = e.target;
    if (e.target.checked) {
      let wholesaler_number_arr = [
        ...this.state.wholesaler_number,
        parseInt(value)
      ];
      this.setState({ wholesaler_number: wholesaler_number_arr });
    }

    else {
      let removeIndex = this.state.wholesaler_number.findIndex(
        (itm) => itm === parseInt(value)
      );
      if (removeIndex > -1) {
        this.state.wholesaler_number.splice(removeIndex, 1); // 2nd parameter means remove one item only
      }
    }
  }

  selectAllCheckboxes(e) {

    const { checked } = e.target;
    const wholesalerIdarr = [];

    if (checked) {
      this.props.remainingWholesaler.map((val) => {
        return (
          wholesalerIdarr.push(val.wholesaler_id)
        )
      });
    }

    this.setState({
      wholesaler_number: wholesalerIdarr,
      ItemsChecked: checked,
    });

  };

  selectRetailer(e) {

    const { value } = e.target;
    const { wholesaler_id } = e.target.dataset;

    if (e.target.checked) {
      let obj = {
        wholesaler_id: parseInt(wholesaler_id),
        retailer_id: parseInt(value)
      };

      this.state.retailer_number.push(obj);
    } else {

      let removeIndex = this.state.retailer_number.findIndex(
        (itm) => itm.retailer_id === parseInt(value)
      );

      if (removeIndex > -1) {
        this.state.retailer_number.splice(removeIndex, 1); // 2nd parameter means remove one item only
      }
    }
  }

  selectAllRetailerCheckboxes(e) {

    const { checked } = e.target;
    const retailerObj = [];

    if (checked) {
      this.props.remainingRetailer.map((val) => {
        return (
          retailerObj.push({ wholesaler_id: val.wholesaler_id, retailer_id: val.retailer_id })
        )
      });
    }

    this.setState({
      retailer_number: retailerObj,
      ItemsChecked: checked,
    });
  };

  validateMissingHeaders = (sheet) => {
    var missingHeaders = [];
    const errors = [];
    HEADERS_MAP.forEach((value, index) => {
      var cell = sheet[XLSX.utils.encode_cell({ c: index, r: 0 })]

      var header = "";
      if (typeof cell === 'undefined') {
        header = XLSX.utils.format_cell(cell);
        if (value !== header) {
          missingHeaders.push(value);
        }
      }
    });
    if (missingHeaders.length !== 0) {

      errors.push({
        row: "Missing Headers",
        column: missingHeaders,
      });


    }
    return errors;
  }

  readExcel = (file) => {
    let upc11arr = [];

    const promise = new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);

      fileReader.onload = (e) => {
        const bufferArray = e.target.result;

        const wb = XLSX.read(bufferArray, { type: "buffer" });

        const wsname = wb.SheetNames[0];

        const ws = wb.Sheets[wsname];

        const errors = this.validateMissingHeaders(ws)

        if (errors.length > 0) {
          errors.forEach((error) => {

            toast.error(` ${`Header column ` + error.column[0] + ` is missing`}`)

          })
          return false;

        }
        else {

          const data = XLSX.utils.sheet_to_json(ws);
          resolve(data);
        }
        // const data = XLSX.utils.sheet_to_json(ws);
        // resolve(data);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });

    promise.then((d) => {

      d.map((item, index) => {
        return (
          (!!(item.UPC)) ?
            ''
            :
            d.splice(index, 1)
        )
      })

      if (Object.hasOwn(d[0], "UPC")) {

        this.setState({ upc11Arr: [] });

        if (d.every((v) => typeof v.UPC === "number") === false) {
          this.setState({ excelError: true });
          return false;
        }

        else if (d.every((v) => v.UPC.toString().length < 10)) {
          this.setState({ excellengthError: true });
          return false;
        }
        else {
          d.map((item) => {

            return upc11arr.push({ 'UPC': item.UPC, 'product_name': item.PRODUCTNAME, 'category': item.CATEGORY });
          });

        }

      }

      else {
        this.setState({ headerError: true });
      }
      this.setState({ upc11Arr: upc11arr });
    });
  };

  showLoader = () => {
    this.setState({ loading: true });
  }
  hideLoader = () => {
    this.setState({ loading: false });
  };

  onFileChange = (event) => {
    let files = event.target.files;
    let images = event.target.files[0];

    if (!images.name.match(/\.(jpg|jpeg|png|JPG|JPEG|PNG)$/)) {
      toast.error("Upload Valid Image. Only PNG,JPEG and JPG are allowed.");
      return false;
    }

    for (var i = 0; i < files.length; i++) {
      let file = files[i];
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.setState({ selectedFile: reader.result });
      };
    }
  };


  saveBrand() {
    var z = new RegExp(/^\d*\.?\d*$/);

    if ($("#brand_name").val().length <= 0) {
      toast.error("Brand Name can't be blank", { theme: "colored" });
      return false;
    }
    else if ($("#address").val().length <= 0) {
      toast.error("Address can't be blank", { theme: "colored" });
      return false;
    }
    else if ($("#city").val().length <= 0) {
      toast.error("City can't be blank", { theme: "colored" });
      return false;
    }
    else if ($("#country_state option:selected").val().length <= 0) {
      toast.error("State can't be blank", { theme: "colored" });
      return false;
    }
    else if ($("#postal_code").val().length <= 0) {
      toast.error("Postal Code can't be blank", { theme: "colored" });
      return false;
    }
    else if (($("#postal_code").val().trim().length > 5) || (!z.test($("#postal_code").val()))) {
      toast.error("Postal Code should be numeric and not have value greater than 5,", { theme: "colored" });
      return false;
    }
    else if ($("#country").val().trim().length <= 0) {
      toast.error("Country can't be blank", { theme: "colored" });
      return false;
    }

    else if ($("#website").val().trim().length <= 0) {
      toast.error("Website No can't be blank", { theme: "colored" });
      return false;
    }

    else if ($("#logo").val().trim().length <= 0) {
      toast.error("Please Upload Logo", { theme: "colored" });
      return false;
    }
    else if (this.state.wholesaler_number <= 0) {
      toast.error("Please select at least one Wholesaler", { theme: "colored" });
      return false;
    }
    // else if (this.state.retailer_number <= 0) {
    //   toast.error("Please select at least one Retailer", {theme: "colored"});
    //   return false;
    // }

    else {
      const upcdata = [];
      if (this.state.upc11Arr.length > 0) {
        // eslint-disable-next-line array-callback-return
        this.state.upc11Arr.map((val, idx) => {

          if (val.UPC.toString().length < 11) {
            if ((typeof val.product_name == 'undefined') && (typeof val.category == 'undefined')) {
              upcdata.push({
                upc11: "0".repeat(11 - val.UPC.toString().length) + val.UPC.toString(),
                product_name: "0".repeat(11 - val.UPC.toString().length) + val.UPC.toString(),
                category: "",
              });
            }
            else if (typeof val.product_name == 'undefined') {
              upcdata.push({
                upc11: "0".repeat(11 - val.UPC.toString().length) + val.UPC.toString(),
                product_name: "0".repeat(11 - val.UPC.toString().length) + val.UPC.toString(),
                category: val.category.toString(),
              });
            }
            else if (typeof val.category == 'undefined') {
              upcdata.push({
                upc11: "0".repeat(11 - val.UPC.toString().length) + val.UPC.toString(),
                product_name: val.product_name.toString(),
                category: "",
              });
            }
            else {
              upcdata.push({
                upc11: "0".repeat(11 - val.UPC.toString().length) + val.UPC.toString(),
                product_name: val.product_name.toString(),
                category: val.category.toString(),
              });
            }
          }
          else {
            if ((typeof val.product_name == 'undefined') && (typeof val.category == 'undefined')) {
              upcdata.push({
                upc11: val.UPC.toString(),
                product_name: "0" + val.UPC.toString(),
                category: ""
              });
            }
            else if (typeof val.product_name == 'undefined') {
              upcdata.push({
                upc11: val.UPC.toString(),
                product_name: "0" + val.UPC.toString(),
                category: val.category.toString()
              });
            }
            else if (typeof val.category == 'undefined') {
              upcdata.push({
                upc11: val.UPC.toString(),
                product_name: val.product_name.toString(),
                category: ""
              });
            }
            else {
              upcdata.push({
                upc11: val.UPC.toString(),
                product_name: val.product_name.toString(),
                category: val.category.toString(),
              });
            }
          }
        });

        this.showLoader();
        var data =
          JSON.stringify({
            company_id: this.props.company_id,
            name: this.state.formControls.brand_name.replace(/'/g, ""),
            address: this.state.formControls.address.replace(/'/g, "") ? this.state.formControls.address.replace(/'/g, "") : '',
            address2: this.state.formControls.address2.replace(/'/g, "") ? this.state.formControls.address2.replace(/'/g, "") : '',
            postal_code: this.state.formControls.postal_code,
            city: this.state.formControls.city.replace(/'/g, ""),
            country_state: this.state.formControls.country_state,
            country: "USA",
            website: this.state.formControls.website,
            logo: this.state.selectedFile ? this.state.selectedFile : "String", //this.state.selectedFile
            state: 1,
            wholesalers: this.state.wholesaler_number,
            retailers: this.state.retailer_number,
            upc: upcdata
          });

        var config = {
          method: "post",
          url: CONFIG.API_URL + "brand",
          headers: CONFIG.config_head.headers,
          data: data
        };
        axios(config)
          .then((response) => {
            if (response.status === 201) {
              this.hideLoader();
              toast.success('Brand is added successfully')
              setTimeout(() => { window.location.reload() }, 2500)
            }
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.status === 502) {
                this.hideLoader();
                toast.error(error.response.data.Message);
                setTimeout(() => { window.location.reload() }, 2500)
              }
              else if (error.response.status === 400) {

                this.hideLoader();
                toast.error(error.response.data.Message)
                setTimeout(() => { window.location.reload() }, 2500)
              }
            }
          })
      }
      else {
        toast.error("UPC file is not uploaded. Please upload a valid UPC file.");
      }
    }

  }


  componentDidUpdate() {

    if (this.state.excelError === true) {

      toast.error("UPCs must be integer");

      $("#formFileSm").val("");

      this.setState({ excelError: false });
    }
    if (this.state.excellengthError === true) {
      toast.error("UPCs must be with a minimum length of 10.");
      $("#formFileSm").val("");

      this.setState({ excellengthError: false });
    }
    if (this.state.headerError === true) {

      toast.error("The excel file can't open due to header error. In header UPC is not set ");

      $("#formFileSm").val("");

      this.setState({ headerError: false });
    }
  }

  componentDidMount() {
    const co = Country.getCountryByCode("US");
    const states = State.getStatesOfCountry(co.isoCode);
    this.setState({ States: states });
  }
  render() {

    const isLogged = ls.get("token");

    const is_consent_provided = ls.get("decodetoken") ? ls.get("decodetoken").is_consent_provided : "";

    if (!isLogged || is_consent_provided === false) {
        
        return <Redirect to="/login" />;
    }

   // console.log('wholesaler_number',this.state.wholesaler_number)

   // const filterWholesaler = this.state.remainingWholesaler.filter(wholesaler => wholesaler)

    return (
      <>
        <Header location={`addUser`} />

        {this.state.loading ? <Loader /> : null}


        <div className='ContentBlock  pt-40 pb-24 Main_Block'>
          <div className='container'>

            <div className='Add_User_Form_Block row g-3 '>
              <h5 style={{ float: 'right' }}>

                {/* {(!!selectedbrand && selectedbrand.length > 0) ? (

                  <a href={`/profile/${this.props.location.state.params.id5}`} style={{ float: 'right' }}> BACK TO PROFILE </a>
                ) :

                  <a href={`/companyprofile/${this.props.location.state.params.id5}`} style={{ float: 'right' }}> BACK TO PROFILE </a>
                }
              */}

              </h5>
              <div className="col-lg-6 col-12">
                <label
                  htmlFor="inputEmail4"
                  className="form-label"
                >
                  Brand Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Brand Name"
                  name="brand_name"
                  id="brand_name"
                  onChange={this.changeHandler}
                />

              </div>
              <div className="col-lg-6 col-12">
                <label
                  htmlFor="inputEmail4"
                  className="form-label "
                >
                  Address
                </label>
                <input
                  type="text"
                  className="form-control profile-input-control"
                  placeholder="Address"
                  name="address"
                  id="address"
                  onChange={this.changeHandler}

                />
              </div>
              <div className="col-lg-6 col-12">
                <label
                  htmlFor="inputEmail4"
                  className="form-label "
                >
                  Address2
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Address2"
                  name="address2"
                  id="address2"
                  onChange={this.changeHandler}
                />

              </div>
              <div className="col-lg-6 col-12">
                <label
                  htmlFor="inputEmail4"
                  className="form-label "
                >
                  Postal Code
                </label>
                <input
                  type="text"
                  className="form-control "
                  placeholder="Postal Code"
                  name="postal_code"
                  id="postal_code"
                  onChange={this.changeHandler}

                />

              </div>

              <div className="col-lg-6 col-12">
                <label
                  htmlFor="inputEmail4"
                  className="form-label "
                >
                  City
                </label>
                <input
                  type="text"
                  className="form-control "
                  placeholder="City"
                  name="city"
                  id="city"
                  onChange={this.changeHandler}

                />

              </div>

              <div className="col-lg-6 col-12">
                <label
                  htmlFor="inputEmail4"
                  className="form-label "
                >
                  State
                </label>
                {this.state.States ? (
                  <select
                    name="country_state"
                    id="country_state"
                    onChange={this.changeHandler}
                    className="form-control"
                  >
                    <option value="">Select please</option>

                    {this.state.States.map((val, idx) => {
                      return (
                        <option key={idx} value={val.name}>{val.name}</option>
                      )
                    })}
                  </select>
                ) : (
                  ""
                )}

              </div>
              <div className="col-lg-6 col-12">
                <label
                  htmlFor="inputEmail4"
                  className="form-label "
                >
                  Country
                </label>
                <input
                  type="text"
                  className="form-control "
                  placeholder="Country"
                  name="country"
                  id="country"
                  onChange={this.changeHandler}
                />
              </div>

              <div className="col-lg-6 col-12">
                <label
                  htmlFor="inputEmail4"
                  className="form-label "
                >
                  Website
                </label>
                <input
                  type="text"
                  className="form-control "
                  placeholder="Website"
                  name="website"
                  id="website"
                  onChange={this.changeHandler}
                />
              </div>

              <div className="col-lg-6 col-12">
                <label
                  htmlFor="inputEmail4"
                  className="form-label "
                >
                  Logo
                </label>

                <input
                  type="file"
                  id="logo"
                  onChange={this.onFileChange}
                  onClick={(event) => {
                    event.target.value = null;
                  }}
                />

              </div>

              <div className="col-lg-6 col-12">
                <label
                  htmlFor="inputEmail4"
                  className="form-label "
                >
                  Upload UPC File*
                </label>

                <input
                  id="formFileSm"
                  type="file"
                  onChange={(e) => {
                    const file = e.target.files[0];
                    this.readExcel(file);
                  }}
                  name="upc"
                  onClick={(event) => {
                    event.target.value = null;
                  }}
                  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                ></input>

                <br />
                <br />

                <a
                  href={this.state.file}
                  target="_blank"
                  rel="noopener noreferrer"
                  download
                  style={{
                    color: "#0d6ef",
                    textDecoration: "underline",
                    marginTop: "4px"
                  }}
                >
                  Click this link to download sample UPC file.
                </a>
              </div>


              {/* <div className="col-lg-6 col-12">
                <br />

                <button
                  className="Digital_Brand_Button"
                  data-bs-toggle="modal"
                  id="retailermodal"
                  data-bs-target="#selectretailer-sel-drop"
                  style={{
                    borderColor: "#3d8ecc",
                    backgroundColor: "#3d8ecc",
                    color: "#fff",
                    fontWeight: "600",
                    padding: "12px",
                    width: '204px'
                  }}
                >
                  Select Retailers
                </button>

              </div> */}


              <div className="col-lg-6 col-12">
                <br />

                <button
                  className="Digital_Brand_Button approve"
                  data-bs-toggle="modal"
                  id="wholesalermodal"
                  data-bs-target="#selectwholesaler-sel-drop"
                  style={{
                    borderColor: "#3d8ecc ",
                    backgroundColor: "#3d8ecc",
                    color: "#fff",
                    fontWeight: "600",
                    padding: "12px",
                    width: '204px'
                  }}
                >
                  Select Wholesalers
                </button>
              </div>

              <div className='col-12'>
                <button type="submit" className="btn Btn_blue w-fit-content px-5 py-2 rounded-2"
                  data-roleguid={``} data-brandjobtitle={``} onClick={(e) => {
                    this.saveBrand(e);
                  }}>Save</button>
              </div>
            </div>
          </div>


          <AddBrandRetailer
            retailerData={this.props.remainingRetailer} brandguidata={this.props.brandguid}
            selectAllRetailerCheckboxes={this.selectAllRetailerCheckboxes} selectRetailer={this.selectRetailer}
            checkedListAll={this.state.retailer_number}
          />

          <AddBrandWholesaler
            selectWholesaler={this.selectWholesaler}
            selectAllCheckboxes={this.selectAllCheckboxes}
            checkedListAll={this.state.wholesaler_number}
            wholesalerData={
              this.props.remainingWholesaler
            }
          />

        </div>
      </>
    )
  }
}
export default AddBrand;
