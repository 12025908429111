import React from "react";
import Header from "../Dashboard/Header";
import { Button } from "react-bootstrap";
import CalenderSvgImage from "../../Assets/images/calender.svg";
import CalenderSvgRedImage from "../../Assets/images/calenderRed.svg";
import Error from "../../Assets/images/error.svg";

//import ArrowGray from "../../Assets/images/error-gray.svg";

import $ from "jquery";
import axios from "axios";
import { toast } from "react-toastify";
import ls from "local-storage";

import "react-toastify/dist/ReactToastify.css";
import { Redirect } from "react-router";

import * as CONFIG from "../../Components/_config";

import ClipsChart from "../../Components/Chart/ClipsChart";
import ClipLoader from "react-spinners/ClipLoader";
import FooterStrict from "../Dashboard/FooterStrict";

class CouponsDetails extends React.Component {
  constructor(props) {
    super(props);
    toast.configure();

    this.state = {
      couponsDetails: "",
      coupon_start_date: "",
      coupon_end_date: "",
      upcArr: "",
      calling: false,
      WholeSalerList: "",
      api_start_date: "",
      api_end_date: "",
      wholesalerlist: "",
      wholesalernumber: "",
      shutoff_date: "",
      WholeSalerListPMB: "",
      UpcList: "",
      upcarray: [],
      TotalUnitWholesaler: "",
      couponAnalysis: "",
      couponAnalysisgraph: "",
      retailerarr: "",
      target_type_id: "",
      retailer_array: "",
      mergerretailer: "",
      response_retailer_arr: [],
      TotalSales: [],
      loader: true,
      file: "https://support.staffbase.com/hc/en-us/article_attachments/360009197031/username.csv",
      upcfile: "",
      TotalSalesQtydetails: [],
      title: 'All Brands'

    };
  }

  componentDidMount() {
    var coupon_guid = this.props.match.params.coupon_guid;

    var guid = ls.get("brands") ? ls.get("brands")[0].guid : "";

    var couponretailer = [];
    var wholesalernumber = [];

    const token_config = {
      headers: CONFIG.config_head.headers
    };

    const requestOne = axios.get(
      CONFIG.API_URL + `coupon/${coupon_guid}`,
      token_config
    );
    const requestTwo = axios.get(
      CONFIG.API_URL + `brand/${guid}`,
      token_config
    );
    const requestThree = axios.get(
      CONFIG.API_URL + `coupon/analysis/${coupon_guid}`,
      token_config
    );

    axios
      .all([requestOne, requestTwo, requestThree])
      .then(
        axios.spread((...responses) => {
          // let responseOne = responses[0];
          // let responseTwo = responses[1];
          let responseThree = responses[2];


          Object.keys(responseThree.data.Message.Coupon.upc).map((val, idx) =>
            this.state.upcarray.push(responseThree.data.Message.Coupon.upc[val].upc11)
          );


          let result = [];

          for (const obj of responseThree.data.Message.Coupon.wholesalers) {

            this.state.response_retailer_arr.push(obj.retailer);
            result = [].concat(...this.state.response_retailer_arr);

            wholesalernumber.push(parseInt(obj.wholesaler_number));
          }
          for (const obj of responseThree.data.Message.Coupon.wholesalers) {
            for (const obj1 of obj.retailer) {
              couponretailer.push(parseInt(obj1.rsa_identifier));
            }
          }

          this.setState(
            {
              couponAnalysis: responseThree.data.Message.Coupon,
              couponAnalysisgraph: responseThree.data.Message.Graph,
              target_type_id:
                responseThree.data.Message.Coupon.coupon_target_type_id,
              retailer_array: this.state.response_retailer_arr,
              wholesalernumber: wholesalernumber,
              mergerretailer: result,
              loader: false,
              calling: true,
              UpcList: this.state.upcarray,
              retailerarr: couponretailer,
              upcArr: this.state.upcarray
            },
            () => {
              this.getProductmovementTotalUnit();
              // this.TotalSalesQty();
              this.TotalSalesQtyWholesaler()
            }
          );

          for (const obj of this.state.couponAnalysis.wholesalers) {
            for (const obj1 of obj.retailer) {
              couponretailer.push(parseInt(obj1.rsa_identifier));
            }
          }
        })
      )
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 400) {
            toast.error(error.response.data.Message);

            // ls.clear("token");
            // ls.clear("brands");

            // window.location.href = "/login";
          } else if (error.response.status === 403) {
            toast.error(error.response.data.Message);
            ls.clear("token");
            ls.clear("brands");
            window.location.href = "/login";
          } else {
            toast.error(error.response.data.Message);
          }
        }
      });
  }

  handleDate(date) {
    var month = date.getMonth() + 1;
    var day = date.getDate();
    var year = date.getFullYear();

    if (month < 10) month = "0" + month.toString();
    if (day < 10) day = "0" + day.toString();

    let calculate_date = year + "-" + month + "-" + day;
    return calculate_date;
  }

  handleDateSecond(date) {
    var month = date.getMonth() + 1;
    var day = date.getDate();
    var year = date.getFullYear();

    if (month < 10) month = "0" + month.toString();
    if (day < 10) day = "0" + day.toString();

    let calculate_date = month + "-" + day + "-" + year;
    return calculate_date;
  }

  couponSearch() {
    //var guid = ls.get("brands") ? ls.get("brands")[0].guid : "";
    //var token = ls.get("token") ? ls.get("token") : "";

    const guid = ls.get('selectedbrand') ? ls.get('selectedbrand')[0].guid : '';


    var data = JSON.stringify({
      brandId: guid,
      active: 1,
      page: 1,
      count: 10,
      sort: "-name",
      "include-clip": true,
      "include-redemption": true,
      search: "",
      "coupon-start-date": this.state.couponsDetails.coupon_start_date,
      "coupon-end-date": this.state.couponsDetails.coupon_end_date
    });

    var config = {
      method: "post",
      url: CONFIG.API_URL + "coupon/search",
      headers: CONFIG.config_head.headers,
      data: data
    };

    axios(config)
      .then((response) => {
        this.setState({ wholesalerlist: response.data.Message.entries });

        //  this.getProductmovement();
      })
      .catch(function (error) { });
  }



  getProductmovementTotalUnit() {
    if (this.state.calling === true) {
      var now = new Date();
      var today = new Date(
        Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate())
      );

      var c_end_date = new Date(
        this.handleDate(new Date(this.state.couponAnalysis.coupon_end_date))
      );
      var c_start_date = new Date(
        this.handleDate(new Date(this.state.couponAnalysis.coupon_start_date))
      );

      if (c_end_date.getTime() < today.getTime()) {
        var api_end_date1 = this.handleDate(
          new Date(this.state.couponAnalysis.coupon_end_date)
        );
      } else {
        // eslint-disable-next-line no-redeclare
        var api_end_date1 = new Date().toISOString().split("T")[0];
      }

      if (c_start_date.getTime() > today.getTime()) {
        var api_start_date = new Date().toISOString().split("T")[0];
      } else {
        // eslint-disable-next-line no-redeclare
        var api_start_date = this.handleDate(
          new Date(this.state.couponAnalysis.coupon_start_date)
        );
      }
      if (this.state.target_type_id === 1) {
        var data = JSON.stringify({
          StartDate: api_start_date ? api_start_date : " ",
          EndDate: api_end_date1 ? api_end_date1 : " ",
          OrderBy: "calMonthYear",
          BrandID: 0,
          UPC: this.state.upcArr,
          type: "QtySalesByUPC",
          Wholesaler: this.state.wholesalernumber,
          Retailer: []
        });
      } else {
        // eslint-disable-next-line no-redeclare
        var data = JSON.stringify({
          StartDate: api_start_date ? api_start_date : " ",
          EndDate: api_end_date1 ? api_end_date1 : " ",
          OrderBy: "calMonthYear",
          BrandID: 0,
          UPC: this.state.upcArr,
          type: "QtySalesByUPC",
          Wholesaler: [],
          Retailer: this.state.retailerarr
        });
      }

      var config = {
        method: "post",
        url: CONFIG.API_URL + "query",
        headers: CONFIG.config_head.headers,
        data: data
      };

      axios(config)
        .then((res) => {
          if (res.status === 200) {
            this.setState({
              calling: false,
              TotalSales: res.data.Message,
              loader: false
            });
          } else {
            this.setState({ calling: false, TotalSales: [] });
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 400) {

              toast.error(error.response.Message)
              this.setState({ calling: false, TotalSales: 0 });
            }
            else if (error.response.status === 403) {
              this.setState({ calling: false, TotalSales: 0 });

              toast.error(error.response.data.Message);
              ls.clear("token");
              ls.clear("brands");
              window.location.href = "/login";
            } else {
              this.setState({ calling: false, TotalSales: 0 });
              toast.error(error.response.data.Message);
            }
          }



        });
    }
  }

  couponClipreport(e) {
    var coupon_guid = this.props.match.params.coupon_guid;

    // var token = ls.get("token") ? ls.get("token") : "";

    var type =
      e.target.getAttribute("data-target_type") === "1"
        ? "wholesaler"
        : "retailer";
    var id = e.target.getAttribute("data-id");

    var config = {
      method: "get",
      url: CONFIG.API_URL + `coupon/clip/${coupon_guid}?type=${type}&id=${id}`,
      headers: CONFIG.config_head.headers
    };

    axios(config)
      .then((response) => {
        if (response.status === 200) {
          this.setState({ file: response.data.Message });
          $("#mybtn").click();
        }
      })
      .catch(function (error) {
        if (error.response.status === 502) {
          toast.error("Bad Request!");
        } else if (error.response.status === 400) {
          // toast.error('Bad Request!');
          toast.error(error.response.data.Message);
        } else {
          console.log(error);
        }
      });
  }

  couponRedemptionreport(e) {
    var coupon_guid = this.props.match.params.coupon_guid;
    //var token = ls.get("token") ? ls.get("token") : "";

    var type =
      e.target.getAttribute("data-target_type") === "1"
        ? "wholesaler"
        : "retailer";
    var id = e.target.getAttribute("data-id");

    var config = {
      method: "get",
      url:
        CONFIG.API_URL +
        `coupon/redemption/${coupon_guid}?type=${type}&id=${id}`,
      headers: CONFIG.config_head.headers
    };

    axios(config)
      .then((response) => {
        if (response.status === 200) {
          this.setState({ file: response.data.Message });

          $("#mybtn").click();
        }
      })
      .catch(function (error) {
        if (error.response.status === 502) {
          toast.error("Bad Request!");
        } else if (error.response.status === 400) {
          // toast.error('Bad Request!');
          toast.error(error.response.data.Message);
        } else {
          console.log(error);
        }
      });
  }


  // TotalSalesQty() {

  //   var now = new Date();
  //   var today = new Date(
  //     Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate())
  //   );

  //   var c_end_date = new Date(
  //     this.handleDate(new Date(this.state.couponAnalysis.coupon_end_date))
  //   );
  //   var c_start_date = new Date(
  //     this.handleDate(new Date(this.state.couponAnalysis.coupon_start_date))
  //   );

  //   if (c_end_date.getTime() < today.getTime()) {
  //     var api_end_date1 = this.handleDate(
  //       new Date(this.state.couponAnalysis.coupon_end_date)
  //     );
  //   } else {
  //     api_end_date1 = new Date().toISOString().split("T")[0];
  //   }
  //   if (c_start_date.getTime() > today.getTime()) {
  //     var api_start_date = new Date().toISOString().split("T")[0];
  //   } else {
  //     api_start_date = this.handleDate(
  //       new Date(this.state.couponAnalysis.coupon_start_date)
  //     );
  //   }
  //   if (this.state.target_type_id == 1) {
  //     var data = JSON.stringify({
  //       StartDate: api_start_date ? api_start_date : " ",
  //       EndDate: api_end_date1 ? api_end_date1 : " ",
  //       OrderBy: "calMonthYear",
  //       BrandID: 0,
  //       UPC: this.state.upcArr,
  //       type: "GUS",
  //       Wholesaler: this.state.wholesalernumber,
  //       Retailer: []
  //     });
  //   } else {
  //     data = JSON.stringify({
  //       StartDate: api_start_date ? api_start_date : " ",
  //       EndDate: api_end_date1 ? api_end_date1 : " ",
  //       OrderBy: "calMonthYear",
  //       BrandID: 0,
  //       UPC: this.state.upcArr,
  //       type: "GUS",
  //       Wholesaler: [],
  //       Retailer: this.state.retailerarr
  //     });
  //   }

  //   var config = {
  //     method: "post",
  //     url: CONFIG.API_URL + "query",
  //     headers: CONFIG.config_head.headers,
  //     data: data
  //   };

  //   axios(config)
  //     .then((res) => {
  //       if (res.status === 200) {

  //         var retailer_rsa_identifier = [];

  //         var wholesaler_identifier = [];

  //         if (this.state.target_type_id == 1) {

  //           var groups = Object.create(null);

  //           res.data.Message.DisplayResult.forEach(function (a) {
  //             groups[a.WholesalerID] = groups[a.WholesalerID] || [];
  //             groups[a.WholesalerID].push(a);
  //           });

  //           res.data.Message.DisplayResult.forEach(function (a) {

  //             wholesaler_identifier.push(a.WholesalerID)
  //           })

  //         }
  //         else {

  //           groups = Object.create(null);

  //           res.data.Message.DisplayResult.forEach(function (a) {
  //             groups[a.RetailerID] = groups[a.RetailerID] || [];
  //             groups[a.RetailerID].push(a);
  //           });

  //           res.data.Message.DisplayResult.forEach(function (a) {

  //             retailer_rsa_identifier.push(a.RetailerID)
  //           })
  //         }
  //         this.setState({ TotalSalesQtydetails: groups, retailer_rsa_identifier: retailer_rsa_identifier, wholesaler_identifier: wholesaler_identifier });

  //       }
  //     })
  //     .catch(function (error) {

  //       if (error.response) {
  //         if (error.response.status === 502) {
  //           toast.error("Bad Request!");
  //         } else if (error.response.status === 400) {
  //           toast.error(error.response.data.Message);
  //         } else {
  //           toast.error(error.res.data.Message);
  //         }
  //       }
  //     });

  // }

  async TotalSalesQtyWholesaler() {

    var now = new Date();
    var today = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate()));


    let i = 0;
    let posts = [];
    let rv = [];
    var retailer_rsa_identifier = [];
    var wholesaler_identifier = [];

    if (this.state.target_type_id === 1) {

      while (i < Object.keys(this.state.couponAnalysis.wholesalers).length) {


        var wholesaler_end_date = new Date(
          this.handleDate(new Date(this.state.couponAnalysis.wholesalers[i].wholesaler_end_date))
        );

        var wholesaler_start_date = new Date(
          this.handleDate(new Date(this.state.couponAnalysis.wholesalers[i].wholesaler_start_date))
        );


        if (wholesaler_end_date.getTime() < today.getTime()) {

          var saleqty_api_end_date = this.handleDate(new Date(this.state.couponAnalysis.wholesalers[i].wholesaler_end_date));
        }
        else {

          saleqty_api_end_date = new Date().toISOString().split("T")[0];
        }

        if (wholesaler_start_date.getTime() > today.getTime()) {

          var saleqty_api_start_date = new Date().toISOString().split("T")[0];
        }
        else {

          saleqty_api_start_date = this.handleDate(new Date(this.state.couponAnalysis.wholesalers[i].wholesaler_start_date));

        }

        var data = JSON.stringify({
          StartDate: saleqty_api_start_date ? saleqty_api_start_date : " ",
          EndDate: saleqty_api_end_date ? saleqty_api_end_date : " ",
          OrderBy: "calMonthYear",
          BrandID: 0,
          UPC: this.state.upcArr,
          type: "GUS",
          Wholesaler: [this.state.couponAnalysis.wholesalers[i].wholesaler_number],
          Retailer: []
        });


        const url = CONFIG.API_URL + "query";

        const result = await axios.post(url, data, {
          headers: CONFIG.config_head.headers

        });

        posts = [...posts, result.data.Message];

        console.log(posts)

        rv = [].concat(...posts);

        i++
      }
      var groups = Object.create(null);

      // eslint-disable-next-line array-callback-return
      rv.map((val, idx) => {

        // console.log(val)

        val.DisplayResult.forEach(function (a) {
          groups[a.WholesalerID] = groups[a.WholesalerID] || [];
          groups[a.WholesalerID].push(a);
          wholesaler_identifier.push(a.WholesalerID)

        });

      });
      // console.log(wholesaler_identifier)

      // console.log(groups)
    }

    else {

      //console.log('merge', this.state.mergerretailer)

      while (i < Object.keys(this.state.mergerretailer).length) {


        var retailer_end_date = new Date(
          this.handleDate(new Date(this.state.mergerretailer[i].end_date))
        );

        var retailer_start_date = new Date(
          this.handleDate(new Date(this.state.mergerretailer[i].start_date))
        );


        if (retailer_end_date.getTime() < today.getTime()) {

          saleqty_api_end_date = this.handleDate(new Date(this.state.mergerretailer[i].end_date));
        }
        else {

          saleqty_api_end_date = new Date().toISOString().split("T")[0];
        }

        if (retailer_start_date.getTime() > today.getTime()) {

          saleqty_api_start_date = new Date().toISOString().split("T")[0];
        }
        else {

          saleqty_api_start_date = this.handleDate(new Date(this.state.mergerretailer[i].start_date));

        }

        data = JSON.stringify({
          StartDate: saleqty_api_start_date ? saleqty_api_start_date : " ",
          EndDate: saleqty_api_end_date ? saleqty_api_end_date : " ",
          OrderBy: "calMonthYear",
          BrandID: 0,
          UPC: this.state.upcArr,
          type: "GUS",
          Wholesaler: [],
          Retailer: [parseInt(this.state.mergerretailer[i].rsa_identifier)]
        });


        const url = CONFIG.API_URL + "query";

        const result = await axios.post(url, data, {
          headers: CONFIG.config_head.headers

        });

        posts = [...posts, result.data.Message];

        console.log(posts)

        rv = [].concat(...posts);

        i++
      }

      groups = Object.create(null);

      // eslint-disable-next-line array-callback-return
      rv.map((val, idx) => {
        //console.log(val)

        val.DisplayResult.forEach(function (a) {
          groups[a.RetailerID] = groups[a.RetailerID] || [];
          groups[a.RetailerID].push(a);
          retailer_rsa_identifier.push(a.RetailerID)
        });

      });

    }

    this.setState({ TotalSalesQtydetails: groups, retailer_rsa_identifier: retailer_rsa_identifier, wholesaler_identifier: wholesaler_identifier });



  }

  downloadupcbreakdown() {
    var now = new Date();
    var today = new Date(
      Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate())
    );

    var c_end_date = new Date(
      this.handleDate(new Date(this.state.couponAnalysis.coupon_end_date))
    );
    var c_start_date = new Date(
      this.handleDate(new Date(this.state.couponAnalysis.coupon_start_date))
    );

    if (c_end_date.getTime() < today.getTime()) {
      var api_end_date1 = this.handleDate(
        new Date(this.state.couponAnalysis.coupon_end_date)
      );
    } else {
      api_end_date1 = new Date().toISOString().split("T")[0];
    }
    if (c_start_date.getTime() > today.getTime()) {
      var api_start_date = new Date().toISOString().split("T")[0];
    } else {
      api_start_date = this.handleDate(
        new Date(this.state.couponAnalysis.coupon_start_date)
      );
    }
    if (this.state.target_type_id === 1) {
      var data = JSON.stringify({
        StartDate: api_start_date ? api_start_date : " ",
        EndDate: api_end_date1 ? api_end_date1 : " ",
        OrderBy: "calMonthYear",
        BrandID: 0,
        UPC: this.state.upcArr,
        type: "QtySalesByUPC",
        Wholesaler: this.state.wholesalernumber,
        Retailer: []
      });
    } else {
      data = JSON.stringify({
        StartDate: api_start_date ? api_start_date : " ",
        EndDate: api_end_date1 ? api_end_date1 : " ",
        OrderBy: "calMonthYear",
        BrandID: 0,
        UPC: this.state.upcArr,
        type: "QtySalesByUPC",
        Wholesaler: [],
        Retailer: this.state.retailerarr
      });
    }

    var config = {
      method: "post",
      url: CONFIG.API_URL + "query",
      headers: CONFIG.config_head.headers,
      data: data
    };

    axios(config)
      .then((res) => {
        if (res.status === 200) {
          this.setState({
            calling: false,
            upcfile: res.data.Message[res.data.Message.length - 1].FilePath
          });
          $("#myupcbtn").trigger("click");
        } else {
          this.setState({ calling: false });
        }
      })
      .catch(function (error) {

        if (error.response) {
          if (error.response.status === 502) {
            toast.error("Bad Request!");
          } else if (error.response.status === 400) {
            toast.error(error.response.data.Message);
          } else {
            toast.error(error.res.data.Message);
          }
        }
      });
  }
  render() {
    //console.log('mr',this.state.mergerretailer)
    var sv = 0;
    for (var i = 0; i < this.state.TotalSales.length - 1; i++) {
      sv = sv + this.state.TotalSales[i].TotalSales;
    }

    var TotalQtySOld = 0;
    for (var j = 0; j < this.state.TotalSales.length - 1; j++) {
      TotalQtySOld = TotalQtySOld + this.state.TotalSales[j].QtySold;
    }

    //var sv =  total;
    // }
    // if (Object.keys(this.state.TotalSales).length>3 ){
    //   var sv =  this.state.TotalSales.reduce((a,v)=>a = a + parseFloat(v.TotalSales), 0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")

    // }else{
    //   var sv =
    // }

    //var TotalQtySOld =  this.state.TotalSales.reduce((a,v)=>a = a + parseFloat(v.QtySold ), 0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")

    const isLogged = ls.get("token");

    if (!isLogged) {
      return <Redirect to="/login" />;
    }
    return (
      <>
        <Header title={this.state.title} />
        <section className="ContentBlock  pt-24 pb-24 Main_Block">
          <div className="container-fluid">
            <div className="row g-3">
              <div className="col-xl-5 col-lg-6 col-12">
                <a
                  href={this.state.file}
                  target="_blank"
                  rel="noopener noreferrer"
                  download
                  style={{ display: "none" }}
                >
                  <Button id="mybtn">Download File</Button>
                </a>

                <a
                  href={this.state.upcfile}
                  target="_blank"
                  rel="noopener noreferrer"
                  download
                  style={{ display: "none" }}
                >
                  <Button id="myupcbtn">Download File</Button>
                </a>

                {this.state.loader ? (
                  <div className={"Common_Block"}>
                    <div className="Clip_Loader Common_Block_Body" style={{ minHeight: "280px" }}>
                      <ClipLoader
                        color={"rgb(0, 143, 251)"}
                        loading={true}
                        size={50}
                      />
                    </div>
                  </div>

                ) : (
                  <div className="Common_Block">
                    <div className="Common_Block_Body">
                      <div className="Coupons_Offer position-relative">
                        <div className="Offer_Badge">
                          <span>
                            {this.state.couponAnalysis.reward_amount
                              ? this.state.couponAnalysis
                                .is_discount_percentage === false
                                ? "$" + this.state.couponAnalysis.reward_amount
                                : this.state.couponAnalysis.reward_amount + "%"
                              : ""}{" "}
                            OFF
                          </span>
                        </div>
                        <div className="Coupons_Head d-flex align-items-center">
                          <div className="Coupons_Image">
                            <img
                              src={
                                this.state.couponAnalysis.image_1
                                  ? this.state.couponAnalysis.image_1
                                  : ""
                              }
                              className="img-fluid"
                              alt="Coupons"
                            />
                          </div>

                          <div className="Coupons_Offer_Info">
                            <h4>
                              {this.state.couponAnalysis.coupon_title
                                ? this.state.couponAnalysis.coupon_title.replace(/\\'/g, "\\'")
                                : ""}
                            </h4>
                            <p>
                              {this.state.couponAnalysis.coupon_description
                                // eslint-disable-next-line no-useless-escape
                                ? this.state.couponAnalysis.coupon_description.replace(/\\'/g, "\'")
                                : ""}
                            </p>

                            <h3>
                              {this.state.couponsDetails.reward_amount
                                ? this.state.couponAnalysis
                                  .is_discount_percentage === false
                                  ? "$ " +
                                  this.state.couponAnalysis.reward_amount
                                  : this.state.couponAnalysis.reward_amount +
                                  " %"
                                : ""}{" "}
                              OFF
                            </h3>
                          </div>
                        </div>

                        <div className="Coupons_Foot flex-wrap">
                          <div className="Coupons_Date mb-5">
                            <img
                              src={CalenderSvgImage}
                              className="img-fluid"
                              alt="Calender"
                            />
                            <span>
                              Start:{" "}
                              {this.state.couponAnalysis.coupon_start_date
                                ? this.handleDateSecond(
                                  new Date(
                                    this.state.couponAnalysis.coupon_start_date
                                  )
                                )
                                : ""}
                            </span>
                          </div>
                          <div className="Coupons_Date mb-5">
                            <img
                              src={CalenderSvgRedImage}
                              className="img-fluid"
                              alt="Calender"
                            />
                            <span>
                              Expires:{" "}
                              {this.state.couponAnalysis.coupon_end_date
                                ? this.handleDateSecond(
                                  new Date(
                                    this.state.couponAnalysis.coupon_end_date
                                  )
                                )
                                : ""}
                            </span>
                          </div>
                          <div className="Coupons_Date mb-5">
                            <img
                              src={CalenderSvgRedImage}
                              className="img-fluid"
                              alt="Calender"
                            />
                            <span>
                              ShutOff:{" "}
                              {this.state.couponAnalysis.coupon_shutoff_date
                                ? this.handleDateSecond(
                                  new Date(
                                    this.state.couponAnalysis.coupon_shutoff_date
                                  )
                                )
                                : ""}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div className="col-xl-7 col-lg-6 col-12 mb-8">
                <div className="Common_Block">
                  <div className="Common_Block_Header d-flex align-items-center justify-content-between">
                    <h5 className="">Coupon Performance Summary</h5>
                  </div>

                  <div className="Common_Block_Body">
                    <div className="Coupons_Summery">
                      <div className="Summery_Count row justify-content-between g-1 align-items-center">
                        {this.state.loader ?
                          <div className="Clip_Loader" style={{ minHeight: "100px" }}>
                            <ClipLoader
                              color={"rgb(0, 143, 251)"}
                              loading={true}
                              size={50}
                            />
                          </div>
                          :
                          <React.Fragment>
                            <ul className="col-xxl-3 col-lg-6 col-12">
                              {this.state.target_type_id === 1 ? (
                                <>
                                  <li key={1}>
                                    <span>Clips</span>
                                    <span>
                                      {this.state.couponAnalysis.wholesalers
                                        ? this.state.couponAnalysis.wholesalers.reduce(
                                          (a, v) =>
                                            (a = a + v.current_clip_total),
                                          0
                                        )
                                        : "0"}{" "}
                                    </span>
                                  </li>
                                  <li key={2}>
                                    <span>Redemptions</span>
                                    <span className="ml-12 ">
                                      {this.state.couponAnalysis.wholesalers
                                        ? this.state.couponAnalysis.wholesalers.reduce(
                                          (a, v) =>
                                            (a = a + v.current_redeem_total),
                                          0
                                        )
                                        : "0"}
                                    </span>
                                  </li>
                                </>
                              ) : (
                                <>
                                  <li key={3}>
                                    <span>Clips</span>
                                    <span>
                                      {this.state.mergerretailer
                                        ? this.state.mergerretailer.reduce(
                                          (a, v) =>
                                          (a =
                                            parseInt(a) +
                                            parseInt(v.current_clip_total)),
                                          0
                                        )
                                        : "0"}
                                    </span>
                                  </li>

                                  <li key={4}>
                                    <span>Redemptions</span>
                                    <span className="ml-12">
                                      {this.state.mergerretailer
                                        ? this.state.mergerretailer.reduce(
                                          (a, v) =>
                                          (a =
                                            a +
                                            parseInt(v.current_redeem_total)),
                                          0
                                        )
                                        : "0"}
                                    </span>
                                  </li>
                                </>
                              )}
                            </ul>

                            <div className="col-xxl-4 col-lg-6 col-12">
                              <ul>
                                <li className="" key={3}>
                                  <span>Total Units Sold </span>
                                  <span>
                                    {this.state.TotalSales
                                      ? TotalQtySOld.toString().replace(
                                        /\B(?=(\d{3})+(?!\d))/g,
                                        ","
                                      )
                                      : "0"}
                                  </span>
                                </li>

                                <li className="" key={4}>
                                  <span>Total Sales </span>
                                  <span>
                                    {this.state.TotalSales
                                      ? "$" +
                                      sv.toFixed(2)
                                        .toString()
                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                      : "0"}
                                  </span>
                                </li>
                              </ul>
                            </div>

                            <div className="col-xxl-5 col-12">

                              {
                                this.state.TotalSales === 0 ?
                                  <button type="button" class="Btn_gray w-auto btn" disabled={true}> Download UPC Breakdown</button>
                                  :
                                  <Button
                                    variant=""
                                    type="button"
                                    className=" Btn_blue w-auto"
                                    data-action={this.state.target_type_id}
                                    onClick={(e) => this.downloadupcbreakdown(e)}
                                  >
                                    Download UPC Breakdown
                                  </Button>
                              }

                              <small className="mt-5 d-block text-start">
                                * Only Coupon UPCs are included
                              </small>
                              <br />
                            </div>
                          </React.Fragment>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>


              {this.state.couponAnalysis ? (
                <div className="col-12">
                  {this.state.target_type_id === 1 ? (
                    this.state.couponAnalysis.wholesalers.map((val, idx) => {

                      return (
                        <div className="Campaign_Details_Card mb-20">
                          <div className="Campaign_Details_Card_Header d-flex align-items-center justify-content-between">
                            <div className="d-flex align-items-center ">
                              <div className="Campaign_Logo">
                                <img
                                  src={val.logo}
                                  className="img-fluid "
                                  alt="campaign"
                                />
                              </div>
                              <h5 className="ml-16">{val.wholesaler_name}</h5>
                            </div>

                            <div className="Product d-flex align-items-center flex-wrap">
                              {val.current_clip_total === 0 ?
                                <Button
                                  variant=""
                                  type="button"
                                  className=" Btn_gray btn mr-15 w-auto"
                                  disabled={true} style={{ padding: '7px 15px', fontSize: '14px' }}
                                >
                                  Download Clips Report
                                </Button>
                                :
                                <Button
                                  variant=""
                                  type="button"
                                  className=" Btn_blue mr-15 w-auto"
                                  data-target_type={this.state.target_type_id}
                                  data-id={val.wholesaler_id}
                                  onClick={(e) => this.couponClipreport(e)}
                                >
                                  Download Clips Report
                                </Button>
                              }
                              {val.current_redeem_total === 0 ?
                                <Button
                                  variant=""
                                  type="button"
                                  className=" Btn_gray btn w-auto"
                                  disabled={true} style={{ padding: '7px 15px', fontSize: '14px' }}
                                > Download Redemptions Report
                                </Button>
                                :

                                <Button
                                  variant=""
                                  type="button"
                                  className=" Btn_blue w-auto"
                                  data-target_type={this.state.target_type_id}
                                  data-id={val.wholesaler_id}
                                  onClick={(e) => this.couponRedemptionreport(e)}
                                >
                                  Download Redemptions Report
                                </Button>
                              }
                            </div>
                            {/* <span> */}
                            {/* <img src={Plus} className="img-fluid " alt="Plus" /> */}
                            {/* </span> */}
                          </div>

                          <div className="Campaign_Details_Card_Body Details_Block">
                            <ul>
                              <li>
                                <div className="d-flex align-items-center Title_Block">
                                  <h5> Start Date</h5>
                                  <img
                                    src={Error}
                                    className="img-fluid ml-5"
                                    alt="Error"
                                  />
                                </div>

                                <div className="Content_Block">
                                  <div className="Date_Picker">
                                    <input
                                      type="date"
                                      name="campaign_start_date"
                                      id="campaign_start_date"
                                      defaultValue={this.handleDate(
                                        new Date(val.wholesaler_start_date)
                                      )}
                                      disabled="disabled"
                                    />
                                  </div>
                                </div>
                              </li>

                              <li>
                                <div className="d-flex align-items-center Title_Block">
                                  <h5>Expiration Date</h5>
                                  <img
                                    src={Error}
                                    className="img-fluid ml-5"
                                    alt="Error"
                                  />
                                </div>
                                <div className="Content_Block">
                                  <div className="Date_Picker">
                                    <input
                                      type="date"
                                      name="camp_end_date"
                                      id="campaign_end_date"
                                      defaultValue={this.handleDate(
                                        new Date(val.wholesaler_end_date)
                                      )}
                                      disabled="disabled"
                                    />
                                  </div>
                                </div>
                              </li>

                              <li>
                                <div className="d-flex align-items-center Title_Block">
                                  <h5>Shutoff Date</h5>
                                  <img
                                    src={Error}
                                    className="img-fluid ml-5"
                                    alt="Error"
                                  />
                                </div>

                                <div className="Content_Block">
                                  <div className="Date_Picker">
                                    <input
                                      type="date"
                                      name="shut_off_date"
                                      defaultValue={this.handleDate(
                                        new Date(val.wholesaler_shutoff_date)
                                      )}
                                      disabled="disabled"
                                    />
                                  </div>
                                </div>
                              </li>

                              <li>
                                <div className="d-flex align-items-center Title_Block">
                                  <h5>Max Clips</h5>
                                  <img
                                    src={Error}
                                    className="img-fluid ml-5"
                                    alt="Error"
                                  />
                                </div>
                                <div className="Content_Block">
                                  <input
                                    type="text"
                                    name="clip_limit"
                                    defaultValue={
                                      val.clip_limit ? val.clip_limit : 0
                                    }
                                    disabled="disabled"
                                  />
                                </div>
                              </li>

                              <li>
                                <div className="d-flex align-items-center Title_Block">
                                  <h5>Clips</h5>
                                  <img
                                    src={Error}
                                    className="img-fluid ml-5"
                                    alt="Error"
                                  />
                                </div>
                                <div className="Content_Block">
                                  <input
                                    type="text"
                                    name="clips"
                                    defaultValue={
                                      val.current_clip_total
                                        ? val.current_clip_total
                                        : 0
                                    }
                                    disabled="disabled"
                                  />
                                </div>
                              </li>
                              <li>
                                <div className="d-flex align-items-center Title_Block">
                                  <h5>Redemptions</h5>
                                  <img
                                    src={Error}
                                    className="img-fluid ml-5"
                                    alt="Error"
                                  />
                                </div>
                                <div className="Content_Block">
                                  <input
                                    type="text"
                                    name="redemptions"
                                    defaultValue={
                                      val.current_redeem_total
                                        ? val.current_redeem_total
                                        : 0
                                    }
                                    disabled="disabled"
                                  />
                                </div>
                              </li>

                              <li>
                                <div className="d-flex align-items-center Title_Block">
                                  <h5>Units Sold</h5>
                                  <img
                                    src={Error}
                                    className="img-fluid ml-5"
                                    alt="Error"
                                  />
                                </div>
                                <div className="Content_Block">
                                  <input
                                    type="text"
                                    name="redemptions"
                                    defaultValue={val.QtySold ? val.QtySold : 0}
                                    disabled="disabled"
                                  />
                                </div>
                              </li>

                              <li>
                                <div className="d-flex align-items-center Title_Block">
                                  <h5>Sales ($)</h5>
                                  <img
                                    src={Error}
                                    className="img-fluid ml-5"
                                    alt="Error"
                                  />
                                </div>
                                <div className="Content_Block">
                                  <input type="text"
                                    name="sales"

                                    defaultValue={Object.keys(this.state.TotalSalesQtydetails).length > 0 ?

                                      this.state.wholesaler_identifier.includes(parseInt(val.wholesaler_number)) ?

                                        '$' + parseFloat(this.state.TotalSalesQtydetails[val.wholesaler_number][0].TotalSales.replace(/,/g, '').replace(/,/g, '').substring(1)).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                        : ''

                                      : ''}


                                    disabled="disabled"
                                  />
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      )
                    })
                  ) : this.state.retailer_array ? (
                    <>
                      {this.state.retailer_array.map((val, idx) => {
                        return val.map((retailer, key) => {
                          return (
                            <div className="Campaign_Details_Card mb-20">
                              <div className="Campaign_Details_Card_Header d-flex align-items-center justify-content-between">
                                <div className="d-flex align-items-center ">
                                  <div className="Campaign_Logo">
                                    <img
                                      src={retailer.logo}
                                      className="img-fluid "
                                      alt="campaign"
                                    />
                                  </div>

                                  <h5 className="ml-16">
                                    {retailer.retailer_name}
                                  </h5>
                                </div>

                                <div className="Product d-flex align-items-center flex-wrap">

                                  {retailer.current_clip_total === 0 ? <Button
                                    variant=""
                                    type="button"
                                    className=" Btn_gray btn mr-15 w-auto"
                                    disabled={true} style={{ padding: '7px 15px', fontSize: '14px' }}
                                  >
                                    Download Clips Report
                                  </Button> :

                                    <Button
                                      variant=""
                                      type="button"
                                      className=" Btn_blue mr-15 w-auto"
                                      data-target_type={this.state.target_type_id}
                                      data-id={retailer.retailer_id}
                                      onClick={(e) => this.couponClipreport(e)}
                                    >
                                      Download Clips Report
                                    </Button>
                                  }

                                  {retailer.current_redeem_total === 0 ?

                                    <Button
                                      variant=""
                                      type="button"
                                      className=" Btn_gray btn w-auto"
                                      disabled={true} style={{ padding: '7px 15px', fontSize: '14px' }}
                                    >
                                      Download Redemptions Report
                                    </Button>
                                    :
                                    <Button
                                      variant=""
                                      type="button"
                                      className=" Btn_blue w-auto"
                                      data-target_type={this.state.target_type_id}
                                      data-id={retailer.retailer_id}
                                      onClick={(e) =>
                                        this.couponRedemptionreport(e)
                                      }
                                    >
                                      Download Redemptions Report
                                    </Button>
                                  }
                                </div>
                              </div>

                              <div className="Campaign_Details_Card_Body Details_Block">
                                <ul>
                                  <li>
                                    <div className="d-flex align-items-center Title_Block">
                                      <h5> Start Date</h5>
                                      <img
                                        src={Error}
                                        className="img-fluid ml-5"
                                        alt="Error"
                                      />
                                    </div>

                                    <div className="Content_Block">
                                      <div className="Date_Picker">
                                        <input
                                          type="date"
                                          name="campaign_start_date"
                                          id="campaign_start_date"
                                          defaultValue={this.handleDate(
                                            new Date(retailer.start_date)
                                          )}
                                          disabled="disabled"
                                        />
                                      </div>
                                    </div>
                                  </li>

                                  <li>
                                    <div className="d-flex align-items-center Title_Block">
                                      <h5>Expiration Date</h5>
                                      <img
                                        src={Error}
                                        className="img-fluid ml-5"
                                        alt="Error"
                                      />
                                    </div>
                                    <div className="Content_Block">
                                      <div className="Date_Picker">
                                        <input
                                          type="date"
                                          name="camp_end_date"
                                          id="campaign_end_date"
                                          defaultValue={this.handleDate(
                                            new Date(retailer.end_date)
                                          )}
                                          disabled="disabled"
                                        />
                                      </div>
                                    </div>
                                  </li>

                                  <li>
                                    <div className="d-flex align-items-center Title_Block">
                                      <h5>Shutoff Date</h5>
                                      <img
                                        src={Error}
                                        className="img-fluid ml-5"
                                        alt="Error"
                                      />
                                    </div>

                                    <div className="Content_Block">
                                      <div className="Date_Picker">
                                        <input
                                          type="date"
                                          name="shut_off_date"
                                          defaultValue={this.handleDate(
                                            new Date(retailer.shutoff_date)
                                          )}
                                          disabled="disabled"
                                        />
                                      </div>
                                    </div>
                                  </li>

                                  <li>
                                    <div className="d-flex align-items-center Title_Block">
                                      <h5>Max Clips</h5>
                                      <img
                                        src={Error}
                                        className="img-fluid ml-5"
                                        alt="Error"
                                      />
                                    </div>
                                    <div className="Content_Block">
                                      <input
                                        type="text"
                                        name="clip_limit"
                                        defaultValue={
                                          retailer.clip_limit
                                            ? retailer.clip_limit
                                            : 0
                                        }
                                        disabled="disabled"
                                      />
                                    </div>
                                  </li>

                                  {/* <li >
                      <div className="d-flex align-items-center Title_Block">
                        <h5>Max Redemptions</h5>
                        <img
                          src={Error}
                          className="img-fluid ml-5"
                          alt="Error"
                        />
                      </div>
                      <div className="Content_Block">
                        <input type="text" defaultValue={0} name="redeem_limit" defaultValue={retailer.redeem_limit} disabled="disabled" />
          
                      </div>
                    </li> */}
                                  <li>
                                    <div className="d-flex align-items-center Title_Block">
                                      <h5>Clips</h5>
                                      <img
                                        src={Error}
                                        className="img-fluid ml-5"
                                        alt="Error"
                                      />
                                    </div>
                                    <div className="Content_Block">
                                      <input
                                        type="text"
                                        name="clips"
                                        defaultValue={
                                          retailer.current_clip_total
                                            ? retailer.current_clip_total
                                            : 0
                                        }
                                        disabled="disabled"
                                      />
                                    </div>
                                  </li>
                                  <li>
                                    <div className="d-flex align-items-center Title_Block">
                                      <h5>Redemptions</h5>
                                      <img
                                        src={Error}
                                        className="img-fluid ml-5"
                                        alt="Error"
                                      />
                                    </div>
                                    <div className="Content_Block">
                                      <input
                                        type="text"
                                        name="redemptions"
                                        defaultValue={
                                          retailer.current_redeem_total ? retailer.current_redeem_total : 0
                                        }
                                        disabled="disabled"
                                      />
                                    </div>
                                  </li>

                                  <li>
                                    <div className="d-flex align-items-center Title_Block">
                                      <h5>Units Sold</h5>
                                      <img
                                        src={Error}
                                        className="img-fluid ml-5"
                                        alt="Error"
                                      />
                                    </div>
                                    <div className="Content_Block">
                                      <input
                                        type="text"
                                        name="redemptions"
                                        defaultValue={retailer.QtySold ? retailer.QtySold : 0}
                                        disabled="disabled"
                                      />
                                    </div>
                                  </li>

                                  <li>
                                    <div className="d-flex align-items-center Title_Block">
                                      <h5>Sales ($)</h5>
                                      <img
                                        src={Error}
                                        className="img-fluid ml-5"
                                        alt="Error"
                                      />
                                    </div>
                                    <div className="Content_Block">

                                      <input
                                        type="text"
                                        name="salesh"

                                        defaultValue={Object.keys(this.state.TotalSalesQtydetails).length > 0 ?

                                          this.state.retailer_rsa_identifier.includes(parseInt(retailer.rsa_identifier)) ?
                                            '$' + parseFloat(this.state.TotalSalesQtydetails[retailer.rsa_identifier][0].TotalSales.replace(/,/g, '').replace(/,/g, '').substring(1)).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                            : ''

                                          : ''}

                                        disabled={true}
                                      />
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          );
                        });
                      })}
                    </>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                <div className="Clip_Loader Campaign_Details_Card " style={{ minHeight: "280px" }}>
                  <ClipLoader
                    color={"rgb(0, 143, 251)"}
                    loading={true}
                    size={50}
                  />
                </div>
              )}

              <div className="col-12 mt-22">
                <div className="Common_Block Wholesaler">
                  <div className="Wholesaler_Header">
                    {this.state.target_type_id === 1 ? (
                      <h5>Coupon Clips/Redemptions/Sold Qty By Wholesaler</h5>
                    ) : (
                      <h5>Coupon Clips/Redemptions/Sold Qty By Retailer</h5>
                    )}
                  </div>
                  <div className="Wholesaler_Body pt-20">
                    <ClipsChart
                      loader={this.state.loader}
                      dataParentToChild={this.state.couponAnalysisgraph}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <FooterStrict />
      </>
    );
  }
}
export default CouponsDetails;
