import React from "react";
import Content from "./Content";

import ls from "local-storage";
import "react-toastify/dist/ReactToastify.css";
import { Redirect } from "react-router";
import FooterStrict from "./FooterStrict";
import CompanyModel from "./CompanyModal";

class Dashboard extends React.Component {
  render() {
    const isLogged = ls.get("token");
    const is_consent_provided = ls.get("decodetoken")
      ? ls.get("decodetoken").is_consent_provided
      : "";
    if (!isLogged || is_consent_provided === false) {
      return <Redirect to="/login" />;
    }
    return (
      <React.Fragment>
        <Content />
        <FooterStrict />
      </React.Fragment>
    );
  }
}

export default Dashboard;
