import React from "react";
import WhHeader from "../../../Components/Wholesaler/layout/header";
import Header from "../Header";

import CardImage from "../../../Assets/images/card.svg";
import { Link, useParams } from "react-router-dom";

import FooterStrict from "../FooterStrict";

function CouponConfirmationMessage() {
  const { slug } = useParams();
  return (
    <>
      {slug === "whcoupon" ? <WhHeader /> : <Header />}

      <section className="ContentBlock pt-24 pb-24 Main_Block Confirmation_Message ">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="Common_Block">
                <div className="Common_Block_Body pr-62 pl-62 pt-62 pb-62 radius-5 text-center CelebrationBg">
                  {/* <img src={Celebration} alt="Celebration" className='img-fluid' /> */}
                  <h5>
                  Coupon list page will take <span style={{color:"#3D8ECC", fontWeight:"700", fontSize:"25px"}}>4-5 minutes</span> to display this newly created coupons
                  </h5>
                  {/* {slug === "whcoupon" ? <h4>2 hours</h4> : <h4>24 hours</h4>} */}
                  <img src={CardImage} alt="" className="img-fluid mt-30" />
                  <ul className="d-flex CountDown align-items-center justify-content-center mt-25"></ul>
                  {slug === "whcoupon" ? (
                    <Link to="/wholesalerlist">
                      <button className="Btn_blue mt-20">
                        Get To My Coupons
                      </button>
                    </Link>
                  ) : (
                    <Link to="/coupons">
                      <button className="Btn_blue mt-20">
                        Get To My Coupons
                      </button>
                    </Link>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <FooterStrict />
    </>
  );
}

export default CouponConfirmationMessage;
