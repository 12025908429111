import React from "react";
import { Link } from "react-router-dom";
import Retailer from "../../../Assets/images/retailer.svg";
import Upcs from "../../../Assets/images/upcs.svg";
import wholesalers from "../../../Assets/images/wholesalers.svg";
import Stores from "../../../Assets/images/stores.svg";
import Sold from "../../../Assets/images/sold.svg";
import ClipLoader from "react-spinners/ClipLoader";


const CompanyOverview = ({loader, companyStore, companyData }) => {
    var TotalSales = 0;
    for (var i = 0; i < companyData.GUS.length; i++) {
        TotalSales = TotalSales + parseFloat(companyData.GUS[i].TotalSales.replace(/,/g, '').substring(1));
    }
    return (

        loader ? (
            <div className="Clip_Loader" style={{ minHeight: "280px" }}>
              <ClipLoader color={"rgb(0, 143, 251)"} loading={true} size={50} />
            </div>
      
          ) :

        companyData ? (
            <ul className="OverviewList">
                <li className="newOverview">
                    <div className="Icon">
                        <img src={Retailer} className="img-fluid" alt="Retailer" />
                    </div>
                    <div className="OverviewList_Info">
                        <p>Retailers</p>
                        <span>{companyData ?
                            companyData.Overview.Retailers
                            : ''
                        }
                        </span>
                    </div>
                </li>
                <li className="newOverview">
                    <div className="Icon">
                        <img src={Upcs} className="img-fluid" alt="Retailer" />
                    </div>
                    <div className="OverviewList_Info">
                        <p>UPCs</p>
                        <span>
                            <Link to='/companyupc'>{companyData ?
                                companyData.Overview.UPCs
                                : ''
                            } 
                            
                            </Link>
                        </span>
                    </div>
                </li>
                <li className="newOverview">
                    <div className="Icon">
                        <img src={Sold} className="img-fluid" alt="Retailer" />
                    </div>
                    <div className="OverviewList_Info">
                        <p>Units Sold </p>
                        <span>
                 
                            {companyData?.Overview?.UnitsSold.toLocaleString()}
                        </span>
                    </div>
                </li>
                <li className="newOverview">
                    <div className="Icon">
                        <img src={wholesalers} className="img-fluid" alt="Retailer" />
                    </div>
                    <div className="OverviewList_Info">
                        <p>Wholesalers</p>
                        <span>
                            {companyData ?
                                companyData.Overview.Wholesalers
                                : ''
                            }
                        </span>
                    </div>
                </li>
                <li className="newOverview">
                    <div className="Icon">
                        <img src={Stores} className="img-fluid" alt="Retailer" />
                    </div>
                    <div className="OverviewList_Info">
                        <p>Stores</p>
                        <span>
                            {companyData ?
                                companyData.Overview.Stores : ''}
                        </span>
                        <p style={{ fontSize: "11px" }}>Selling UPCs</p>
                    </div>
                </li>
                <li className="newOverview">
                    <div className="Icon">
                        <img src={Stores} className="img-fluid" alt="Retailer" />
                    </div>
                    <div className="OverviewList_Info">
                        <p> Stores</p>
                        <span>
                            {companyStore
                                ? companyStore.SubscribedStores
                                : ""}
                        </span>
                        <p style={{ fontSize: "11px" }}>Subscribed</p>
                    </div>
                </li>
            </ul>
        ) : (
            ""
        )
    )
};

export default CompanyOverview;